import React, { useEffect, useMemo, useRef } from "react";
import { useGetCreatorPosts } from "../../../api/creator/service";
import { Center, Flex, Text, createStyles } from "@mantine/core";
import MediaCard from "./MediaCard";
import FetchLoader from "../loading/FetchLoader";
import "./styles.css";

const Media = ({ creatorName, translation }: any) => {
  const { data: creatorPosts,isFetchingNextPage,fetchNextPage, isLoading }: any =
    useGetCreatorPosts(creatorName);
  const { classes } = useStyles();
  const divRef: any = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (
        !isFetchingNextPage &&
        divRef.current &&
        Math.abs(divRef?.current?.scrollTop) +
          0.5 +
          divRef.current?.clientHeight >=
          divRef.current?.scrollHeight
      ) {
        fetchNextPage();
      }
    };
    const currentDivRef: any = divRef?.current;

    currentDivRef.addEventListener("scroll", handleScroll);
    if (currentDivRef) {
    }
    return () => {
      if (currentDivRef) {
        currentDivRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, [divRef.current]);

  const flattenedData: any = useMemo(
    () =>
      creatorPosts
        ? creatorPosts?.pages?.flatMap((item: any) => item.data.creatorPosts)
        : [],
    [creatorPosts]
  );

  return (
    <div ref={divRef} className={`${classes.media_area} scroll_area`}>
      {flattenedData.length === 0 && !isLoading && (
        <Center h="100%">
          <Text fw={800} opacity=".5" color="white">
            {translation.not_have_audio}
          </Text>
        </Center>
      )}
      {isLoading && (
        <Center h="100%">
          <FetchLoader />
        </Center>
      )}
      {flattenedData !== undefined &&
        flattenedData.map((x: any, i: number) => {
          return (
            <Flex key={i} direction="column">
              <MediaCard closeInfo={true} data={x} />
            </Flex>
          );
        })}
          <div className={classes.inifinty_loading_area}>
        {isFetchingNextPage && <FetchLoader />}
      </div>
    </div>
  );
};
const useStyles = createStyles((theme) => ({
  media_area: {
    height: "100%",
    width: "100%",
    padding: 20,
    paddingBottom: 200,
  },
  inifinty_loading_area: {
    paddingTop: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
export default Media;

import { getToken } from "../../utils/auth/auth";
import restClient from "../restClient";

export const purchaseItemFn = async (itemId: number, isNew: boolean) => {
  const token = await getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let data = {};

  try {
    await restClient
      .post("/pay-assets", { post: itemId, isNew: isNew }, config)
      .then(async (res) => {
        data = res
      })
      .catch((err) => {
        console.log(err);
      });
      return data
  } catch (error) {
    console.log("error", error);
  }
  return {
    data,
  };
};
export const getWallet = async () => {
  try {
    const token = await getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let data = {};
    await restClient
      .get("/account", config)
      .then((res) => {
        data = res.data.data.attributes.wallet
      })
      .catch((err) => {
        console.log(err);
      });
      return {
        ...data,
      };
  } catch (error) {
    console.log("error", error);
  }

};

export const getpaymentLinksFn = async () => {
  const token = await getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let data = {};
  try {
    await restClient
      .get("/payment-link", config)
      .then(async (res) => {
        data = res.data.attributes;
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (error) {
    console.log("error", error);
  }
  return {
    ...data,
  };
};

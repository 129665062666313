import { useMutation } from "@tanstack/react-query";

import { complaintsFn } from "../api";

export const usePostComplaints = () => {
  const {
    data,
    isLoading,
    isError,
    error,
    isSuccess,
    isIdle,
    mutate: postComplaints,
  } = useMutation((data: any) => complaintsFn(data), {
    onSuccess: async (newData: any) => {},
  });

  return { data, isLoading, isIdle, isSuccess, isError, error, postComplaints };
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import {
  Text,
  Title,
  Avatar,
  createStyles,
  Flex,
  Button,
  Image,
  Loader,
  Modal,
} from "@mantine/core";
import WaveAudio from "../waveAudio/WaveAudio";
import LockedWaveAudio from "../waveAudio/LockedWaveAudio";
import { IoIosLock } from "react-icons/io";
import { getBase64FromUrl } from "../../../utils/getBase64FromUrl";
import { Transition } from "@mantine/core";
import { User } from "../../../hooks/user.hook";
import { useDisclosure } from "@mantine/hooks";
import { IoClose } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { usePurchaseVoice } from "../../../api/pay/service";
import { RxDotsHorizontal } from "react-icons/rx";

const CreatorCard = ({
  data,
  profile = false,
  closeInfo = false,
}: any) => {
  const { payItem, isLoading, isSuccess } = usePurchaseVoice(
    data?.creatorProfile?.username
  );
  const { classes } = useStyles();
  const [openNoMondayModal, { open, close }] = useDisclosure(false);
  const navigate = useNavigate();
  const [duration, setDuration] = useState(0);
  const [img, setImg] = useState<null | string>(null);
  const [id, setId] = useState<null | number>(null);
  const { user } = User();

  const handleImageLoad = useCallback(async (url: string) => {
    if (!url) return;

    const img = await getBase64FromUrl(url);
    if (img && typeof img === "string") {
      setImg(img);
    }
  }, []);

  useEffect(() => {
    if (!data?.creatorProfile?.avatarThumbC144?.c144) return;
    handleImageLoad(data?.creatorProfile?.avatarThumbC144?.c144);
  }, [data.creatorProfile, handleImageLoad]);

  const payPost = async (payData: any) => {
    if (user?.attributes?.wallet?.balance >= payData?.attributes?.price) {
      setId(payData.id);
      await payItem({ itemId: payData.id, isNew: false });
    } else {
      open();
    }
  };

  return (
    <>
      <Modal
        opened={openNoMondayModal}
        classNames={{
          body: classes.modal_body,
          inner: classes.modal_inner,
        }}
        onClose={close}
        withCloseButton={false}
        centered
      >
        <button onClick={close} className={classes.close}>
          <IoClose color="white" size={25} />
        </button>
        <Flex align="center" justify="center">
          <div className={classes.delete_image}>
            <Image
              height="100%"
              fit="contain"
              src={require("../../../assets/payment/wallet.png")}
            />
          </div>
        </Flex>
        <Flex direction="column" align="center">
          <Text
            w="80%"
            align="center"
            className={classes.delete_modal_text}
            color="white"
          >
            You have insufficient balance, please buy
          </Text>
          <Button
            className={classes.yes_delete_button}
            onClick={() => navigate("/my/payment")}
          >
            Buy
          </Button>
        </Flex>
      </Modal>
      <div className={classes.card} style={{ marginBottom:data.isPaid? 14: 30 }}>
        <Flex
          direction="row"
          className={classes.flex}
          align={data.isPaid && !profile ? "start" : "center"}
        >
          <div
            className={`${classes.avatar_area} `}
          >
            <Transition
              mounted={img !== null}
              transition="fade"
              duration={600}
              timingFunction="ease"
            >
              {(styles) => (
                <Avatar
                  style={styles}
                  classNames={{
                    image: classes.creator_image,
                    root: classes.avatar,
                  }}
                  opacity={!data.isPaid && !profile ? 0.5 : 1}
                  variant="outline"
                  radius={20}
                  src={img}
                  alt="avatar image"
                />
              )}
            </Transition>

            {!data.isPaid && !profile && (
              <div className={classes.lock_icon}>
                <IoIosLock size={"100%"} color="white" />
              </div>
            )}
          </div>

          <Flex
            className={classes.content_area}
            justify={!data.isPaid ? "start" : "space-between"}
            direction="column"
          >
            {!closeInfo && (
              <Flex direction="row" justify="space-between">
                <Flex direction="column" className={classes.creator_info}>
                  <Title color="white" size={17}>
                    {data?.creatorProfile?.name}
                  </Title>
                  <Text color="#C6CCD2" size={13} ta="left">
                    {"@" + data?.creatorProfile?.username}
                  </Text>
                </Flex>
                {profile && (
                  <Link
                    className={classes.edit_button}
                    to={`/my/post/edit/${data.id}`}
                  >
                    <RxDotsHorizontal color="white" size={20} />
                  </Link>
                )}
              </Flex>
            )}
            <Flex w="100%" align={"center"} justify={"space-between"} direction="row" >
              <Text
                color={!data.isPaid ? "rgba(255, 255, 255, 0.3)" : "white"}
                mt={7}
                ml={10}
                mb={5}
                fz={16}
                className={classes.creator_desc}
              >
                {data?.attributes.text}
              </Text>
              {profile && (
                <Link
                  className={classes.edit_button_mobile}
                  to={`/my/post/edit/${data.id}`}
                >
                  <RxDotsHorizontal color="white" size={20} />
                </Link>
              )}
            </Flex>
            {!data.isPaid && !profile && (
              <LockedWaveAudio
                peaks={data?.attributes?.assetData?.peaks}
                duration={data?.attributes?.assetData?.duration}
                setDuration={setDuration}
              />
            )}

            {(profile || (data?.attributes?.assets?.audio && data.isPaid)) && (
              <WaveAudio
                setDuration={setDuration}
                playSound={data.playSound}
                isFirstPlay={data.isFirstPlay}
                peaks={data?.attributes?.assetData?.peaks}
                duration={data?.attributes?.assetData?.duration}
                url={data?.attributes?.assets?.audio?.url}
                id={data.id}
              />
            )}

            {!data.isPaid && !profile && (
              <div className={classes.buy_button_area}>
                <Button
                  onClick={() => payPost(data)}
                  disabled={data.id === id && (isLoading || isSuccess)}
                  className={classes.buy_button}
                >
                  {(isLoading || isSuccess) && data.id === id ? (
                    <Loader variant="dots" color="black" />
                  ) : (
                    <>
                      {"Buy  " + data?.attributes?.price}{" "}
                      <Image
                        classNames={{ image: classes.star_image }}
                        ml={8}
                        src={require("../../../assets/profile_start.png")}
                      />
                    </>
                  )}
                </Button>
              </div>
            )}
          </Flex>
        </Flex>
      </div>
      {/*<AudioPlayer
        audioUrl={data?.attributes?.assets?.audio?.url}
        peaks={data?.attributes?.assetData?.peaks}
        duration={data?.attributes?.assetData?.duration}
      /> */}
    </>
  );
};
const useStyles = createStyles((theme) => ({
  card: {
    background: "rgba(255, 255, 255, 0.2)",
    padding: "14px 12px",
    borderRadius: 20,
    [`@media (max-width: 500px)`]: {
      position: "relative",
      padding: "4px 10px",
    },
  },
  creator_image: {
    objectFit: "cover",
    objectPosition: "50% 0%",
  },
  avatar: {
    height: "103px",
    width: "98px",
  },
 
  avatar_area: {
    height: "103px",
    position: "relative",
    width: "98px",
   
  },
  avatar_area_close_info: {
    height: "100%",
    position: "relative",
    width: 103,
    [`@media (max-width: 500px)`]: {
      width: 98,
    },
  },
  lock_icon: {
    position: "absolute",
    right: -20,
    bottom: -7,
    width: 60,
    height: 60,
    [`@media (max-width: 500px)`]: {
      width: 40,
    },
  },
  flex: {
    [`@media (max-width: 500px)`]: {
      alignItems: "center",
    },
  },
  content_area: {
    marginLeft: 18,
    width: "100%",
    position:"relative"
   
  },
  creator_info: {
    [`@media (max-width: 500px)`]: {
      display: "none",
    },
  },
  edit_button: {
    [`@media (max-width: 500px)`]: {
      display: "none",
    },
  },
  edit_button_mobile:{
    display: "none",
    [`@media (max-width: 500px)`]: {
      display: "flex",
    },
  },
  creator_desc: {},
  buy_button_area: {
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    bottom: -30,
    right: 0,
    [`@media (max-width: 500px)`]: {
    bottom: -20,
    },
  },
  buy_button: {
    background: "linear-gradient(90deg, #D2E951 0%, #799F0C 100%)",
    border: 0,
    borderRadius: 70,
    width: 156,
    marginTop: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 20,
    height: 37,
    [`@media (max-width: 500px)`]: {
      width: 111,
      height: 35,
      fontSize: 16,
    },
  },
  star_image: {
    width: "30px !important",
    height: "30px !important",
    [`@media (max-width: 500px)`]: {
      width: "22px !important",
      height: "22px !important",
    },
  },
  modal_body: {
    backgroundColor: theme.colors.blue[0],
    padding: "45px",
    [`@media (max-width: 768px)`]: {
      borderRadius: "33px",
    },
    [`@media (max-width: 500px)`]: {
      borderRadius: "33px 33px 0px 0px",
    },
  },
  modal_inner: {
    [`@media (max-width: 500px)`]: {
      padding: 0,
      alignItems: "flex-end",
    },
  },
  yes_delete_button: {
    backgroundColor: theme.colors.green,
    color: "black",
    width: 220,
    height: 50,
    borderRadius: "50px",
    transition: "all .2s",
    "&:hover": {
      transition: "all .5s",
      transform: "scale(1.1)",
    },
    [`@media (max-width: 500px)`]: {
      width: "100%",
    },
  },
  delete_image: {
    width: 140,
    [`@media (max-width: 500px)`]: {
      width: 88,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  delete_modal_text: {
    marginBottom: 30,
    marginTop: 30,
    [`@media (max-width: 500px)`]: {
      marginBottom: 10,
      marginTop: 10,
    },
  },
  close: {
    position: "absolute",
    zIndex: 999,
    right: 30,
    top: 30,
    backgroundColor: "transparent",
    border: "0px",
    padding: 0,
    cursor: "pointer",
  },
}));

export default CreatorCard;

import React, { useEffect, useState } from "react";
import { useGetStatistics, usePayout } from "../../../api/statistics/service";
import WaveAudio from "../waveAudio/WaveAudio";
import {
  ActionIcon,
  Avatar,
  Button,
  Center,
  Divider,
  Flex,
  Modal,
  Text,
  Title,
  createStyles,
} from "@mantine/core";
import { RiMoneyEuroCircleLine, RiUserVoiceLine } from "react-icons/ri";
import { MdOutlineFileDownload, MdOutlineRemoveRedEye } from "react-icons/md";
import { useParams } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import { AiOutlineClose } from "react-icons/ai";
import { formatProfileDate } from "../../../utils/formatProfileDate";
import { BiUserVoice } from "react-icons/bi";

const Contents = ({
  translation,
  setActiveRight,
  activeRight,
  creatorId,
}: any) => {
  const { data, isLoading, isSuccess }: any = useGetStatistics(creatorId);
  const {
    payout,
    isLoading: loadingPayout,
    isSuccess: successPayout,
  }: any = usePayout();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [duration, setDuration] = useState(0);
  const { statistics_name } = useParams();
  const [arr] = useState([...Array(6)]);
  const { classes } = useStyles();
  const [payoutModal, { open, close }] = useDisclosure(false);

  useEffect(() => {
    if (successPayout) {
      open();
    }
  }, [successPayout]);

  useEffect(() => {
    if (!statistics_name) {
      setActiveRight("");
    }
    if (statistics_name === "contents") {
      setActiveRight(translation.statistics_page.contents);
    }
  }, [statistics_name]);

  const handlePayout = () => {
    payout(creatorId);
  };
  const PayoutModal = () => {
    return (
      <Modal
        classNames={{ body: classes.modal_body }}
        withCloseButton={false}
        overlayProps={{
          opacity: 0.75,
          blur: 5,
        }}
        centered
        size={600}
        zIndex={999}
        opened={payoutModal}
        onClose={close}
      >
        <Flex
          direction="row"
          justify="space-between"
          align="center"
          mb={20}
          className={classes.modal_title}
        >
          <Flex direction="column" justify="center">
            <Title className={classes.name} fz={20} fw="bold" color="white">
              {translation?.statistics_page?.payout}
            </Title>
          </Flex>
          <ActionIcon variant="transparent" mr={5} onClick={close}>
            <AiOutlineClose color="white" size={20} />
          </ActionIcon>
        </Flex>

        <Center>
          <Title fz={20} mt={20} mb={7} color="white">
            {translation.statistics_page.payout_send_success}
          </Title>
        </Center>
      </Modal>
    );
  };
  return (
    <div>
      <PayoutModal />
      {isLoading ? (
        <div className={classes.skeleten} style={{ height: 110 }}></div>
      ) : (
        <div
          style={{
            backgroundColor: "rgba(0,0,0,.1)",
            padding: 10,
            marginBottom: 20,
            borderRadius: 10,
          }}
        >
          <Title color="lightgray">
            {translation.statistics_page.content_analysis}
          </Title>
          <Divider mt={10} color="gray" />
          <Flex justify="space-between" direction="column" mt={10}>
            <Flex className={classes.total_area} gap={20}>
              <Flex align="center" gap={5}>
                <RiUserVoiceLine color="white" size={20} />
                <Text color="white">
                  {translation.statistics_page.total_listen +
                    data?.data?.totalListenCount}
                </Text>
              </Flex>
              <Flex align="center" gap={5}>
                <MdOutlineFileDownload color="white" size={20} />
                <Text color="white">
                  {translation.statistics_page.total_purchase +
                    data?.data?.totalPurchases}
                </Text>
              </Flex>
              <Flex align="center" gap={5}>
                <RiMoneyEuroCircleLine color="white" size={20} />
                <Text color="white">
                  {translation.statistics_page.total_money_won +
                    data?.data?.totalPrice}
                </Text>
              </Flex>
            </Flex>
            <Flex mt={10} mb={10} justify="flex-end">
              <Button
                loading={loadingPayout}
                onClick={handlePayout}
                w="25%"
                color="red"
              >
                {translation.statistics_page.payout}
              </Button>
            </Flex>
          </Flex>
        </div>
      )}

      {isLoading &&
        arr.map((i: number) => (
          <div key={i} className={classes.skeleten}></div>
        ))}
      {data?.data?.response?.map((data: any) => (
        <Flex
          direction="column"
          style={{
            backgroundColor: "rgba(0,0,0,.1)",
            padding: 10,
            marginBottom: 20,
            borderRadius: 10,
          }}
        >
          <Flex>
            <Avatar size={100} src={data?.avatar?.thumbs?.c250} />
            <Flex ml={10} w="100%" direction="column">
              <Text color="white">{data.text}</Text>
              <WaveAudio
                setDuration={setDuration}
                playSound={false}
                isFirstPlay={false}
                peaks={data?.assetData?.peaks}
                duration={data?.assetData?.duration}
                url={data?.assets?.audio?.url}
                id={data.id}
              />
            </Flex>
          </Flex>
          <Divider mt={10} color="gray" />
          <Flex mt={10} direction="row" justify="space-between">
            <>
              <Text color="white">{formatProfileDate(data?.createdAt)}</Text>
            </>
            <Flex gap={15}>
              <Flex align="center" gap={5}>
                <MdOutlineRemoveRedEye color="white" />
                <Text color="white">{data.views}</Text>
              </Flex>
              <Flex align="center" gap={5}>
                <RiUserVoiceLine color="white" />
                <Text color="white">{data.listenCount}</Text>
              </Flex>
              {!data.free && (
                <Flex align="center" gap={5}>
                  <MdOutlineFileDownload color="white" />
                  <Text color="white">{data.payAssetsCount}</Text>
                </Flex>
              )}
              {!data.free && (
                <Flex align="center" gap={5}>
                  <RiMoneyEuroCircleLine color="white" />
                  <Text color="white">{data.totalPrice}</Text>
                </Flex>
              )}
              {data.free && (
                <Text color="white">{translation.statistics_page.free}</Text>
              )}
            </Flex>
          </Flex>
        </Flex>
      ))}
      {data?.data?.response?.length === 0 && isSuccess && (
        <Title align="center" color="gray" mt={10}>
          {translation.statistics_page.have_no_content}
        </Title>
      )}
    </div>
  );
};
const useStyles = createStyles((theme) => ({
  skeleten: {
    background: " linear-gradient(-45deg, #b0b1b1, #8f9191, #6a6b6b, #555555)",
    backgroundSize: "400% 400%",
    borderRadius: 20,
    height: 150,
    marginBottom: 20,
    width: "100%",
    paddingBottom: 20,
    animation: "gradient 2s ease infinite",
    "@keyframes gradient": {
      "0%": {
        backgroundPosition: "0% 50%",
      },
      "50%": {
        backgroundPosition: "100% 50%",
      },
      "100%": {
        backgroundPosition: "0% 50%",
      },
    },
  },
  total_area: {
    [`@media (max-width: 768px)`]: {
      flexDirection: "column",
    },
  },
  modal_body: {
    backgroundColor: theme.colors.blue[0],
    padding: "45px",
    [`@media (max-width: 500px)`]: {
      backgroundColor: "transparent",
      padding: "0px 0px",
    },
  },
  modal_title: {
    position: "sticky",
    top: 0,
    zIndex: 99,
    backgroundColor: theme.colors.blue[0],
    [`@media (max-width: 500px)`]: {
      position: "relative",
      backgroundColor: "transparent",
    },
  },
  name: {
    marginBottom: 0,
    [`@media (max-width: 500px)`]: {
      marginBottom: 8,
    },
  },
}));
export default Contents;

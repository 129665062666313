const SendIcon = ({ active }: any) => {
  // active ? "#D2E951":"#8C8FA5"
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4912 0.866769L10.9919 10.5946C10.9749 10.7078 10.9323 10.8157 10.8672 10.9099C10.8022 11.004 10.7164 11.0821 10.6165 11.1379C10.5166 11.1938 10.4052 11.226 10.2909 11.2321C10.1766 11.2382 10.0624 11.218 9.9571 11.1731L7.08979 9.97474L5.89775 11.762C5.85726 11.8345 5.79809 11.8948 5.72639 11.9366C5.65469 11.9785 5.57308 12.0004 5.49006 12C5.3608 12 5.23684 11.9486 5.14544 11.8572C5.05404 11.7658 5.00269 11.6418 5.00269 11.5126V9.25963C5.00213 9.09302 5.05711 8.93098 5.15894 8.79911L10.2499 2.25247L3.36881 8.44622L0.965076 7.44653C0.831897 7.39103 0.717483 7.29841 0.635474 7.17969C0.553465 7.06097 0.50732 6.92118 0.502541 6.77696C0.490761 6.63667 0.520112 6.49598 0.587004 6.37211C0.653896 6.24823 0.75543 6.14654 0.879192 6.07945L11.3789 0.0988886C11.5012 0.0288689 11.6409 -0.00518584 11.7818 0.000639777C11.9227 0.00646539 12.059 0.0519348 12.1752 0.13182C12.2914 0.211706 12.3827 0.322764 12.4386 0.452227C12.4945 0.58169 12.5127 0.724301 12.4912 0.863664V0.866769Z"
        fill="black"
      />
    </svg>
  );
};

export default SendIcon;

const MembersIcon = ({ active, white }: any) => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.375 11.375C12.3033 11.375 13.1935 11.7437 13.8499 12.4001C14.5063 13.0565 14.875 13.9467 14.875 14.875V16.1875C14.875 16.5356 14.7367 16.8694 14.4906 17.1156C14.2444 17.3617 13.9106 17.5 13.5625 17.5H3.0625C2.7144 17.5 2.38056 17.3617 2.13442 17.1156C1.88828 16.8694 1.75 16.5356 1.75 16.1875V14.875C1.75 13.9467 2.11875 13.0565 2.77513 12.4001C3.4315 11.7437 4.32174 11.375 5.25 11.375H11.375ZM16.625 11.375C17.3212 11.375 17.9889 11.6516 18.4812 12.1438C18.9734 12.6361 19.25 13.3038 19.25 14V15.3125C19.25 15.6606 19.1117 15.9944 18.8656 16.2406C18.6194 16.4867 18.2856 16.625 17.9375 16.625H16.625V14.875C16.6255 14.1957 16.4676 13.5256 16.1639 12.9181C15.8601 12.3105 15.4188 11.7821 14.875 11.375H16.625ZM8.3125 2.625C9.35679 2.625 10.3583 3.03984 11.0967 3.77827C11.8352 4.51669 12.25 5.51821 12.25 6.5625C12.25 7.60679 11.8352 8.60831 11.0967 9.34673C10.3583 10.0852 9.35679 10.5 8.3125 10.5C7.26821 10.5 6.26669 10.0852 5.52827 9.34673C4.78984 8.60831 4.375 7.60679 4.375 6.5625C4.375 5.51821 4.78984 4.51669 5.52827 3.77827C6.26669 3.03984 7.26821 2.625 8.3125 2.625ZM15.75 5.25C16.4462 5.25 17.1139 5.52656 17.6062 6.01884C18.0984 6.51113 18.375 7.17881 18.375 7.875C18.375 8.57119 18.0984 9.23887 17.6062 9.73116C17.1139 10.2234 16.4462 10.5 15.75 10.5C15.0538 10.5 14.3861 10.2234 13.8938 9.73116C13.4016 9.23887 13.125 8.57119 13.125 7.875C13.125 7.17881 13.4016 6.51113 13.8938 6.01884C14.3861 5.52656 15.0538 5.25 15.75 5.25Z" fill="#F8F8F8"/>
    </svg>
    
  );
};

export default MembersIcon;

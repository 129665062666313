import { useMemo } from "react";
import {
  Text,
  ActionIcon,
  Flex,
  Image,
  Avatar,
  Menu,
  Modal,
} from "@mantine/core";
import { Auth } from "../../../hooks/auth.hook";
import { createStyles, getStylesRef } from "@mantine/core";
import { Link, useLocation } from "react-router-dom";
import { AiFillAudio } from "react-icons/ai";
import { HiDotsVertical, HiOutlineUser } from "react-icons/hi";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { useDisclosure } from "@mantine/hooks";
import CreateCreatorPreFace from "../../low-level/CreateCreator/CreateCreatorPreFace";
import HomeIcon from "../../../assets/left-menu/home-icon";
import ChatIcon from "../../../assets/left-menu/chat-icon";
import SettingsIcon from "../../../assets/left-menu/settings-icon";
import { useGetWallet } from "../../../api/pay/service";
import { useGetUser } from "../../../api/user/service";
import { FaUserPlus } from "react-icons/fa";
import { useGetAppSettings } from "../../../api/app-settings/service";
import { IoStatsChartOutline } from "react-icons/io5";

const LeftMenu = ({ translation, littleLeftMenu }: any) => {
  const { data: appSettings } = useGetAppSettings();

  const location = useLocation();
  const { data }: any = useGetWallet();
  const { logoutUser } = Auth();
  const { data: user } = useGetUser();
  const { classes } = useStyles();
  const [newCreatorModal, { open, close }] = useDisclosure(false);

  /*
  const {
    notifications,
    hasNextPage,
    isLoading,
    isFetching,
    unseenCount,
    isFetchingNextPage,
    fetchNextPage,
    markNotificationAsRead,
    markNotificationAsSeen,
    removeMessage,
    markAllNotificationsAsRead,
    markAllNotificationsAsSeen,
  } = useNotifications();

  const showNotification = useCallback(() => {
    if (!notifications || notifications.length <= 0) {
      return;
    }
    const content: any = notifications[0].content;
    mantineNotifications.show({
      title: "New notification",
      message: content,
    });
  }, [notifications]);

  useEffect(() => {
    showNotification();
  }, [
    notificationCountCheck,
    isLoading,
    showNotification,
    unseenCount,
    notifications,
  ]);
  */

  const internalMenuList = useMemo(
    () => [
      {
        title: translation.home,
        to: "/my/feed",
        permission: true,
        icon: <HomeIcon active={location.pathname === "/my/feed"} />,
      },
      {
        title: translation.profile,
        to: "/my/profile",
        permission: user?.data?.isCreator,
        icon: (
          <HiOutlineUser
            className={`${
              (location.pathname === "/my/profile" ||
                location.pathname === "/my/profile/edit") &&
              classes.active_link
            }`}
            size={26}
          />
        ),
      },

      //     {
      //       title: "Notification",
      //       to: "#",
      //       permission: true,
      //       icon: (
      //         <PopoverNotificationCenter
      //         colorScheme="dark"
      //       >
      //         {({ unseenCount }) => <NotificationBell unseenCount={unseenCount} />}
      //       </PopoverNotificationCenter>
      // )
      //     },
      {
        title: translation.chat,
        to: "/my/chat",
        permission: appSettings?.data?.LeftMenuChatVisible,
        icon: <ChatIcon active={location.pathname.includes("my/chat")} />,
      },
      // {
      //   title: translation.events,
      //   to: "/my/events",
      //   permission: appSettings?.data?.LeftMenuEventsVisible,
      //   icon: <ChatIcon active={location.pathname.includes("my/events")} />,
      // },
      // {
      //   title: translation.chat,
      //   to: "/my/chat",
      //   permission: true,
      //   icon: <ChatIcon active={location.pathname === "/my/chat"} />,
      // },
      {
        title: translation.statistics,
        to: "/my/statistics",
        permission: user?.data?.isCreator,
        icon: (
          <IoStatsChartOutline
            color={
              location.pathname.includes("my/statistics")
                ? "#D2E951"
                : "#8C8FA5"
            }
          />
        ),
      },
      {
        title: translation.settings,
        to: "/my/settings",
        permission: true,
        icon: (
          <SettingsIcon active={location.pathname.includes("my/settings")} />
        ),
      },
      {
        title: "Deine Lips",
        to: "/my/payment",
        permission: appSettings?.data?.LeftMenuPaymentVisible,
        icon: (
          <RiMoneyDollarCircleLine
            className={`${
              location.pathname === "/my/payment" && classes.active_link
            }`}
            size={26}
          />
        ),
      },
    ],
    [
      classes.active_link,
      location.pathname,
      translation,
      user?.data?.isCreator,
      appSettings,
    ]
  );

  // useEffect(() => {
  //   if (!user) {
  //     setMenuList(externalMenuList);
  //   } else {
  //     setMenuList(internalMenuList);
  //   }
  // }, [user, setMenuList, externalMenuList, internalMenuList]);

  return (
    <div
      className={`${classes.left_box} ${
        littleLeftMenu && classes.left_menu_little
      }`}
      style={{ height: "100vh", position: "relative" }}
    >
      <Modal
        opened={newCreatorModal}
        onClose={close}
        size="1017px"
        classNames={{
          body: classes.modal_body,
          inner: classes.modal_inner,
        }}
        overlayProps={{
          color: " rgb(35, 35, 35)",
          opacity: 0.75,
          blur: 4.5,
        }}
        withCloseButton={false}
        centered
      >
        <CreateCreatorPreFace translation={translation} close={close} />
      </Modal>
      <div
        className={`${classes.left_box_container} ${
          littleLeftMenu && classes.left_menu_box_little
        }`}
      >
        <div
          className={`${classes.group} ${
            littleLeftMenu && classes.group_little
          }`}
        >
          <Link to="/my/feed">
            <Image
              mt={30}
              mb={30}
              ml={10}
              classNames={{ root: classes.logo }}
              src={require("../../../assets/logo.png")}
            />
          </Link>
          {internalMenuList.map(
            (x: any, index: number) =>
              x.permission && (
                <Link
                  className={`${classes.link} ${
                    littleLeftMenu && classes.link_little
                  }`}
                  to={x.to}
                  key={index}
                >
                  <Flex align="center" className={classes.link_hover}>
                    {x?.icon}
                    <Text
                      className={`${classes.link_text} ${
                        littleLeftMenu && classes.link_text_little
                      } ${
                        (location.pathname === x.to ||
                          (x.to !== "/" && location.pathname.includes(x.to))) &&
                        classes.active_link
                      } `}
                    >
                      {/* {translation?.attributes?.fields?.home} */}
                      {x.title}
                    </Text>
                  </Flex>
                </Link>
              )
          )}
          <Link
            to="/my/payment"
            style={{
              textDecoration: "none",
            }}
            className={`${classes.amount_area} ${
              littleLeftMenu && classes.amount_area_little
            }`}
          >
            <Image
              width={littleLeftMenu ? 15:20}
              height={littleLeftMenu ? 15:20}
              mr={4}
              className={classes.amount_image}
              src={require("../../../assets/amount.png")}
            />
            {data?.balance
              ? littleLeftMenu && data?.balance > 99
                ? "+99"
                : data?.balance
              : 0}
          </Link>
          {/* {user?.data?.isCreator && ( */}
          {user &&
            (user?.data?.isCreator ? (
              <Link
                className={`${classes.new_post_and_become_creator_button} ${
                  littleLeftMenu &&
                  classes.new_post_and_become_creator_button_little
                } `}
                to={"/post/create"}
              >
                <span
                  className={`${classes.link_text} ${
                    littleLeftMenu && classes.link_text_little
                  }`}
                  style={{ marginLeft: 0 }}
                >
                  {translation.left_menu.createPost}
                </span>
                <span
                  className={`${classes.link_icon} ${
                    littleLeftMenu && classes.link_icon_little
                  }`}
                >
                  <AiFillAudio size={20} />
                </span>
              </Link>
            ) : (
              appSettings?.data?.CreateCreatorButtonVisible && (
                <Link
                  className={classes.new_post_and_become_creator_button}
                  to={"#"}
                  onClick={open}
                >
                  <span
                    className={`${classes.link_text} ${
                      littleLeftMenu && classes.link_text_little
                    }`}
                    style={{ marginLeft: 0 }}
                  >
                    {translation.left_menu.createCreator}
                  </span>
                  <span
                    className={`${classes.link_icon} ${
                      littleLeftMenu && classes.link_icon_little
                    }`}
                  >
                    <FaUserPlus size={20} />
                  </span>
                </Link>
              )
            ))}

          {/* )} */}
        </div>
        {user?.data?.isCreator && (
          <div
            className={`${classes.creator_profile} ${
              littleLeftMenu && classes.creator_profile_little
            }`}
          >
            <Flex
              className={`${classes.creator_profile_container} ${
                littleLeftMenu && classes.creator_profile_container_little
              }`}
            >
              <Link
                to={`/my/profile`}
                style={{
                  textDecoration: "none",
                }}
              >
                <Flex align="center">
                  <Avatar
                    variant="filled"
                    size={39}
                    radius="xl"
                    src={
                      user?.data?.attributes?.creatorProfile?.avatarThumbs
                        ?.sizes?.asr24
                    }
                  />
                  <Flex
                    className={`${classes.creator_profile_info} ${
                      littleLeftMenu && classes.creator_profile_info_little
                    }`}
                    direction="column"
                    ml={10}
                  >
                    <Text fz={14} color="white">
                      {user?.data?.attributes?.creatorProfile?.name}
                    </Text>
                    <Text fz={12} color="#8899A6">
                      {"@" + user?.data?.attributes?.creatorProfile?.username}
                    </Text>
                  </Flex>
                </Flex>
              </Link>
              <div
                className={`${classes.logout_modal} ${
                  littleLeftMenu && classes.logout_modal_little
                }`}
              >
                <Menu
                  styles={{
                    dropdown: {
                      border: "0px",
                      backgroundColor: "rgba(77, 116, 254, 0.9)",
                      borderRadius: 18,
                    },
                    arrow: {
                      backgroundColor: "rgba(77, 116, 254, 0.9)",
                      border: 0,
                      right: "11px !important",
                      bottom: "-0.12rem !important",
                    },
                  }}
                  position="top-end"
                  withArrow
                  offset={5}
                  shadow="md"
                >
                  <Menu.Target>
                    <ActionIcon variant="transparent">
                      <HiDotsVertical color="white" />
                    </ActionIcon>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item onClick={logoutUser} color="white">
                      <Flex align="center">
                        <Image
                          height={24}
                          fit="contain"
                          mr={5}
                          src={require("../../../assets/logout_2.png")}
                        />
                        <Text fw={400} fz={16}>
                          {translation.logout}
                        </Text>
                      </Flex>
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </div>
            </Flex>
          </div>
        )}
      </div>
    </div>
  );
};
const useStyles = createStyles((theme) => ({
  left_box: {
    width: "30vw",
    zIndex: 99,
    [`@media (max-width: 1265px)`]: {
      width: "10vw",
    },
    [`@media (max-width: 500px)`]: {
      width: "0vw",
      display: "none",
    },
  },
  logout_modal: {
    [`@media (max-width: 1265px)`]: {
      width: "0vw",
      display: "none",
    },
  },
  logout_modal_little: {
    width: "0vw",
    display: "none",
  },
  left_menu_little: {
    width: "10vw !important",
    [`@media (max-width: 500px)`]: {
      width: "0vw",
      display: "none",
    },
  },
  modal_body: {
    backgroundColor: "#171717",
    padding: "45px",
    [`@media (max-width: 768px)`]: {
      borderRadius: "33px",
    },
    [`@media (max-width: 500px)`]: {
      borderRadius: "33px 33px 0px 0px",
    },
  },
  modal_inner: {
    [`@media (max-width: 500px)`]: {
      padding: 0,
      alignItems: "flex-end",
    },
  },
  left_box_container: {
    width: "30vw",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    height: "100vh",
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    background: "rgba(53, 56, 62, 0.4)",
    [`@media (max-width: 1265px)`]: {
      width: "10vw",
      justifyContent: "center",
      alignItems: "center",
    },
    [`@media (max-width: 500px)`]: {
      width: "0vw",
      display: "none",
    },
  },
  left_menu_box_little: {
    width: "10vw !important",
    justifyContent: "center",
    alignItems: "center",
    [`@media (max-width: 500px)`]: {
      width: "0vw",
      display: "none",
    },
  },
  group: {
    width: "275px",
    height: "100%",
    display: "flex",
    paddingTop: "10px ",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "flex-start",
    [`@media (max-width: 1265px)`]: {
      alignItems: "center",
      padding: "10px 0px",
    },
  },
  group_little: {
    width: "100%",
    padding: "10px 0px",
    alignItems: "center",
  },
  creator_profile: {
    height: "25%",
    width: "275px",
    padding: "10px 20px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "start",
    [`@media (max-width: 1265px)`]: {
      padding: "10px 0px",
    },
  },
  creator_profile_little: {
    padding: "10px 0px",
  },
  creator_profile_container: {
    width: "100%",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
    [`@media (max-width: 1265px)`]: {
      justifyContent: "center",
    },
  },
  creator_profile_container_little: {
    justifyContent: "center",
  },
  creator_profile_info: {
    [`@media (max-width: 1265px)`]: {
      display: "none",
    },
  },
  creator_profile_info_little: {
    display: "none !important",
  },
  amount_area: {
    padding: "0px 20px",
    height: 41,
    borderRadius: 21,
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 14,
    fontWeight: 900,
    marginBottom: 30,
    marginTop: 30,
    [`@media (max-width: 1265px)`]: {
      width: 50,
      padding: "0px 0px",
      height: 50,
      borderRadius: "50%",
      position: "relative",
    },
  },
  amount_area_little: {
    width: 50,
    padding: "0px 0px",
    height: 50,
    borderRadius: "50%",
    position: "relative",
  },
  amount_image: {
    [`@media (max-width: 1265px)`]: {
      position: "absolute",
      right: -5,
      bottom: -5,
    },
  },
  logo: {
    width: "5vw !important",
    transition: "all 0.5s ease",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  link: {
    color: "#8C8FA5",
    textDecoration: "none",
    fontSize: 20,
    width: "100%",
    fontWeight: 400,
    [`@media (max-width: 1265px)`]: {
      margin: "5px 0px",
      width: "fit-content",
    },
    [`&:hover .${getStylesRef("link_hover")}`]: {
      backgroundColor: "rgba(231, 233, 234, .1)",
      borderRadius: 60,
      transition: "all .2s",
    },
  },
  link_little: {
    margin: "5px 0px",
    width: "fit-content",
  },
  link_hover: {
    padding: 12,
    transition: "all .2s",
    width: "fit-content",
    ref: getStylesRef("link_hover"),
  },
  link_text: {
    marginLeft: 10,
    [`@media (max-width: 1265px)`]: {
      display: "none",
    },
  },
  link_text_little: {
    display: "none",
  },
  active_link: {
    color: theme.colors.green[0],
    fontWeight: 600,
  },
  icon: {
    width: 30,
  },
  link_icon: {
    display: "none",
    [`@media (max-width: 1265px)`]: {
      display: "flex",
    },
  },
  link_icon_little: {
    display: "flex",
  },
  new_post_and_become_creator_button: {
    width: "80%",
    marginTop: 20,
    backgroundColor: theme.colors.green[0],
    color: "#111111",
    fontSize: 18,
    fontWeight: "bold",
    borderRadius: 58,
    padding: "10px",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [`@media (max-width: 1265px)`]: {
      borderRadius: "50%",
      padding: "0px",
      width: 54,
      height: 54,
    },
    [`@media (max-width: 950px)`]: {
      borderRadius: "50%",
      padding: "0px",
      width: 40,
      height: 40,
    },
  },
  new_post_and_become_creator_button_little: {
    borderRadius: "50%",
    padding: "0px",
    width: 54,
    height: 54,
    marginTop: 20,
    backgroundColor: theme.colors.green[0],
    color: "#111111",
    fontSize: 18,
    fontWeight: "bold",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [`@media (max-width: 950px)`]: {
      borderRadius: "50%",
      padding: "0px",
      width: 40,
      height: 40,
    },
  },
}));
export default LeftMenu;

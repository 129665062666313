import React, { useState } from "react";
import {
  Button,
  TextInput,
  Textarea,
  Group,
  Title,
  Text,
  createStyles,
  Flex,
  Loader,
} from "@mantine/core";
import LandingLayout from "../../components/high-level/layout/landingLayout";
import { usePostComplaints } from "../../api/complaints-form/service";
import i18n from "../../i18n";
import { CiCircleCheck } from "react-icons/ci";

const ComplaintsFormPage = ({ translation }: any) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    urls: "",
    description: "",
  });
  const [languageState, setLanguageState] = useState(i18n.language);
  const { postComplaints, isSuccess, isLoading } = usePostComplaints();
  const { classes } = useStyles();

  const changeLanguage = (e: any) => {
    setLanguageState(e);
    i18n.changeLanguage(e);
    window.location.reload();
  };
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    postComplaints(formData);
  };
  return (
    <LandingLayout
      language={true}
      backgorund={false}
      languageState={languageState}
      translation={translation}
      setLanguageState={changeLanguage}
    >
      <div className={classes.header}>
        <Title className={classes.title} align="center" mt={-300} fz={60}>
          {translation.complaints_page.title}
        </Title>
      </div>

      <Flex w="100%" mb={20} align="center" justify="center" direction="column">
        {isLoading ? (
          <Flex h="50dvh" w="70%" justify="center" align="center" direction="column">
            <Loader color="rgb(77, 116, 254)" variant="dots" size={100} />
          </Flex>
        ) : isSuccess ? (
          <Flex h="50dvh" w="70%" align="center" direction="column">
            <CiCircleCheck color="rgb(210, 233, 81)" size={150}/>
            <Text size={40} fw="bold" color="white" align="center">
              {translation.complaints_page.success_message}
            </Text>
          </Flex>
        ) : (
          <Flex w="70%" align="left" direction="column">
            <Text color="white" align="center">
              {translation.complaints_page.subtitle_1}
              <a href="https://gethelp.segpay.com/docs/Content/ComplianceDocs/WebsiteComplianceRequirements.htm">
                {translation.complaints_page.subtitle_2}
              </a>{" "}
              {translation.complaints_page.subtitle_3}
            </Text>
            <Text
              color="white"
              align="center"
              fw="bold"
              mt={20}
              mb={10}
              fz={25}
            >
              {translation.complaints_page.subtitle_4}
            </Text>
            <form onSubmit={handleSubmit}>
              <TextInput
                label={translation.complaints_page.firstName}
                placeholder="John"
                required
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                classNames={{ input: classes.input, label: classes.label }}
                color="white"
                mb="sm"
              />
              <TextInput
                label={translation.complaints_page.lastName}
                placeholder="Doe"
                required
                name="lastName"
                classNames={{ input: classes.input, label: classes.label }}
                value={formData.lastName}
                onChange={handleChange}
                color="white"
                mb="sm"
              />
              <TextInput
                label={translation.complaints_page.email}
                placeholder="johndoe@example.com"
                name="email"
                type="email"
                classNames={{ input: classes.input, label: classes.label }}
                value={formData.email}
                onChange={handleChange}
                required
                mb="sm"
              />
              <Textarea
                label={translation.complaints_page.urls}
                placeholder={translation.complaints_page.urls_placeholder}
                name="urls"
                classNames={{ input: classes.input, label: classes.label }}
                value={formData.urls}
                onChange={handleChange}
                minRows={4}
                mb="sm"
              />
              <Textarea
                label={translation.complaints_page.description}
                placeholder={
                  translation.complaints_page.description_placeholder
                }
                name="description"
                value={formData.description}
                classNames={{ input: classes.input, label: classes.label }}
                onChange={handleChange}
                minRows={4}
                mb="sm"
              />
              <Group position="left" mt="md">
                <Button color="blue" variant="gradient" type="submit">
                  {translation.complaints_page.submit}
                </Button>
              </Group>
            </form>
            <Text align="left" fw="bold" color="white" mt={20} mb={10} fz={25}>
              {translation.complaints_page.conact_us_title}
            </Text>
            <Text className={classes.text} color="white" mt={20}>
              <b>{translation.complaints_page.operator}:</b> Madarray gmbh
            </Text>
            <Text className={classes.text} color="white" mt={20}>
              <b>Email:</b> mail@madarray.com
            </Text>
            <Text align="left" fw="bold" color="white" mt={20} mb={10} fz={25}>
              {translation.complaints_page.complaint_policy}
            </Text>
            <Text className={classes.text} fw="700" color="white" mt={20}>
              {translation.complaints_page.title_1}
            </Text>
            <Text className={classes.text} color="white" mt={20}>
              {translation.complaints_page.text_1}
            </Text>

            <Text className={classes.text} fw="700" color="white" mt={20}>
              {translation.complaints_page.title_2}
            </Text>
            <Text className={classes.text} color="white" mt={20}>
              {translation.complaints_page.text_2}
            </Text>

            <Text className={classes.text} fw="700" color="white" mt={20}>
              {translation.complaints_page.title_3}
            </Text>
            <Text className={classes.text} color="white" mt={20}>
              {translation.complaints_page.text_3}
            </Text>
            <Text className={classes.text} fw="700" color="white" mt={20}>
              {translation.complaints_page.title_4}
            </Text>
            <Text className={classes.text} color="white" mt={20}>
              {translation.complaints_page.text_4}
            </Text>
            <Text className={classes.text} fw="700" color="white" mt={20}>
              {translation.complaints_page.title_5}
            </Text>
            <Text className={classes.text} color="white" mt={20}>
              {translation.complaints_page.text_5}
            </Text>
            <Text className={classes.text} color="white" mt={20} ml={20}>
              {translation.complaints_page.text_5_1}
            </Text>
            <Text className={classes.text} color="white" mt={20} ml={20}>
              {translation.complaints_page.text_5_2}
            </Text>
            <Text className={classes.text} color="white" mt={20} ml={20}>
              {translation.complaints_page.text_5_3}
            </Text>
            <Text className={classes.text} color="white" mt={20} ml={40}>
              {translation.complaints_page.text_5_4}
            </Text>
            <Text className={classes.text} color="white" mt={20} ml={40}>
              {translation.complaints_page.text_5_5}
            </Text>
            <Text className={classes.text} color="white" mt={20} ml={40}>
              {translation.complaints_page.text_5_6}
            </Text>
            <Text className={classes.text} fw="700" color="white" mt={20}>
              {translation.complaints_page.title_6}
            </Text>
            <Text className={classes.text} color="white" mt={20}>
              {translation.complaints_page.text_6}
            </Text>

            <Text className={classes.text} fw="700" color="white" mt={20}>
              {translation.complaints_page.title_7}
            </Text>
            <Text className={classes.text} color="white" mt={20}>
              {translation.complaints_page.text_7}
            </Text>
            <Text className={classes.text} color="white" mt={20} ml={20}>
              {translation.complaints_page.text_7_1}
            </Text>
            <Text className={classes.text} color="white" mt={20} ml={20}>
              {translation.complaints_page.text_7_2}
            </Text>
            <Text className={classes.text} color="white" mt={20} ml={20}>
              {translation.complaints_page.text_7_3}
            </Text>
            <Text className={classes.text} color="white" mt={20} ml={20}>
              {translation.complaints_page.text_7_4}
            </Text>
            <Text className={classes.text} fw="700" color="white" mt={20}>
              {translation.complaints_page.title_8}
            </Text>
            <Text className={classes.text} color="white" mt={20}>
              {translation.complaints_page.text_8}
            </Text>
          </Flex>
        )}
      </Flex>
    </LandingLayout>
  );
};
const useStyles = createStyles((theme) => ({
  header: {
    paddingBottom: 20,
    marginBottom: 20,
    height: "30vh",
    backgroundColor: "rgba(56, 56, 56, .7)",
    color: "white",
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  text: {
    fontSize: 22,
    [`@media (max-width: 768px)`]: {
      fontSize: 16,
    },
  },
  title: {
    fontSize: 45,
    [`@media (max-width: 768px)`]: {
      fontSize: 30,
    },
  },
  linkStyle: {
    color: "white", // Farbe des Links
    fontSize: "32px", // Schriftgröße des Links
    textDecoration: "none",
  },
  input: {
    backgroundColor: "transparent",
    border: "1px solid rgba(255, 255, 255, .7)",
    color: "white",
  },
  label: {
    color: "white",
  },
}));
export default ComplaintsFormPage;

import {
  ActionIcon,
  Avatar,
  Button,
  Flex,
  Image,
  Text,
  Title,
  Transition,
  createStyles,
} from "@mantine/core";
import { useElementSize } from "@mantine/hooks";
import React, { useEffect, useState } from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { eventEnum } from "../../../utils/enums/eventEnum";
import { useUpdateEvent } from "../../../api/event/service";
import { useOnlineChatMembersService } from "../../../api/chat/service/online-chat-members-service";

const CreatorDetailArea = ({
  user,
  translation,
  creatorData,
  open,
  activeTab,
  setActiveTab,
  setOpenChat,
  openChat,
  setOpenVoice,
  setCreatorHeight,
}: any) => {
  const { ref, height } = useElementSize();
  const { classes } = useStyles({ creatorData });
  let { id }: any = useParams();
  const navigate = useNavigate();
  const { updateEvent } = useUpdateEvent();
  const [online, setOnline] = useState(false);
  const members = useOnlineChatMembersService(); // Verwende den Hook, um auf die Mitglieder zuzugreifen

  useEffect(() => {
    if (ref) {
      setCreatorHeight(height);
    }
  }, [ref, height]);

  const backButton = () => {
    navigate(`/my/chat`);
  };

  const openVoiceArea = () => {
    setOpenVoice(true);
  };
  const leaveEvent = () => {
    const data = {
      id: id,
      user_profiles: [user.data.attributes.userProfile.id],
      type: eventEnum.REMOVE_USER,
    };
    updateEvent({ newData: data });
  };

  const creatorOrUser = creatorData?.results?.attributes?.isCreator;

  useEffect(() => {
    if (members === undefined) {
      setOnline(false);
    } else {
      const findCretor: any = members[id]?.members?.find(
        (x) =>
          x.profileId ===
          creatorData.results.attributes.creator_profiles.data.userId
      );
      setOnline(findCretor?.isOnline);
    }
  }, [members, id, creatorData]);
  return (
    <div ref={ref} className={`${classes.creator_detail_area}`}>
      <Flex
        style={{ zIndex: 999 }}
        direction="column"
        className={`${classes.main_creator_area} `}
        w="100%"
      >
        <Flex direction="row" justify="space-between" align="center">
          <Flex>
            <div className={classes.back_button_mobile}>
              <ActionIcon size={30} variant="transparent" onClick={backButton}>
                <IoIosArrowRoundBack color="white" size={30} />
              </ActionIcon>
            </div>

            <Flex
              onClick={creatorOrUser ? open : undefined}
              style={{ cursor: "pointer" }}
              align="center"
            >
              <div className={classes.avatar_area}>
                <div className={classes.avatar}>
                  <Avatar
                    src={
                      creatorData?.results?.attributes?.creator_profiles?.data
                        ?.avatar?.images?.thumbs?.c250
                    }
                    className={`${
                      !!creatorData?.results?.attributes?.creator_profiles?.data
                        ?.avatar &&
                      classes.avatar_animation
                    }`}
                    radius={14}
                    size={63}
                  />
                </div>
                <div
                  className={`${classes.online_offline_box} ${
                    online ? classes.online_user : classes.offline_user
                  }`}
                ></div>
              </div>

              <Flex
                ml={14}
                className={`${classes.name_area}`}
                justify="center"
                direction={"column"}
              >
                <Title style={{ zIndex: 99 }} fz={14} c="white">
                  {
                    creatorData?.results?.attributes?.creator_profiles?.data
                      ?.name
                  }
                </Title>
                <Text
                  style={{ zIndex: 99 }}
                  fz={14}
                  c={creatorData?.results?.attributes?.isUser ? "white" : "red"}
                >
                  {creatorData?.results?.attributes?.isUser
                    ? "@" +
                      creatorData?.results?.attributes?.creator_profiles?.data
                        ?.username
                    : translation.chat_page.not_found_user}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        {!creatorData?.results?.attributes?.isGroup && creatorOrUser && (
          <div className={classes.tabs}>
            <Button
              onClick={() => setActiveTab("chat")}
              className={`${classes.tab_button} ${
                activeTab === "chat" && classes.active_tab
              }`}
            >
              {translation.chat}
            </Button>
            <Button
              onClick={() => setActiveTab("media")}
              className={`${classes.tab_button} ${
                activeTab === "media" && classes.active_tab
              }`}
            >
              {translation.media}
            </Button>
          </div>
        )}
      </Flex>
    </div>
  );
};
const useStyles = createStyles((theme, { creatorData }: any) => ({
  chat_right_area: {
    position: "relative",
    height: "calc(100vh - 50px)",
    [`@media (max-width: 768px)`]: {
      height: "100dvh",
    },
  },
  creator_detail_area: {
    position: "relative",
    backgroundColor: "transparent",
    borderBottom: "1px solid rgba(98,98,98,0.44)",
    [`@media (max-width: 768px)`]: {
      borderBottom: "0px solid rgba(98,98,98,0.44)",
      backgroundColor: "rgb(28,28,28)",
    },
  },

  linear_area: {
    [`@media (max-width: 768px)`]: {
      position: "absolute",
      width: "100%",
      height: "100%",
      borderBottomLeftRadius: 50,
      borderBottomRightRadius: 50,
      backdropFilter: "blur(3px)",
      background:
        "linear-gradient(180deg, rgba(0,0,0,0.99) 0%, rgba(0,0,0,0) 100%)",
    },
  },
  tabs: {
    marginTop: 15,
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    display: "flex",
    justifyContent: "space-between",
    borderRadius: 40,
    [`@media (max-width: 768px)`]: {
      display: "none",
    },
  },
  tab_button: {
    borderRadius: 40,
    width: "100%",
    color: "lightgrey",
    transition: "all 0.3s",
    "&:hover": {
      color: theme.colors.green[0],
    },
  },
  active_tab: {
    // backgroundColor:theme.colors.green[0],
    border: `1px solid ${theme.colors.green[0]}`,
    borderRadius: 40,
    color: "white !important",
    transition: "all 0.3s",
  },
  call_button: {
    width: 34,
    height: 34,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#6E767D",
    borderRadius: "50%",
  },
  media_button: {
    display: "none",
    [`@media (max-width: 768px)`]: {
      display: "flex",
    },
  },
  media_grid: {
    overflowY: "scroll",
    height: "100%",
  },
  media_area: {
    height: "100%",
    width: "100%",
    padding: 20,
    paddingBottom: 200,
    overflowY: "scroll",
    paddingTop: 10,
    [`@media (max-width: 768px)`]: {
      paddingTop: 25,
    },
  },
  main_creator_area: {
    padding: "25px 35px",
    [`@media (max-width: 768px)`]: {
      padding: "31px 15px 31px 15px",
    },
  },

  back_button_mobile: {
    display: "none",
    [`@media (max-width: 768px)`]: {
      display: "block",
      marginRight: 18,
    },
  },
  name_area: {
    [`@media (max-width: 768px)`]: {
      height: "100%",
      justifyContent: "flex-end",
    },
  },

  chat_button: {
    backgroundColor: theme.colors.blue[0],
  },
  modal_body: {
    backgroundColor: theme.colors.blue[0],
    padding: "45px",
    [`@media (max-width: 500px)`]: {
      backgroundColor: "transparent",
      padding: "0px 0px",
    },
  },
  modal_title: {
    position: "sticky",
    top: 0,
    zIndex: 99,
    backgroundColor: theme.colors.blue[0],
    [`@media (max-width: 500px)`]: {
      position: "relative",
      backgroundColor: "transparent",
    },
  },
  name: {
    marginBottom: 0,
    [`@media (max-width: 500px)`]: {
      marginBottom: 8,
    },
  },
  offline_user: {
    backgroundColor: theme.colors.red[5],
    webkitBoxShadow: "0px 0px 30px 4px rgba(255,107,107,.5)",
    mozBoxShadow: "0px 0px 30px 4px rgba(255,107,107,.5)",
    boxShadow: "0px 0px 30px 4px rgba(255,107,107,.5)",
  },
  online_user: {
    backgroundColor: theme.colors.green[0],
    webkitBoxShadow: "0px 0px 30px 4px rgba(210,233,81,.5)",
    mozBoxShadow: "0px 0px 30px 4px rgba(210,233,81,.5)",
    boxShadow: "0px 0px 30px 4px rgba(210,233,81,.5)",
  },
  online_offline_box: {
    width: 20,
    height: 20,
    position: "absolute",
    bottom: -5,
    right: -5,
    border: "0.1px solid rgba(0,0,0,.5)",
    borderRadius: "50%",
  },
  avatar: {
    background: "linear-gradient(132.14deg, #D2E951 9.16%, #4D74FE 92.2%)",
    padding: 3,
    borderRadius: 14,
  },
  avatar_area: {
    position: "relative",
  },
  avatar_animation: {
    opacity: 0,
    animationName: "avatarAnimation",
    animationDuration: ".4s",
    animationTimingFunction: "linear",
    animationFillMode: "both",
    "@keyframes avatarAnimation": {
      "0%": {
        opacity: 0,
      },

      "100%": {
        opacity: 1,
      },
    },
  },
}));

export default CreatorDetailArea;

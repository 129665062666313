/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import PublicLayout from "../../components/high-level/layout/publicLayout";
import { useNavigate, useParams } from "react-router-dom";
import {
  Flex,
  Image,
  Title,
  Text,
  createStyles,
  Avatar,
  Modal,
  ActionIcon,
  Loader,
  Center,
  Input,
  Button,
  TextInput,
  PasswordInput,
  Group,
} from "@mantine/core";
import PublicCreatorProfilePostsCard from "../../components/low-level/CreatorProfilePostsCard/PublicCreatorProfilePostsCard";
import {
  useDisclosure,
  useElementSize,
  useScrollIntoView,
} from "@mantine/hooks";
import { AiOutlineClose } from "react-icons/ai";
import "./styles.css";
import {
  useGetCreatorPublicPosts,
  useGetPublicCreatorDetail,
} from "../../api/creator/service";
import { useGetUser } from "../../api/user/service";
import { Auth } from "../../hooks/auth.hook";
import { useForm } from "@mantine/form";
const PublicCreatorProfilePage = ({ translation }: any) => {
  const { classes } = useStyles();
  const [registerModal, { open, close }] = useDisclosure(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [loginModal, { open: loginModalOpen, close: loginModalClose }] =
    useDisclosure(false);
  const { ref } = useElementSize();
  const { targetRef } = useScrollIntoView<HTMLDivElement>({
    offset: 60,
  });
  const { register, login, error, loading, loginSuccess } = Auth();

  let { creator_name }: any = useParams();
  const {
    data: creatorPosts,
    isLoading: creatorPostsLoading,
    fetchNextPage,
    isFetchingNextPage,
  }: any = useGetCreatorPublicPosts(creator_name);
  const { data: creatorData, isLoading: creatorDetailLoading }: any =
    useGetPublicCreatorDetail(creator_name);
  const form = useForm({
    initialValues: {
      username: "",
      email: "",
      password: "",
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  // const { data: user } = useGetUser();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const flattenedData: any = useMemo(
    () =>
      creatorPosts
        ? creatorPosts?.pages?.flatMap((item: any) => item.data.creatorPosts)
        : [],
    [creatorPosts]
  );
  const onRegister = (values: any) => {
    register(values.username, values.email, values.password);
  };
  const goLogin = async () => {
    if (form.values.password.length < 3) {
      setErrorPassword(true);
    } else {
      await login(form.values.email, form.values.password);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        !isFetchingNextPage &&
        targetRef.current &&
        window.innerHeight + window.scrollY >= targetRef.current.offsetTop
      ) {
        fetchNextPage();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isFetchingNextPage]);
  const goForgotPassPage = () => {
    navigate("/forgot-password");
  };

  return (
    <PublicLayout
      registerModalOpen={open}
      loginModalOpen={loginModalOpen}
      headerTitle={creatorData?.data?.attributes?.username}
    >
      {/* Register Modal */}
      <Modal
        classNames={{ body: classes.modal_body }}
        withCloseButton={false}
        styles={{
          content: {
            borderRadius: "10px !important",
            backgroundColor: "transparent",
          },
        }}
        overlayProps={{
          opacity: 0.75,
          blur: 5,
        }}
        centered
        size={600}
        zIndex={9999}
        opened={registerModal}
        onClose={close}
      >
        <Flex
          direction="row"
          justify="space-between"
          align="center"
          mb={20}
          className={classes.modal_title}
        >
          <Flex direction="column" justify="center">
            <Title className={classes.name} fz={20} fw="bold" color="white">
              {translation.register}
            </Title>
            <Text color="#A5A5A5" fz={16}></Text>
          </Flex>
          <ActionIcon variant="transparent" mr={5} onClick={close}>
            <AiOutlineClose color="white" size={20} />
          </ActionIcon>
        </Flex>
        <Flex w="100%">
          <form
            style={{ width: "100%" }}
            onSubmit={form.onSubmit((values) => onRegister(values))}
          >
            <TextInput
              label={translation.email}
              classNames={{
                input: classes.input,
                label: classes.input_label,
              }}
              placeholder={translation.email_placeholder}
              {...form.getInputProps("email")}
            />
            <TextInput
              label={translation.username}
              mt={15}
              classNames={{
                input: classes.input,
                label: classes.input_label,
              }}
              placeholder={translation.username}
              {...form.getInputProps("username")}
            />
            <PasswordInput
              minLength={5}
              classNames={{
                input: classes.input,
                innerInput: classes.innerInput,
                label: classes.input_label,
                visibilityToggle: classes.visibilityToggle,
              }}
              mt={15}
              label={translation.password}
              placeholder={translation.password_placeholder}
              {...form.getInputProps("password")}
            />
            <Text c="dimmed" fz="sm" mt={2}>
              {error}
            </Text>
            <Button
              className={classes.submit_button}
              classNames={{ root: classes.login_button }}
              disabled={
                loading ||
                form?.values?.password.length < 6 ||
                form?.values?.username.length < 3 ||
                form?.errors?.email
              }
              loading={loading}
              type="submit"
            >
              {translation.register_page.register_button}
            </Button>
          </form>
        </Flex>
      </Modal>
      {/* Login Modal */}
      <Modal
        classNames={{ body: classes.modal_body }}
        withCloseButton={false}
        styles={{
          content: {
            borderRadius: "10px !important",
            backgroundColor: "transparent",
          },
        }}
        overlayProps={{
          opacity: 0.75,
          blur: 5,
        }}
        centered
        size={600}
        zIndex={9999}
        opened={loginModal}
        onClose={loginModalClose}
      >
        <Flex
          direction="row"
          justify="space-between"
          align="center"
          mb={20}
          className={classes.modal_title}
        >
          <Flex direction="column" justify="center">
            <Title className={classes.name} fz={20} fw="bold" color="white">
              {translation.login}
            </Title>
            <Text color="#A5A5A5" fz={16}></Text>
          </Flex>
          <ActionIcon variant="transparent" mr={5} onClick={loginModalClose}>
            <AiOutlineClose color="white" size={20} />
          </ActionIcon>
        </Flex>
        <Flex w="100%" direction="column">
          <TextInput
            label={translation.email}
            classNames={{
              input: classes.input,
              label: classes.input_label,
            }}
            placeholder={translation.email_placeholder}
            {...form.getInputProps("email")}
          />
          <PasswordInput
            label={translation.password}
            onClick={(e) => e.preventDefault()}
            styles={{
              input: {
                borderColor: errorPassword
                  ? "red"
                  : "1px solid rgba(255, 255, 255, 0.4)",
              },
            }}
            classNames={{
              input: classes.input,
              innerInput: classes.innerInput,
              label: classes.input_label,
              visibilityToggle: classes.visibilityToggle,
            }}
            minLength={5}
            mt={15}
            placeholder={translation.password_placeholder}
            {...form.getInputProps("password")}
          />
          {errorPassword && (
            <Text fz={11} color="red" mt={4}>
              {translation.password_error_message}
            </Text>
          )}
          <Group position="right" mt="md">
            {/* <Button component="a" href="/terms">
              Terms
            </Button> */}
            <button
              className={classes.forgot_password_button}
              onClick={goForgotPassPage}
            >
              {translation.forgot_password}
            </button>
          </Group>
          <Button
            classNames={{ root: classes.login_button }}
            className={classes.submit_button}
            loading={loading}
            disabled={
              loading ||
              form?.values?.password.length < 6 ||
          
              form?.values?.email.length < 6 ||
              form?.errors?.email
            }
            onClick={goLogin}
          >
            {translation.login}
          </Button>
        </Flex>
      </Modal>
      <div
        className={classes.creator_info}
        style={{
          height: 280,
        }}
      >
        <div
          className={classes.creator_image}
          style={{
            backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%), url(${creatorData?.data?.attributes?.avatarThumbs?.sizes?.asr24})`,
          }}
        ></div>

        <Flex
          className={classes.name_area}
          direction="row"
          justify="space-between"
          align="center"
        >
          <Flex onClick={open} style={{ cursor: "pointer" }}>
            <Avatar
              size={92}
              radius={20}
              classNames={{
                root: classes.avatar,
                image: classes.creator_avatar,
              }}
              src={creatorData?.data?.attributes?.avatarThumbs?.sizes?.asr24}
            />
            <Flex
              className={classes.name_area_content}
              direction="column"
              justify="center"
            >
              <Title className={classes.name} fz={20} color="white">
                {creatorData?.data?.attributes?.name}
              </Title>
              <Text color="#A5A5A5">
                {"@" + creatorData?.data?.attributes?.username}
              </Text>
              <Text color="#D2E951" className={classes.detail_button}>
                {translation.profile_edit_page.about_me}
              </Text>
              {!creatorData?.data?.attributes?.isSub && (
                <div className={classes.mobile_sounds}>
                  <Text fz={18} fw={400} color="white" align="center">
                    {!creatorData?.data?.attributes?.postsCount}
                  </Text>
                  <Text fz={18} fw={400} color="#939393" align="center">
                    {translation.sounds}
                  </Text>
                </div>
              )}
            </Flex>
          </Flex>
          <Flex></Flex>
          {/* <button className={classes.play_button}>
            <BsFillPlayFill size={"55%"} color="white" />
          </button> */}
        </Flex>
      </div>
      {!creatorData?.data?.attributes?.isSub && (
        <Text className={classes.about} color="white">
          {isOpen
            ? creatorData?.data?.attributes?.about
            : creatorData?.data?.attributes?.about?.substring(0, 20) +
              (creatorData?.data?.attributes?.about?.length > 20 ? "..." : "")}
        </Text>
      )}
      {creatorData?.data?.attributes?.about?.length > 20 &&
        !creatorData?.data?.attributes?.isSub && (
          <div className={classes.about_more_button}>
            <button
              style={{
                backgroundColor: "transparent",
                color: "#D2E951",
                padding: "0px 0px ",
                cursor: "pointer",
                border: 0,
                fontSize: 15,
              }}
              onClick={open}
            >
              {isOpen
                ? translation.collapse_info
                : translation.read_more + "..."}
            </button>
          </div>
        )}

      <Text
        className={classes.web_sounds}
        fz={18}
        fw={400}
        color="white"
        m={10}
        align="right"
      >
        {creatorData?.data?.attributes?.postsCount + " Sounds"}
      </Text>
      <div ref={ref} className={classes.sounds_area} style={{ marginTop: 20 }}>
        {flattenedData !== undefined &&
          flattenedData.map((x: any, i: number) => {
            // const isNewPost =
            //   x.isNew &&
            //   creator_name !== user?.attributes?.creatorProfile?.username;

            return (
              <Flex key={i} direction="column">
                {/* {isNewPost && !isNewVoiceAdded && (
                  <>
                    <Text fz={20} color="white" align="center" mb={30}>
                      {translation.new_voice}
                    </Text>
                    {(isNewVoiceAdded = true)}
                    </>
                )} */}
                <PublicCreatorProfilePostsCard
                  data={x}
                  creator={creatorData?.data?.attributes}
                  profile={false}
                />
              </Flex>
            );
          })}
        {flattenedData.length === 0 && (
          <Center mt={100}>
            <Text color="white" fw="700" fz={25} w="50%" align="center">
              {translation.not_have_audio}
            </Text>
          </Center>
        )}

        <div ref={targetRef} />
        <div className={classes.inifinty_loading_area}>
          {isFetchingNextPage && <Loader variant="bars" color="#D2E951" />}
        </div>
      </div>
    </PublicLayout>
  );
};

const useStyles = createStyles((theme) => ({
  creator_info: {
    alignItems: "flex-end",
    display: "flex",
    position: "relative",
    width: "100%",
    overflow: "hidden",
    borderEndEndRadius: 20,
    borderEndStartRadius: 20,

    [`@media (max-width: 500px)`]: {
      borderEndEndRadius: 50,
      borderEndStartRadius: 50,
      marginTop: 30,
    },
  },
  sounds_area: {
    [`@media (max-width: 500px)`]: {
      margin: "0px 20px",
    },
  },
  about: {
    display: "none",
    [`@media (max-width: 500px)`]: {
      margin: "18px 20px 0px 20px",
      display: "flex",
    },
  },
  about_more_button: {
    width: "100%",
    justifyContent: "flex-end",
    marginRight: 12,
    cursor: "pointer",
    display: "none",
    [`@media (max-width: 500px)`]: {
      margin: "10px 0px",
      paddingRight: 20,
      display: "flex",
    },
  },
  creator_image: {
    filter: "blur(1.2px)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "10%",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: -1,
    [`@media (max-width: 500px)`]: {
      marginTop: 60,
    },
  },
  creator_avatar: {
    objectPosition: "50% 0%",
  },
  avatar: {
    border: `1px solid ${theme.colors.green[0]}`,
    [`@media (max-width: 500px)`]: {
      display: "none",
    },
  },
  web_sounds: {
    [`@media (max-width: 500px)`]: {
      display: "none",
    },
  },
  mobile_sounds: {
    display: "none",
    [`@media (max-width: 500px)`]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "fit-content",
      marginTop: 24,
    },
  },
  name_area: {
    width: "100%",
    margin: "20px 30px",
  },
  name: {
    marginBottom: 0,
    [`@media (max-width: 500px)`]: {
      marginBottom: 8,
    },
  },
  play_button: {
    width: 68,
    height: 68,
    borderRadius: "50%",
    border: 0,
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(180deg, #D2E951 0%, #4D74FE 100%)",
    [`@media (max-width: 950px)`]: {
      width: 64,
      height: 63,
      position: "absolute",
      right: 29,
      bottom: 17,
    },
    [`@media (max-width: 500px)`]: {
      width: 53,
      height: 53,
      position: "absolute",
      right: 29,
      bottom: 17,
    },
  },
  name_area_content: {
    marginLeft: 25,
    [`@media (max-width: 500px)`]: {
      marginLeft: 0,
    },
  },
  modal_body: {
    backgroundColor: "#1E1E1E",
    padding: "45px",
    [`@media (max-width: 500px)`]: {
      backgroundColor: "transparent",
      padding: "0px 0px",
    },
  },
  modal_title: {
    position: "sticky",
    top: 0,
    zIndex: 99,
    [`@media (max-width: 500px)`]: {
      position: "relative",
      backgroundColor: "transparent",
    },
  },
  detail_button: {
    [`@media (max-width: 500px)`]: {
      // display: "none",
    },
  },
  header: {
    height: 50,
    top: 0,
    position: "fixed",
    display: "flex",
    zIndex: 999,
  },
  inifinty_loading_area: {
    paddingTop: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  submit_button: {
    ":before": {
      backgroundColor: "transparent !important",
    },
  },
  login_button: {
    width: "100%",
    backgroundColor: theme.colors.green[0],
    color: "black",
    borderRadius: 75,
    marginTop: 20,
    height: 59,
    zIndex: 20,
    transition: "all .5s",
    "&:hover": {
      transform: "scale(1.02)",
      transition: "all .5s",
    },
  },
  input: {
    border: "1px solid rgba(255, 255, 255, 0.4)",
    color: "white",
    borderRadius: 38,
    height: "56px",
    paddingLeft: 29,
    backgroundColor: "transparent",
  },
  innerInput: {
    height: 56,
    color: "white",
    paddingLeft: 29,
  },
  input_label: {
    color: "white",
    marginBottom: 7,
  },
  visibilityToggle: {
    color: theme.colors.green[0],
    marginRight: 29,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  forgot_password_button: {
    color: theme.colors.green[0],
    border: "0px solid",
    cursor: "pointer",
    backgroundColor: "transparent",
    margin: "0px 0px 20px 0px",
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
  forgot_password_button_mobile: {
    display: "none",
    [theme.fn.smallerThan("sm")]: {
      display: "block",
      backgroundColor: "transparent",
      color: theme.colors.green[0],
      border: "0px solid",
    },
  },
}));
export default PublicCreatorProfilePage;

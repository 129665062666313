/* eslint-disable @typescript-eslint/no-unused-vars */
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { AppDispatch, RootState } from "../store/store";
import {
  setUser,
  setUserToken,
  setLoading,
  setError,
  setSuccess,
  setLogin,
  setForgotPasswordMailPost,
} from "../store/reducers/user.reducer";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "@mantine/hooks";
import { removeToken } from "../utils/auth/auth";
import restClient from "../api/restClient";
import { User } from "./user.hook";
import { useGetTranslations } from "../api/translations/service";
import { forgotPasswordEvent, loginEvent, registrationEvent } from "../utils/log-events";
import { logEvent, setUserGA } from "../utils/analytics-service";

export const Auth = () => {
  const { getUser } = User();

  const { data } = useGetTranslations();
  const dispatch: AppDispatch = useAppDispatch();
  const navigate = useNavigate();
  const [token, setToken] = useLocalStorage({
    key: "token",
    defaultValue: null,
  });
  const translation = data?.data

  const loading: any = useAppSelector(
    (state: RootState) => state.authReducer.loading
  );
  const error: any = useAppSelector(
    (state: RootState) => state.authReducer.error
  );
  const loginSuccess: any = useAppSelector(
    (state: RootState) => state.authReducer.success
  );
  const loginControl: any = useAppSelector(
    (state: RootState) => state.authReducer.loginControl
  );

  const forgotPasswordMailPost: any = useAppSelector(
    (state: RootState) => state.authReducer.forgotPasswordMailPost
  );

  const login = async (email: string, password: string) => {
    dispatch(setLoading(true));
    try {
      await restClient
        .post(`/auth/local`, {
          identifier: email,
          password: password,
        })
        .then(async function (response) {
          dispatch(setSuccess(translation.login_page.login_success));
          setTimeout(async () => {
            await handleUserInfo(response.data);
            await getUser();
            logEvent(loginEvent);
            setUserGA(response.data.id)
            dispatch(setLogin(true));
            dispatch(setLoading(false));
            dispatch(setSuccess(""));
            navigate("/");
          }, 2000);
        })
        .catch(function (error) {
          dispatch(setError(error.response?.data.error?.message));
          if (error.message) {
            dispatch(setError(error.message));
          }
          setTimeout(() => {
            dispatch(setLoading(false));
            dispatch(setError(""));
          }, 4000);
          console.log(error);
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  const register = async (
    username: string,
    email: string,
    password: string
  ) => {
    let stripe_id = "";
    dispatch(setLoading(true));

    try {
      await restClient
        .post(`/auth/local/register`, {
          username: username,
          email: email,
          password: password,
          stripe_id: stripe_id,
        })
        .then(async function (response) {
          dispatch(setSuccess(translation.register_page.register_success));
          logEvent(registrationEvent);
          setTimeout(async () => {
            dispatch(setLoading(false));
            dispatch(setSuccess(""));
            await login(email, password);
          }, 2000);
        })
        .catch(function (error) {
          dispatch(setError(error.response.data.error?.message));
          setTimeout(() => {
            dispatch(setLoading(false));
            dispatch(setError(""));
          }, 2000);
          console.log(error);
        });
    } catch (error) {}
  };

  const forgotPassword = async (email: string) => {
    dispatch(setLoading(true));
    try {
      await restClient
        .post(`/auth/forgot-password`, {
          email: email,
        })
        .then(function (response) {
          logEvent(forgotPasswordEvent);
          dispatch(setForgotPasswordMailPost(response.data));
          dispatch(setLoading(false));
        })
        .catch(function (error) {
          console.log("error", error);
          dispatch(setLoading(false));
        });
    } catch (error) {}
  };

  const resetPassword = async (
    code: any,
    password: any,
    passwordConfirmation: any
  ) => {
    dispatch(setLoading(true));
    try {
      await restClient
        .post(`/auth/reset-password`, {
          code: code,
          password: password,
          passwordConfirmation: passwordConfirmation,
        })
        .then(function (response) {
          dispatch(setLoading(false));
          navigate("/");
        })
        .catch(function (error) {
          console.log("error", error);
          dispatch(setLoading(false));
        });
    } catch (error) {}
  };
  const logoutUser = () => {
    dispatch(setLoading(true));
    removeToken();
    navigate("/login");
    window.location.reload();
  };

  const handleUserInfo = async (user: any) => {
    dispatch(setUserToken(user.jwt));
    setToken(user.jwt);
    dispatch(setUser(user.user));
  };
  return {
    loading,
    error,
    loginSuccess,
    loginControl,
    forgotPasswordMailPost,
    login,
    logoutUser,
    register,
    forgotPassword,
    resetPassword,
  };
};

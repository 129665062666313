const WaveIcon = ({ color="#00000" }: any) => {
  // active ? "#D2E951":"#8C8FA5"
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="54.000000pt"
      height="63.000000pt"
      viewBox="0 0 54.000000 63.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,63.000000) scale(0.100000,-0.100000)"
        fill={color}
        stroke="none"
      >
        <path
          d="M127 624 c-11 -12 -8 -612 3 -619 6 -3 19 -3 30 0 20 6 20 14 18 313
-3 300 -3 307 -23 310 -12 2 -24 0 -28 -4z"
        />
        <path
          d="M365 538 c-3 -7 -4 -112 -3 -233 l3 -220 25 0 25 0 0 230 0 230 -23
3 c-12 2 -24 -3 -27 -10z"
        />
        <path
          d="M245 468 c-3 -8 -4 -81 -3 -163 l3 -150 25 0 25 0 0 160 c0 158 0
160 -23 163 -12 2 -24 -3 -27 -10z"
        />
        <path
          d="M16 427 c-14 -10 -16 -28 -14 -117 3 -105 3 -105 28 -105 25 0 25 0
28 106 3 115 -7 142 -42 116z"
        />
        <path
          d="M487 414 c-11 -11 -8 -192 3 -199 6 -3 19 -3 30 0 19 6 20 14 18 103
-3 89 -5 97 -23 100 -12 2 -24 0 -28 -4z"
        />
      </g>
    </svg>
  );
};

export default WaveIcon;

import axios from 'axios';

// const baseUrl = "https://mysterious-beach-67818.herokuapp.com/api";

const baseUrl = process.env.REACT_APP_API_URL;
// const baseUrl = process.env.REACT_APP_LIVE_URL;
// const baseUrl = process.env.REACT_APP_DEV_URL;
console.log("baseApiUrl", baseUrl)



export default axios.create({
  baseURL:baseUrl,
  responseType: 'json',
  //5 min timeout
  timeout: 300000,

  //follow up to 10 HTTP 3xx redirects
  maxRedirects: 10,

  //cap the maximum content length we'll accept to 50MBs, just in case
  maxContentLength: 50 * 1000 * 1000,
} );

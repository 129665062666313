/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import Layout from "../../components/high-level/layout/layout";
import { useNavigate, useParams } from "react-router-dom";
import {
  Flex,
  Image,
  Title,
  Text,
  createStyles,
  Avatar,
  Modal,
  ActionIcon,
  Loader,
  Center,
  Button,
} from "@mantine/core";
import CreatorCard from "../../components/low-level/CreatorProfilePostsCard/CreatorProfilePostsCard";
import {
  useDisclosure,
  useElementSize,
  useScrollIntoView,
} from "@mantine/hooks";
import { AiOutlineClose } from "react-icons/ai";
import "./styles.css";

import Loading from "../../components/low-level/loading/Loading";
import {
  useGetCreatorDetail,
  useGetCreatorPosts,
} from "../../api/creator/service";
import { useGetUser } from "../../api/user/service";
import CreatorEvents from "../../components/low-level/Creator/CreatorEvents";
const CreatorProfilePage = ({ translation }: any) => {
  const { classes } = useStyles();
  const [activeTab, setActiveTab] = useState("audio");
  const [opened, { open, close }] = useDisclosure(false);
  const { ref } = useElementSize();
  const navigate = useNavigate();
  const { targetRef } = useScrollIntoView<HTMLDivElement>({
    offset: 60,
  });

  let { creator_name }: any = useParams();
  const {
    data: creatorPosts,
    isLoading: creatorPostsLoading,
    fetchNextPage,
    isFetchingNextPage,
  }: any = useGetCreatorPosts(creator_name);
  const { data: creatorData, isLoading: creatorDetailLoading }: any =
    useGetCreatorDetail(creator_name);

  const { data: user } = useGetUser();
  const [isOpen, setIsOpen] = useState(false);
  const [newData, setNewData] = useState([]);

  useEffect(() => {
    if (user === null) {
      navigate("/login", { replace: true });
      return;
    }
  }, [user]);

  const flattenedData: any = useMemo(
    () =>
      creatorPosts
        ? creatorPosts?.pages?.flatMap((item: any) => item.data.creatorPosts)
        : [],
    [creatorPosts]
  );

  useEffect(() => {
    const handleScroll = () => {
      if (
        !isFetchingNextPage &&
        targetRef.current &&
        window.innerHeight + window.scrollY >= targetRef.current.offsetTop
      ) {
        fetchNextPage();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isFetchingNextPage]);

  useEffect(() => {
    if(!creatorDetailLoading && creatorData){
      if(!creatorData.data.creatorInfo.attributes.isSub && !creatorData.data.creatorInfo.attributes.isActive){
        navigate(`/my/feed`);
      }
    }
  }, [creatorData,creatorDetailLoading]);
  useEffect(() => {
    if (!creatorPostsLoading && flattenedData?.length > 0) {
      const _newData = flattenedData?.map((x: any) => ({
        ...x,
        playSound: false,
        isFirstPlay: false,
      }));
      setNewData(_newData);
    }
  }, [creatorPostsLoading, flattenedData]);

  if (
    creatorPostsLoading ||
    flattenedData === undefined ||
    creatorDetailLoading
  ) {
    return <Loading />;
  }

  const goChat = () => {
    navigate(`/my/chat/${creatorData.data.creatorInfo.attributes.chatId}`);
  };
  return (
    <Layout
      backButton={true}
      headerTitle={creatorData?.data?.creatorInfo?.attributes?.username}
    >
      <Modal
        classNames={{ body: classes.modal_body }}
        withCloseButton={false}
        overlayProps={{
          opacity: 0.75,
          blur: 5,
        }}
        centered
        size={600}
        zIndex={999}
        opened={opened}
        onClose={close}
      >
        <Flex
          direction="row"
          justify="space-between"
          align="center"
          mb={20}
          className={classes.modal_title}
        >
          <Flex direction="column" justify="center">
            <Title className={classes.name} fz={20} fw="bold" color="white">
              {creatorData?.data?.creatorInfo?.attributes?.name}
            </Title>
            <Text color="#A5A5A5" fz={16}>
              {" "}
              {"@" + creatorData?.data?.creatorInfo?.attributes?.username}
            </Text>
          </Flex>
          <ActionIcon variant="transparent" mr={5} onClick={close}>
            <AiOutlineClose color="white" size={20} />
          </ActionIcon>
        </Flex>

        <Image
          width="100%"
          height="50vh"
          fit="cover"
          styles={{ image: { border: "2px solid black" } }}
          radius={20}
          src={
            creatorData?.data?.creatorInfo?.attributes?.avatarThumbs?.sizes
              ?.original
          }
        />

        <Title fz={20} mt={20} mb={7} color="white">
          {translation.profile_edit_page.about_me}
        </Title>
        <Text fz={15} color="white">
          {creatorData?.data?.creatorInfo?.attributes?.about}
        </Text>
      </Modal>

      <div
        className={classes.creator_info}
        style={{
          height: creatorData?.data?.creatorInfo?.attributes?.isSub ? 200 : 280,
        }}
      >
        <div
          className={classes.creator_image}
          style={{
            backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%), url(${creatorData?.data?.creatorInfo?.attributes?.avatarThumbs?.sizes?.original})`,
          }}
        ></div>

        <Flex
          className={classes.name_area}
          direction="row"
          justify="space-between"
          align="center"
        >
          <Flex onClick={open} style={{ cursor: "pointer" }}>
            <Avatar
              size={92}
              radius={20}
              classNames={{
                root: classes.avatar,
                image: classes.creator_avatar,
              }}
              src={
                creatorData?.data?.creatorInfo?.attributes?.avatarThumbs?.thumbs
                  ?.c144
              }
            />
            <Flex
              className={classes.name_area_content}
              direction="column"
              justify="center"
            >
              <Title className={classes.name} fz={20} color="white">
                {creatorData?.data?.creatorInfo?.attributes?.name}
              </Title>
              <Text color="#A5A5A5">
                {"@" + creatorData?.data?.creatorInfo?.attributes?.username}
              </Text>
              <Text color="#D2E951" className={classes.detail_button}>
                {translation.profile_edit_page.about_me}
              </Text>
              {!creatorData?.data?.creatorInfo?.attributes?.isSub && (
                <div className={classes.mobile_sounds}>
                  <Text fz={18} fw={400} color="white" align="center">
                    {!creatorData?.data?.creatorInfo?.attributes?.postsCount}
                  </Text>
                  <Text fz={18} fw={400} color="#939393" align="center">
                    {translation.sounds}
                  </Text>
                </div>
              )}
            </Flex>
          </Flex>
          <Flex>
            <ActionIcon onClick={goChat} size={50} variant="transparent">
              <Image src={require("../../assets/chat_icon.png")} />
            </ActionIcon>
          </Flex>
          {/* <button className={classes.play_button}>
            <BsFillPlayFill size={"55%"} color="white" />
          </button> */}
        </Flex>
      </div>
      {!creatorData?.data?.creatorInfo?.attributes?.isSub && (
        <Text className={classes.about} color="white">
          {isOpen
            ? creatorData?.data?.creatorInfo?.attributes?.about
            : creatorData?.data?.creatorInfo?.attributes?.about?.substring(
                0,
                20
              ) +
              (creatorData?.data?.creatorInfo?.attributes?.about?.length > 20
                ? "..."
                : "")}
        </Text>
      )}
      {creatorData?.data?.creatorInfo?.attributes?.about?.length > 20 &&
        !creatorData?.data?.creatorInfo?.attributes?.isSub && (
          <div className={classes.about_more_button}>
            <button
              style={{
                backgroundColor: "transparent",
                color: "#D2E951",
                padding: "0px 0px ",
                cursor: "pointer",
                border: 0,
                fontSize: 15,
              }}
              onClick={open}
            >
              {isOpen
                ? translation.collapse_info
                : translation.read_more + "..."}
            </button>
          </div>
        )}
      <div className={classes.tabs}>
        <Button
          onClick={() => setActiveTab("audio")}
          className={`${classes.tab_button} ${
            activeTab === "audio" && classes.active_tab
          }`}
        >
          {translation.audio}
        </Button>
        <Button
          onClick={() => setActiveTab("event")}
          className={`${classes.tab_button} ${
            activeTab === "event" && classes.active_tab
          }`}
        >
          {translation.events}
        </Button>
      </div>
      {activeTab === "audio" && (
        <>
          <Text
            className={classes.web_sounds}
            fz={18}
            fw={400}
            color="white"
            m={10}
            align="right"
          >
            {creatorData?.data?.creatorInfo?.attributes?.postsCount + " Sounds"}
          </Text>
          <div
            ref={ref}
            className={classes.sounds_area}
            style={{ marginTop: 20 }}
          >
            {newData !== undefined &&
              newData.map((x: any, i: number) => {
                // const isNewPost =
                //   x.isNew &&
                //   creator_name !== user?.attributes?.creatorProfile?.username;

                return (
                  <Flex key={i} direction="column">
                    {/* {isNewPost && !isNewVoiceAdded && (
                  <>
                    <Text fz={20} color="white" align="center" mb={30}>
                      {translation.new_voice}
                    </Text>
                    {(isNewVoiceAdded = true)}
                  </>
                )} */}
                    <CreatorCard
                      data={x}
                      profile={
                        creator_name ===
                        user?.data?.attributes?.creatorProfile?.username
                      }
                    />
                  </Flex>
                );
              })}
            {newData.length === 0 && (
              <Center mt={100}>
                <Text color="white" fw="700" fz={25} w="50%" align="center">
                  {translation.not_have_audio}
                </Text>
              </Center>
            )}
            {/* {creatorPosts !== undefined &&
          creatorPosts
            ?.filter((y: any) => !y.isNew)
            .map((x: any, i: number) => (
              <Flex key={i} direction="column">
                <CreatorCard
                  data={x}
                  profile={
                    creator_name === user?.attributes?.creatorProfile?.username
                  }
                />
              </Flex>
            ))}
        {creator_name !== user?.attributes?.creatorProfile?.username &&
          creatorPosts?.filter((y: any) => y.isNew).length > 0 && (
            <Text fz={20} color="white" align="center" mb={30}>
              New voice message
            </Text>
          )}

        {creatorPosts !== undefined &&
          creatorPosts
            ?.filter((y: any) => y.isNew)
            .map((x: any, i: number) => (
              <Flex key={i} direction="column">
                <CreatorCard
                  data={x}
                  profile={
                    creator_name === user?.attributes?.creatorProfile?.username
                  }
                />
              </Flex>
            ))} */}
            <div ref={targetRef} />
            <div className={classes.inifinty_loading_area}>
              {isFetchingNextPage && <Loader variant="bars" color="#D2E951" />}
            </div>
          </div>
        </>
      )}
      {activeTab === "event" && <CreatorEvents translation={translation} user={user} creatorId={creatorData.data.creatorInfo.id} />}
    </Layout>
  );
};

const useStyles = createStyles((theme) => ({
  creator_info: {
    alignItems: "flex-end",
    display: "flex",
    position: "relative",
    width: "100%",
    overflow: "hidden",
    borderEndEndRadius: 20,
    borderEndStartRadius: 20,

    [`@media (max-width: 500px)`]: {
      borderEndEndRadius: 50,
      borderEndStartRadius: 50,
      marginTop: 60,
    },
  },
  sounds_area: {
    [`@media (max-width: 500px)`]: {
      margin: "0px 20px",
    },
  },
  about: {
    display: "none",
    [`@media (max-width: 500px)`]: {
      margin: "18px 20px 0px 20px",
      display: "flex",
    },
  },
  about_more_button: {
    width: "100%",
    justifyContent: "flex-end",
    marginRight: 12,
    cursor: "pointer",
    display: "none",
    [`@media (max-width: 500px)`]: {
      margin: "10px 0px",
      paddingRight: 20,
      display: "flex",
    },
  },
  creator_image: {
    filter: "blur(1.2px)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "10%",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: -1,
    [`@media (max-width: 500px)`]: {
      marginTop: 60,
    },
  },
  creator_avatar: {},
  avatar: {
    border: `1px solid ${theme.colors.green[0]}`,
    [`@media (max-width: 500px)`]: {
      display: "none",
    },
  },
  web_sounds: {
    [`@media (max-width: 500px)`]: {
      display: "none",
    },
  },
  mobile_sounds: {
    display: "none",
    [`@media (max-width: 500px)`]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "fit-content",
      marginTop: 24,
    },
  },
  name_area: {
    width: "100%",
    margin: "20px 30px",
  },
  name: {
    marginBottom: 0,
    [`@media (max-width: 500px)`]: {
      marginBottom: 8,
    },
  },
  play_button: {
    width: 68,
    height: 68,
    borderRadius: "50%",
    border: 0,
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(180deg, #D2E951 0%, #4D74FE 100%)",
    [`@media (max-width: 950px)`]: {
      width: 64,
      height: 63,
      position: "absolute",
      right: 29,
      bottom: 17,
    },
    [`@media (max-width: 500px)`]: {
      width: 53,
      height: 53,
      position: "absolute",
      right: 29,
      bottom: 17,
    },
  },
  name_area_content: {
    marginLeft: 25,
    [`@media (max-width: 500px)`]: {
      marginLeft: 0,
    },
  },
  modal_body: {
    backgroundColor: theme.colors.blue[0],
    padding: "45px",
    [`@media (max-width: 500px)`]: {
      backgroundColor: "transparent",
      padding: "0px 0px",
    },
  },
  modal_title: {
    position: "sticky",
    top: 0,
    zIndex: 99,
    backgroundColor: theme.colors.blue[0],
    [`@media (max-width: 500px)`]: {
      position: "relative",
      backgroundColor: "transparent",
    },
  },
  detail_button: {
    [`@media (max-width: 500px)`]: {
      // display: "none",
    },
  },
  header: {
    height: 50,
    top: 0,
    position: "fixed",
    display: "flex",
    zIndex: 999,
  },
  inifinty_loading_area: {
    paddingTop: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tabs: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    display: "flex",
    justifyContent: "space-between",
    borderRadius: 40,
    marginTop: 30,
    marginBottom: 20,
    [`@media (max-width: 950px)`]: {
      marginTop: 20,
    },
    [`@media (max-width: 500px)`]: {
      marginLeft: 20,
      marginRight: 20,
    },
  },
  tab_view: {
    [`@media (max-width: 500px)`]: {
      paddingRight: 20,
      paddingLeft: 20,
    },
  },
  tab_button: {
    borderRadius: 40,
    width: "100%",
    color: "lightgrey",
    transition: "all 0.3s",
    "&:hover": {
      color: theme.colors.green[0],
    },
  },
  active_tab: {
    // backgroundColor:theme.colors.green[0],
    border: `1px solid ${theme.colors.green[0]}`,
    borderRadius: 40,
    color: "white !important",
    transition: "all 0.3s",
  },
}));
export default CreatorProfilePage;

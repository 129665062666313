/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  Image,
  Text,
  createStyles,
  TextInput,
  Drawer,
  Button,
  Group,
  Flex,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import { Auth } from "../../hooks/auth.hook";
import AuthLayout from "../../components/high-level/layout/authLayout";
import { BiArrowBack } from "react-icons/bi";
import { useDisclosure } from "@mantine/hooks";
import { useViewportSize } from "@mantine/hooks";

const ForgotPasswordPage = ({translation}:any) => {
  const [sendMailModal, { open, close }] = useDisclosure(false);
  const {  width } = useViewportSize();
  const { forgotPassword, loading, forgotPasswordMailPost } = Auth();
  const navigate = useNavigate();
  const { classes } = useStyles();

  const form = useForm({
    initialValues: {
      email: "",
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  const onResetPassword = (values: any) => {
    forgotPassword(values.email);
  };

  useEffect(() => {
    if (!loading && forgotPasswordMailPost?.ok) {
      open();
    }
  }, [loading, forgotPasswordMailPost]);

  return (
    <AuthLayout>
      <Drawer
        opened={sendMailModal}
        onClose={close}
        position={width > 768 ? "right" : "bottom"}
        title=" "
        styles={{
          content: { backgroundColor: "#4D74FE" },
          body: {backgroundColor: "#4D74FE" },
          header: { backgroundColor: "#4D74FE" },
          close: { color: "white",backgroundColor:"transparent" },
        }}
        overlayProps={{ opacity: 0.5, blur: 4 }}
      >
        <div className={classes.modal_body}>
          <Image
            height={width > 768 ? 229 : 103}
            width={width > 768 ? 200 : 90}
            src={require("../../assets/send_mail_icon.png")}
          />
          <Text fz={20} ta="center" color="white" mt={50}>
            {translation.forgot_password_page.mail_send_succes_text_1}
           
          </Text>
          <Text fz={20} ta="center" color="white" mt={50}>
          {translation.forgot_password_page.mail_send_succes_text_2}

            
          </Text>
        </div>
        {/* Drawer content */}
      </Drawer>

      <div className={`${classes.login_area_container} `}>
        <div className={classes.login_area}>
          <div className={classes.login_area_top}>
            <button
              className={classes.go_back_button}
              onClick={() => navigate("/login")}
            >
              <BiArrowBack size={40} color="white" />
            </button>
          </div>
          <div className={classes.login_area_bottom}>
            <Flex className={classes.headerText}>
              <Text fz="32px" fw={300}>
              {translation.forgot_password_page.title}
              </Text>

            </Flex>

            <form onSubmit={form.onSubmit((values) => onResetPassword(values))}>
              <TextInput
                
                classNames={{
                  input: classes.input,
                  label: classes.input_label,
                }}
                placeholder={translation.email_placeholder}
                {...form.getInputProps("email")}
              />
              <Group position="center" mt={30}>
                <Button
                  classNames={{ root: classes.login_button }}
                  loading={loading}
                  type="submit"
                >
              {translation.forgot_password_page.send_mail_button}

                  
                </Button>
              </Group>
            </form>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};
const useStyles = createStyles((theme) => ({
  login_area_container: {
    width: "40%",
    height: "100%",
    color: "white",
    zIndex: 9,
    [theme.fn.smallerThan("sm")]: {
      width: "100%",
      marginTop: 50,
    },
  },
  login_area_animation: {
    animationName: "slideInDown",
    animationDuration: "1s",
    animationFillMode: "both",
    "@keyframes slideInDown": {
      "0%": {
        transform: "translateY(100%)",
      },
      "100%": {
        transform: "translateY(0%)",
      },
    },
  },
  login_area: {
    width: "85%",
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    height: "100%",
    justifyContent: "center",
    [theme.fn.smallerThan("sm")]: {
      width: "100%",
      alignItems: "center",
    },
  },
  input: {
    border: "1px solid rgba(255, 255, 255, 0.4)",
    color: "white",
    borderRadius: 38,
    height: "56px",
    paddingLeft: 29,
    backgroundColor: "transparent",
  },
  innerInput: {
    height: 56,
    color: "white",
    paddingLeft: 29,
  },
  input_label: {

    color: "white",
    marginBottom: 10,
  },
  visibilityToggle: {
    color: theme.colors.green[0],
    marginRight: 29,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  forgot_password_button: {
    color: theme.colors.green[0],
    border: "0px solid",
    cursor: "pointer",
    backgroundColor: "transparent",
    margin: "10px 0px 39px 0px",
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
    zIndex: 20,
  },
  forgot_password_button_mobile: {
    display: "none",
    zIndex: 20,
    [theme.fn.smallerThan("sm")]: {
      display: "block",
      backgroundColor: "transparent",
      color: theme.colors.green[0],
      border: "0px solid",
    },
  },
  login_button: {
    width: "100%",
    backgroundColor: theme.colors.green[0],
    color: "black",
    borderRadius: 75,
    height: 59,
    zIndex: 20,
    transition: "all .5s",
    "&:hover": {
      transform: "scale(1.02)",
      transition: "all .5s",
    },
  },
  login_area_top: {

    height: "20%",
    width: "90%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    backgroundColor: "transparent",
  },
  login_area_bottom: {
    height: "80%",
    [theme.fn.smallerThan("sm")]: {
      height: "80%",
      width: "90%",
    },
  },
  headerText: {
    paddingBottom: 40,
  },
  go_back_button: {
    border: "0px",
    zIndex: 20,
    marginBottom: 20,
    cursor: "pointer",
    backgroundColor: "transparent",
  },

  login_front_page: {
    display: "none",
    [theme.fn.smallerThan("sm")]: {
      display: "block",
    },
  },
  modal_body: {
    display: "flex",
    height: "90vh",
    width: "100%",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    [theme.fn.smallerThan("sm")]: {
      height: "50vh",
    },
  },
  send_mail_image: {},
}));
export default ForgotPasswordPage;

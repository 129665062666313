import { getToken } from "../../utils/auth/auth";
import restClient from "../restClient";

export const complaintsFn = async (data: any) => {
  const token = await getToken();

  const bearer = `Bearer ${token}`;
  const response = await restClient.post("/complaints-form", data, {
    headers: {
      Authorization: bearer,
    },
  });

  return response;
};

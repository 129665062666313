/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { User } from "./hooks/user.hook";
import { ReAuth } from "./hooks/reAuth.hook";
import { Image, createStyles } from "@mantine/core";
import AnimatedRoutes from "./components/high-level/AnimatedRoutes/AnimatedRoutes";
import { useLocalStorage } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { useGetTranslations } from "./api/translations/service";
import { logAppInformation } from "./utils/app-information";
import { initializeAnalytics, setupGlobalErrorHandling } from "./utils/analytics-service";
import { SocketProvider } from "./contexts/socketContext";

function App() {
  const { i18n }: any = useTranslation();
  const [language, setLanguage] = useLocalStorage({
    key: "i18nextLng",
    defaultValue: i18n.resolvedLanguage,
  });
  const { data, isLoading } = useGetTranslations();
  const { classes } = useStyles();

  const { user } = User();

  const { loading, reAuth } = ReAuth();

  useEffect(() => {
    initializeAnalytics('G-3KCK19FVJ6');
    const teardownErrorHandling = setupGlobalErrorHandling();

    return () => {
      teardownErrorHandling();
    };
  }, []);

  useEffect(() => {
    logAppInformation();
  }, []);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n, language]);

  useEffect(() => {
    reAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /*
  useEffect(() => {
    return;
    if (user) {
      console.log("WEBSOCKET INIT");

      const JWT_TOKEN = getToken();

      const URL = process.env.REACT_APP_WS_URL;
      if (!URL) {
        throw new Error("WS URL is not defined in the environment variables.");
      }
      const socket = io(URL, {
        transports: ["websocket"],
        auth: {
          strategy: "jwt",
          token: JWT_TOKEN,
        },
      });

      socket.on("connect", function () {
        console.log("Connected to server");
      });
      socket.on("disconnect", function () {
        console.log("Disconnected from server");
      });

      socket.on("userJoinedChat", function (response) {
        console.log("userJoinedChat");
        console.log(response);
      });

      socket.on("userLeftChat", function (response) {
        console.log("userLeftChat");
        console.log(response);
      });

      socket.on("hello from server", function (response) {
        console.log("server says hello to socket with id ", response.socketId);
        socket.emit("initial data from user", {
          id: user.id,
          isCreator: user.isCreator,
        });
        setTimeout(() => {
          // Emitte ein Event an den Client
          socket.emit("join chat", {
            chatId: 1,
            userId: user.id,
          });
        }, 3000); // Verzögerung in Millisekunden
        setTimeout(() => {
          // Emitte ein Event an den Client
          socket.emit("join chat", {
            chatId: 3,
            userId: user.id,
          });
        }, 3000); // Verzögerung in Millisekunden
      });

      // Cleanup-Funktion
      return () => {
        socket.off("connect");
        socket.off("disconnect");
        socket.close();
        console.log("WebSocket disconnected and cleaned up");
      };
    }
  }, [user]);

  */
  if (isLoading || loading || data === undefined)
    return (
      <div className={classes.logo}>
        <Image
          src={require("./assets/logo_loading.png")}
          fit="contain"
          height="20vh"
          width="20vw"
        />
      </div>
    );
  return (
    <SocketProvider user={user}>
      <Router>
        {user && (
          <>
            <div className={classes.background_light_blue}></div>
            <div className={classes.background_light_green}></div>
          </>
        )}
        {/* <button onClick={updateUserStatus}>UPDATE</button> */}
        <AnimatedRoutes translation={data?.data} user={user} />
      </Router>
    </SocketProvider>
  );
}
const useStyles = createStyles((theme) => ({
  logo: {
    width: "100vw",
    height: "100dvh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    animation: "lightBlueAnimationLogin 1s ease-in-out infinite",
    "@keyframes lightBlueAnimationLogin": {
      "0%": {
        transform: "scale(1)",
      },
      "50%": {
        transform: "scale(1.2)",
      },
      "100%": {
        transform: "scale(1)",
      },
    },
  },
  background_light_green: {
    backgroundColor: theme.colors.green[0],
    width: 486,
    height: 486,
    position: "fixed",
    left: "50%",
    marginLeft: "-102px",
    bottom: 0,
    opacity: 0.6,
    filter: "blur(225px)",
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      width: 204,
      height: 204,
      filter: "blur(100px)",
      opacity: 0.9,
    },
  },
  background_light_blue: {
    backgroundColor: theme.colors.blue[0],
    width: 728,
    height: 728,
    position: "fixed",
    left: "10%",
    top: 0,
    opacity: 0.7,
    filter: "blur(225px)",
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      width: 328,
      height: 328,
      right: -20,
      bottom: "23%",
      filter: "blur(100px)",
      background: "rgba(77, 116, 254, 0.9)",
    },
  },
}));
export default App;

/**
 * 
 * //  
    //       <Switch>
    //         <Route path="/" element={<FeedPage />} />
    //         <Route path="/profile" element={<ProfilePage />} />
    //         <Route path="/payment" element={<PaymentPage />} />
    //         <Route path="/record" element={<RecordPage />} />
    //         <Route path="/audio" element={<AudioPlayerPage />} />
    //         <Route path="/landing" element={<LandingPage />} />
    //         <Route path="/creator-feed" element={<CreatorFeedPage />} />
    //         <Route path="/payment-success" element={<PaymentSuccessPage />} />
    //         <Route path="/:creator_name" element={<CreatorProfilePage />} />
    //         <Route path="/" element={<LoginPage />} />
    //         <Route path="/register" element={<RegisterPage />} />
    //         <Route path="/terms" element={<TermsPage />} />
    //         <Route path="/forgot-password" element={<ForgotPasswordPage />} />
    //         <Route path="/reset-password" element={<ResetPasswordPage />} />
    //         <Route path="/success-login" element={<SocialMediaLoginPage />} />
    //       </Routes>
    //   </>
 */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetChatCreatorDetail } from "../../../api/chat/service";
import {
  ActionIcon,
  Center,
  Flex,
  Grid,
  Image,
  Modal,
  Text,
  Title,
  createStyles,
} from "@mantine/core";
import Media from "./Media";
import Messages from "./Messages";
import { useDisclosure } from "@mantine/hooks";
import { AiOutlineClose } from "react-icons/ai";
import FetchLoader from "../loading/FetchLoader";
import CreatorDetailArea from "./CreatorDetailArea";
import { useSocket } from "../../../contexts/socketContext";

const ChatRightArea = ({
  translation,
  setOpenVoice,
  openVoice,
  user,
  setActiveTab,
  activeTab,
  setOpenChat,
  openChat,
  setIsCreator
}: any) => {
  let { id }: any = useParams();
  const [creatorHeight, setCreatorHeight] = useState(0);
  const [opened, { open, close }] = useDisclosure(false);

  const { data: creatorData, isLoading } = useGetChatCreatorDetail(id);
  const { classes } = useStyles({ creatorData });

  const profileId = user?.data?.attributes?.userProfile?.id;
  const userId = user?.data?.id;

  const { joinChat, leaveChat }: any = useSocket();
  // const members = useOnlineChatMembersService(); // Verwende den Hook, um auf die Mitglieder zuzugreifen
  useEffect(() => {
    if (joinChat && id && userId && profileId) {
      joinChat(id, userId, profileId);
    }
    // Cleanup-Funktion, die beim Unmounten aufgerufen wird
    return () => {
      if (leaveChat) {
        leaveChat(parseInt(id));
      }
    };
  }, [userId, profileId, joinChat, id, leaveChat]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  useEffect(()=>{

    if(creatorData){
      setIsCreator(creatorData?.results?.attributes?.isCreator);
    }
  },[creatorData])

  const activeVoiceData = [
    {
      image: require("../../../assets/chat/voice_image.png"),
    },
  ];
  const otherVoiceData = [
    {
      image: require("../../../assets/chat/voice_image_2.png"),
    },
    {
      image: require("../../../assets/chat/voice_image.png"),
    },
    {
      image: require("../../../assets/chat/voice_image.png"),
    },
  ];

  const CreatorModal = () => {
    return (
      <Modal
        classNames={{ body: classes.modal_body }}
        withCloseButton={false}
        overlayProps={{
          opacity: 0.75,
          blur: 5,
        }}
        centered
        size={600}
        zIndex={999}
        opened={opened}
        onClose={close}
      >
        <Flex
          direction="row"
          justify="space-between"
          align="center"
          mb={20}
          className={classes.modal_title}
        >
          <Flex direction="column" justify="center">
            <Title className={classes.name} fz={20} fw="bold" color="white">
              {creatorData?.results?.attributes?.creator_profiles?.data?.name}
            </Title>
            <Text color="#A5A5A5" fz={16}>
              {" "}
              {"@" +
                creatorData?.results?.attributes?.creator_profiles?.data
                  ?.username}
            </Text>
          </Flex>
          <ActionIcon variant="transparent" mr={5} onClick={close}>
            <AiOutlineClose color="white" size={20} />
          </ActionIcon>
        </Flex>

        <Image
          width="100%"
          height="45vh"
          fit="cover"
          styles={{ image: { border: "2px solid black" } }}
          radius={20}
          src={
            creatorData?.results?.attributes?.creator_profiles?.data?.avatar
              ?.images?.sizes?.original
          }
        />

        <Title fz={20} mt={20} mb={7} color="white">
          {translation.profile_edit_page.about_me}
        </Title>
        <Text fz={15} color="white">
          {creatorData?.results?.attributes?.creator_profiles?.data?.about}
        </Text>
      </Modal>
    );
  };

  if (isLoading)
    return (
      <Center h="100%">
        <FetchLoader />
      </Center>
    );

  return (
    <div className={classes.chat_right_area} style={{}}>
      <CreatorModal />
      <CreatorDetailArea
        activeTab={activeTab}
        user={user}
        setActiveTab={setActiveTab}
        open={open}
        translation={translation}
        creatorData={creatorData}
        setOpenChat={setOpenChat}
        openChat={openChat}
        setOpenVoice={setOpenVoice}
        setCreatorHeight={setCreatorHeight}
      />

      {activeTab === "chat" && !openVoice && (
        <Messages
          creatorAreaHeight={creatorHeight}
          translation={translation}
          creator={creatorData}
          id={id}
        />
      )}
      {activeTab === "media" && !openVoice && (
        <Media
          translation={translation}
          creatorName={
            creatorData?.results.attributes?.creator_profiles?.data?.username
          }
        />
      )}
      <Grid
        className={`${activeTab === "media" && classes.media_grid}`}
        style={{ flexDirection: "row-reverse" }}
      >
        {openVoice && openChat && (
          <Grid.Col
            style={{
              position: "relative",
              padding: 30,
            }}
            span={openChat ? 6 : 12}
          >
            <div
              style={{
                position: "relative",
                backgroundColor: "rgba(67,67,67,.7)",
                height: "100%",
                borderRadius: 19,
              }}
            >
              {activeTab === "chat" && (
                <Messages
                  creatorAreaHeight={creatorHeight}
                  translation={translation}
                  creator={creatorData}
                  id={id}
                />
              )}
            </div>
          </Grid.Col>
        )}

        <Grid.Col span={openChat ? 6 : 12}>
          {openVoice && (
            <Flex
              w={openChat ? "100%" : "auto"}
              align="center"
              pos="relative"
              m={30}
            >
              <Grid style={{ width: "100%" }} align="stretch" grow>
                <Grid.Col span={otherVoiceData?.length > 1 ? 7 : 12}>
                  <Image
                    width="100%"
                    height="calc(100vh - 224px)"
                    radius={14}
                    fit="cover"
                    src={activeVoiceData[0].image}
                  />
                </Grid.Col>
                <Grid.Col span={5}>
                  <Flex
                    justify="space-between"
                    direction="column"
                    h={`calc(100vh - 224px ${
                      otherVoiceData.length > 1
                        ? `- (15px * ${otherVoiceData.length} )`
                        : `- 0px`
                    })`}
                  >
                    {otherVoiceData.map((x: any) => (
                      <Image
                        styles={{
                          root: {
                            height: `calc(100% / ${otherVoiceData.length})`,
                          },
                          figure: { height: "100%" },
                          imageWrapper: { height: "100%" },
                          image: { height: "100%" },
                        }}
                        width="100%"
                        height="100%"
                        mt={5}
                        mb={5}
                        radius={14}
                        fit="cover"
                        src={x.image}
                      />
                    ))}
                  </Flex>
                </Grid.Col>
              </Grid>
            </Flex>
          )}
        </Grid.Col>
      </Grid>
    </div>
  );
};
const useStyles = createStyles((theme, { creatorData }: any) => ({
  chat_right_area: {
    position: "relative",
    height: "calc(100vh - 50px)",
    [`@media (max-width: 768px)`]: {
      height: "100dvh",
    },
  },
  creator_detail_area: {
    borderBottom: "1px solid rgba(98,98,98,0.44)",
    position: "relative",
    [`@media (max-width: 768px)`]: {
      height: "20vh",
      borderBottomLeftRadius: 50,
      borderBottomRightRadius: 50,
      backgroundImage: `url(${creatorData?.results?.attributes?.creator_profiles?.data?.avatar?.images?.sizes.original})`,
    },
  },
  linear_area: {
    [`@media (max-width: 768px)`]: {
      position: "absolute",
      width: "100%",
      height: "100%",
      borderBottomLeftRadius: 50,
      borderBottomRightRadius: 50,
      backdropFilter: "blur(3px)",
      background:
        "linear-gradient(180deg, rgba(0,0,0,0.99) 0%, rgba(0,0,0,0) 100%)",
    },
  },
  tabs: {
    marginTop: 10,
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    display: "flex",
    justifyContent: "space-between",
    borderRadius: 40,
    marginBottom: 20,
    [`@media (max-width: 768px)`]: {
      display: "none",
    },
  },
  tab_button: {
    borderRadius: 40,
    width: "100%",
    color: "lightgrey",
    transition: "all 0.3s",
    "&:hover": {
      color: theme.colors.green[0],
    },
  },
  active_tab: {
    // backgroundColor:theme.colors.green[0],
    border: `1px solid ${theme.colors.green[0]}`,
    borderRadius: 40,
    color: "white !important",
    transition: "all 0.3s",
  },
  call_button: {
    width: 34,
    height: 34,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#6E767D",
    borderRadius: "50%",
  },
  media_button: {
    display: "none",
    [`@media (max-width: 768px)`]: {
      display: "flex",
    },
  },
  media_grid: {
    overflowY: "scroll",
    height: "100%",
    paddingTop: 10,
    [`@media (max-width: 768px)`]: {
      paddingTop: 25,
    },
  },
  media_area: {
    height: "100%",
    width: "100%",
    padding: 20,
    paddingBottom: 200,
  },
  main_creator_area: {
    padding: "25px 35px",
    [`@media (max-width: 768px)`]: {
      padding: "31px 15px 31px 15px",
    },
  },

  back_button_mobile: {
    display: "none",
    [`@media (max-width: 768px)`]: {
      display: "block",
      marginRight: 18,
    },
  },
  name_area: {
    [`@media (max-width: 768px)`]: {
      height: "100%",
      justifyContent: "flex-end",
    },
  },
  chat_button: {
    backgroundColor: theme.colors.blue[0],
  },
  modal_body: {
    backgroundColor: theme.colors.blue[0],
    padding: "45px",
    [`@media (max-width: 500px)`]: {
      backgroundColor: "transparent",
      padding: "0px 0px",
    },
  },
  modal_title: {
    position: "sticky",
    top: 0,
    zIndex: 99,
    backgroundColor: theme.colors.blue[0],
    [`@media (max-width: 500px)`]: {
      position: "relative",
      backgroundColor: "transparent",
    },
  },
  name: {
    marginBottom: 0,
    [`@media (max-width: 500px)`]: {
      marginBottom: 8,
    },
  },
}));
export default ChatRightArea;


const ChatIcon = ({ active }: any) => {
  // active ? "#D2E951":"#8C8FA5"
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 15C12.2167 15 12.3957 14.929 12.537 14.787C12.6783 14.645 12.7493 14.466 12.75 14.25V5.75C12.75 5.53333 12.679 5.35433 12.537 5.213C12.395 5.07167 12.216 5.00067 12 5C11.7833 5 11.6043 5.071 11.463 5.213C11.3217 5.355 11.2507 5.534 11.25 5.75V14.25C11.25 14.4667 11.321 14.6457 11.463 14.787C11.605 14.9283 11.784 14.9993 12 15ZM9.25 13C9.46667 13 9.64567 12.929 9.787 12.787C9.92833 12.645 9.99934 12.466 10 12.25V7.75C10 7.53334 9.929 7.35433 9.787 7.213C9.645 7.07167 9.466 7.00067 9.25 7C9.03333 7 8.85433 7.071 8.713 7.213C8.57167 7.355 8.50067 7.534 8.5 7.75V12.25C8.5 12.4667 8.571 12.6457 8.713 12.787C8.855 12.9283 9.034 12.9993 9.25 13ZM6.75 11C6.96667 11 7.14567 10.929 7.287 10.787C7.42833 10.645 7.49933 10.466 7.5 10.25V9.75C7.5 9.53334 7.429 9.35434 7.287 9.213C7.145 9.07167 6.966 9.00067 6.75 9C6.53333 9 6.35433 9.071 6.213 9.213C6.07167 9.355 6.00067 9.534 6 9.75V10.25C6 10.4667 6.071 10.6457 6.213 10.787C6.355 10.9283 6.534 10.9993 6.75 11ZM14.75 13C14.9667 13 15.1457 12.929 15.287 12.787C15.4283 12.645 15.4993 12.466 15.5 12.25V7.75C15.5 7.53334 15.429 7.35433 15.287 7.213C15.145 7.07167 14.966 7.00067 14.75 7C14.5333 7 14.3543 7.071 14.213 7.213C14.0717 7.355 14.0007 7.534 14 7.75V12.25C14 12.4667 14.071 12.6457 14.213 12.787C14.355 12.9283 14.534 12.9993 14.75 13ZM17.25 11C17.4667 11 17.6457 10.929 17.787 10.787C17.9283 10.645 17.9993 10.466 18 10.25V9.75C18 9.53334 17.929 9.35434 17.787 9.213C17.645 9.07167 17.466 9.00067 17.25 9C17.0333 9 16.8543 9.071 16.713 9.213C16.5717 9.355 16.5007 9.534 16.5 9.75V10.25C16.5 10.4667 16.571 10.6457 16.713 10.787C16.855 10.9283 17.034 10.9993 17.25 11ZM2 19.575V4C2 3.45 2.196 2.979 2.588 2.587C2.98 2.195 3.45067 1.99934 4 2H20C20.55 2 21.021 2.196 21.413 2.588C21.805 2.98 22.0007 3.45067 22 4V16C22 16.55 21.804 17.021 21.412 17.413C21.02 17.805 20.5493 18.0007 20 18H6L3.7 20.3C3.38334 20.6167 3.02067 20.6877 2.612 20.513C2.20333 20.3383 1.99933 20.0257 2 19.575ZM4 17.175L5.175 16H20V4H4V17.175Z"
        fill={active ? "#D2E951":"#8C8FA5"}
      />
    </svg>
  );
};

export default ChatIcon;

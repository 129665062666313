import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { eventEnum } from "../../../utils/enums/eventEnum";
import { useUpdateEvent } from "../../../api/event/service";
import { useDisclosure, useElementSize, useViewportSize } from "@mantine/hooks";
import {
  ActionIcon,
  Avatar,
  Button,
  Flex,
  Menu,
  Modal,
  Text,
  Title,
  createStyles,
} from "@mantine/core";
import { HiOutlineDotsVertical } from "react-icons/hi";
import MessagesIcon from "../../../assets/event/messages";
import { IoClose } from "react-icons/io5";
import { useSocket } from "../../../contexts/socketContext";
import { CiStreamOn } from "react-icons/ci";

const EventCreatorDetailArea = ({
  user,
  translation,
  creatorData,
  open,
  setCreatorHeight,
  openChat,
  setOpenChat,
  setOpenVoice,
  handleJoinAsHost,
  openVoice,
  isMuted,
  mute,
  unmute,
}: any) => {
  let { id }: any = useParams();
  const { ref, height } = useElementSize();
  const { updateEvent } = useUpdateEvent();
  const { classes } = useStyles({ creatorData });
  const { width } = useViewportSize();
  const [leaveModal, { open: leaveModalOpen, close }] = useDisclosure(false);
  const { leaveOwnerEvent, eventGoLive }: any = useSocket();

  useEffect(() => {
    if (ref) {
      setCreatorHeight(height);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, height]);

  useEffect(() => {
    // Function to handle the event going live
    const handleEventOnUnload = () => {
      if (
        creatorData?.results?.isOwner &&
        creatorData?.results?.attributes?.isOnline
      ) {
        startEvent();
      }
    };

    // Add the event listener for beforeunload
    window.addEventListener("beforeunload", handleEventOnUnload);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleEventOnUnload);
      if (
        creatorData?.results?.isOwner &&
        creatorData?.results?.attributes?.isOnline
      ) {
        startEvent();
      }
    };
  }, [creatorData, id]);

  const leaveEvent = () => {
    const data = {
      id: id,
      registered_users: [user.data.attributes.userProfile.id],
      type: eventEnum.REMOVE_USER,
    };
    updateEvent({ newData: data });
    if (creatorData?.results?.isOwner) {
      leaveOwnerEvent(creatorData?.results?.attributes?.chat?.data?.id);
    }
  };

  // const openVoiceArea = () => {
  //   if (!creatorData?.results?.isOwner) {
  //     handleJoin();
  //   }
  //   setOpenVoice(!openVoice);
  // };
  // useEffect(() => {
  //   console.log("zxcgirdi",creatorData?.results?.attributes?.isOnline)
  //   return () => {
  //     if (
  //       creatorData?.results?.attributes?.isOnline &&
  //       creatorData?.results?.isOwner
  //     ) {
  //       eventGoLive(
  //         id,
  //         false,
  //         creatorData?.results?.attributes?.chat?.data?.id
  //       );
  //     }
  //   };
  // }, [creatorData]);

  const startEvent = () => {
    eventGoLive(
      id,
      !creatorData?.results?.attributes?.isOnline,
      creatorData?.results?.attributes?.chat?.data?.id
    );
  };

  return (
    <div ref={ref} className={classes.creator_detail_area}>
      <Modal
        opened={leaveModal}
        classNames={{
          body: classes.modal_body,
          inner: classes.modal_inner,
        }}
        onClose={close}
        withCloseButton={false}
        centered
      >
        <button onClick={close} className={classes.close}>
          <IoClose color="white" size={25} />
        </button>
        <Flex direction="column" align="center">
          <Text
            w="80%"
            align="center"
            className={classes.delete_modal_text}
            color="white"
          >
            {translation?.event_page?.leave_event_text}
          </Text>
          <Button
            className={classes.yes_delete_button}
            onClick={() => leaveEvent()}
          >
            {translation?.chat_page?.leave_event}
          </Button>
        </Flex>
      </Modal>
      <Flex
        style={{ zIndex: 999 }}
        direction="column"
        className={`${classes.main_creator_area} `}
        w="100%"
      >
        <Flex direction="row" justify="space-between" align="center">
          <Flex>
            <Flex onClick={open} style={{ cursor: "pointer" }} align="center">
              <div className={classes.avatar}>
                <Avatar
                  src={creatorData?.results?.owner?.avatar?.thumbs?.c250}
                  size={63}
                  radius={14}
                />
              </div>

              <Flex
                ml={14}
                className={classes.name_area}
                justify="center"
                direction={"column"}
              >
                <Title style={{ zIndex: 99 }} fz={14} c="white">
                  {creatorData?.results?.owner?.name}
                </Title>
                <Text style={{ zIndex: 99 }} fz={14} color="white">
                  {"@" + creatorData?.results?.owner?.username}
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex gap={10} direction="row" display="flex">
            {width > 768 ? (
              <>
                {(creatorData?.results?.attributes?.isOnline ||
                  creatorData?.results?.isOwner) && (
                  <Button
                    onClick={
                      creatorData?.results?.isOwner ? startEvent : undefined
                    }
                    radius={10}
                    style={{
                      backgroundColor: creatorData?.results?.attributes
                        ?.isOnline
                        ? "#fa5252"
                        : "rgba(77, 116, 254, 1)",
                    }}
                    variant="filled"
                  >
                    {creatorData?.results?.attributes?.isOnline && (
                      <CiStreamOn style={{ marginRight: 10 }} size={25} />
                    )}

                    <Text fw="600" fz={14} color="white">
                      {creatorData?.results?.attributes?.isOnline
                        ? translation?.event_page?.on_air
                        : translation?.event_page?.go_live}
                    </Text>
                  </Button>
                )}
                {/* <Button
                  radius={10}
                  variant="filled"
                  onClick={openVoiceAreaHost}
                  className={classes.chat_button}
                >
                  <Text ml={7} fw="600" fz={14} color="white">
                    Voice Host
                  </Text>
                </Button> */}
                {/* <Button
                  radius={10}
                  variant="filled"
                  onClick={openVoiceArea}
                  className={classes.chat_button}
                >
                  <Text ml={7} fw="600" fz={14} color="white">
                    {translation.voice}
                  </Text>
                </Button> */}
                {openVoice && (
                  <Button
                    radius={10}
                    variant="filled"
                    style={{
                      backgroundColor:
                        openChat && openVoice ? "#D2E951" : "rgb(77, 116, 254)",
                    }}
                    onClick={() => setOpenChat(!openChat)}
                    className={classes.chat_button}
                  >
                    <MessagesIcon
                      color={openChat && openVoice ? "black" : "white"}
                      active={true}
                    />
                    <Text
                      ml={7}
                      fw="600"
                      fz={14}
                      color={openChat && openVoice ? "black" : "white"}
                    >
                      {translation.chat}
                    </Text>
                  </Button>
                )}
                <Button
                  onClick={leaveModalOpen}
                  radius={10}
                  color="red"
                  variant="filled"
                >
                  <Text fw="600" fz={14} color="white">
                    {translation?.chat_page?.leave_event}
                  </Text>
                </Button>
              </>
            ) : (
              <Menu
                styles={{
                  dropdown: {
                    border: "0px",
                    backgroundColor: "#fa5252",
                    borderRadius: 18,
                  },
                  arrow: {
                    backgroundColor: "#fa5252",
                    border: 0,
                    right: "-4px !important",
                  },
                }}
                position="right"
                withArrow
                offset={5}
                shadow="md"
              >
                <Menu.Target>
                  <ActionIcon
                    className={classes.settings_button}
                    variant="transparent"
                  >
                    <HiOutlineDotsVertical color="white" size={20} />
                  </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    style={{ padding: "5px 10px" }}
                    onClick={leaveModalOpen}
                    color="white"
                  >
                    <Text color="white" fz={14} fw={700}>
                      {translation?.chat_page?.leave_event}
                    </Text>
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            )}
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
};

const useStyles = createStyles((theme, { creatorData }: any) => ({
  creator_detail_area: {
    position: "relative",
    backgroundColor: "transparent",
    borderBottom: "1px solid rgba(98,98,98,0.44)",
    [`@media (max-width: 768px)`]: {
      borderBottom: "0px solid rgba(98,98,98,0.44)",
      backgroundColor: "rgb(28,28,28)",
    },
  },
  chat_button: {
    backgroundColor: theme.colors.blue[0],
  },
  main_creator_area: {
    padding: "20px 35px",
    [`@media (max-width: 768px)`]: {
      padding: "31px 15px 31px 15px",
    },
  },

  name_area: {
    [`@media (max-width: 768px)`]: {
      height: "100%",
    },
  },
  back_button_mobile: {
    display: "none",
    [`@media (max-width: 768px)`]: {
      display: "block",
      marginRight: 18,
    },
  },
  settings_button: {
    backgroundColor: "rgb(48,48,48)",
    borderRadius: "50%",
    width: 31,
    height: 31,
  },
  avatar: {
    background: "linear-gradient(132.14deg, #D2E951 9.16%, #4D74FE 92.2%)",
    padding: 3,
    borderRadius: 14,
  },
  delete_modal_text: {
    marginBottom: 30,
    marginTop: 30,
    [`@media (max-width: 500px)`]: {
      marginBottom: 10,
      marginTop: 10,
    },
  },
  close: {
    position: "absolute",
    zIndex: 999,
    right: 30,
    top: 30,
    backgroundColor: "transparent",
    border: "0px",
    padding: 0,
    cursor: "pointer",
  },
  yes_delete_button: {
    backgroundColor: theme.colors.red[6],
    color: "white",
    width: 220,
    height: 50,
    borderRadius: "50px",
    transition: "all .2s",
    "&:hover": {
      transition: "all .5s",
      transform: "scale(1.1)",
    },
    [`@media (max-width: 500px)`]: {
      width: "100%",
    },
  },
  modal_body: {
    backgroundColor: theme.colors.blue[0],
    padding: "45px",
    [`@media (max-width: 768px)`]: {
      borderRadius: "33px",
    },
    [`@media (max-width: 500px)`]: {
      borderRadius: "33px 33px 0px 0px",
    },
  },
  modal_inner: {
    [`@media (max-width: 500px)`]: {
      padding: 0,
      alignItems: "flex-end",
    },
  },
}));
export default EventCreatorDetailArea;

import React, { useState } from "react";
import StepsButton from "../steps/StepsButton";
import { useNavigate } from "react-router-dom";
import {
  ActionIcon,
  Flex,
  Image,
  Text,
  Title,
  createStyles,
} from "@mantine/core";
import { IoClose } from "react-icons/io5";

const CreateCreatorPreFace = ({ translation, close }: any) => {
  const [step, setStep] = useState(1);
  var steps = 3;
  const { classes } = useStyles();

  const navigate = useNavigate();
  const nextStep = () => {
    if (step !== steps) {
      setStep(step + 1);
    } else {
      navigate("/creator/create");
    }
  };

  const content = [
    {
      id: 1,
      title: translation?.create_creator_page.create_creator_pre_title_1,
      text: translation?.create_creator_page.create_creator_pre_text_1,
    },
    {
      id: 2,
      title: translation?.create_creator_page.create_creator_pre_title_2,
      text: translation?.create_creator_page.create_creator_pre_text_2,
    },
    {
      id: 3,
      title: translation?.create_creator_page.create_creator_pre_title_3,
      text: translation?.create_creator_page.create_creator_pre_text_3,
    },
  ];
  return (
    <div style={{ height: "100%" }}>
      <ActionIcon
        onClick={() => (step === 1 ? navigate("/creator/create") : close())}
        className={classes.close_button}
      >
        {step === 1 ? (
          <Text color="white">{translation.create_creator_page.skip}</Text>
        ) : (
          <IoClose color="white" size={20} />
        )}
      </ActionIcon>
      <Flex direction="row" h="100%">
        <Image
          height="100%"
          fit="contain"
          src={require(`../../../assets/creator/create_creator_${step}.webp`)}
        />
        <div className={classes.right_menu}>
          <div className={classes.info_box_content}>
            <div className={classes.info_box}>
              {content
                ?.filter((y: any) => y.id === step)
                ?.map((x: any, i: number) => (
                  <Flex
                    key={i}
                    direction="column"
                    justify="center"
                    align="center"
                    w="75%"
                    pt={35}
                    pb={step === 3 ? 50 : 120}
                    h="100%"
                  >
                    <Title align="left" fw={800} fz={step === 3 ? 35 : 25}>
                      {x.title}
                    </Title>
                    <Text mt={21} color="white">
                      {x.text}
                    </Text>
                  </Flex>
                ))}
            </div>
            <div className={classes.step_button}>
              <StepsButton translation={translation} step={step} steps={steps} nextStep={nextStep} />
            </div>
          </div>
        </div>
      </Flex>
    </div>
  );
};
const useStyles = createStyles((theme) => ({
  close_button: {
    position: "absolute",
    right: 25,
    top: 25,
    transition: "all .2s",
    ":hover": {
      backgroundColor: "transparent",
      transform: "scale(1.2)",
      transition: "all .5s",
    },
  },
  info_box_content: { position: "relative" },
  info_box: {
    width: "435px",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "white",
    backdropFilter: "blur(5.5px)",
    borderRadius: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  step_button: {
    position: "absolute",
    bottom: -50,
    left: "50%",
    transform: "translate(-50%, 0%)",
  },
  right_menu: {
    marginLeft: 35,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
export default CreateCreatorPreFace;

import LandingHeader from "../header/landingHeader";
import { createStyles } from "@mantine/core";
import LandingFooter from "../footer/landingFooter";
import { User } from "../../../hooks/user.hook";

const LandingLayout = ({
  translation,
  children,
  background,
  language,
  languageState,
  setLanguageState,
}: any) => {
  const { classes } = useStyles();
  const { user } = User();

  // useEffect(() => {
  //   document.body.style.overflowY = "visible";
  //   return () => {
  //     document.body.style.overflowY = "hidden";
  //   };
  // }, []);

  return (
    <div
      style={{
        overflow: "hidden",
        position: "relative",
      }}
    >
      <LandingHeader
        language={language}
        setLanguageState={setLanguageState}
        languageState={languageState}
        user={user}
        translation={translation}
      />
      <div>{children}</div>
      <div style={{ position: background ? "relative" : undefined }}>
        <div className={classes.yellow} style={{ bottom: 0 }}></div>
        <div className={classes.blue}></div>
      </div>
      <LandingFooter user={user} translation={translation} />
    </div>
  );
};
const useStyles = createStyles((theme) => ({
  background_area: {
    position: "relative",
  },
  yellow: {
    backgroundColor: "rgba(210, 233, 81, 1)",
    width: 730,
    height: 650,
    position: "absolute",
    top: 21,
    zIndex: -1,
    left: 50,
    opacity: 0.6,
    filter: "blur(250px)",
    [`@media (max-width: 768px)`]: {
      left: "inherit !important",
      right: 0,
    },
  },
  blue: {
    backgroundColor: "rgba(77, 116, 254, 1)",
    width: 730,
    height: 650,
    position: "absolute",
    top: 21,
    zIndex: -1,
    right: 50,
    opacity: 0.6,
    filter: "blur(250px)",
  },
}));
export default LandingLayout;

import Layout from "../../components/high-level/layout/layout";
import { User } from "../../hooks/user.hook";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { useViewportSize } from "@mantine/hooks";
import { Grid, Text, createStyles, NavLink, Flex } from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { FaChevronRight } from "react-icons/fa";
import { IoIosArrowRoundBack } from "react-icons/io";
import Contents from "../../components/low-level/Statistics/Contents";
import { TbFileShredder, TbLivePhoto } from "react-icons/tb";
import { MdOutlineAnalytics } from "react-icons/md";
import Analytics from "../../components/low-level/Statistics/Analytics";
import Events from "../../components/low-level/Statistics/Events";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const StatisticsPage = ({ translation }: any) => {
  const { user } = User();
  const { width } = useViewportSize();
  const [active, setActive] = useState("");
  const [activeLabel, setActiveLabel] = useState("");
  const [activeRight, setActiveRight]: any = useState("");
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { statistics_name } = useParams();

  useEffect(() => {
    if (statistics_name) {
      setActive(statistics_name);
      if (statistics_name === "contents") {
        setActiveLabel(translation.statistics_page.contents);
      }
      if (statistics_name === "analytics") {
        setActiveRight(translation.statistics_page.analytics);
      }
    }
  }, [statistics_name, translation]);

  useEffect(() => {
    if (width > 768 && active === "") {
      navigate("/my/statistics/contents");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, active]);

  const rightMenu = () => {
    if (active === "contents")
      return (
        <Contents
          creatorId={user?.attributes?.creatorProfile.id}
          translation={translation}
          setActiveRight={setActiveRight}
          activeRight={activeRight}
        />
      );
    if (active === "events")
      return (
        <Events
          creatorId={user?.attributes?.creatorProfile.id}
          translation={translation}
          setActiveRight={setActiveRight}
          activeRight={activeRight}
        />
      );
    if (active === "analytics")
      return (
        <Analytics
          creatorId={user?.attributes?.creatorProfile.id}
          translation={translation}
          setActiveRight={setActiveRight}
          activeRight={activeRight}
        />
      );
  };
  const leftMenuData = [
    {
      icon: <TbFileShredder size={22} />,
      value: "contents",
      label: translation.statistics_page.contents,
      permission: true,
    },
    {
      icon: <TbLivePhoto size={22} />,
      value: "events",
      label: translation.events,
      permission: true,
    },
    {
      icon: <MdOutlineAnalytics size={22} />,
      value: "analytics",
      label: translation.statistics_page.analytics,
      permission: true,
    },
  ];
  const items = leftMenuData.map(
    (item, index) =>
      item.permission && (
        <NavLink
          key={index}
          classNames={{ root: classes.navlink_root }}
          styles={{
            root: {
              padding: "16px 30px 15px 20px",
              fontSize: 20,
              fontWeight: 600,
              backgroundColor:
                item.value === active
                  ? "rgba(77, 116, 254, 0.4)"
                  : "transparent",
              color: "white",
            },
          }}
          icon={item.icon}
          label={item.label}
          rightSection={<FaChevronRight />}
          onClick={() => {
            setActive(item.value);
            setActiveLabel(item.label);
            navigate(`/my/statistics/${item.value}`);
          }}
        />
      )
  );

  return (
    <Layout
      mobileHeader={false}
      right={false}
      header={false}
      padding={false}
      mobileHeaderBack={width < 768}
      headerFixed={false}
      headerTitle="Statistics"
    >
      <Grid style={{ margin: 0 }}>
        <Grid.Col
          span={width > 768 ? 4 : 12}
          className={classes.left_menu_title}
        >
          {width > 768 ? (
            <Flex align="center">
              <Text fz={18} ml={14} fw={600}>
                {translation.statistics}
              </Text>
            </Flex>
          ) : (
            <Flex
              justify="space-between"
              direction="row"
              align="center"
              style={{ width: "100%" }}
            >
              <div
                style={{
                  width: 40,
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "center",
                }}
              >
                {(width < 501 || activeRight !== "") && (
                  <IoIosArrowRoundBack
                    onClick={() => {
                      navigate(-1);
                      if (
                        activeRight === "" ||
                        activeRight === translation.statistics
                      ) {
                        setActive("");
                      }
                    }}
                    size={30}
                  />
                )}
              </div>
              <Text fz={18} fw={600}>
                {activeRight ? activeRight : translation.statistics}
              </Text>
              <div style={{ width: 40, height: 30 }}></div>
            </Flex>
          )}
        </Grid.Col>
        <Grid.Col span={8} className={classes.right_menu_title}>
          <Flex align="center" ml={10}>
            <Text fw={600}>
              {activeRight !== "" ? activeRight : activeLabel}
            </Text>
          </Flex>
        </Grid.Col>
      </Grid>
      <Grid style={{ margin: 0 }}>
        <Grid.Col
          span={width > 768 ? 4 : 12}
          style={{
            padding: "0",
            display: active !== "" && width < 768 ? "none" : "block",
          }}
        >
          {/* <TextInput
            placeholder="Search"
            classNames={{ input: classes.search_input }}
          /> */}
          {items}
        </Grid.Col>
        <Grid.Col
          span={width > 768 ? 8 : 12}
          className={classes.right_menu}
          style={{
            overflowY: "auto",
            height:
              active || activeRight
                ? width > 768
                  ? "calc(100vh - 40px)"
                  : "100%"
                : 0,
            padding:
              active || activeRight
                ? width > 768
                  ? "23px 25px 6px 25px"
                  : "6px 15px 200px 15px"
                : "0px",
          }}
        >
          {rightMenu()}
        </Grid.Col>
      </Grid>
    </Layout>
  );
};
const useStyles = createStyles((theme) => ({
  navlink_root: {
    "&:hover": {
      backgroundColor: theme.colors.green,
      color: "black",
      transform: "scale(1.05)",
      transition: "all .2s",
    },
  },

  left_menu_title: {
    color: "white",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid rgba(98, 98, 98, 0.44)",
    [`@media (max-width: 768px)`]: {
      borderBottom: "0px ",
      padding: "20px 10px",
    },
  },
  right_menu: {
    paddingRight: "0em",
    height: "calc(100vh - 40px)",
    overflow: "hidden",
    borderLeft: "1px solid rgba(98, 98, 98, 0.44)",
  },
  right_menu_title: {
    display: "flex",
    alignItems: "center",
    borderLeft: "1px solid rgba(98, 98, 98, 0.44)",
    borderBottom: "1px solid rgba(98, 98, 98, 0.44)",
    color: "white",
    [`@media (max-width: 768px)`]: {
      display: "none",
    },
  },
}));
export default StatisticsPage;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Loader, createStyles } from "@mantine/core";
import WaveSurfer from "wavesurfer.js";
import { IoPlayCircleOutline, IoStopCircleOutline } from "react-icons/io5";

const ChatAudio = ({
  url,
  showAudioTime = true,
  disabled = false,
  interact = true,
  uploadLoading= false,
  setDuration,
  loading,
  duration,
  peaks,
}: {
  url?: string;
  showAudioTime?: boolean;
  disabled?: boolean;
  uploadLoading?: boolean;
  interact?: boolean;
  setDuration?: any;
  duration?: any;
  loading: boolean;
  audioCurrentTime?: number;
  play?: any;
  id?: number | null;
  peaks: any;
}) => {

  const { classes } = useStyles();
  const waveformRef: any = useRef(null);
  const wavesurfer: any = useRef(null);
  const [playing, setPlay] = useState(false);
  const [timePlay, setTimePlay] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const [totalTime, setTotalTime] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [disabledButton, setDisabledButton] = useState(false);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  const formWaveSurferOptions = (ref: any) => ({
    container: ref,
    waveColor: "rgba(255, 255, 255, 0.3)",
    progressColor: "#D2E951",
    cursorColor: "#D2E951",
    barWidth: 2.5,
    barRadius: 20,
    responsive: true,
    hideScrollbar: true,
    height: 38,
    barGap: 5.5,
    interact: interact,
    url: url,
    // If true, normalize by the maximum peak instead of 1.0.
    normalize: true,
    // Use the PeakCache to improve rendering speed of large waveforms.
    peaks: peaks[0],
    // backend: "MediaElement",
    mediaType: "audio",
  });

  useEffect(() => {
    if (duration === 0) {
      setDuration(totalTime);
    }
  }, [duration, totalTime]);

  useEffect(() => {
    // setLoading(true);
    setPlay(false);
    setTimePlay(false);

    const options: any = formWaveSurferOptions(waveformRef.current);
    wavesurfer.current = WaveSurfer.create(options);

    /*
    let _peaks = testData.data;
    if (peaks && peaks.length > 0 && peaks !== "" && peaks !== undefined) {
      _peaks = peaks.filter((x: any) => x !== 0 && x !== undefined);
    }
    wavesurfer.current.backend.setPeaks(_peaks);
    wavesurfer.current.drawBuffer();
    */
    wavesurfer.current.on("audioprocess", function () {
      let _currentTime = wavesurfer.current.getCurrentTime();
      setCurrentTime(_currentTime);
    });
    wavesurfer.current.on("ready", function () {
      setDisabledButton(false);
      // setLoading(false);
      let _totalTime = wavesurfer.current.getDuration();
      setTotalTime(_totalTime);
      // https://wavesurfer-js.org/docs/methods.html
      // wavesurfer.current.play();

      // make sure object stillavailable when file loaded
      if (wavesurfer.current) {
        wavesurfer.current.setVolume(volume);
        setVolume(volume);
      }
    });
    wavesurfer.current.on("finish", function () {
      setPlay(false);
      setTimePlay(false);
    });

    return () => wavesurfer.current.destroy();
  }, [url]);

  useEffect(() => {
    setDisabledButton(disabled);
  }, [disabled]);

  // useEffect(() => {
  //   console.log("file", url);
  //   if (url) {
  //     wavesurfer.current.load(url);
  //   }
  // }, [url]);
  useEffect(() => {
    if (playing) {
      const _remainingTime = (duration ? duration : totalTime) - currentTime;
      setRemainingTime(_remainingTime);
    }
  }, [currentTime, totalTime, playing]);

  const handlePlayPause = () => {
    setTimePlay(true);
    setPlay(!playing);
    if (playing) {
      wavesurfer.current.pause();
    } else {
      wavesurfer.current.play();
    }
  };

  const formatTime = function (time: any) {
    return [
      Math.floor((time % 3600) / 60), // minutes
      ("00" + Math.floor(time % 60)).slice(-2), // seconds
    ].join(":");
  };
  const getLeftPadding = () => {
    if (windowSize.innerWidth < 600) {
      return "0px";
    }
    if (showAudioTime) {
      return "10px";
    } else {
      return "0px";
    }
  };
  const getRightPadding = () => {
    if (windowSize.innerWidth < 600) {
      return "2px";
    } else {
      return "0px";
    }
  };
  return (
    <div style={{ width: "100%", position: "relative" }}>
      <div
        className={classes.main}
        style={{
          display: "flex",
          alignItems: "center",
          borderRadius: 40,
        }}
      >
        <div>
          <button
            className={classes.play_button}
            style={{ border: 0, opacity: disabledButton ? 0.5 : 1 }}
            onClick={handlePlayPause}
            disabled={disabledButton}
          >
            {!playing ? (
              <IoPlayCircleOutline size={"100%"} color="white" />
            ) : (
              <IoStopCircleOutline size={"100%"} color="white" />
            )}
          </button>
        </div>
        <div
          className={classes.wave_area}
          style={{ marginLeft: getLeftPadding(), marginRight: 10 }}
        >
          <div
            style={{
              width: "100%",
            }}
            id="waveform"
            ref={waveformRef}
          />
          {uploadLoading && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                height: "100%",
              }}
            >
                <Loader variant="bars" color="#D2E951"/>
            </div>
          )}
        </div>

        {showAudioTime && (
          <span style={{ paddingRight: getRightPadding() }}>
            <div style={{ width: "10%", height: "100%", color: "white" }}>
              {duration === 0
                ? timePlay
                  ? formatTime(remainingTime) === "-1:-1"
                    ? "0:00"
                    : formatTime(remainingTime)
                  : formatTime(totalTime)
                : timePlay
                ? formatTime(remainingTime)
                : formatTime(duration)}
            </div>
          </span>
        )}
      </div>
    </div>
  );
};
const useStyles = createStyles((theme) => ({
  play_button: {
    backgroundColor: "transparent",
    width: 38,
    height: 38,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [`@media (max-width: 500px)`]: {
      backgroundColor: "transparent",
      width: "60px !important",
      height: "60px !important",
    },
  },
  main: {
    width: "100%",
    [`@media (max-width: 500px)`]: {
      marginRight: 10,
    },
  },
  icon: {
    color: "black",
    [`@media (max-width: 500px)`]: {
      color: "white",
    },
  },
  wave_area: {
    width: "15vw",
    position: "relative",
    [`@media (max-width: 500px)`]: {
    width: "45vw",
    }
  },
}));

const MemoizedWaveAudioWithUrl = React.memo(
  ChatAudio,
  (prevProps, nextProps) => {
    return prevProps.url === nextProps.url;
  }
);
export default MemoizedWaveAudioWithUrl;

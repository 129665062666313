import React, { useMemo } from "react";
import {
  createStyles,
  Flex,
  Text,
  ActionIcon,
  Image,
  Center,
} from "@mantine/core";
import { Link } from "react-router-dom";
import { TbChevronRight, TbChevronLeft } from "react-icons/tb";
import { SimpleGrid } from "@mantine/core";
import { useGetPublicRecommendCreators } from "../../../api/feed/service";
import FetchLoader from "../../low-level/loading/FetchLoader";
import { useLocalStorage } from "@mantine/hooks";
const PublicRightMenu = ({ translation }: any) => {
  const { classes } = useStyles();
  const {
    data: recommendData,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
  }: any = useGetPublicRecommendCreators();
  // console.log("zxcisLoading",isLoading,"isFetched",isFetched,"isFetching",isFetching)
  const [initalSlide, setInitalSlide] = useLocalStorage({
    key: "initial-slide-page",
    defaultValue: 0,
  });

  const flattenedData: any = useMemo(
    () =>
      recommendData
        ? recommendData?.pages?.flatMap((item: any) => ({
            result: item.results,
            page: item.page,
          }))
        : [],
    [recommendData]
  );

  const nextSlide = () => {
    setInitalSlide(initalSlide + 1);
    if (initalSlide + 1 === flattenedData[flattenedData.length - 1]?.page) {
      fetchNextPage();
    }
  };

  const prevSlide = () => {
    if (initalSlide > 0) {
      setInitalSlide(initalSlide - 1);
    }
  };
  // console.log("zxcflattenedData",flattenedData)
  return (
    <div
      className={classes.right_box}
      style={{ height: "100vh", position: "relative" }}
    >
      <div
        className={classes.right_box_container}
        style={{
          height: "100%",
          position: "fixed",
        }}
      >
        <div className={classes.right_area_main}>
          {/* <Input
            mb={40}
            icon={<AiOutlineSearch />}
            styles={{
              input: {
                backgroundColor: "#35383E",
                borderRadius: 50,
                border: 0,
                height: 40,
                color: "white",
              },
            }}
            placeholder="Search"
          /> */}
          <div className={`${classes.creator_box} ${flattenedData[initalSlide]?.result?.length === 0 && classes.creator_box_no_suggestions}`}>
            <Flex direction="row" justify="space-between">
              <Text color="white" fw={600}>
                {translation.layout.suggestions}
              </Text>

              {flattenedData[initalSlide]?.result?.length > 0 && (
                <Flex>
                  
                  <ActionIcon
                    disabled={initalSlide === 0}
                    variant="transparent"
                    size={25}
                    onClick={prevSlide}
                  >
                    <TbChevronLeft
                      size="100%"
                      color={initalSlide === 0 ? "#5F5F5F" : "white"}
                    />
                  </ActionIcon>
                  <ActionIcon
                    variant="transparent"
                    disabled={flattenedData[initalSlide]?.result?.length < 6}
                    size={25}
                    onClick={nextSlide}
                  >
                    <TbChevronRight
                      size="100%"
                      color={
                        flattenedData[initalSlide]?.result?.length < 6
                          ? "#5F5F5F"
                          : "white"
                      }
                    />
                  </ActionIcon>
                </Flex>
              )}
            </Flex>
            {flattenedData[initalSlide]?.result?.length === 0 && (
              <Center mt={10} h="100%">
                <Text color="white" opacity={0.5} mt={10}>
                  {translation.layout.no_suggestions_yet}
                </Text>
              </Center>
            )}
            {(isFetchingNextPage || isLoading) && (
              <Center h={199}>
                <FetchLoader />
              </Center>
            )}
            <SimpleGrid cols={3} mt={19}>
              {flattenedData[initalSlide]?.result?.length > 0 &&
                flattenedData[initalSlide]?.result?.map((x: any, i: number) => (
                  <Link
                    key={i}
                    to={`/${x?.username}`}
                    className={classes.image_box}
                  >
                    <div>
                      <Image
                        radius={20}
                        classNames={{ image: classes.image }}
                        src={x?.avatar?.c144}
                      />
                      <Text
                        align="center"
                        color="white"
                        fw={500}
                        fz={10}
                        mt={5}
                      >
                        {x?.name?.substring(0, 15) +
                          (x?.name?.length > 15 ? "..." : "")}
                      </Text>
                    </div>
                  </Link>
                ))}
            </SimpleGrid>
          </div>
          <Flex wrap="wrap" justify="center" align="center" mt={5}>
            <Link to="/legalnotice" className={classes.link}>
              {translation.legal_notice}
            </Link>
            <div className={classes.dot}></div>
            <Link to="/terms" className={classes.link}>
              {translation.agb}
            </Link>
            <div className={classes.dot_null}></div>
            <div className={classes.dot_two}></div>
            <Link to="/privacy" className={classes.link}>
              {translation.datenschutz}
            </Link>
          </Flex>
        </div>
      </div>
    </div>
  );
};
const useStyles = createStyles((theme) => ({
  right_box: {
    width: "30vw",
    zIndex: 99,
    [`@media (max-width: 1265px)`]: {
      width: "30vw",
    },
    [`@media (max-width: 950px)`]: {
      width: "0vw",
      display: "none",
    },
  },
  image: {
    width: "92px !important",
    height: "101px !important",
    [`@media (max-width: 1265px)`]: {
      width: "62px !important",
      height: "71px !important",
    },
  },
  right_box_container: {
    width: "30vw",
    overflow: "hidden",
    marginTop: 20,
    [`@media (max-width: 1265px)`]: {
      width: "30vw",
    },
    [`@media (max-width: 950px)`]: {
      width: "0vw",
      display: "none",
    },
  },
  right_area_main: {
    width: "360px",
    [`@media (max-width: 1265px)`]: {
      width: "260px",
    },
  },
  creator_box: {
    width: "100%",
    backgroundColor: "#35383E",
    borderRadius: 20,
    padding: "21px 16px 36px 16px",
  },
  creator_box_no_suggestions:{
    padding: "21px 16px !important",

  },
  dot: {
    width: 4,
    height: 4,
    backgroundColor: "#D9D9D9",
    borderRadius: "50%",
  },
  dot_two: {
    width: 4,
    height: 4,
    backgroundColor: "#D9D9D9",
    borderRadius: "50%",
    [`@media (max-width: 1094px)`]: {
      display: "none",
    },
  },
  dot_null: {
    display: "none",
    [`@media (max-width: 1094px)`]: {
      display: "flex",
      width: 4,
      height: 4,
    },
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: 13,
    fontWeight: 400,
    paddingLeft: 10,
    paddingRight: 10,
  },
  image_box: {
    textDecoration: "none",
    width: "100%",
  },
}));
export default PublicRightMenu;

import { getToken } from "../../utils/auth/auth";
import restClient from "../restClient";

export const getStatisticsFn = async (id: number) => {
  try {
    const token = await getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const data = await restClient.get(`/statistics/${id}`, config);

    return {
      data: data.data,
    };
  } catch (error) {
    console.log("error", error);
  }
};
export const getStatisticsEventFn = async (id: number) => {
  try {
    const token = await getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const data = await restClient.get(`/analytics-events/${id}`, config);

    return {
      data: data.data,
    };
  } catch (error) {
    console.log("error", error);
  }
};
export const getAnalyticsFn = async (id: number) => {
  try {
    const token = await getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const data = await restClient.get(`/analytics/${id}`, config);

    return {
      data: data.data,
    };
  } catch (error) {
    console.log("error", error);
  }
};

export const postPayout = async (id: number) => {
  try {
    const token = await getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const data = await restClient.post(`/payout`, { id: id }, config);

    return {
      data: data.data,
    };
  } catch (error) {
    console.log("error", error);
  }
};

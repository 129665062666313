import { getToken } from "../../utils/auth/auth";
import restClient from "../restClient";

export const getCreatorPostsFn = async (
  username: string,
  pageParam: number
) => {
  const token = await getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let data = {};
  const offset = pageParam ? pageParam : 0;
  data = {
    ...data,
    offset: offset + 6,
  };
  try {
    await restClient
      .get(
        `/posts?pagination[start]=${offset}&sort=createdAt:DESC&pagination[limit]=6&filters[creator_profile][username][$eq]=${username}`,
        config
      )
      .then(function (response) {
        data = {
          ...data,
          offset: offset + 6,
          pagination: response.data.meta.pagination,
          creatorPosts: response.data.data,
        };
      })
      .catch(function (error) {
        console.log("error", error);
      });
  } catch (error) {
    console.log("error", error);
  }

  return {
    data,
  };
};
export const getCreatorPublicPostsFn = async (
  username: string,
  pageParam: number
) => {
  let data = {};
  const offset = pageParam ? pageParam : 0;
  data = {
    ...data,
    offset: offset + 6,
  };
  try {
    await restClient
      .get(
        `/public-post?pagination[start]=${offset}&sort=createdAt:DESC&pagination[limit]=6&filters[creator_profile][username][$eq]=${username}`
        // config
      )
      .then(function (response) {
        data = {
          ...data,
          offset: offset + 6,
          pagination: response.data.meta.pagination,
          creatorPosts: response.data.data,
        };
      })
      .catch(function (error) {
        console.log("error", error);
      });
  } catch (error) {
    console.log("error", error);
  }

  return {
    data,
  };
};
export const getCreatorDetailFn = async (username: string) => {
  const token = await getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let data = {};
  try {
    await restClient
      .get("/creator-by-slug/" + username, config)
      .then(async (res) => {
        data = { creatorInfo: res.data.data };
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (error) {
    console.log("error", error);
  }
  return {
    data,
  };
};
export const getPublicCreatorDetailFn = async (username: string) => {
  let data = {};
  try {
    await restClient
      .get("/public-creator-by-slug/" + username)
      .then(async (res) => {
        data = res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (error) {
    console.log("error", error);
  }
  return {
    data,
  };
};
export const getPost = async (id: string) => {
  const token = await getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let data = {};
  try {
    await restClient
      .get("/posts/" + id, config)
      .then(async (res) => {
        data = res.data.data;
      })
      .catch((err) => {
        console.log("error:", err);
      });
  } catch (error) {
    console.log("error", error);
  }
  return {
    ...data,
  };
};

export const createCreatorFn = async (avatar: any, user: any) => {
  const token = await getToken();
  const data: any = {};

  const formData = new FormData();

  data.about = user.about;
  data.username = user.username;
  data.name = user.name;
  data.user_profile = user.userId;

  formData.append("files.avatar", avatar);

  formData.append("data", JSON.stringify(data));
  const bearer = `Bearer ${token}`;
  const response = await  restClient.post("/creator-profiles", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: bearer,
    },
  });
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await restClient.put(
    "/user-profiles/" + user.userId,
    { data: { username: user.username } },
    config
  );
  return response;
};
// export const createPostFn = async (audio: any, newData: any) => {
//   const data: any = {};

//   const formData = new FormData();

//   data.text = newData.text;
//   data.creator_profile = newData.creator_profile;
//   data.isPublic = true;
//   data.mediaType = newData.mediaType;
//   data.price = newData.price;
//   data.assetData = newData.assetData;

//   formData.append("data", JSON.stringify(data));
//   formData.append("files.asset", audio);

//   const token = await getToken();
//   const bearer = `Bearer ${token}`;
//   const response = await restClient.post("/posts", formData, {
//     headers: {
//       "Content-Type": "multipart/form-data",
//       Authorization: bearer,
//     },
//     onUploadProgress: (progressEvent:any) => {
//       const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
//       console.log('Upload progress:', percentage);
//     },
//   });

//   return response.data;
// };

export const updatePostFn = async (id: any, data: any) => {
  const token = await getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    await restClient
      .put("/posts/" + id, { data: data }, config)
      .then(async (res) => {
        data = res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (error) {
    console.log("error", error);
  }
  return {
    ...data,
  };
};

export const upadateProfileFn = async (user: any) => {
  const token = await getToken();
  const data: any = {};
  const formData = new FormData();

  data.about = user.about;
  data.username = user.username;
  data.name = user.name;
  data.user_profile = user.userId;
  formData.append("files.avatar", user.avatar);
  formData.append("data", JSON.stringify(data));
  const bearer = `Bearer ${token}`;
  try {
    await restClient
      .put("/creator-profiles/" + user.id, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: bearer,
        },
      })
      .then(async(res) => {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        await restClient.put(
          "/user-profiles/" + user.userId,
          { data: { username: user.username } },
          config
        );
      });
  } catch (error) {
    console.log("error", error);
  }
  return {
    ...data,
  };
};

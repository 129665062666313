/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import {
  Textarea,
  TextInput,
  Flex,
  Text,
  Button,
  Avatar,
  createStyles,
  Image,
  Modal,
  Divider,
} from "@mantine/core";
import { Creator } from "../../../hooks/creator.hook";
import { MdAlternateEmail } from "react-icons/md";
import { useDisclosure, useViewportSize } from "@mantine/hooks";
import { IoClose } from "react-icons/io5";
import { useGetUser } from "../../../api/user/service";
import { useUpdateProfile } from "../../../api/creator/service";
const EditProfile = ({ translation, setActiveRight }: any) => {
  const { width } = useViewportSize();
  const [saveButtonControl, setSaveButtonControl] = useState(true);
  const [errorUsername, setErrorUsername] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [avatar, setAvatar] = useState<any>();
  const [mobileOpenCamera, setMobileOpenCamera] = useState<any>();
  const [avatarUploadModal, { open, close }] = useDisclosure(false);
  const {
    getCreatorUsernameControl,
    getCreatorUsernameSuggestControl,
    usernameCheck,
    usernameSuggestCheck,
  } = Creator();
  const { mutateUpdateProfile, updateLoading } = useUpdateProfile();

  const { data: user, isLoading } = useGetUser();
  const [name, setName] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [about, setAbout] = useState<string>("");
  const [suggestUsername, setSuggestUsername] = useState<string>("");

  const { classes } = useStyles();
  useEffect(() => {
    if (!isLoading) {
      setUsername(user?.data?.attributes?.creatorProfile?.username);
      setName(user?.data?.attributes?.creatorProfile?.name);
      setAbout(user?.data?.attributes?.creatorProfile?.about);
    }
  }, [user, isLoading]);

  useEffect(() => {
    setActiveRight(translation.settings_page.edit_profile);
  }, []);

  useEffect(() => {
    let suggestedUsername: string;
    if (usernameSuggestCheck?.available) {
      suggestedUsername = name.toLowerCase().replace(/\s/g, "");
    } else {
      suggestedUsername =
        name.toLowerCase().replace(/\s/g, "") + Math.floor(Math.random() * 100);
    }
    setSuggestUsername(suggestedUsername);
  }, [usernameSuggestCheck, usernameCheck, name]);

  useEffect(() => {
    if (username.length > 2) {
      if (usernameCheck?.available) {
        setError(false);
        setSaveButtonControl(false);
        setErrorUsername(usernameCheck?.message);
      } else {
        setError(true);
        setSaveButtonControl(true);
        setErrorUsername(usernameCheck?.message);
      }
    } else {
      setError(true);
      setSaveButtonControl(false);
      setErrorUsername("");
    }
  }, [usernameCheck, username]);

  useEffect(() => {
    if (username?.length >= 3 && name?.length >= 3) {
      if (
        avatar !== undefined ||
        name !== user?.data?.attributes?.creatorProfile?.name ||
        username !== user?.data?.attributes?.creatorProfile?.username ||
        about !== user?.data?.attributes?.creatorProfile?.about
      ) {
        setSaveButtonControl(false);
      } else {
        setSuggestUsername("");
        setSaveButtonControl(true);
      }
    } else {
      setSaveButtonControl(true);
    }
  }, [user, name, username, about, avatar, isLoading]);

  const hiddenFileInput: any = useRef(null);

  const handleUsernameClick = () => {
    setUsername(suggestUsername);
    usernameControl(suggestUsername);
    setSuggestUsername("");
  };
  const usernameControl = async (e: any) => {
    setUsername(e);
    if (e?.length > 2) {
      await getCreatorUsernameControl(e);
    }
  };
  const avatarChange = () => {
    hiddenFileInput.current.click();
  };

  const updateCreator = async () => {
    const creatorInformation = {
      userId: user?.data?.attributes.userProfile.id,
      id: user?.data?.attributes.creatorProfile.id,
      name: name,
      username: username,
      about: about,
      avatar: avatar,
    };
    await mutateUpdateProfile(creatorInformation);
  };
  const changeHandler = (event: any) => {
    setAvatar(event.target.files[0]);
  };

  const handleKeyDown = (e: any) => {
    if (
      e.keyCode === 32 ||
      e.keyCode === 191 ||
      e.keyCode === 187 ||
      e.keyCode === 49 ||
      e.keyCode === 189
    ) {
      e.preventDefault();
    }
  };
  const suggestNameControl = (name: string) => {
    const suggestedUsername: string = name.toLowerCase().replace(/\s/g, "");
    setSuggestUsername(suggestedUsername);
  };
  return (
    <>
      <Modal
        opened={avatarUploadModal}
        onClose={close}
        withCloseButton={false}
        classNames={{
          body: classes.modal_body,
          inner: classes.modal_inner,
        }}
      >
        <button onClick={close} className={classes.close}>
          <IoClose color="white" size={25} />
        </button>
        <Flex align="center" justify="center" direction="column">
          <Button
            onClick={() => {
              setMobileOpenCamera(true);
              setTimeout(() => {
                avatarChange();
              }, 0);
            }}
            h={50}
            w="100%"
          >
            <Text fw={500} fz={16} color="white">
              {translation.profile_edit_page.take_photo}
            </Text>
          </Button>
          <Divider color="#85A0FF" w={"100%"} />
          <Button
            onClick={() => {
              setMobileOpenCamera(false);
              setTimeout(() => {
                avatarChange();
              }, 0);
            }}
            h={60}
            w="100%"
          >
            <Text fw={500} fz={16} color="white">
              {translation.profile_edit_page.select_photo}
            </Text>
          </Button>
        </Flex>
      </Modal>
      <div>
        <Flex justify="center">
          <button
            onClick={() => (width > 768 ? avatarChange() : open())}
            style={{
              border: 0,
              backgroundColor: "transparent",
              position: "relative",
              cursor: "pointer",
            }}
          >
            <Avatar
              size={130}
              variant="outline"
              styles={{
                placeholder: { border: 0, backgroundColor: "transparent" },
              }}
              radius={20}
              src={
                avatar
                  ? URL.createObjectURL(avatar)
                  : user?.data?.attributes.creatorProfile?.avatarThumbs?.thumbs
                      ?.c144
              }
              alt="Random image"
            />
            <div className={classes.change_avatar_icon}>
              <Image
                width={"100%"}
                src={require("../../../assets/settings/avatar_change_pencil.png")}
              />
            </div>
          </button>
        </Flex>
        <input
          type="file"
          accept="image/*"
          ref={hiddenFileInput}
          capture={mobileOpenCamera ? "environment" : undefined}
          onChange={changeHandler}
          style={{ display: "none" }}
        />

        <TextInput
          label={translation.profile_edit_page.user_screen_name}
          placeholder={translation.profile_edit_page.user_screen_name}
          maxLength={30}
          value={name}
          classNames={{
            label: classes.input_label,
            input: classes.input,
          }}
          onChange={(e: any) => {
            setName(e.target.value.replace(/[^a-zA-Z0-9_-]/g, ""));
            setTimeout(() => {
              suggestNameControl(e.target.value);
            }, 1000);
          }}
        />
        <TextInput
          label={translation.username}
          placeholder={translation.username}
          maxLength={16}
          styles={{
            icon: { marginLeft: 8 },
          }}
          icon={<MdAlternateEmail color={"white"} />}
          classNames={{
            label: classes.input_label,
            input: classes.input,
          }}
          onKeyDown={(e: any) => handleKeyDown(e)}
          onChange={(e: any) =>
            usernameControl(
              e.target.value.toLowerCase().replace(/[^a-zA-Z0-9_-]/g, "")
            )
          }
          value={username}
        />
        <Text
          mb={10}
          style={{
            color: !error && usernameCheck?.available ? "green" : "red",
          }}
        >
          {errorUsername && errorUsername}
        </Text>
        {suggestUsername?.length > 3 && (
          <Flex mb={20} direction="column">
            <Text fw={600} color="white">
              {translation.layout.suggestions}
            </Text>
            <Text
              fw={500}
              fz={16}
              style={{
                cursor: "pointer",
                color: "rgba(210, 233, 81, 1)",
              }}
              onClick={handleUsernameClick}
              color="white"
            >
              {"@" + suggestUsername}
            </Text>
          </Flex>
        )}
        <Textarea
          label="Über mich"
          maxLength={200}
          placeholder="Über mich"
          classNames={{
            input: classes.textarea,
            label: classes.input_label,
          }}
          value={about}
          onChange={(e: any) => setAbout(e.target.value)}
        />
        <Flex className={classes.save_button_area}>
          <Button
            className={classes.save_button}
            disabled={saveButtonControl || error}
            onClick={updateCreator}
            loading={updateLoading}
          >
            {translation.profile_edit_page.update_profile}
          </Button>
        </Flex>
      </div>
    </>
  );
};
const useStyles = createStyles((theme) => ({
  input_label: {
    color: "white",
    marginBottom: 7,
    fontSize: 16,
  },
  input: {
    backgroundColor: "transparent",
    borderRadius: 30,
    marginBottom: 20,
    border: "1px solid rgba(255, 255, 255, 0.4)",
    color: "white",
    height: 50,
    padding: "0px 29px",
    fontSize: 18,
  },
  textarea: {
    padding: "15px 29px",
    fontSize: 18,
    backgroundColor: "transparent",
    borderRadius: 30,
    border: "1px solid rgba(255, 255, 255, 0.4)",
    color: "white",
    minHeight: "20vh",
  },
  change_avatar_icon: {
    position: "absolute",
    right: 0,
    bottom: 0,
    width: 50,
  },
  save_button: {
    backgroundColor: theme.colors.green,
    color: "black",
    width: 220,
    height: 50,
    borderRadius: "50px",
    transition: "all .2s",
    ":before": {
      backgroundColor: "transparent !important",
    },
    "&:hover": {
      backgroundColor: theme.colors.blue,
      color: "white",
      transition: "all .5s",
      transform: "scale(1.1)",
    },
    [`@media (max-width: 768px)`]: {
      width: "90%",
      height: 60,
    },
  },
  save_button_area: {
    justifyContent: "right",
    marginTop: 40,
    [`@media (max-width: 768px)`]: {
      justifyContent: "center",
    },
  },
  modal_body: {
    backgroundColor: theme.colors.blue[0],
    padding: "45px",
    [`@media (max-width: 768px)`]: {
      borderRadius: "33px 33px 0px 0px",
      width: "100%",
    },
  },
  modal_inner: {
    [`@media (max-width: 768px)`]: {
      padding: 0,
      alignItems: "flex-end",
    },
  },
  close: {
    position: "absolute",
    zIndex: 999,
    right: 20,
    top: 20,
    backgroundColor: "transparent",
    border: "0px",
    padding: 0,
    cursor: "pointer",
  },
}));

export default EditProfile;

import { Image, createStyles } from "@mantine/core";
const AuthLayout = ({ children, pageName }: any) => {
  const { classes } = useStyles();

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

 
  return (
    <div
      className={classes.main_container}
      style={{ height: isIOS ? "-webkit-fill-available" : "100vh" }}
    >
      <div
        className={`${classes.background_light_blue}  ${
          pageName === "login" && classes.background_light_blue_animation_login
        } ${pageName === "register" && classes.background_light_blue_animation}`}
      ></div>

      <div
        className={`${classes.background_light_green}  ${
          pageName === "login" && classes.background_light_green_animation_login
        } ${
          pageName === "register" && classes.background_light_green_animation
        }`}
      ></div>

      <div className={classes.image_area}>
        <Image
          height={"100vh"}
          fit="contain"
          src={require("../../../assets/login_image.webp")}
        />
      </div>
      <div className={classes.background_image_area}></div>
      {children}
    </div>
  );
};
const useStyles = createStyles((theme) => ({
  main_container: {
    width: "100vw",
    display: "flex",
    overflow: "hidden",
    overflowX: "hidden",
    position: "relative",
    backgroundColor: "#1E1E1E",
  },
  image_area: {
    position: "absolute",
    left: 0,
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
  background_image_area: {
    width: "65vw",
    [theme.fn.smallerThan("sm")]: {
      width: "0vw",
    },
  },
  background_light_green: {
    backgroundColor: theme.colors.green[0],
    width: 576,
    height: 576,
    position: "absolute",
    left: "20%",
    bottom: 0,
    opacity: 0.6,
    filter: "blur(225px)",
    transform: "rotate(-15deg)",
    [theme.fn.smallerThan("sm")]: {
      opacity: 1,
      width: 204,
      height: 204,
      filter: "blur(100px)",
    },
  },
  background_light_green_animation_login: {
    animation: "lightGreenAnimationLogin 1s ease-in-out both",
    "@keyframes lightGreenAnimationLogin": {
      "0%": {
        transform: "translate(0, 0)",
      },

      "100%": {
        transform: "translate(40%, 0)",
      },
    },
  },
  background_light_green_animation: {
    animation: "lightGreenAnimation 1s ease-in-out both",
    "@keyframes lightGreenAnimation": {
      "0%": {
        transform: "translate(40%, 0)",
      },

      "100%": {
        transform: "translate(0, 0)",
      },
    },
  },
  background_light_blue: {
    backgroundColor: theme.colors.blue[0],
    width: 576,
    height: 576,
    position: "absolute",
    left: 0,
    top: 0,
    opacity: 0.6,
    filter: "blur(225px)",
    [theme.fn.smallerThan("sm")]: {
      opacity: 1,
      width: 204,
      height: 204,
      filter: "blur(100px)",
    },
  },
  background_light_blue_animation_login: {
    animation: "lightBlueAnimationLogin 1s ease-in-out both",
    "@keyframes lightBlueAnimationLogin": {
      "0%": {
        transform: "translate(30%, 0)",
      },

      "100%": {
        transform: "translate(0, 0)",
      },
    },
  },
  background_light_blue_animation: {
    animation: "lightBlueAnimation 1s ease-in-out both",
    "@keyframes lightBlueAnimation": {
      "0%": {
        transform: "translate(0, 0)",
      },

      "100%": {
        transform: "translate(30%, 0)",
      },
    },
  },
}));
export default AuthLayout;

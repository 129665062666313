import { useViewportSize } from "@mantine/hooks";
import { Image, createStyles } from "@mantine/core";
const AuthLayout = ({ children, pageName, backgroundImage, step }: any) => {
  const { classes } = useStyles();
  const { width } = useViewportSize();

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setShowImg1(false);
  //     setShowImg3(true);
  //     setTimeout(() => {
  //       setShowImg5(true);
  //       setShowImg3(false);
  //       setTimeout(() => {
  //         setShowImg3(true);
  //       }, 500);
  //       setTimeout(() => {
  //         setShowImg5(false);
  //         setShowImg3(false);
  //         setShowImg1(true);
  //       }, 1000);
  //     }, 1000);
  //   }, 1000);
  // });

  return (
    <div
      className={classes.main_container}
      style={{ height: isIOS ? "-webkit-fill-available" : "100vh" }}
    >
      <div className={classes.logo}>
        <Image
          height={122}
          fit="contain"
          src={require("../../../assets/logo.png")}
        />
      </div>
      <div
        className={`${classes.background_light_blue}  ${
          step === 1 && classes.background_light_blue_animation_login
        } ${step === 2 && classes.background_light_blue_animation}  ${
          step === 3 && classes.background_light_blue_animation
        }`}
      ></div>
      <div
        className={`${classes.background_light_green}  ${
          step === 1 && classes.background_light_green_animation_login
        } ${step === 2 && classes.background_light_green_animation} ${
          step === 3 && classes.background_light_green_animation_login
        }`}
      ></div>
      <div className={classes.image_area}>
        <div className={classes.wave_audio_animation_background}>
          <div className={classes.wave_audio_animation_background_container}>
            <Image
              className={`${classes.wave_audio_animation_background_1}`}
              src={require("../../../assets/creator/ellipse_1.png")}
            />
            <Image
              className={`${classes.wave_audio_animation_background_2}`}
              src={require("../../../assets/creator/ellipse_2.png")}
            />
            {true && (
              <Image
                className={classes.wave_audio_animation_background_3}
                src={require("../../../assets/creator/ellipse_3.png")}
              />
            )}
            {true && (
              <Image
                className={classes.wave_audio_animation_background_5}
                src={require("../../../assets/creator/ellipse_5.png")}
              />
            )}
          </div>
        </div>
        <div className={classes.wave_audio_animation_container}>
          <div className={classes.wave_audio_box}></div>
          <div className={classes.wave_audio_box}></div>
          <div className={classes.wave_audio_box}></div>
          <div className={classes.wave_audio_box}></div>
          <div className={classes.wave_audio_box}></div>
          <div className={classes.wave_audio_box}></div>
          <div className={classes.wave_audio_box}></div>
          <div className={classes.wave_audio_box}></div>
          <div className={classes.wave_audio_box}></div>
        </div>
        <Image
          height={"100vh"}
          fit={width < 768 ? "cover" : "contain"}
          src={backgroundImage}
        />
        <div className={classes.mobile_filter}></div>
      </div>
      {children}
    </div>
  );
};
const useStyles = createStyles((theme) => ({
  mobile_filter: {
    display: "none",
    [`@media (max-width: 768px)`]: {
      display: "flex",
      background:
        "linear-gradient(0deg, #1E1E1E 37.25%, rgba(30, 30, 30, 0) 91.62%)",
      width: "100vw",
      position: "absolute",
      zIndex: 999,
      bottom: 0,
      left: 0,
      right: 0,
      height: "75vh",
    },
  },
  wave_audio_box: {
    height: "100%",
    width: 8,
    background: "rgba(255, 255, 255, 1)",
    borderRadius: "50px",
    animation: "animate 2s linear infinite",
    "@keyframes animate": {
      "50%": {
        height: "20%",
      },
      "100%": {
        height: "100%",
      },
    },
    "&:nth-of-type(1)": {
      animationDelay: "0s",
    },
    "&:nth-of-type(2)": {
      animationDelay: "0.3s",
    },
    "&:nth-of-type(3)": {
      animationDelay: "0.6s",
    },
    "&:nth-of-type(4)": {
      animationDelay: "0.9s",
    },
    "&:nth-of-type(5)": {
      animationDelay: "0.6s",
    },
    "&:nth-of-type(6)": {
      animationDelay: "0.3s",
    },
    "&:nth-of-type(7)": {
      animationDelay: "0.9s",
    },
    "&:nth-of-type(8)": {
      animationDelay: "0s",
    },
    "&:nth-of-type(9)": {
      animationDelay: "0.6s",
    },
  },
  wave_audio_animation_background_container: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  wave_audio_animation_background_1: {
    position: "absolute",
    left: "-25%",
    top: 0,
  },
  wave_audio_animation_background_2: {
    position: "absolute",
    right: "-25%",
    top: "-50%",
  },
  wave_audio_animation_background_3: {
    position: "absolute",
    left: "-25%",
    top: 0,
    animation: "animateBackground3 1s  ease-in-out both",
    "@keyframes animateBackground3": {
      "0%": {
        opacity: 0,
      },
      "100%": {
        opacity: 1,
      },
    },
  },
  wave_audio_animation_background_5: {
    position: "absolute",
    left: "-25%",
    top: 0,
    animation: "animateBackground5 1s ease-in-out both",
    "@keyframes animateBackground5": {
      "0%": {
        opacity: 0,
      },
      "100%": {
        opacity: 1,
      },
    },
  },

  wave_audio_animation_container: {
    height: "10vh",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 99,
    width: "10vw",
    top: "35vh",
    position: "absolute",
    [`@media (max-width: 768px)`]: {
      display: "none",
    },
  },
  wave_audio_animation_background: {
    position: "absolute",
    width: "15vw",
    top: "35vh",
    height: "15vh",
    zIndex: 999,
    [`@media (max-width: 768px)`]: {
      display: "none",
    },
  },

  main_container: {
    width: "100vw",
    display: "flex",
    overflow: "hidden",
    overflowX: "hidden",
    position: "relative",
    backgroundColor: "#1E1E1E",
  },
  image_area: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    left: 100,
    zIndex: 999,
    [`@media (max-width: 968px)`]: {
      left: 0,
    },
    [`@media (max-width: 768px)`]: {
      left: 0,
      zIndex: 0,
    },
  },
  background_light_green: {
    backgroundColor: theme.colors.green[0],
    width: 576,
    height: 576,
    position: "absolute",
    left: "25%",
    bottom: 0,
    opacity: 0.6,
    filter: "blur(225px)",
    transform: "rotate(-15deg)",
    [`@media (max-width: 768px)`]: {
      width: 284,
      height: 284,
      left: "auto",
      right: "0%",
      bottom: "50% !important",
      filter: "blur(100px)",
    },
  },
  background_light_green_animation_login: {
    animation: "lightGreenAnimationLogin 1s linear both",
    "@keyframes lightGreenAnimationLogin": {
      "0%": {
        left: 0,
        bottom: 0,
      },

      "100%": {
        right: "20%",
        bottom: 0,
      },
      [`@media (max-width: 768px)`]: {
        "0%": {
          left: 0,
          bottom: 0,
        },

        "100%": {
          right: "20%",
          bottom: "50% !important",
        },
      },
    },
  },
  background_light_green_animation: {
    animation: "lightGreenAnimation 1s linear both",
    "@keyframes lightGreenAnimation": {
      "0%": {
        left: "20%",
        bottom: 0,
      },

      "100%": {
        left: 0,
        bottom: 0,
      },
    },
    [theme.fn.smallerThan("sm")]: {
      animationName: "lightGreenAnimationMobile",
      animationDelay: ".5s",
      animationDuration: "1s",
      animationFillMode: "both",
      animationTimingFunction: "linear",
      "@keyframes lightGreenAnimationMobile": {
        "0%": {
          width: 204,
          height: 204,
          filter: "blur(100px)",
          right: -30,
          top: "35%",
          opacity: 1,
        },

        "100%": {
          width: 204,
          height: 204,
          filter: "blur(100px)",
          left: 10,
          top: 10,
          opacity: 1,
        },
      },
    },
  },
  background_light_blue: {
    backgroundColor: theme.colors.blue[0],
    width: 728,
    height: 737,
    position: "absolute",
    left: 0,
    top: -100,
    opacity: 0.7,
    filter: "blur(225px)",
    [`@media (max-width: 768px)`]: {
      width: 328,
      height: 328,
      top: 0,
      filter: "blur(100px)",
    },
  },
  background_light_blue_animation_login: {
    animation: "lightBlueAnimationLogin 1s ease-in-out both",
    "@keyframes lightBlueAnimationLogin": {
      "0%": {
        left: "20%",
        top: 0,
      },

      "100%": {
        left: 0,
        top: 0,
      },
    },
  },
  background_light_blue_animation: {
    animation: "lightBlueAnimation 1s ease-in-out both",
    "@keyframes lightBlueAnimation": {
      "0%": {
        left: 0,
        top: 0,
      },

      "100%": {
        left: "20%",
        top: 0,
      },
    },
    [theme.fn.smallerThan("sm")]: {
      animationName: "lightBlueAnimationMobile",
      animationDelay: ".5s",
      animationDuration: "1s",
      animationFillMode: "both",
      animationTimingFunction: "linear",
      "@keyframes lightBlueAnimationMobile": {
        "0%": {
          width: 204,
          height: 204,
          filter: "blur(100px)",
          left: 0,
          top: 0,
          opacity: 1,
        },

        "100%": {
          width: 204,
          height: 204,
          filter: "blur(100px)",
          left: "50%",
          top: "35%",
          opacity: 1,
        },
      },
    },
  },
  logo: {
    zIndex: 999,
    position: "absolute",
    left: 100,
    top: 30,
    [`@media (max-width: 768px)`]: {
      display: "none",
    },
  },
}));
export default AuthLayout;

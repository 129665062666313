import {  useMutation, useQuery } from "@tanstack/react-query";
import { getNotificationCount, getNotifications, postSeenNotification, postSeenNotificationAll } from "../api";

export const useGetNotifications = () => {
  const { data, isLoading, isSuccess,isError, error } = useQuery({
    queryKey: ["notifications"],
    queryFn: () => getNotifications(),
  });

  return { data, isLoading,isSuccess, isError, error };
};

export const useGetNotificationCount = () => {
  const { data, isLoading, isSuccess,isError, error } = useQuery({
    queryKey: ["notification-count"],
    queryFn: () => getNotificationCount(),
  });

  return { data, isLoading,isSuccess, isError, error };
};


export const useNotificationSeen = () => {

  const {
    data,
    isLoading,
    isError,
    error,
    isSuccess,
    isIdle,
    mutate: postSeenNotificationFn,
  } = useMutation((notificationId: string) => postSeenNotification(notificationId), {
    
  });

  return { data, isLoading, isIdle, isSuccess, isError, error, postSeenNotificationFn };
};
export const useNotificationSeenAll = () => {

  const {
    data,
    isLoading,
    isError,
    error,
    isSuccess,
    isIdle,
    mutate: seenAllNotificationFn,
  } = useMutation(() => postSeenNotificationAll(), {
    
  });

  return { data, isLoading, isIdle, isSuccess, isError, error, seenAllNotificationFn };
};
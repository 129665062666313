import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "../../interfaces/user.interface";

interface IUserState {
  user: IUser | null;
  error: string;
  loading: boolean;
  userLoading: boolean;
  success:string,
  userToken: string | null;
}

const initialState: IUserState = {
  user: null,
  error: "",
  loading: false,
  success:"",
  userLoading: false,
  userToken: null,
};

export const slice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setLoading: (state: any, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setLogin: (state: any, action: PayloadAction<boolean>) => {
      state.loginControl = action.payload;
    },
    setUser: (state: any, action: PayloadAction<IUser[] | null>) => {
      state.user = action.payload;
      state.userLoading = false;
    },
    setUserToken: (state: any, action: PayloadAction<string | null>) => {
      state.userToken = action.payload;
    },
    setProfileId: (state: any, action: PayloadAction<number>) => {
      state.profileId = action.payload;
    },
    setError: (state: any, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setSuccess: (state: any, action: PayloadAction<string>) => {
      state.success = action.payload;
    },
    setForgotPasswordMailPost: (state: any, action: PayloadAction<any>) => {
      state.forgotPasswordMailPost = action.payload;
    },
    setUserLoading: (state: any, action: PayloadAction<any>) => {
      state.userLoading = action.payload;
    },
  },
});

export const {
  setUser,
  setUserToken,
  setLoading,
  setError,
  setProfileId,
  setLogin,
  setForgotPasswordMailPost,
  setUserLoading,
  setSuccess
} = slice.actions;

export default slice.reducer;

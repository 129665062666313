import { createSlice, PayloadAction } from "@reduxjs/toolkit";


const initialState = {};

export const slice = createSlice({
  name: "subscription",
  initialState: initialState,
  reducers: {
    setSubscriptions: (state: any, action: PayloadAction<any>) => {
      state.subscriptions = action.payload;
    },
    setLoading: (state: any, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
   
  },
});

export const { setSubscriptions,setLoading } = slice.actions;

export default slice.reducer;

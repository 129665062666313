import { configureStore, Action } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";

import authReducer from "./reducers/user.reducer";
import userReducer from "./reducers/user.reducer";
import creatorsReducer from "./reducers/creators.reducer";
import subscriptionReducer from "./reducers/subscription.reducer";
import notificationsReducer from "./reducers/notifications.reducer";


const store: any = configureStore({
  reducer: {
    authReducer: authReducer,
    user: userReducer,
    creators:creatorsReducer,
    subscription:subscriptionReducer,
    notifications:notificationsReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export default store;

import LandingLayout from "../../components/high-level/layout/landingLayout";
import { Flex, Text, Title, createStyles } from "@mantine/core";

const TermsPage = ({ translation }: any) => {
  const { classes } = useStyles();

  return (
    <LandingLayout translation={translation}>
      <div className={classes.header}>
        <Title className={classes.title} align="center" mt={-300} fz={60}>
          Nutzungsbedingungen
        </Title>
      </div>
      <Flex w="100%" mb={20} align="center" justify="center" direction="column">
        <Flex w="90%" align="left" direction="column">
          <Text className={classes.text} color="white" mt={20}>
            Nutzungsbedingungen für Peepvoice, einen Service der madarray GmbH{" "}
          </Text>

          <Text className={classes.text} fw="700" color="white" mt={20}>
            1. Allgemeine Informationen
          </Text>
          <Text className={classes.text} color="white" mt={20}>
            Die unter "www.peepvoice.com", "www.peepvoicevoices.com" und
            "www.peepvoice.de" verfügbaren Dienste werden von der Peepvoice, c/o
            MadArray GmbH, Fürstenrieder Str 279 in München (im Folgenden als
            "Peepvoice", "wir", "unser" oder "uns") angeboten. Gegenstand der
            von Peepvoice angebotenen Dienste ist insbesondere die elektronische
            Speicherung und Verarbeitung sowie die Vervielfältigung,
            Reproduktion und Veröffentlichung der von Ihnen bereitgestellten
            Audiodateien (im Folgenden als "Sie") auf Websites, Apps und/oder
            anderen OnlinePlattformen von Peepvoice.
          </Text>
          <Text color="white" fw="700" mt={20}>
            2. Kontakt{" "}
          </Text>
          <Text color="white" mt={20}>
            Wenn Peepvoice vernünftige Gründe hat, den Verdacht auf
            betrügerische Aktivitäten oder andere Verstöße gegen diese
            Nutzungsbedingungen durch Sie zu hegen, ist Peepvoice berechtigt,
            Ihr betreffendes Profil und alle damit verbundenen Inhalte
            unverzüglich zu blockieren. Sie können den Verdacht innerhalb einer
            Frist von 7 Arbeitstagen widerlegen. Die Frist beginnt, sobald
            Peepvoice Sie per E-Mail über den Verdacht informiert. Wenn Sie den
            Verdacht innerhalb dieser Frist nicht eindeutig widerlegen können,
            behält sich Peepvoice das Recht vor, Ihr Profil und alle damit
            verbundenen Inhalte dauerhaft zu blockieren oder zu löschen. Durch
            Ihre Registrierung stimmen Sie den folgenden Nutzungsbedingungen zu,
            wie auf dieser Seite beschrieben, und gewähren Peepvoice das Recht,
            Sie jederzeit über die angegebene E-Mail-Adresse zu kontaktieren, zu
            jedem Zweck, der mit dem Service in Verbindung steht.
          </Text>
          <Text color="white" fw="700" mt={20}>
            3. Gewährleistungen und Haftung
          </Text>
          <Text color="white" mt={20}>
            3.1. Sie versichern uns, dass Ihnen gemäß diesen Nutzungsbedingungen
            alle gewährten Rechte vollumfänglich zustehen, d.h. dass alle von
            Ihnen hochgeladenen Audiodateien frei von Rechten Dritter sind,
            insbesondere Urheberrechten, Nebenrechten, Titelrechten,
            Namensrechten, Markenrechten oder anderen Rechten, oder dass Sie
            berechtigt sind, über alle gemäß diesen Nutzungsbedingungen
            gewährten Rechte zu verfügen.
          </Text>
          <Text color="white" mt={20}>
            3.2. Wenn Sie im Rahmen der Produktion der Audiodateien
            Drittarbeiten (z.B. Kompositionen, Texte, etc.) oder
            Dienstleistungen (z.B. Produzenten, Musiker, etc.) genutzt haben,
            garantieren Sie uns, dass Sie von den jeweiligen Rechteinhabern
            wirksam alle erforderlichen Nutzungsrechte, Lizenzen,
            Einwilligungen, etc. erlangt haben. Sie sind für die entsprechende
            Abrechnung und Zahlung von Lizenzgebühren oder anderer Vergütungen
            an die jeweiligen Rechteinhaber verantwortlich und stellen Peepvoice
            von etwaigen Ansprüchen Dritter frei.
          </Text>
          <Text color="white" mt={20}>
            3.3. Ihnen ist es untersagt, Audiodateien mit inflammatiorischem,
            gewaltverherrlichendem, rassistischem, diskriminierendem, homophobem
            oder anderweitig rechtsverletzendem und/oder strafrechtlich
            relevantem Inhalt auf Websites von Peepvoice hochzuladen.
          </Text>
          <Text color="white" mt={20}>
            3.4. Zur Klarstellung wird erneut darauf hingewiesen, dass
            insbesondere die Verwendung von Tonaufnahmen ohne die entsprechende
            Zustimmung der jeweiligen Rechteinhaber einen Verstoß gegen diese
            Nutzungsbedingungen darstellt.
          </Text>
          <Text color="white" mt={20}>
            3.5. Wenn gegen Peepvoice aufgrund einer Rechtsverletzung von
            Dritten infolge eines Verstoßes gegen diese Nutzungsbedingungen
            durch Sie Ansprüche geltend gemacht werden oder ein begründeter
            Verdacht auf andere Verstöße durch Sie besteht, wird Peepvoice Sie
            zunächst per E-Mail über den Vorwurf der Rechtsverletzung
            informieren und Sie auffordern, innerhalb einer Frist von 5
            Arbeitstagen eine Stellungnahme abzugeben. Wenn Sie, der die
            Beweislast trägt, den Vorwurf der Rechtsverletzung nicht ausreichend
            widerlegen können, ist Peepvoice berechtigt, die betroffenen
            Audiodateien zu löschen. Peepvoice ist nicht verpflichtet, Ansprüche
            Dritter und/oder die Vereinbarkeit der Audiodateien mit geltendem
            Recht zu überprüfen oder rechtliche Schritte zur Klärung der
            Rechtslage einzuleiten. Bei offensichtlichen Rechtsverletzungen ist
            Peepvoice auch berechtigt, Audiodateien sofort und ohne Rücksprache
            mit Ihnen zu löschen.
          </Text>
          <Text color="white" mt={20}>
            3.6. Peepvoice ist in keiner Weise verpflichtet, die Rechtmäßigkeit
            der von Ihnen hochgeladenen Audiodateien zu überprüfen. Sie haften
            für alle Schäden, Kosten und Auslagen, die Peepvoice infolge eines
            Verstoßes gegen diese Nutzungsbedingungen durch Sie entstehen. Sie
            stellen Peepvoice (einschließlich seiner Mitarbeiter, Vertreter,
            Anteilseigner und Erfüllungsgehilfen) auf erstes Anfordern von
            sämtlichen Ansprüchen Dritter, die daraus resultieren,
            vollumfänglich frei. Dies umfasst auch etwaige erforderliche
            Anwaltskosten und Gerichtskosten in angemessener Höhe.
          </Text>
          <Text color="white" mt={20}>
            3.7. Wenn ein Fan erfolgreich eine Rückerstattung oder Rückbuchung
            bei seinem Kreditkartenanbieter in Bezug auf eine Fan-Zahlung, die
            an Sie geleistet wurde, beantragt, können wir eine Untersuchung
            durchführen und entscheiden, einen Betrag in Höhe der von Ihnen auf
            der zurückgebuchten oder rückerstatteten Summe verdienten
            Ersteller-Verdienste von Ihrem Konto abzuziehen. Sie stimmen zu,
            dass Sie keine unberechtigten Rückerstattungsanfragen in Bezug auf
            Transaktionen zwischen Fan und Ersteller stellen oder unberechtigte
            Rückbuchungsanfragen bei Ihrem Zahlungskartenanbieter in Bezug auf
            Transaktionen zwischen Fan und Ersteller stellen werden. Wenn wir
            der Auffassung sind, dass eine Rückerstattungsanfrage oder
            Rückbuchungsanfrage von Ihnen böswillig gestellt wurde, haben wir
            das Recht, Ihr Benutzerkonto zu sperren oder zu löschen.
          </Text>
          <Text color="white" mt={20}>
            3.8. Coins: Sie können auf Peepvoice einen Betrag im Voraus bezahlen
            (als "Coins" bezeichnet), den Sie später für Fan- Zahlungen
            verwenden können. Käufe auf Peepvoice können nicht aufgeteilt werden
            - wenn Sie einen Kauf tätigen, der mehr kostet als der Gesamtbetrag
            Ihrer verbleibenden Coins, wird Ihre Zahlungskarte mit dem vollen
            Betrag für diesen Kauf belastet. Coins unterliegen einem von uns von
            Zeit zu Zeit festgelegten Höchstbetrag. Auf Coins fallen keine
            Zinsen an. Coins sind nicht erstattungsfähig, das heißt, Sie haben
            keinen Anspruch auf Rückerstattung für nicht verwendete Coins.
          </Text>
          <Text color="white" mt={20}>
            3.9. Wenn ein Fan erfolgreich eine Rückerstattung oder Rückbuchung
            bei seinem Kreditkartenanbieter in Bezug auf eine Fan-Zahlung, die
            an Sie geleistet wurde, beantragt, können wir eine Untersuchung
            durchführen und entscheiden, einen Betrag in Höhe der von Ihnen auf
            der zurückgebuchten oder rückerstatteten Summe verdienten
            Ersteller-Verdienste von Ihrem Konto abzuziehen.
          </Text>

          <Text color="white" fw="700" mt={20}>
            4. Rückerstattungsrichtlinie{" "}
          </Text>
          <Text color="white" mt={20}>
            4.1. Diese Rückerstattungsrichtlinie gilt für den Kauf von Coins
            über unsere Plattform unter Verwendung des Zahlungsanbieters segpay.
            Mit dem Kauf von Coins über unsere Plattform stimmen Sie diesen
            Bedingungen zu.
          </Text>
          <Text color="white" mt={20}>
            4.2. Alle Käufe von Coins über die Plattform sind endgültig und
            nicht erstattungsfähig. Dies schließt, ohne Einschränkung, alle
            Fälle ein, in denen die Coins verwendet, transferiert, oder auf
            irgendeine Weise aufgebraucht wurden.
          </Text>

          <Text color="white" mt={20}>
            4.3. Eine Rückerstattung kann nur in folgenden Fällen in Betracht
            gezogen werden: a) Technische Probleme: Bei technischen Problemen,
            die auf Seiten unserer Plattform oder des Zahlungsanbieters segpay
            zu einem fehlerhaften oder doppelten Kauf führen. b) Gesetzliche
            Anforderungen: Wenn gesetzliche Bestimmungen in Ihrer
            Gerichtsbarkeit eine Rückerstattung vorschreiben.
          </Text>
          <Text color="white" mt={20}>
            4.4. Um eine Rückerstattung zu beantragen, kontaktieren Sie bitte
            unseren Kundenservice innerhalb von 14 Tagen nach dem Kaufdatum. Sie
            müssen Ihren Kaufbeleg und eine detaillierte Beschreibung des
            Problems vorlegen. Wir behalten uns das Recht vor, jede
            Rückerstattungsanfrage nach unserem Ermessen zu prüfen und zu
            entscheiden.
          </Text>
          <Text color="white" mt={20} mb={20}>
            Unsere Kontaktadresse für Rückerstattungsanfragen lautet: <br />
            <a
              href="mailto:support@peepvoice.com"
              className={classes.linkStyle}
            >
              support@peepvoice.com
            </a>
            <br />
          </Text>

          <Text color="white" mt={20}>
            4.5. Rückerstattungen sind ausgeschlossen, wenn die Coins bereits
            für den Erwerb von Dienstleistungen innerhalb der Plattform
            verwendet wurden.
          </Text>
          <Text color="white" fw="700" mt={20}>
            5. Geistige Eigentumsrechte - Eigentum und Lizenzen{" "}
          </Text>
          <Text color="white" mt={20}>
            5.1. Sie behalten das volle Eigentum an Ihrem Inhalt auf Peepvoice,
            aber Peepvoice benötigt Lizenzen für Ihre Kreationen, um Peepvoice
            effektiv betreiben zu können. Der Zweck dieser Lizenzen ist streng
            darauf beschränkt, uns zu ermöglichen, Ihren Inhalt neuen
            potenziellen Benutzern zur Verfügung zu stellen und zu bewerben.
            Peepvoice wird niemals Ihren Inhalt stehlen, der - neben Ihrem
            Vertrauen - unser wertvollstes Gut ist, oder sie auf eine
            ausbeuterische Weise nutzen.
          </Text>
          <Text color="white" mt={20}>
            5.2. Durch das Hochladen einer Audiodatei auf Peepvoice gewähren Sie
            Peepvoice eine Lizenz für deren Veröffentlichung auf allen derzeit
            und in der Zukunft von Peepvoice betriebenen Apps, Websites und
            anderen Online-Plattformen. Zu diesem Zweck gewähren Sie Peepvoice
            ein nichtexklusives und räumlich uneingeschränktes Nutzungsrecht an
            allen bestehenden Rechten (insbesondere Urheberrecht, Titelrecht,
            Namensrecht und Nebenrechte) an der Audiodatei, insbesondere zum
            Zweck der Vervielfältigung, Reproduktion, öffentlichen
            Zugänglichmachung und/oder Sendung sowie der digitalen Verbreitung,
            ob kostenlos oder gegen Entgelt, ganz oder teilweise, in
            bearbeiteter oder unbearbeiteter Form in allen gängigen
            elektronischen Konfigurationen und Formaten mittels aller derzeit
            verfügbaren und zukünftig verfügbaren elektronischen Vertriebskanäle
            und -verfahren. Darüber hinaus ist Peepvoice berechtigt, die von
            Ihnen gewährten Rechte im Falle eines Verkaufs unseres Unternehmens
            oder von Vermögenswerten an Dritte ganz oder teilweise zu
            übertragen.
          </Text>
          <Text color="white" mt={20}>
            5.3. Sie verzichten auf alle moralischen Rechte, die Ihnen nach
            geltendem Recht zustehen könnten, um gegen abwertende Behandlung von
            Inhalten, die von Ihnen auf Peepvoice veröffentlicht wurden,
            Einspruch zu erheben. Dieser Verzicht beeinträchtigt in keiner Weise
            Ihr Eigentum an geistigen Eigentumsrechten an Ihrem Inhalt oder die
            Rechte, die Sie haben, um zu verhindern, dass Ihr Inhalt ohne Ihre
            Erlaubnis kopiert wird. Der Verzicht dient dazu, es uns zu
            ermöglichen, Ihren Inhalt bei der Bearbeitung und/ oder Bearbeitung
            für die Markenwerbung von Peepvoice auf unseren Marketingkanälen zu
            verwenden, was auch Ihre Präsenz und Einnahmen auf Peepvoice
            effektiv fördert. Wenn Sie Bedenken hinsichtlich der werblichen
            Nutzung Ihres Inhalts haben, schreiben Sie uns bitte eine EMail an
            support@peepvoice.com.
          </Text>
          <Text color="white" fw="700" mt={20}>
            6. Sonstiges
          </Text>
          <Text color="white" mt={20}>
            6.1. Sie stimmen zu, den Service von Peepvoice ausschließlich in
            Übereinstimmung mit den gesetzlichen Bestimmungen und diesen
            Nutzungsbedingungen zu nutzen.
          </Text>
          <Text color="white" mt={20}>
            6.2. Alle rechtlichen Beziehungen zwischen Peepvoice und Ihnen
            unterliegen den Gesetzen der Bundesrepublik Deutschland sowie der
            Europäischen Union. Erfüllungsort und Gerichtsstand ist Berlin,
            soweit gesetzlich zulässig. Peepvoice ist auch berechtigt,
            rechtliche Schritte gegen Sie an Ihrem allgemeinen Gerichtsstand
            einzuleiten.
          </Text>
          <Text color="white" mt={20}>
            6.3. Die aktuellen Nutzungsbedingungen können auf der Website von
            www.peepvoice.com, www.peepvoicevoices.com und www.peepvoice.de
            abgerufen werden. Peepvoice ist berechtigt, die Nutzungsbedingungen
            jederzeit zu ändern. Im Falle einer Änderung wird Peepvoice Sie
            umgehend per E-Mail benachrichtigen. Ihre Zustimmung zu den
            Änderungen gilt als erteilt, wenn Sie nicht innerhalb von 30 Tagen
            nach Erhalt der Änderungsmitteilung schriftlich widersprechen.
            Peepvoice informiert Sie in der Änderungsmitteilung über die Folgen
            des Unterlassens eines Widerspruchs.
          </Text>
          <Text color="white" fw="700" mt={20}>
            7. Verfahren bei Einschränkung der Datenschutzrechte{" "}
          </Text>
          <Text color="white" mt={20}>
            7.1. Wenn Sie als Benutzer oder Besucher von Peepvoice der Ansicht
            sind, dass Ihre Datenschutzrechte, Urheberrechte oder andere Rechte
            durch Inhalte auf Peepvoice eingeschränkt oder verletzt wurden,
            kontaktieren Sie uns bitte umgehend per E-Mail:
            support@peepvoice.com. Unsere Beschwerde-Richtlinie sieht vor, dass
            wir dann die relevanten Inhalte überprüfen, ob sie im Einklang mit
            diesen Nutzungsbedingungen (insbesondere 3. und 4.) sowie unseren
            Inhaltsrichtlinien stehen. Wenn eine Verletzung festgestellt wird,
            werden wir die relevanten Inhalte innerhalb von 7 Arbeitstagen
            sofort entfernen und/oder löschen.
          </Text>
          <Text color="white" mt={20} mb={20}>
            7.2. "Informationen zu 'integrierten Diensten' von Facebook oder
            Google: Wenn Sie sich dafür entschieden haben, sich über Facebook
            Connect oder Google anzumelden oder einzuloggen (im Falle von
            'integrierten Diensten'), gestatten Sie Peepvoice auch, die mit
            Ihrem Konto auf diesen integrierten Diensten verbundenen
            personenbezogenen Daten zu erheben sowie sie zu analysieren, zu
            teilen und zu speichern, soweit dies im Rahmen dieser
            Datenschutzrichtlinie zulässig ist." Diese Daten umfassen Ihre
            Benutzerprofil-Daten (E-Mail-Adresse, Benutzernamen, InstagramNamen)
            und Ihre nutzungsbezogenen Daten (Anmeldefrequenz, Nutzungsdauer,
            Anzahl der Likes, Kommentare und aufgelisteten gehörten Peepvoice-
            Stories). Wir benötigen diese Informationen unter anderem, um Ihr
            Benutzererlebnis auf Peepvoice jeden Tag ein wenig besser zu
            machen."
          </Text>
          <Text align="left" fw="bold" color="white" mt={20} mb={10} fz={25}>
            Beschwerderichtlinie
          </Text>
          <Text  fw="700" color="white" mt={20}>
            1. Einführung
          </Text>
          <Text  color="white" mt={20}>
            Dieses Dokument legt unsere Mitteilungsrichtlinie fest. Wenn Sie ein
            Benutzer von peepvoice.com sind, ist diese Mitteilungsrichtlinie
            Teil Ihrer Vereinbarung mit uns.
          </Text>

          <Text  fw="700" color="white" mt={20}>
            2. Auslegung
          </Text>
          <Text  color="white" mt={20}>
            In dieser Mitteilungsrichtlinie haben definierte Begriffe die
            gleichen Bedeutungen wie in den Allgemeinen Geschäftsbedingungen.
            Darüber hinaus bedeutet der Begriff "Werktage" jeden Tag, der kein
            Samstag, Sonntag oder gesetzlicher Feiertag in Deutschland ist.
          </Text>

          <Text  fw="700" color="white" mt={20}>
            3. Wer kann diese Mitteilungsrichtlinie nutzen
          </Text>
          <Text  color="white" mt={20}>
            Unabhängig davon, ob Sie ein Benutzer von peepvoice.com sind oder
            nicht, können Sie diese Mitteilungsrichtlinie nutzen, um uns auf
            eine Mitteilung aufmerksam zu machen, die Sie in Bezug auf
            peepvoice.com haben.
          </Text>

          <Text  fw="700" color="white" mt={20}>
            4. Wie man eine Mitteilung einreicht
          </Text>
          <Text  color="white" mt={20}>
            Wenn Sie eine Mitteilung über peepvoice.com haben (einschließlich
            einer Mitteilung über Inhalte, die auf peepvoice.com erscheinen,
            oder das Verhalten eines Benutzers), reichen Sie bitte Ihre
            Mitteilung ein, indem Sie unser Online-Formular auf dieser Seite
            ausfüllen. Sie müssen alle Pflichtfelder ausfüllen, eine
            Beschreibung Ihrer Mitteilung angeben und, falls sich Ihre
            Mitteilung auf Inhalte bezieht, die URL für den Inhalt angeben, auf
            den sich Ihre Mitteilung bezieht.
          </Text>

          <Text  fw="700" color="white" mt={20}>
            5. Wie wir mit Mitteilungen über illegale oder nicht einvernehmliche
            Inhalte umgehen
          </Text>
          <Text  color="white" mt={20}>
            Nach Erhalt Ihrer Mitteilung über illegale oder nicht
            einvernehmliche Inhalte gemäß Abschnitt 4 oben:
          </Text>
          <Text  color="white" mt={20} ml={20}>
            • Wir werden die Schritte unternehmen, die wir für angemessen
            halten, um Ihre Mitteilung innerhalb von 7 Werktagen zu lösen.
          </Text>
          <Text  color="white" mt={20} ml={20}>
            • Wenn wir weitere Informationen oder Dokumente von Ihnen benötigen,
            werden wir Sie kontaktieren, um Sie darüber zu informieren.
          </Text>
          <Text  color="white" mt={20} ml={20}>
            • Wir werden Ihre Mitteilung nach Treu und Glauben untersuchen und
            die folgenden Maßnahmen ergreifen:
          </Text>
          <Text  color="white" mt={20} ml={40}>
            • Wenn wir feststellen, dass der Inhalt rechtswidrig oder nicht
            einvernehmlich ist, werden wir diesen Inhalt unverzüglich entfernen
            und Sie über unsere Entscheidung per E-Mail oder andere
            elektronische Nachricht informieren.
          </Text>
          <Text  color="white" mt={20} ml={40}>
            • Wenn wir feststellen, dass der Inhalt nicht rechtswidrig oder
            nicht einvernehmlich ist, werden wir Sie über unsere Entscheidung
            per E-Mail oder andere elektronische Nachricht informieren.
          </Text>
          <Text  color="white" mt={20} ml={40}>
            • Jeder Streit über unsere Entscheidung bezüglich nicht
            einvernehmlicher Inhalte wird von uns auf unsere Kosten an eine
            neutrale Schiedsorganisation weitergeleitet.
          </Text>

          <Text  fw="700" color="white" mt={20}>
            6. Wie wir mit Mitteilungen im Zusammenhang mit
            Urheberrechtsverletzungen umgehen
          </Text>
          <Text  color="white" mt={20}>
            Mitteilungen im Zusammenhang mit Urheberrechtsverletzungen müssen
            gemäß unserer DMCA-Richtlinie eingereicht werden, und wir werden auf
            Mitteilungen über Urheberrechtsverletzungen wie in dieser Richtlinie
            festgelegt reagieren.
          </Text>

          <Text  fw="700" color="white" mt={20}>
            7. Wie wir mit anderen Mitteilungen umgehen
          </Text>
          <Text  color="white" mt={20}>
            Nach Erhalt anderer Mitteilungen (einschließlich Mitteilungen über
            andere Verstöße gegen unsere Richtlinie zur akzeptablen Nutzung)
            gemäß Abschnitt 4 oben:
          </Text>
          <Text  color="white" mt={20} ml={20}>
            • Wir werden die Schritte unternehmen, die wir für angemessen
            halten, um Ihre Mitteilung innerhalb eines Zeitraums zu untersuchen,
            der der Art Ihrer Mitteilung angemessen ist.
          </Text>
          <Text  color="white" mt={20} ml={20}>
            • Wenn wir weitere Informationen oder Dokumente von Ihnen benötigen,
            werden wir Sie kontaktieren, um Sie darüber zu informieren.
          </Text>
          <Text  color="white" mt={20} ml={20}>
            • Wir werden nach Treu und Glauben die Maßnahmen ergreifen, die wir
            für angemessen halten, um das Problem zu lösen, das Ihre Mitteilung
            aufgeworfen hat. Wenn Sie sich über Inhalte mitgeteilt haben, die
            auf peepvoice.com erscheinen, und wir feststellen, dass die Inhalte
            anderweitig gegen unsere Richtlinie zur akzeptablen Nutzung
            verstoßen, werden wir schnell handeln, um solche Inhalte zu
            entfernen.
          </Text>
          <Text  color="white" mt={20} ml={20}>
            • Wir sind nicht verpflichtet, Sie über das Ergebnis Ihrer
            Mitteilung zu informieren.
          </Text>

          <Text  fw="700" color="white" mt={20}>
            8. Unbegründete oder missbräuchliche Mitteilungen
          </Text>
          <Text  color="white" mt={20}>
            Wenn Sie ein Benutzer von peepvoice.com sind, garantieren Sie (was
            bedeutet, dass Sie ein rechtlich durchsetzbares Versprechen
            abgeben), dass Sie keine Mitteilung gemäß dieser
            Mitteilungsrichtlinie einreichen werden, die völlig unbegründet,
            missbräuchlich oder in böser Absicht erfolgt. Wenn wir feststellen,
            dass Sie gegen diese Garantie verstoßen haben, können wir Ihr
            Benutzerkonto sperren oder kündigen.
          </Text>
        </Flex>
      </Flex>
    </LandingLayout>
  );
};
const useStyles = createStyles((theme) => ({
  header: {
    paddingBottom: 20,
    marginBottom: 20,
    height: "30vh",
    backgroundColor: "rgba(56, 56, 56, .7)",
    color: "white",
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  text: {
    fontSize: 22,
    [`@media (max-width: 768px)`]: {
      fontSize: 16,
    },
  },
  title: {
    fontSize: 45,
    [`@media (max-width: 768px)`]: {
      fontSize: 30,
    },
  },
  linkStyle: {
    color: "white", // Farbe des Links
    fontSize: "32px", // Schriftgröße des Links
    textDecoration: "none",
  },
}));
export default TermsPage;

import LandingLayout from "../../components/high-level/layout/landingLayout";
import { Flex, Text, Title, createStyles } from "@mantine/core";

const LegalNoticePage = ({translation}: any) => {
  const { classes } = useStyles();

  return (
    <LandingLayout translation={translation}>
      <div className={classes.header}>
        <Title mt={-300} className={classes.title} fz={60}>{translation.legal_notice_page.title}</Title>
      </div>
      <Flex
        w="100%"
        mb={20}
        align="center"
        justify="center"
        direction="column"
      >
        <Flex w="80%" align="left" direction="column">
          <Title color="white">{translation.legal_notice_page.title_2}</Title>
          <Text color="white">{translation.legal_notice_page.address}</Text>
          <Text color="white" mt={20}>
            Fürstenrieder str. 279a
          </Text>
          <Text color="white">
            81377 münchen
          </Text>
          <Text color="white" mt={20}>
          {translation.legal_notice_page.contact}
            
          </Text>
          <Title color="white" fz="md" mt={20}>
          {translation.email}: info@peepvoice.com
          </Title>
          <Text color="white">{translation.legal_notice_page.text_1}</Text>
          <Text color="white" mt={20}>
          {translation.legal_notice_page.text_2}
            
          </Text>
          <Text color="white">
          {translation.legal_notice_page.text_3}
            </Text>
          <Text color="white" mt={20}>
          {translation.legal_notice_page.text_4}

            
          </Text>
          <Title color="white" fz="md" mt={20}>
          {translation.legal_notice_page.title_2}
          </Title>
          <Text color="white">
          {translation.legal_notice_page.text_2_1}

            
          </Text>
          <Text color="white">
          {translation.legal_notice_page.text_2_2}

          </Text>

          <Title color="white" fz="md" mt={20}>
          {translation.legal_notice_page.title_3}
            
          </Title>
          <Text color="white">
          {translation.legal_notice_page.text_3_1}

            
          </Text>

          <Title color="white" fz="md" mt={20}>
          {translation.legal_notice_page.title_4}

           
          </Title>
          <Text color="white">
          {translation.legal_notice_page.text_4_1}
          </Text>
        </Flex>
      </Flex>
    </LandingLayout>
  );
};
const useStyles = createStyles((theme) => ({
  header: {
    paddingBottom: 20,
    marginBottom:20,
    height: "30vh",
    backgroundColor: "rgba(56, 56, 56, .7)",
    color: "white",
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  title:{
    fontSize:45,
    [`@media (max-width: 768px)`]: {
      fontSize:30
    },
  },
}));
export default LegalNoticePage;

const state:any = {}

export const saveState = (component: any, object: any) => {
    
    state[component] = object
    
}

export const getState = (component: any) => {
    
    return state[component]
    
}
import React, { useMemo, useState } from "react";
import { useGetCreatorEvents } from "../../../api/event/service";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Center, createStyles, Text } from "@mantine/core";
import CreatorEventCard from "./CreatorEventCard";

const CreatorEvents = ({ creatorId, translation, user }: any) => {
  const { data, isLoading } = useGetCreatorEvents(creatorId);
  const { classes } = useStyles();
  const [arr] = useState([...Array(5)]);

  const flattenedData: any = useMemo(
    () => (data ? data?.pages?.flatMap((item: any) => item.results) : []),
    [data]
  );
  return (
    <>
      {" "}
      {isLoading && (
        <Masonry
          columnsCount={1}
          className={classes.tab_view}
          gutter="10px 15px"
        >
          {arr.map((x: any, i: number) => (
            <div
              key={i}
              style={{ height: x }}
              className={classes.skeleten}
            ></div>
          ))}
        </Masonry>
      )}
      <Masonry columnsCount={1} className={classes.tab_view} gutter="10px 15px">
        {flattenedData?.map((x: any, i: number) => (
          <CreatorEventCard
            key={i}
            user={user}
            event={x}
            creatorId={creatorId}
            translation={translation}
          />
        ))}
      </Masonry>
      {flattenedData.length === 0 && !isLoading &&
        <Center mt={100}>
          <Text color="white" fw="700" fz={25} w="50%" align="center">
            {translation.not_have_audio}
          </Text>
        </Center>
      }
    </>
  );
};
const useStyles = createStyles((theme) => ({
  tab_view: {
    [`@media (max-width: 500px)`]: {
      paddingRight: 20,
      paddingLeft: 20,
    },
  },
  skeleten: {
    background: " linear-gradient(-45deg, #b0b1b1, #8f9191, #6a6b6b, #555555)",
    backgroundSize: "400% 400%",
    borderRadius: 20,
    height: 91,
    paddingBottom: 20,
    animation: "gradient 2s ease infinite",
    "@keyframes gradient": {
      "0%": {
        backgroundPosition: "0% 50%",
      },
      "50%": {
        backgroundPosition: "100% 50%",
      },
      "100%": {
        backgroundPosition: "0% 50%",
      },
    },
  },
}));
export default CreatorEvents;

import React from "react";
import CreatorProfilePage from "./CreatorProfilePage";
import PublicCreatorProfilePage from "./PublicCreatorProfilePage";
import { getToken } from "../../utils/auth/auth";

const Index = ({ translation }: any) => {
  const token = getToken();

  const creatorProfile = () => {
    if (token === "null") {
      return <PublicCreatorProfilePage translation={translation} />;
    } else {
      return <CreatorProfilePage translation={translation} />;
    }
  };
  return creatorProfile();
};

export default Index;

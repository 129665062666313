import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Flex,
  Grid,
  Image,
  Text,
  createStyles,
} from "@mantine/core";
import EventMessages from "./EventMessages";
import {  useViewportSize } from "@mantine/hooks";
import { useSocket } from "../../../contexts/socketContext";
import { SlVolume2, SlVolumeOff } from "react-icons/sl";

const EventRightArea = ({
  translation,
  user,
  openChat,
  openMemberArea,
  setOpenVoice,
  creatorHeight,
  handleJoinAsHost,
  openVoice,
  joinState,
  data,
  mute,
  unmute,
  isMuted,
  connecting,
}: any) => {
  let { id }: any = useParams();
  const { classes } = useStyles();
  const { width } = useViewportSize();
  const userId = user?.data?.attributes?.user?.id;
  const profileId = user?.data?.attributes?.userProfile?.id;
  const chatId = data?.results?.attributes?.chat?.data?.id;
  const isOwner = data?.results?.isOwner

  const { joinChat, leaveChat }: any = useSocket();

  useEffect(() => {
    if (joinChat && chatId && userId && profileId) {
      joinChat(chatId, userId, profileId);
    }
    // Cleanup-Funktion, die beim Unmounten aufgerufen wird
    return () => {
      if (leaveChat) {
        leaveChat(parseInt(chatId));
      }
    };
  }, [chatId, id, joinChat, leaveChat, profileId, userId]);

  return (
    <>
      <div
        style={{ display: openMemberArea && "none" }}
        className={classes.event_right_area}
      >
        {/* {user && (
          <EventCreatorDetailArea
            user={user}
            open={open}
            translation={translation}
            openVoice={openVoice}
            setOpenChat={setOpenChat}
            handleJoin={handleJoin}
            handleJoinAsHost={handleJoinAsHost}
            openChat={openChat}
            setOpenVoice={setOpenVoice}
            creatorData={data}
            setCreatorHeight={setCreatorHeight}
          />
        )} */}

        {!openVoice && data && (
          <EventMessages
            creatorAreaHeight={creatorHeight}
            translation={translation}
            openVoice={false}
            creator={data}
            id={data?.results?.attributes?.chat?.data?.id}
          />
        )}
        <Grid
          style={{
            flexDirection: "row-reverse",
            height: `100%`,
          }}
        >
          {openVoice && openChat && width > 768 && (
            <Grid.Col
              style={{
                position: "relative",
                padding: 30,
                paddingLeft: 17,
                height: "100%",
              }}
              span={openChat ? 6 : 12}
            >
              <div
                style={{
                  position: "relative",
                  backgroundColor: "rgba(67,67,67,.7)",
                  height: "100%",
                  borderRadius: 19,
                }}
              >
                <EventMessages
                  creatorAreaHeight={creatorHeight}
                  translation={translation}
                  openVoice={openVoice}
                  isOwner={isOwner}
                  creator={data}
                  id={data?.results?.attributes?.chat?.data?.id}
                />
              </div>
            </Grid.Col>
          )}
          <Grid.Col
            style={{
              padding: 30,
              position: "relative",
              height: "100%",
              paddingRight: openChat ? 17 : 30,
            }}
            span={openChat && width > 768 ? 6 : 12}
          >
            {openVoice && (
              <Flex
                w={openChat ? "100%" : "auto"}
                h="100%"
                align="center"
                pos="relative"
              >
                <div className={classes.voice_image_area}>
                  <div className={classes.voice_image_background}></div>

                  {/* TODO: KORAY: Burazin Design olarak lütfen düzelt ve translationi de koy*/}
                  {joinState || !data?.results?.isOwner ? (
                    <>
                      <Image
                        width={200}
                        height={200}
                        radius={100}
                        fit="cover"
                        src={data?.results?.owner?.avatar?.sizes?.original}
                      />
                    </>
                  ) : connecting ? (
                    <Text fz={14} fw="bold" style={{zIndex:99}} color="white">
                      {translation.event_page.connecting}
                    </Text>
                  ) : (
                    <Button onClick={handleJoinAsHost}>
                      {translation.event_page.start}
                    </Button>
                  )}

                  <div className={classes.owner_username_area}>
                    <Text fz={20} fw={500} color="white">
                      {data?.results?.owner?.username}
                    </Text>
                  </div>
                  {joinState && data?.results?.isOwner && (
                    <Button
                      className={classes.mute_button}
                      onClick={isMuted ? unmute : mute}
                    >
                      {isMuted ? (
                        <>
                          <SlVolumeOff />
                          <Text ml={10}>Unmute</Text>
                        </>
                      ) : (
                        <>
                          <SlVolume2 />
                          <Text ml={10}>Mute</Text>
                        </>
                      )}
                    </Button>
                  )}
                </div>
                {/* <Grid style={{ width: "100%" }} align="stretch" grow>
                  <Grid.Col span={otherVoiceData?.length > 1 ? 7 : 12}>
                  </Grid.Col> */}
                {/* <Grid.Col span={5}>
                    <Flex
                      justify="space-between"
                      direction="column"
                      h={`calc(100vh - 224px ${
                        otherVoiceData.length > 1
                          ? `- (15px * ${otherVoiceData.length} )`
                          : `- 0px`
                      })`}
                    >
                      {otherVoiceData.map((x: any) => (
                        <Image
                          styles={{
                            root: {
                              height: `calc(100% / ${otherVoiceData.length})`,
                            },
                            figure: { height: "100%" },
                            imageWrapper: { height: "100%" },
                            image: { height: "100%" },
                          }}
                          width="100%"
                          height="100%"
                          mt={5}
                          mb={5}
                          radius={14}
                          fit="cover"
                          src={x.image}
                        />
                      ))}
                    </Flex>
                  </Grid.Col> */}
                {/* </Grid> */}
              </Flex>
            )}
          </Grid.Col>
        </Grid>
      </div>
    </>
  );
};

const useStyles = createStyles((theme) => ({
  event_right_area: {
    position: "relative",
    height: "100%",
  },

  voice_image_area: {
    height: "100%",
    width: "100%",
    backgroundColor: "rgba(77, 116, 254, 0.5)",
    backdropFilter: "blur(5px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
  },
  voice_image_background: {
    backgroundColor: "rgba(182, 90, 255, 0.5)",
    width: 210,
    borderRadius: 150,
    height: 210,
    position: "absolute",
    animation: "animation 2s ease-in-out infinite",
    "@keyframes animation": {
      "0%": {
        transform: "scale(1)",
      },
      "50%": {
        transform: "scale(1.2)",
      },
      "100%": {
        transform: "scale(1)",
      },
    },
  },
  owner_username_area: {
    position: "absolute",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    bottom: 20,
    padding: "8px 30px",
    borderRadius: 30,
    left: 10,
  },
  mute_button: {
    position: "absolute",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    bottom: 20,
    padding: "8px 30px",
    borderRadius: 30,
    right: 10,
  },
}));
export default EventRightArea;

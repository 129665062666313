import axios from "axios";

export const getTranslationsFn = async () => {
const locale = localStorage.getItem("i18nextLng");

  try {
    const data = await axios.get("https://mysterious-beach-67818.herokuapp.com/api/translation", {
      params: { locale: locale },
    });
    return {
       data:data.data.data.attributes.fields,
    };
  } catch (error) {
    console.log("error", error);
  }
};

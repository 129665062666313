import { getToken } from "../../utils/auth/auth";
import restClient from "../restClient";

export const createEventFn = async (createData: any) => {
  try {
    const token = await getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const data: any = await restClient.post(
      `/events`,
      { data: createData },
      config
    );
    return data;
  } catch (error) {
    console.log("error try catch:", error);
    throw error; // Hata durumunda hatayı yeniden fırlat
  }
};

export const getEventsFn = async (pageParam: number) => {
  try {
    const page = pageParam ? pageParam : 1;
    const offset = (page - 1) * 10;
    const token = await getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const data = await restClient.get(
      `/events?populate=*&sort=createdAt:DESC&pagination[start]=${offset}&pagination[limit]=10`,
      config
    );

    return {
      results: data.data.data,
      offset: offset + 10,
      pagination: data.data.meta.pagination,
    };
  } catch (error) {
    console.error("post api error:", error);
    throw error;
  }
};

export const getCreatorEventsFn = async (pageParam: number, id: number) => {
  try {
    const page = pageParam ? pageParam : 1;
    const offset = (page - 1) * 10;
    const token = await getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const data = await restClient.get(
      `/events?filters[owner][id][$eq]=${id}&populate=*&sort=createdAt:DESC&pagination[start]=${offset}&pagination[limit]=10`,
      config
    );

    return {
      results: data.data.data,
      offset: offset + 10,
      pagination: data.data.meta.pagination,
    };
  } catch (error) {
    console.error("post api error:", error);
    throw error;
  }
};
export const getEvent = async (id: number) => {
  try {
    const token = await getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const data = await restClient.get(`/events/${id}`, config);

    return {
      results: data.data,
    };
  } catch (error) {
    console.error("post api error:", error);
    throw error;
  }
};
export const updateEventFn = async (newData: any) => {
  try {
    const token = await getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const data = await restClient.put(
      `/events/` + newData.id,
      { data: newData },
      config
    );

    return {
      data: data.data,
      id: newData.id,
      creator_id:newData.creator_id,
      type: newData.type,
      userId: newData?.registered_users && newData?.registered_users[0],
    };
  } catch (error) {
    console.error("post api error:", error);
    throw error;
  }
};

import React from "react";
import { Line } from "react-chartjs-2";
import { useGetAnalytics } from "../../../api/statistics/service";


const Analytics = ({ translation, creatorId }: any) => {
    const { data }: any = useGetAnalytics(creatorId);
    const maxSalesCount = Math.max(...(data?.data?.map((data: any) => data?.salesCount) || [0]));

     const options = {
        responsive: true,
        plugins: {
            legend: {
              display: false 
            }
          },
        scales: {
          y: {
            ticks: {
              color: "#FFFFFF",
              stepSize: 1,
              callback: function (value: any) {
                return Number.isInteger(value) ? value : null;
              },
            },
            max: maxSalesCount * 2
          },
          x: {
            ticks: {
              color: "#FFFFFF",
            },
          },
        },
      };
  return (
    <div>
      <Line
        options={options}
        data={{
          labels: data?.data?.map((data: any, i: number) => data.date),
          datasets: [
            {
              fill: true,
              label: "",
              data: data?.data?.map((data: any) => data?.salesCount),
              borderColor: "#D2E951",
              backgroundColor: "#D2E951",
            },
          ],
        }}
      />
    </div>
  );
};

export default Analytics;

import { useQuery } from "@tanstack/react-query";
import { getTranslationsFn } from "../api";

export const useGetTranslations = () => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["translations"],
    queryFn: () => getTranslationsFn(),
    staleTime: 3600000,
    // retry:false,
    // refetchOnMount:false,
    // refetchOnWindowFocus:false,
    // select: (res) => res,
  });

  return { data, isLoading, isError, error };
};

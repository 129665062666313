import React, { useEffect, useMemo, useState } from "react";
import { useGetAudios } from "../../../api/feed/service";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useScrollIntoView } from "@mantine/hooks";
import { createStyles } from "@mantine/core";
import AudioPostsCard from "./AudioPostsCard";
import FetchLoader from "../loading/FetchLoader";

const Audios = ({ user, translation }: any) => {
  const { data, isLoading, fetchNextPage,isFetchingNextPage } = useGetAudios();
  const [arr] = useState([...Array(6)]);
  const { classes } = useStyles();
  const { targetRef } = useScrollIntoView<HTMLDivElement>({
    offset: 60,
  });
  const flattenedData: any = useMemo(
    () => (data ? data?.pages?.flatMap((item: any) => item.results) : []),
    [data]
  );
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        fetchNextPage();
      }
    });

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [targetRef]);
  return (
    <>
      {isLoading && (
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1 }}>
          <Masonry className={classes.tab_view} gutter="10px 15px">
            {arr.map((x: any, i: number) => (
              <div
                key={i}
                style={{ height: 200 }}
                className={classes.skeleten}
              ></div>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      )}
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1 }}>
        <Masonry className={classes.tab_view} gutter="10px 15px">
          {flattenedData?.map((x: any, i: number) => (
            <AudioPostsCard data={x} profile={x.isCreator} />
          ))}
        </Masonry>
      </ResponsiveMasonry>
      <div ref={targetRef} />
      <div className={classes.inifinty_loading_area}>
        {isFetchingNextPage && <FetchLoader />}
      </div>
    </>
  );
};
const useStyles = createStyles((theme) => ({
  tab_view: {
    [`@media (max-width: 500px)`]: {
      paddingRight: 20,
      paddingLeft: 20,
    },
  },
  skeleten: {
    background: " linear-gradient(-45deg, #b0b1b1, #8f9191, #6a6b6b, #555555)",
    backgroundSize: "400% 400%",
    borderRadius: 20,
    height: 91,
    paddingBottom: 20,
    animation: "gradient 2s ease infinite",
    "@keyframes gradient": {
      "0%": {
        backgroundPosition: "0% 50%",
      },
      "50%": {
        backgroundPosition: "100% 50%",
      },
      "100%": {
        backgroundPosition: "0% 50%",
      },
    },
  },
  inifinty_loading_area: {
    paddingTop: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
export default Audios;

/* eslint-disable react-hooks/exhaustive-deps */
import {
  ActionIcon,
  Avatar,
  Button,
  Center,
  Divider,
  Flex,
  Text,
  TextInput,
  createStyles,
  getStylesRef,
} from "@mantine/core";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  useGetMessages,
  usePostMessage,
  usePostMessageAudio,
} from "../../../api/chat/service";
import { User } from "../../../hooks/user.hook";
import { useParams } from "react-router-dom";
import { FaMicrophone, FaPlay, FaStop } from "react-icons/fa";
import SendIcon from "../../../assets/send";
import { AiFillCloseCircle } from "react-icons/ai";
import { useReactMediaRecorder } from "react-media-recorder-2";
import WaveSurfer from "wavesurfer.js";
import ChatAudio from "../waveAudio/ChatAudio";
import "./styles.css";

const Messages = ({ translation, creator, creatorAreaHeight }: any) => {
  const {
    status,
    startRecording,
    pauseRecording,
    resumeRecording,
    stopRecording,
    clearBlobUrl,
    mediaBlobUrl,
  }: // previewAudioStream,
  any = useReactMediaRecorder({
    video: false,
    blobPropertyBag: {
      // type: isSafari ? "video/mp4;codecs=avc1" : "video/webm;codec=h246"
    },
  });

  let { id }: any = useParams();

  const divRef: any = useRef<HTMLDivElement | null>(null);
  const waveformRef: any = useRef();
  const ws: any = useRef();
  const { classes } = useStyles();
  const { user } = User();
  const [text, setText] = useState("");
  const [sendAudioCheck, setSendAudioCheck] = useState(false);
  const [focusInput, setFocusInput] = useState(false);

  const [duration, setDuration] = useState(0);
  const [audioFile, setAudioFile] = useState<any>(null);
  const [audioTime, setAudioTime] = useState<any>(60);
  const [peaks, setPeaks] = useState<any>([]);
  const [dateData, setDateData] = useState<any>([]);
  const { data, isLoading, isFetchingNextPage, fetchNextPage } =
    useGetMessages(id);

  const { sendMessageAudioChat, isLoading: loadingMessageAudio } =
    usePostMessageAudio(user.attributes.userProfile.id, id, mediaBlobUrl);
  const { sendMessage } = usePostMessage(user.attributes.userProfile.id, id);
  const flattenedData: any = useMemo(
    () => (data ? data?.pages?.flatMap((item: any) => item.results) : []),
    [data]
  );
  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const timeDifference = today.getTime() - date.getTime();
    const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

    if (daysDifference === 0) {
      return translation.chat_page.days.today;
    } else if (daysDifference === 1) {
      return translation.chat_page.days.yesterday;
    } else if (daysDifference <= 2) {
      return getDayNameFromDate(date);
    } else if (daysDifference <= 3) {
      return getDayNameFromDate(date);
    } else if (daysDifference <= 4) {
      return getDayNameFromDate(date);
    } else if (daysDifference <= 5) {
      return getDayNameFromDate(date);
    } else if (daysDifference <= 6) {
      // Tarih formatı: "dd.mm.yy" (örnek: 13.02.24)
      return (
        ("0" + date.getDate()).slice(-2) +
        "." +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "." +
        date.getFullYear().toString().slice(-2)
      );
    } else {
      // Tarih formatı: "dd.mm.yy" (örnek: 13.02.24)
      return (
        ("0" + date.getDate()).slice(-2) +
        "." +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "." +
        date.getFullYear().toString().slice(-2)
      );
    }
  };
  const getDayNameFromDate = (dateString: any) => {
    const date = new Date(dateString);
    const dayNames = [
      `${translation.chat_page.days.sunday}`,
      `${translation.chat_page.days.monday}`,
      `${translation.chat_page.days.tuesday}`,
      `${translation.chat_page.days.wednesday}`,
      `${translation.chat_page.days.thursday}`,
      `${translation.chat_page.days.friday}`,
      `${translation.chat_page.days.saturday}`,
    ];
    const dayIndex = date.getDay();
    return dayNames[dayIndex];
  };

  useEffect(() => {
    if (data) {
      // Tüm tarihleri al
      const allDates = data?.pages.flatMap((page: any) =>
        page.results.map((message: any) => {
          const createdAtDate = new Date(message.attributes?.messageSentTime);
          return {
            datetime: createdAtDate.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }),
            date: formatDate(createdAtDate),
            id: message?.id,
          };
        })
      );

      // Tarihleri grupla ve ID'si en küçük olanı seç
      const groupedDataMap = new Map();
      allDates.forEach(({ date, id, datetime }) => {
        if (!groupedDataMap.has(date) || id < groupedDataMap.get(date)?.id) {
          groupedDataMap.set(date, { id, datetime });
        }
      });

      // Map'teki verileri diziye dönüştür
      const pairedData = Array.from(
        groupedDataMap,
        ([date, { id, datetime }]) => ({
          date,
          datetime,
          id,
        })
      );

      setDateData(pairedData);
    }
  }, [data, isLoading]);
  useEffect(() => {
    const handleScroll = () => {
      if (
        !isFetchingNextPage &&
        divRef.current &&
        Math.abs(divRef?.current?.scrollTop) +
          0.5 +
          divRef.current?.clientHeight >=
          divRef.current?.scrollHeight
      ) {
        fetchNextPage();
      }
    };
    const currentDivRef: any = divRef?.current;

    currentDivRef.addEventListener("scroll", handleScroll);
    if (currentDivRef) {
    }
    return () => {
      if (currentDivRef) {
        currentDivRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, [divRef]);

  function isUserCreator(item: any) {
    return user?.attributes?.userProfile?.id === item?.messageSenderInfo?.id
      ? false
      : true;
  }
  useEffect(() => {
    if (!focusInput) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      document.body.style.overflow = "hidden";
      document.body.style.position = "relative";
      document.body.style.height = "100%";
    }
  }, [focusInput]);
  const clearAudio = () => {
    clearBlobUrl();
    setAudioTime(60);
  };
  useEffect(() => {
    if (audioTime !== 0 && status === "recording") {
      const timerId = setTimeout(() => {
        setAudioTime(audioTime - 1);
      }, 1000);
      return () => clearTimeout(timerId);
    }
  }, [audioTime, status]);
  useEffect(() => {
    if (audioTime === 0) {
      stopRecording();
    }
  }, [audioTime]);
  // useEffect(() => {
  //   ws.current = WaveSurfer.create({ container: wavDiv.current });

  //   ws.current.on("ready", function () {
  //     try {
  //       const peaks = ws.current.exportPeaks();
  //       const duration = ws.current.getDuration();
  //       console.log("zxcpeaks", peaks);
  //     } catch (error) {
  //       console.log("error", error);
  //     }
  //   });
  // }, [ws]);
  useEffect(() => {
    if (waveformRef.current) {
      ws.current = WaveSurfer.create({
        container: waveformRef.current,
      });
      ws.current.on("ready", function () {
        console.log("hehehe", ws.current.exportPeaks());
        // console.log('hehehe2', ws.current.getDecodedData())
        try {
          const peaks = ws.current.exportPeaks();
          const duration = ws.current.getDuration();
          if (peaks.length > 0) {
            setSendAudioCheck(true);
          }
          setPeaks(peaks);
          setDuration(duration);
        } catch (error) {
          console.log("error", error);
        }
      });
    } else {
      console.error(
        "Container not found. Make sure waveformRef is correctly initialized."
      );
    }
  }, [waveformRef]);

  const handleConvert = async (mediaBlobUrl: string) => {
    let blob = await fetch(mediaBlobUrl).then((r) => r.blob());
    const randomName = Math.random().toString(36).substring(7);
    const file = new File([blob], `${randomName}.mp3`, {});
    return file;
  };

  const sendButtonFn = () => {
    if (status === "idle") {
      sendMessageText();
    } else {
      stopRecording();
    }
  };
  const sendMessageText = async () => {
    const data = {
      user_profile: [user?.attributes?.userProfile?.id],
      text: text,
      messageSentTime: new Date(),
      chat: [parseInt(id)],
      contentType: mediaBlobUrl ? "audio" : "text",
    };
    await sendMessage(data);
    setText("");
  };
  useEffect(() => {
    if (mediaBlobUrl && status === "stopped") {
      console.log("audio1bloburl", mediaBlobUrl);
      sendMessageAudio();
    }
  }, [mediaBlobUrl, status]);
  useEffect(() => {
    if (peaks.length > 0 && sendAudioCheck) {
      sendAudio();
    }
  }, [peaks, sendAudioCheck]);

  const sendMessageAudio = async () => {
    const file = await handleConvert(mediaBlobUrl);
    const newArrBuff = await file.arrayBuffer();
    let blob = new Blob([new Uint8Array(newArrBuff)], { type: file.type });
    await ws.current.loadBlob(blob);
    setAudioFile(file);
  };

  const sendAudio = async () => {
    const data: any = {
      user_profile: [user?.attributes?.userProfile?.id],
      messageSentTime: new Date(),
      chat: [parseInt(id)],
      contentType: "audio",
      assetData: { peaks, duration },
    };
    sendMessageAudioChat({ data: data, audio: audioFile });
    clearBlobUrl();
    setSendAudioCheck(false);
    setAudioFile(null);
    setPeaks([]);
    setDuration(0);
    setAudioTime(60);
    clearBlobUrl();
  };

  const handleEnterKeyPress = (event: any) => {
    if (event.key === "Enter" && text.trim().length > 0) {
      sendButtonFn();
    }
  };

  const borderRadius = (index: number) => {
    const currentItem = flattenedData[index];
    const previousItem = flattenedData[index - 1];

    if (
      currentItem?.messageSenderInfo?.id === previousItem?.messageSenderInfo?.id
    ) {
      return user?.attributes?.userProfile?.id ===
        currentItem?.messageSenderInfo?.id
        ? "16px 16px 4px 16px"
        : "16px 16px 16px 4px";
    } else {
      return user?.attributes?.userProfile?.id ===
        currentItem?.messageSenderInfo?.id
        ? "16px 4px 16px 16px"
        : "4px 16px 16px 16px";
    }
  };

  return (
    <div
      className={classes.main_area}
      style={{ height: `calc(100% - ${creatorAreaHeight}px)` }}
    >
      <div
        ref={divRef}
        style={{
          flexDirection: "column-reverse",
          display: "flex",
        }}
        className={`${classes.data_area} scroll_area`}
      >
        {flattenedData?.length === 0 && !isLoading && (
          <Center h="100%">
            <Text fw={800} opacity=".5" color="white">
              {translation.chat_page.not_have_message_send_new_message}
            </Text>
          </Center>
        )}
        {flattenedData.map((item: any, i: number) => (
          <React.Fragment key={i}>
            <div
              className={`${
                isUserCreator(item)
                  ? classes.message_box_creator
                  : classes.message_box
              } `}
              style={{
                justifyContent: isUserCreator(item) ? "left" : "right",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 2,
                }}
                className={`${isUserCreator(item) && classes.message_box_left}`}
              >
                {item.isCreator && creator && isUserCreator(item) && (
                  <Avatar
                    radius={50}
                    size={28}
                    mr={8}
                    ml={14}
                    src={
                      creator?.results?.attributes?.creator_profiles?.data
                        ?.avatar?.images?.thumbs.c250
                    }
                  />
                )}
                {item.attributes?.contentType === "text" && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: isUserCreator(item)
                        ? "flex-start"
                        : "flex-end",
                      marginRight: isUserCreator(item) ? 0 : 8,
                    }}
                  >
                    <div
                      style={{
                        width:
                          item.attributes.text.length < 60 ? "100%" : "70%",
                        display: "flex",
                        position: "relative",
                        backgroundColor: isUserCreator(item)
                          ? "rgb(38, 38, 38)"
                          : item.isLoading
                          ? "rgb(136 150 199 / 50%)"
                          : "#4D74FE",
                        borderRadius: borderRadius(i),
                        WebkitBorderRadius: borderRadius(i),
                        flexDirection:
                          item.attributes.text.length < 60 ? "row" : "column",
                        alignItems:
                          item.attributes.text.length < 60
                            ? "flex-end"
                            : "inherit",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {creator.results.attributes.isGroup &&
                          isUserCreator(item) && (
                            <Text
                              fz={14}
                              fw={700}
                              className={`${classes.text_group_sender_username} `}
                              style={{
                                justifyContent: "flex-end",
                                wordBreak: "break-word",
                                color: item.messageSender.includes("Anonymous")
                                  ? "grey"
                                  : "#D2E951",
                              }}
                            >
                              {item.messageSender}
                            </Text>
                          )}
                        <Text
                          fz={14}
                          fw={500}
                          className={`${classes.text} `}
                          style={{
                            padding: creator.results.attributes.isGroup
                              ? isUserCreator(item)
                                ? "6px 12px"
                                : "8px 12px"
                              : "8px 12px",
                            wordBreak: "break-word",
                          }}
                        >
                          {item.attributes.text}
                        </Text>
                      </div>

                      <Text
                        fz={9}
                        color="white"
                        style={{
                          display: "flex",
                          opacity: ".5",
                          left:
                            item.attributes.text.length > 60 &&
                            isUserCreator(item)
                              ? 12
                              : 0,
                          paddingTop: item.attributes.text.length > 60 ? 0 : 12,
                          marginBottom:
                            item.attributes.text.length > 60 &&
                            isUserCreator(item)
                              ? 10
                              : item.attributes.text.length > 60
                              ? 10
                              : 0,
                          paddingRight: 12,
                          justifyContent: isUserCreator(item)
                            ? "flex-start"
                            : "flex-end",
                        }}
                      >
                        {item.isLoading
                          ? new Date()?.toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            })
                          : new Date(
                              item.attributes?.createdAt
                            )?.toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                      </Text>
                    </div>
                  </div>
                )}
                {item.attributes?.contentType === "audio" &&
                  item.audio != null && (
                    <div
                      className={`${classes.text} `}
                      style={{
                        borderRadius: borderRadius(i),
                        WebkitBorderRadius: borderRadius(i),
                        padding: "8px",
                        paddingTop: 20,
                        backgroundColor: isUserCreator(item)
                          ? "rgb(38, 38, 38)"
                          : item.isLoading
                          ? "rgb(136 150 199 / 50%)"
                          : "#4D74FE",
                      }}
                    >
                      <ChatAudio
                        loading={loadingMessageAudio}
                        uploadLoading={item?.isLoading}
                        setDuration={setDuration}
                        peaks={item?.attributes?.assetData?.peaks}
                        duration={item?.attributes?.assetData?.duration}
                        url={item?.audio?.audio?.url}
                        disabled={loadingMessageAudio}
                        id={i}
                      />
                      <Text
                        fz={9}
                        color="white"
                        opacity={0.5}
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {item.isLoading
                          ? new Date()?.toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            })
                          : new Date(
                              item.attributes?.createdAt
                            )?.toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                      </Text>
                    </div>
                  )}
              </div>
            </div>
            {dateData
              ?.filter((x: any) => x?.id === item?.id && x?.date !== "aN.aN.aN")
              .map((dateItem: any, j: number) => (
                <Center mt={21} mb={21} key={j}>
                  <Divider
                    size={".5px"}
                    w={"100%"}
                    styles={{
                      label: {
                        ":root": {
                          backgroundColor: "red",
                        },
                      },
                    }}
                    color="rgba(255, 255, 255,.3)"
                    label={
                      <Text fw={700} fz={12}>
                        {dateItem.date}
                        {dateItem.date === translation.chat_page.days.today &&
                          " " + dateItem.datetime}
                      </Text>
                    }
                    labelPosition="center"
                  />
                </Center>
              ))}
          </React.Fragment>
        ))}

        <div style={{ width: "0px", height: "0px" }} ref={waveformRef}></div>
      </div>
      {text.length === 1000 && (
        <div
          style={{
            height: 16,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text style={{ color: "white", fontWeight: 900 }}>
            {translation.chat_page.max_length}
          </Text>
        </div>
      )}
      {creator.results.attributes.isUser && (
        <div
          style={{
            position: "relative",
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
            marginTop: text.length === 1000 ? 0 : 16,
            marginBottom: 16,
            justifyContent: "center",
            width: "100%",
          }}
          className={classes.input_main}
        >
          <div
            style={{
              border: text.length === 1000 ? "1px solid #f14a71" : "0px",
            }}
            className={classes.input_area}
          >
            {status === "idle" || status === "acquiring_media" ? (
              <TextInput
                color="white"
                placeholder={translation.chat_page.message}
                value={text}
                onFocus={() => setFocusInput(true)}
                maxLength={1000}
                onBlur={() => setFocusInput(false)}
                styles={{ rightSection: { width: "auto" } }}
                classNames={{ input: classes.input_root }}
                onChange={(e) => {
                  // setFocusInput(true);
                  setText(e.target.value);
                }}
                disabled={isLoading}
                // rightSection={inputButton}
                onKeyUp={handleEnterKeyPress}
                className={classes.input}
              />
            ) : (
              <div className={classes.microphone_mode_area}>
                <ActionIcon onClick={clearAudio} ml={13} variant="transparent">
                  <AiFillCloseCircle color="white" size={24} />
                </ActionIcon>
                <div className={classes.microphone_record_area}>
                  <div className={classes.audio_timer}>
                    {audioTime < 10 ? "0:0" + audioTime : "0:" + audioTime}
                  </div>
                  <div
                    className={`${classes.record_animation} ${
                      status === "recording"
                        ? classes.record_animation_active
                        : classes.record_animation_stop
                    }`}
                  ></div>
                  <div
                    style={{ width: "0px", height: "0px" }}
                    id="waveform-container"
                  ></div>
                  <Button
                    className={classes.mic_play_stop_button}
                    onClick={() =>
                      status === "recording"
                        ? pauseRecording()
                        : resumeRecording()
                    }
                    styles={{ label: { justifyContent: "center" } }}
                    ml={4}
                  >
                    {status === "recording" ? (
                      <FaStop color="#4D74FE" size="14" />
                    ) : (
                      <FaPlay color="#4D74FE" size="14" />
                    )}
                  </Button>
                </div>
              </div>
            )}
            <Flex mr={10}>
              {(status === "idle" || status === "acquiring_media") && (
                <ActionIcon
                  onClick={() => status === "idle" && startRecording()}
                  variant="transparent"
                  mr={12}
                >
                  <FaMicrophone color="white" />
                </ActionIcon>
              )}
              <Divider mr={12} opacity={0.1} orientation="vertical" />
              <ActionIcon
                radius={100}
                className={classes.send_button}
                disabled={
                  status !== "paused" &&
                  status !== "recording" &&
                  text.trim().length < 1
                }
                onClick={sendButtonFn}
                styles={{ root: { backgroundColor: "#D2E951", width: 20 } }}
              >
                <SendIcon />
              </ActionIcon>
            </Flex>
          </div>
        </div>
      )}
    </div>
  );
};
const useStyles = createStyles((theme) => ({
  main_area: {
    [`@media (max-width: 768px)`]: {
      height: "75vh",
    },
  },
  message_box: {
    width: "100%",
    ref: getStylesRef("message_box"),
    display: "flex",
  },
  message_box_creator: {
    width: "100%",
    ref: getStylesRef("message_box_creator"),
    display: "flex",
  },
  text: {
    width: "auto",
    color: "white",
    marginRight: 8,
  },
  data_area: {
    height: "calc(90% - 32px)",
    [`@media (max-width: 768px)`]: {
      height: "calc(88% - 32px)",
    },
    // [`.${getStylesRef("message_box")}:first-child`]: {
    //     [`.${getStylesRef("text_box")}`]: {
    //       borderRadius: "16px 4px 16px 16px !important",
    //     },
    //   },
    // [`.${getStylesRef("message_box")}:last-child`]: {
    //   [`.${getStylesRef("text_box")}`]: {
    //     borderRadius: "16px 16px 4px 16px",
    //   },
    // },
    // [`.${getStylesRef("message_box_creator")}:first-child`]: {
    //     backgroundColor:"blue",
    //     [`.${getStylesRef("text_creator")}`]: {
    //       borderRadius: "4px 16px 16px 16px !important",
    //     },
    //   },
    // [`.${getStylesRef("message_box_creator")}:last-child`]: {
    //     backgroundColor:"green",
    //   [`.${getStylesRef("text_creator")}`]: {
    //     borderRadius: "16px 16px 4px 16px ",
    //   },
    // },
    // span: {
    //     padding: "7px 12px",
    //     backgroundColor: "blue",
    //     color: "white",
    //     display:"table",
    //     borderRadius: "16px 16px 4px 16px",
    //     ":first-child": {
    //       borderRadius: "16px 4px 16px 16px",
    //     },
    //     ":last-child": {
    //       borderRadius: "16px 16px 4px 16px",
    //     },
    //   },
  },
  input_main: {
    height: "10%",
    [`@media (max-width: 768px)`]: {
      height: "12%",
    },
  },
  input_root: {
    height: 52,
    border: 0,
    color: "white",
    backgroundColor: "transparent",
    ":before": {
      backgroundColor: "transparent !important",
    },
    ":disabled": {
      backgroundColor: "transparent",
    },
  },
  send_button: {
    backgroundColor: theme.colors.green[0],
  },
  input: {
    width: "100%",
  },
  input_area: {
    borderRadius: 53,
    position: "absolute",
    width: "97%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#1E1E1E",
    display: "flex",
    alignItems: "center",
  },
  microphone_mode_area: {
    height: 52,
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  microphone_record_area: {
    width: "100%",
    backgroundColor: "#4D74FE",
    alignItems: "center",
    display: "flex",
    height: 34,
    borderRadius: 34,
    position: "relative",
    overflow: "hidden",
  },
  record_animation: {
    position: "absolute",
    height: "100%",
    zIndex: 1,
    justifyContent: "flex-end",
    backgroundColor: "rgba(144,169,254,.9)",

    animation: "colorChange 60s ease-in-out",
    "@keyframes colorChange": {
      "0%": {
        width: 0,
      },
      "100%": {
        width: "100%",
      },
    },
  },
  record_animation_active: {
    animationPlayState: "playing",
  },
  record_animation_stop: {
    animationPlayState: "paused",
  },
  header_mobile: {
    marginLeft: 20,
    display: "none",
    [`@media (max-width: 768px)`]: {
      display: "flex",
      alignItems: "center",
    },
  },
  mic_play_stop_button: {
    height: 28,
    paddingLeft: 0,
    paddingRight: 0,
    width: 28,
    borderRadius: "50%",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 9,
  },
  audio_timer: {
    backgroundColor: "white",
    position: "absolute",
    right: 10,
    borderRadius: 12,
    color: "rgb(0, 128, 255)",
    fontSize: 13,
    zIndex: 999,
    padding: "0px 5px",
  },
  text_group_sender_username: {
    padding: "2px 8px",
  },
  message_box_left: {
    marginLeft: 15,
    [`@media (max-width: 768px)`]: {
      marginLeft: 9,
    },
  },
}));
export default Messages;

// analyticsService.ts
import ReactGA from "react-ga4";
import { IEvent } from "../interfaces/log-event.interface";
import { errorEvent, promiseRejectionEvent } from "./log-events";

export const initializeAnalytics = (trackingID: string) => {
  ReactGA.initialize(trackingID);
};

export const logEvent = (event: IEvent) => {
  try {
    ReactGA.event({
      category: event.category,
      action: event.action,
      label: event.label,
    });
  } catch (error) {}
};

export const setUserGA = (userId: string) => {
  try {
    ReactGA.set({ userId });
  } catch (error) {}
};

export const setupGlobalErrorHandling = () => {
  const handleError = (event: ErrorEvent) => {
    logEvent(errorEvent(event.message, event.filename, event.lineno));
  };

  const handlePromiseRejection = (event: PromiseRejectionEvent) => {
    logEvent(promiseRejectionEvent(event.reason));
  };

  window.addEventListener("error", handleError);
  window.addEventListener("unhandledrejection", handlePromiseRejection);

  return () => {
    window.removeEventListener("error", handleError);
    window.removeEventListener("unhandledrejection", handlePromiseRejection);
  };
};

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getWallet, getpaymentLinksFn, purchaseItemFn } from "../api";

export const usePurchaseVoice = (username: string) => {
  const queryClient = useQueryClient();
  const {
    isLoading,
    isIdle,
    status,
    isSuccess,
    mutate: payItem,
  } = useMutation(({ itemId, isNew }: any) => purchaseItemFn(itemId, isNew), {
    onSuccess: async (data: any) => {
      queryClient.setQueryData(["creatorPosts-", username], (oldData: any) => {
        if (oldData) {
          const updatedPages = oldData.pages.map((page: any) => {
            return {
              ...page,
              data: {
                ...page.data,
                creatorPosts: page.data.creatorPosts.map((post: any) => {
                  if (post.id === data.data.data.post.id) {
                    return {
                      ...post,
                      attributes: {
                        ...data.data.data.post.attributes,
                      },
                      isPaid: true,
                    };
                  }
                  return post;
                }),
              },
            };
          });

          return {
            ...oldData,
            pages: updatedPages,
          };
        }
        return oldData;
      });
      await queryClient.invalidateQueries({
        queryKey: ["wallet"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["user"],
      });
    },
  });
  return { payItem, isLoading, isIdle, isSuccess, status };
};
export const useGetWallet = () => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["wallet"],
    queryFn: () => getWallet(),
  });

  return { data, isLoading, isError, error };
};
export const useGetPaymentLinks = () => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["paymentLinks"],
    queryFn: () => getpaymentLinksFn(),
    staleTime: 3600000,
    // retry:false,
    // refetchOnMount:false,
    // refetchOnWindowFocus:false,
    // select: (res) => res,
  });

  return { data, isLoading, isError, error };
};

export const usePurchaseVoiceFeed = () => {
  const queryClient = useQueryClient();
  const {
    isLoading,
    isIdle,
    status,
    isSuccess,
    mutate: payItem,
  } = useMutation(({ itemId, isNew }: any) => purchaseItemFn(itemId, isNew), {
    onSuccess: async (data: any) => {
      queryClient.setQueryData(["audios"], (oldData: any) => {
        console.log("oldData", oldData);
        if (oldData) {
          const updatedPages = oldData.pages.map((page: any) => {
            return {
              ...page,
              results: page.results.map((post: any) => {
                if (post.id === data.data.data.post.id) {
                  return {
                    ...post,
                    attributes: {
                      ...data.data.data.post.attributes,
                    },
                    isPaid: true,
                  };
                }
                return post;
              }),
            };
          });

          return {
            ...oldData,
            pages: updatedPages,
          };
        }
        return oldData;
      });
      await queryClient.invalidateQueries({
        queryKey: ["wallet"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["user"],
      });
    },
  });
  return { payItem, isLoading, isIdle, isSuccess, status };
};
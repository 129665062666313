// src/contexts/SocketContext.tsx
import React, { createContext, useContext, useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";
import { getToken } from "../utils/auth/auth";

export interface SocketContextType {
  socket: Socket | null;
  joinChat?: (chatId: number, userId: number, profileId: number) => void;
  leaveChat?: () => void;
  eventGoLive?: (
    eventId: number,
    isLiveEvent: boolean,
    chatId:number
  ) => void;
  excludeMemberFromChat?: (
    chatId: number,
    userId: number,
    profileId: number
  ) => void;
  leaveOwnerEvent?: (chatId: number) => void;
}

const SocketContext = createContext<SocketContextType | null>(null);

export const useSocket = (): SocketContextType | null =>
  useContext(SocketContext);

interface SocketProviderProps {
  user: any | null;
  children: React.ReactNode;
}

export const SocketProvider: React.FC<SocketProviderProps> = ({
  user,
  children,
}) => {
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    try {
      if (!user) return;

      const profileId = user.attributes.userProfile.id;
      console.log("WEBSOCKET INIT");

      const JWT_TOKEN = getToken();
      const URL = process.env.REACT_APP_WS_URL;
      if (!URL) {
        throw new Error("WS URL is not defined in the environment variables.");
      }

      const newSocket = io(URL, {
        transports: ["websocket"],
        auth: {
          strategy: "jwt",
          token: JWT_TOKEN,
        },
      });

      newSocket.on("connect", () => {
        console.log("Connected to server");
      });

      newSocket.on("disconnect", () => {
        console.log("Disconnected from server");
      });

      newSocket.on("hello from server", (response) => {
        console.log("server says hello to socket with id ", response.socketId);
        newSocket.emit("initial data from user", {
          id: user.id,
          profileId: profileId,
          isCreator: user.isCreator,
        });
      });

      setSocket(newSocket);

      return () => {
        newSocket.off("connect");
        newSocket.off("disconnect");
        newSocket.off("userJoinedChat");
        newSocket.off("userLeftChat");
        newSocket.off("hello from server");
        newSocket.close();
        console.log("WebSocket disconnected and cleaned up");
      };
    } catch (error: any) {
      console.error("Error initializing WebSocket:", error.message);
    }
  }, [user]);

  const joinChat = (chatId: number, userId: number, profileId: number) => {
    try {
      if (socket) {
        socket.emit("join chat", { chatId, userId, profileId });
      } else {
        console.error("Socket is not connected.");
      }
    } catch (error: any) {
      console.error("Error joining chat:", error.message);
    }
  };

  const leaveChat = (chatId?: number) => {
    try {
      if (socket) {
        socket.emit("leave chat", { chatId });
      } else {
        console.error("Socket is not connected.");
      }
    } catch (error: any) {
      console.error("Error leaving chat:", error.message);
    }
  };

  const eventGoLive = (eventId: number, isLiveEvent: boolean,chatId:number) => {
    try {
      if (socket) {
        socket.emit("event live", { eventId, isLiveEvent,chatId });
      } else {
        console.error("Socket is not connected.");
      }
    } catch (error: any) {
      console.error("Error joining chat:", error.message);
    }
  };
  const excludeMemberFromChat = (chatId: number, userId: number) => {
    try {
      if (socket) {
        socket.emit("kick chat", { chatId, userId });
      } else {
        console.error("Socket is not connected.");
      }
    } catch (error: any) {
      console.error("Error joining chat:", error.message);
    }
  };

  const leaveOwnerEvent = (chatId: number) => {
    try {
      if (socket) {
        socket.emit("owner leave event", { chatId });
      } else {
        console.error("Socket is not connected.");
      }
    } catch (error: any) {
      console.error("Error joining chat:", error.message);
    }
  };
  return (
    <SocketContext.Provider
      value={{
        socket,
        eventGoLive,
        joinChat,
        leaveChat,
        excludeMemberFromChat,
        leaveOwnerEvent,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

import { getToken } from "../../utils/auth/auth";
import restClient from "../restClient";

export const getUserFn = async () => {
  try {
    const token = await getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const data = await restClient.get("/account",config);

    return {
       data:data.data.data,
    };
  } catch (error) {
    console.log("error", error);
  }
};

import { Button, Flex, Image, Text, createStyles } from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";

const LandingFooter = ({ translation, user }: any) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.container}>
      <Flex w="100%" justify="center">
        <Flex w="80%" direction="column" justify="center" align="center">
          <Flex w="100%" align="center" className={classes.footer_top} mb={69}>
            <Flex justify="flex-start">
              <Link to={"/"}>
                <Image
                  width={114}
                  height={74}
                  fit="contain"
                  src={require("../../../assets/logo.png")}
                />
              </Link>
            </Flex>

            <Flex align="center" direction="column">
              <Flex mb={44} mt={44}>
                <Link to="/" className={classes.link}>
                  <Text mr={24} fz={24} fw={700}>
                    {translation?.home}
                  </Text>
                </Link>
                <Link to="/creators" className={classes.link}>
                  <Text ml={24} fz={24} fw={700} className={classes.link}>
                    {translation?.for_creators}
                  </Text>
                </Link>
              </Flex>

              <Flex>
                <Image
                  className={classes.social_media}
                  width={46}
                  height={46}
                  mr={28}
                  src={require("../../../assets/landing/twitter.png")}
                />
                <Image
                  className={classes.social_media}
                  width={46}
                  height={46}
                  src={require("../../../assets/landing/facebook.png")}
                />
                <Image
                  className={classes.social_media}
                  width={46}
                  ml={28}
                  height={46}
                  src={require("../../../assets/landing/instagram.png")}
                />
              </Flex>
            </Flex>
            <Flex justify="flex-end">
              {user == null && (
                <Button
                  pl={40}
                  pr={40}
                  onClick={() => navigate("/register")}
                  bg="rgba(210, 233, 81, 0.3)"
                  h="58px"
                  className={classes.register_button}
                  radius={60}
                >
                  {translation?.register}
                </Button>
              )}
            </Flex>
          </Flex>
          <div className={classes.dvider}></div>
          <Text mt={36} style={{ textAlign: "center" }}>
            {translation?.copyright}
            <Flex align="center">
              <Link to={"/legalnotice"} className={classes.link}>
                {translation?.legal_notice}
              </Link>
              <div className={classes.dot}></div>
              <Link to={"/terms"} className={classes.link}>
                {translation?.agb}
              </Link>
              <div className={classes.dot}></div>
              <Link to={"/privacy"} className={classes.link}>
                {translation?.datenschutz}
              </Link>
              <div className={classes.dot}></div>
              <Link to={"/complaints-form"} className={classes.link}>
                {translation?.complaint}
              </Link>
            </Flex>
          </Text>
        </Flex>
      </Flex>
    </div>
  );
};
const useStyles = createStyles((theme) => ({
  container: {
    paddingBottom: 20,
    backgroundColor: "rgba(56, 56, 56, .7)",
    color: "white",
    width: "100%",
    display: "flex",
    alignItems: "center",
    [`@media (max-width: 500px)`]: {
      padding: "20px 0px",
    },
  },
  link: {
    textDecoration: "none",
    color: "white",
  },
  social_media: {
    transition: "all .5s",
    ":hover": {
      transition: "all 1s",
      transform: "scale(1.1)",
    },
  },
  footer_top: {
    justifyContent: "space-between",
    flexDirection: "row",
    [`@media (max-width: 500px)`]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  dvider: {
    width: "100%",
    height: 2,
    backgroundColor: "white",
  },
  register_button: {
    transition: "all .5s",
    ":hover": {
      transition: "all 1s",
      backgroundColor: theme.colors.blue,
    },
    [`@media (max-width: 500px)`]: {
      marginTop: 20,
    },
  },
  dot: {
    width: 4,
    height: 4,
    marginLeft: 10,
    marginRight: 10,
    backgroundColor: "#D9D9D9",
    borderRadius: "50%",
  },
}));
export default LandingFooter;

import { IEvent } from "../interfaces/log-event.interface";

export const loginEvent: IEvent = {
  category: "User",
  action: "Login Attempt",
  label: "Login Page",
};

export const reAuthEvent: IEvent = {
  category: "User",
  action: "Reauthentication Attempt",
  label: "Any Page",
};

export const registrationEvent: IEvent = {
  category: "User",
  action: "Registration Attempt",
  label: "Registration Page",
};

export const forgotPasswordEvent: IEvent = {
  category: "User",
  action: "Forgot Password Attempt",
  label: "Forgot Password Page",
};

export const sendAudioMessage: IEvent = {
  category: "User",
  action: "Send Audio",
  label: "Chat Page",
};

export const sendTextMessage: IEvent = {
  category: "User",
  action: "Send Text",
  label: "Chat Page",
};

export const deleteMeEvent: IEvent = {
  category: "User",
  action: "Delete Account",
  label: "Management Page",
};

export const paymentLinkClickEvent = (
  id: number,
  price: number,
  coins: number
): IEvent => ({
  category: "User",
  action: "Payment Click",
  label: "Payment Page",
  params: {
    id: id,
    price: price,
    coins: coins,
  },
});

export const errorEvent = (
  message: string,
  filename: string,
  lineno: number
): IEvent => ({
  category: "Error",
  action: "Unerwarteter Fehler",
  label: `${message} at ${filename}:${lineno}`,
});

export const promiseRejectionEvent = (reason: any): IEvent => ({
  category: "Error",
  action: "Promise-Ablehnung",
  label: reason.toString(),
});

import {  Notification, Text, createStyles } from "@mantine/core";
import { useSocket } from "@novu/notification-center";
import React, { useEffect, useState } from "react";
import { TbMessage } from "react-icons/tb";
import { Link } from "react-router-dom";
import WaveIcon from "../../../assets/new-post/wave";

const NotificationComp = ({ translation }: any) => {
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationContent, setNotificationContent]: any = useState({});
  const [notificationContentCreator, setNotificationContentCreator] =
    useState("");
  const [notificationUrl, setNotificationUrl] = useState("#");
  const [notificationId, setNotificationId] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const { socket } = useSocket();
  const { classes } = useStyles();

  useEffect(() => {
    if (socket) {
      socket.on("notification_received", (data) => {
        setNotificationType(data.message.templateIdentifier);
        setNotificationContent(data.message.content);
        setNotificationContentCreator(data.message.payload.data.creatorName);
        setNotificationUrl(data.message.cta.data.url);
        setNotificationId(data.message.id);
        setOpenNotification(true);
      });
    }
    return () => {
      if (socket) {
        socket.off("notification_received");
      }
    };
  }, [socket]);

  const checkIcon = <TbMessage color="#D2E951" size={30} />;
  const newPostIcon = <WaveIcon  color="#D2E951"/>

  const icon = () => {
    if (notificationType === "new-post-info-for-subscriber") {
      return newPostIcon;
    } else {
      return checkIcon;
    }
  };

  const seenNotification = () => {
    setOpenNotification(false);
  };
  return (
    <>
      {openNotification && (
        <Link
          onClick={seenNotification}
          to={notificationUrl}
          style={{
            position: "fixed",
            zIndex: 9999,
            right: 10,
            textDecoration: "none",
          }}
        >
          <Notification
            icon={icon()}
            className={classes.notification_box}
            style={{
              backgroundColor: "#1E1E1E",
              border: "0px solid #D2E951",
              borderRadius: 10,
              color: "#D2E951",
              padding: 20,
            }}
            withCloseButton={false}
            color="#D2E951"
            styles={{ title: { color: "#D2E951", fontWeight: 900 } }}
            title={translation.notification[notificationType]}
            mt="md"
          >
            <Text color="white">
              {translation.notification[notificationType + ".message"] +
                " " +
                notificationContentCreator?.charAt(0)?.toUpperCase() + notificationContentCreator?.slice(1)}
            </Text>
          </Notification>
        </Link>
      )}
    </>
  );
};
const useStyles = createStyles((theme) => ({
  notification_box: {
    boxShadow: "0px 0px 10px 0px rgba(210,233,81,.7)",
    animation: "animate 2s linear infinite",
    "@keyframes animate": {
      "50%": {
        boxShadow: "0px 0px 10px 0px rgba(210,233,81,.1)",
      },
      "100%": {
        boxShadow: "0px 0px 10px 0px rgba(210,233,81,.7)",
      },
    },
  },
}));
export default NotificationComp;

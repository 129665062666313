import React, { useState, useEffect } from "react";
import { Checkbox, Divider, Select, Switch } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { NavLink, createStyles } from "@mantine/core";
import { AiOutlineRight } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { useGetTranslations } from "../../../api/translations/service";
import {
  useGetNotificationEmailSetting,
  useUpdateNotificationSettings,
} from "../../../api/app-settings/service";

const Display = ({ setActiveRight }: any) => {
  const { i18n, ready }: any = useTranslation();

  const { right_menu } = useParams();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { data } = useGetTranslations();
  const { data: notificationEmail, isLoading } =
    useGetNotificationEmailSetting();
  const { updateNotifctnSettings, updateLoading } =
    useUpdateNotificationSettings();
  const translation = data?.data;
  const [languages, setLanguages]: any = useState([]);
  const [checkMail, setCheckMail]: any = useState(false);
  const [eventMail, setEventMail]: any = useState(false);
  const [postsMail, setPostsMail]: any = useState(false);
  const [language, setLanguage] = useLocalStorage({
    key: "i18nextLng",
    defaultValue: i18n.resolvedLanguage,
  });

  const changeLanguage = (e: any) => {
    i18n.changeLanguage(e);
    setLanguage(e);
    window.location.reload();
  };

  useEffect(() => {
    let languages: any = i18n?.options?.supportedLngs?.filter(
      (x: any) => x !== "cimode"
    );
    setLanguages(
      languages.map((x: any) => {
        return { value: x, label: translation?.[x] };
      })
    );
  }, [i18n, ready, translation]);

  const displayData = [
    {
      value: "language",
      label: translation.language,
      description: translation?.[language],
    },
    {
      value: "notifications",
      label: translation.notifications,
    },
  ];

  useEffect(() => {
    if (notificationEmail) {
      setCheckMail(notificationEmail?.allNotification);
      setEventMail(notificationEmail?.eventNotification);
      setPostsMail(notificationEmail?.postsNotification);
    }
  }, [notificationEmail]);

  const updateEmailNotification = async (e: any) => {
    setCheckMail(!checkMail);
    const data = {
      type: "all",
      value: !notificationEmail?.allNotification,
    };
    updateNotifctnSettings(data);
  };

  const updateEventNotification = async (e: any) => {
    setEventMail(!eventMail);
    const data = {
      type: "events",
      value: !notificationEmail?.eventNotification,
    };
    updateNotifctnSettings(data);
  };
  const updatePostsNotification = async (e: any) => {
    setPostsMail(!postsMail);
    const data = {
      type: "posts",
      value: !notificationEmail?.postsNotification,
    };
    updateNotifctnSettings(data);
  };
  const activeSection = () => {
    if (right_menu === "language") {
      return (
        <Select
          label={translation?.settings_page.language}
          placeholder="Pick one"
          data={languages}
          value={language}
          onChange={(e) => changeLanguage(e)}
          styles={(theme) => ({
            item: {
              // applies styles to selected item
              "&[data-selected]": {
                "&, &:hover": {
                  backgroundColor: theme.colors.green[0],
                  color: "black",
                  borderRadius: 10,
                },
              },

              // applies styles to hovered item (with mouse or keyboard)
              "&[data-hovered]": {
                backgroundColor: theme.colors.green[0],
                color: "black",
                borderRadius: 10,
              },
            },
          })}
          classNames={{
            label: classes.select_label,
            input: classes.select_input,
            dropdown: classes.select_dropdown,
            item: classes.select_item_wrapper,
          }}
        />
      );
    }
    if (right_menu === "notifications") {
      return (
        <>
          <Switch
            color="indigo"
            checked={checkMail}
            onChange={updateEmailNotification}
            disabled={isLoading || updateLoading}
            description={
              translation.settings_page.email_notification_description
            }
            styles={{
              body: { justifyContent: "space-between" },
              label: { color: "white" },
            }}
            labelPosition="left"
            label={translation.settings_page.email_notification_label}
          />
          <Divider color="gray" mt={10} mb={10} />
          <Checkbox
            color="indigo"
            checked={postsMail}
            onChange={updatePostsNotification}
            disabled={isLoading || updateLoading || !checkMail}
            mt={10}
            styles={{
              body: { justifyContent: "space-between" },
              label: { color: "white" },
            }}
            labelPosition="left"
            label={translation.settings_page.email_notification_post_label}
          />
           {/* <Checkbox
            color="indigo"
            checked={checkMail}
            onChange={updateEmailNotification}
            disabled={isLoading || updateLoading}
            mt={10}
            styles={{
              body: { justifyContent: "space-between" },
              label: { color: "white" },
            }}
            labelPosition="left"
            label={translation.settings_page.email_notification_message_label}
          /> */}
          <Checkbox
            color="indigo"
            checked={eventMail}
            onChange={updateEventNotification}
            disabled={isLoading || updateLoading || !checkMail}
            mt={10}
            styles={{
              body: { justifyContent: "space-between" },
              label: { color: "white" },
            }}
            labelPosition="left"
            label={translation.settings_page.email_notification_event_label}
          />
        </>
      );
    }
  };
  return (
    <>
      {right_menu === undefined ? (
        <>
          {displayData?.map((item, index) => (
            <NavLink
              classNames={{ root: classes.navlink_root }}
              key={item.label}
              label={item.label}
              className={classes.title}
              description={item?.description}
              rightSection={<AiOutlineRight />}
              onClick={() => {
                setActiveRight(item.label);
                navigate(`/my/settings/display/${item.value}`);
              }}
            />
          ))}
        </>
      ) : (
        <>
          <div style={{ padding: "0px 10px" }}>{activeSection()}</div>
        </>
      )}
    </>
  );
};
const useStyles = createStyles((theme) => ({
  title: {
    padding: "8px 0px",
    color: "white",
  },
  links: {
    borderBottom: "1px solid rgba(138,150,163,.25)",
  },
  navlink_root: {
    "&:hover": {
      backgroundColor: "transparent",
      transform: "scale(1.05)",
      transition: "all .2s",
    },
  },
  select_input: {
    backgroundColor: "transparent",
    border: "1px solid rgba(255, 255, 255, 0.4)",
    borderRadius: 30,
    padding: "18px 30px",
    color: "white",
  },
  select_label: {
    color: "white",
    marginBottom: 3,
    fontSize: 14,
    fontWeight: 400,
  },
  select_dropdown: {
    backgroundColor: "rgba(30, 30, 30, 0.7)",
    border: "1px solid rgba(255, 255, 255, 0.4)",
    color: "white",
    borderRadius: 20,
  },

  select_item_wrapper: {
    color: "white",
    marginLeft: 0,
  },
}));
export default Display;

import {
  Text,
  Title,
  createStyles,
  Flex,
  Image,
} from "@mantine/core";
import { NavLink } from "react-router-dom";
import { ICreatorProfile } from "../../../interfaces/creator-profile.interface";
import { useViewportSize } from "@mantine/hooks";
const CreatorImageCard = ({ creator }: { creator: ICreatorProfile }) => {
  const { classes } = useStyles();
  const { width } = useViewportSize();

 
  return (
    <>
      <Flex direction="column" >
        <div className={classes.card_box}>
          <Flex direction="row" justify="space-between" mb={10}>
            <div
              className={classes.creator_profile}
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
              }}
            >
              <NavLink
                className={classes.navLink}
                to={`/${creator.attributes?.username}`}
              >
                <Image
                  radius={14}
                  width={width < 768 ? 62:74}
                  height={width < 768 ? 62:74}
                  src={
                    width < 768
                      ? creator.attributes?.avatarThumbs?.thumbs?.c50
                      : creator.attributes?.avatarThumbs?.thumbs?.c250
                  }
                  alt="Random image"
                />
                <div className={classes.creator_name_area}>
                  <Title size={17}>{creator?.attributes?.name}</Title>
                  <Text className={classes.username} size={13} ta="left">
                    {"@" + creator.attributes?.username}
                  </Text>
                </div>
              </NavLink>
              <div>
                <Image
                  mt={10}
                  variant="outline"
                  radius={14}
                  classNames={{ image: classes.image }}
                  src={creator.attributes?.avatarThumbs?.thumbs?.c250}
                  alt="Random image"
                />
              </div>
            </div>
          </Flex>
        </div>
      </Flex>
    </>
  );
};
const useStyles = createStyles((theme) => ({
  card_box: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    margin: "5px 0px",
    color: "white",
    backdropFilter: "blur(20px)",
    zIndex: 9,
    borderRadius: 20,
  },
  creator_profile: {
    margin: "13px 18px 0px 18px",
    color: "white",
  },
  creator_name_area: {
    marginLeft: 20,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  username: {
    color: "#C9C9C9",
  },
  image: {
    maxHeight: 550,
    objectFit: "cover",
    objectPosition: "center",
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      maxHeight: 300,
    },
  },
  navLink: {
    color: "white",
    alignItems:"center",
    textDecoration: "none",
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
}));
export default CreatorImageCard;

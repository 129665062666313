import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";

  const LANGUAGES = [
    { code: "tr", name: "Türkçe" },
    { code: "en", name: "English" },
    { code: "de-DE", name: "German" },
  ];

  i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    supportedLngs: LANGUAGES.map((l) => l.code),
    detection: {
      order: ["localStorage", "navigator"],
      caches: ["localStorage"],
      excludeCacheFor: ['cimode'],
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
  });
export default i18n;

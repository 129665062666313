/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import Layout from "../../components/high-level/layout/layout";
import { Button, Image, Text, createStyles } from "@mantine/core";
import {
  useViewportSize,
  useElementSize,
  useLocalStorage,
  useScrollIntoView,
} from "@mantine/hooks";
import { CreatorCard } from "../../components/low-level/feedCard";
import { getState, saveState } from "../../utils/ui/stateSaver";
import { useFeedPosts, useGetRecommendCreators } from "../../api/feed/service";
import FetchLoader from "../../components/low-level/loading/FetchLoader";
import RecommendCarousel from "../../components/low-level/Carousel/RecommendCarousel";
import Masonry from "react-responsive-masonry";
import { BiBell } from "react-icons/bi";
import { useGetNotificationCount } from "../../api/notifications/service";
import { Link } from "react-router-dom";
import { useGetUser } from "../../api/user/service";
import Events from "../../components/low-level/Feed/Events";
import Audios from "../../components/low-level/Feed/Audios";
// import { useGetCategories } from "../../api/categories/service";

const FeedPage = ({ translation }: any) => {
  const { data, isLoading, fetchNextPage, isFetchingNextPage } = useFeedPosts();
  const { data: recommendData, fetchNextPage: recommendNextPage,isLoading:recommendLoading }: any =
    useGetRecommendCreators();
  

  const [arr] = useState(
    [...Array(15)]
      .map(() => Math.floor(Math.random() * (250 - 100 + 1)) + 170)
      .sort((a, b) => a - b)
  );
  // const { data: categories } = useGetCategories();
  const { data: user } = useGetUser();
  // const [selectedCategory, setSelectedCategory] = useState("");
  const [initalSlide, setInitalSlide] = useLocalStorage({
    key: "initial-slide-page",
    defaultValue: 0,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeTab, setActiveTab] = useLocalStorage({
    key: 'active-tab',
    defaultValue: 'flow',
  });
  const { targetRef } = useScrollIntoView<HTMLDivElement>({
    offset: 60,
  });
  const { width } = useViewportSize();
  const { ref } = useElementSize();
  const { classes } = useStyles();
  const { data: notificationCount } = useGetNotificationCount();

  useEffect(() => {
    const save = () => {
      saveState("Feed", { scrollY: window.pageYOffset });
    };

    if (getState("Feed")) {
      let { scrollY } = getState("Feed");

      setTimeout(() => {
        window.scrollTo(0, scrollY);
      }, 50);
    } else {
      save();
    }

    document.addEventListener("scroll", save);

    return () => document.removeEventListener("scroll", save);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        fetchNextPage();
      }
    });

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [targetRef]);

  const flattenedData: any = useMemo(
    () => (data ? data?.pages?.flatMap((item: any) => item.results) : []),
    [data]
  );



  return (
    <Layout
      headerTitle={translation.home}
      headerRightIcon={
        <div style={{ display: "flex", alignItems: "center", height: 35 }}>
          {user?.data?.isCreator && (
            <Link
              to="/my/create-event"
              className={classes.create_group_button}
            >
              <Image
                mr={10}
                width={18}
                height={18}
                src={require("../../assets/plus.png")}
              />
              <Text style={{textDecoration:'none'}} lh={0} fz={14} fw={600}>
                {translation.create_event}
              </Text>
            </Link>
          )}
          <Link
            to="/my/chat"
            style={{
              marginRight: 10,
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <BiBell color="white" size={26} />
            {notificationCount !== undefined && notificationCount !== 0 && (
              <div
                style={{
                  height: 15,
                  width: 15,
                  backgroundColor: "rgb(210, 233, 81)",
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text color="black" fw={700} fz={10}>
                  {notificationCount}
                </Text>
              </div>
            )}
          </Link>
        </div>

        // ) : (
        //   <HomeIcon white={true} />
        // )
      }
    >
      <div
        ref={ref}
        className={`${classes.container}  `}
        style={{
          minHeight: "100vh",
        }}
      >
        {/* {categories?.results.length > 0 && (
          <Category
            data={categories}
            setSelectedCategory={setSelectedCategory}
            selectedCategory={selectedCategory}
          />
        )} */}
        { recommendData?.pages[0]?.results?.length > 0 && (
          <RecommendCarousel
            nextPage={recommendNextPage}
            loading={recommendLoading}
            setPage={setInitalSlide}
            page={initalSlide}
            data={recommendData}
          />
        )}
        <div className={classes.tabs}>
          <Button
            onClick={() => setActiveTab("flow")}
            className={`${classes.tab_button} ${
              activeTab === "flow" && classes.active_tab
            }`}
          >
            {translation.flow}
          </Button>
          <Button
            onClick={() => setActiveTab("event")}
            className={`${classes.tab_button} ${
              activeTab === "event" && classes.active_tab
            }`}
          >
            {translation.events}
          </Button>
          <Button
            onClick={() => setActiveTab("audios")}
            className={`${classes.tab_button} ${
              activeTab === "audios" && classes.active_tab
            }`}
          >
            {translation.audios}
          </Button>
        </div>
        {isLoading && (
          <Masonry
            className={classes.tab_view}
            columnsCount={width > 568 ? 2 : 1}
            gutter="20px"
          >
            {arr.map((x: any, i: number) => (
              <div
                key={i}
                style={{ height: x }}
                className={classes.skeleten}
              ></div>
            ))}
          </Masonry>
        )}
        {activeTab === "flow" && (
          <>
          <Masonry
            className={classes.tab_view}
            columnsCount={width > 568 ? 2 : 1}
            gutter="20px"
          >
            {flattenedData
              ?.slice()
              ?.sort(
                (a: any, b: any) =>
                  b.attributes.createdAt - a.attributes.createdAt
              )
              .map((x: any, i: number) => (
                <CreatorCard key={i} creator={x} />
              ))}
            <div ref={targetRef} />
          </Masonry>
           <div ref={targetRef} />
           <div className={classes.inifinty_loading_area}>
             {isFetchingNextPage && <FetchLoader />}
           </div>
          </>
        )}
        {activeTab === "event" && (
          <Events user={user} translation={translation} />
        )}
          {activeTab === "audios" && (
          <Audios user={user} translation={translation} />
        )}
      </div>
     
    </Layout>
  );
};

const useStyles = createStyles((theme) => ({
  tab: {
    color: "#727CAC !important",
    fontSize: "16px !important",
    "&:hover": {
      backgroundColor: "transparent",
      color: "white !important",
      border: 0,
    },
  },
  container: {
    padding: "4vw 0vw 0vw 0vw",
    marginTop: 2,
    [`@media (max-width: 950px)`]: {
      padding: "20px 0px 60px 0px ",
      marginTop: 40,
    },
    [`@media (max-width: 500px)`]: {
      padding: "10px 0px",
      marginTop: 60,
    },
  },
  none_recommend_area: {
    marginTop: 40,
    [`@media (max-width: 950px)`]: {
      marginTop: 0,
    },
  },

  show_popular_creator_image: {
    border: `2px solid ${theme.colors.blue[0]}`,
    width: 75,
    height: 75,
    borderRadius: "50%",
    padding: 2,
    display: "flex",
  },
  tab_list_user: {
    marginTop: 0,
  },
  tab_list: {
    height: 36,
    borderBottom: 0,
    backgroundColor: "rgba(181, 181, 181, 0.2)",
    borderRadius: 40,
    marginTop: 40,
    [`@media (max-width: 950px)`]: {
      marginTop: 0,
    },
  },
  tab_root: {
    [`@media (max-width: 500px)`]: {
      margin: "0px 20px",
    },
  },
  tabs: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    display: "flex",
    justifyContent: "space-between",
    borderRadius: 40,
    marginBottom: 20,
    marginTop: 20,
    
    [`@media (max-width: 500px)`]: {
      marginLeft: 20,
      marginRight: 20,
    },
  },
  tab_view: {
    [`@media (max-width: 500px)`]: {
      paddingRight: 20,
      paddingLeft: 20,
    },
  },
  tab_button: {
    borderRadius: 40,
    width: "100%",
    color: "lightgrey",
    transition: "all 0.3s",
    "&:hover": {
      color: theme.colors.green[0],
    },
  },
  active_tab: {
    // backgroundColor:theme.colors.green[0],
    border: `1px solid ${theme.colors.green[0]}`,
    borderRadius: 40,
    color: "white !important",
    transition: "all 0.3s",
  },
  inifinty_loading_area: {
    paddingTop: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  wrapper: {
    columnCount: 2,
    gridGap: "20px 20px",
  },
  
  skeleten: {
    background: " linear-gradient(-45deg, #b0b1b1, #8f9191, #6a6b6b, #555555)",
    backgroundSize: "400% 400%",
    borderRadius: 20,
    paddingBottom: 20,
    animation: "gradient 2s ease infinite",
    "@keyframes gradient": {
      "0%": {
        backgroundPosition: "0% 50%",
      },
      "50%": {
        backgroundPosition: "100% 50%",
      },
      "100%": {
        backgroundPosition: "0% 50%",
      },
    },
  },
  create_group_button: {
    backgroundColor: theme.colors.blue[0],
    border: 0,
    color: "white",
    padding: "9px 20px",
    height: "100%",
    borderRadius: 10,
    textDecoration:"none",
    marginRight: 10,
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

}));
export default FeedPage;

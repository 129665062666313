import React, { useRef, useEffect } from "react";

const AgoraPlayer = (props: {audioTrack: any}) => {
    const container = useRef(null);
    useEffect(() => {
        if (props.audioTrack) {
            props.audioTrack?.play();
        }
        return () => {
            props.audioTrack?.stop();
        };
    }, [props.audioTrack]);
    return (
        <div ref={container} className="audio-player"></div>
    );
}

export default AgoraPlayer;
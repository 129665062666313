import React from "react";
import { Image, Text, createStyles } from "@mantine/core";

interface ComponentProps {
  setFile: (image: string) => void;
  accept: string;
  image: any;
  error: string;
}

const FileUploadImage: React.FC<ComponentProps> = ({
  setFile,
  image,
  error,
  accept,
}) => {
  const { classes } = useStyles();

  const changeHandler = (event: any) => {
    setFile(event.target.files[0]);
  };

  const assets = () => {
    if (image?.type.includes("image")) {
      return (
        <Image
          width={"100%"}
          height={"40vh"}
          fit="contain"
          radius={20}
          src={URL.createObjectURL(image)}
          alt="With default placeholder"
          withPlaceholder
        />
      );
    }
  };

  return (
    <>
      <input
        onChange={changeHandler}
        accept={accept}
        // capture="environment" open camera mobile
        className={classes.upload_input}
        type="file"
        id="fileInput"
      />
      <label htmlFor="fileInput" className={classes.custom_upload_input}>
        {image !== undefined ? (
          assets()
        ) : (
          <Image
            width={"10vh"}
            src={require("../../../assets/upload_camera.png")}
          />
        )}
      </label>
      <Text mt={15} mb={15} align="center" color="white" fz={20}>
        {error.length > 0 ?  error  : "Add your photo"}
      </Text>
    </>
  );
};
const useStyles = createStyles((theme) => ({
  custom_upload_input: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    borderRadius: 20,
    background:
      "linear-gradient(180.76deg, #D2E951 2.99%, rgba(121, 159, 12, 0.39) 98.13%)",
  },
  upload_input: {
    visibility: "hidden",
    height: 0,
    width: 0,
  },
}));

export default FileUploadImage;

import { getToken } from "../../utils/auth/auth";
import restClient from "../restClient";

export const getChatsFn = async (pageParam: number) => {
  try {
    const page = pageParam ? pageParam : 1;
    const offset = (page - 1) * 15;
    const token = await getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    // TODO: chat scroll not new fetch  bug *****
    const data: any = await restClient.get(
      `/chats?populate=*&pagination[start]=${offset}&pagination[limit]=15`,
      config
    );
    return {
      results: data.data.data,
      offset: offset + 15,
      pagination: data.data.meta.pagination,
    };
  } catch (error) {
    console.log("error try catch:", error);
    throw error; // Hata durumunda hatayı yeniden fırlat
  }
};
export const getCreatorDetailFn = async (id: number) => {
  try {
    const token = await getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const data: any = await restClient.get(`/chats/${id}?populate=*`, config);
    return {
      results: data.data.data,
    };
  } catch (error) {
    console.log("error try catch:", error);
    throw error; // Hata durumunda hatayı yeniden fırlat
  }
};
export const getMessages = async (pageParam: number, id: number) => {
  const page = pageParam ? pageParam : 1;
  const offset = (page - 1) * 30;
  const token = await getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // &pagination[start]=${offset}&pagination[limit]=10

  const data = await restClient.get(
    `/chat-messages?populate=*&filters[chat]=${id}&sort=createdAt:DESC&pagination[start]=${offset}&pagination[limit]=30`,
    config
  );

  return {
    results: data.data.data,
    offset: offset + 30,
    pagination: data.data.meta.pagination,
  };
};

export const getLastMessage = async (id: number) => {
  const token = await getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await restClient.get(
    `/chat-messages?populate=*&filters[chat]=${id}&sort=createdAt:DESC&pagination[start]=0&pagination[limit]=1`,
    config
  );

  return response.data.data[0];
};

export const postMessageFn = async (data: any) => {
  try {
    const token = await getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let _data = {};
    await restClient
      .post("/chat-messages", { data: data }, config)
      .then(async (res) => {
        _data = res.data;
      })
      .catch((err) => {
        console.log(err);
      });


    return {
      _data,
    };
  } catch (error) {
    console.log("error try catch:", error);
    throw error; // Hata durumunda hatayı yeniden fırlat
  }
};

export const postMessageAudioFn = async (data: any, audio: any) => {
  console.log("audio6postFn", data, audio);
  try {
    const formData = new FormData();

    formData.append("data", JSON.stringify(data));
    formData.append("files.asset", audio);
    console.log("audio7formData", formData);

    const token = await getToken();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    let _data = {};

    await restClient
      .post(`/chat-messages`, formData, config)
      .then((response) => {
        _data = response.data;
      })
      .catch((error) => console.log("error", error));
    return {
      _data,
    };
  } catch (error) {
    console.log("error try catch:", error);
    throw error; // Hata durumunda hatayı yeniden fırlat
  }
};

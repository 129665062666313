import axios from "axios";
import { getToken } from "../../utils/auth/auth";
import restClient from "../restClient";

export const getNotifications = async () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: "https://api.novu.co/v1/subscribers/4/notifications/feed",
    withCredentials: false,
    headers: {
      authorization: "ApiKey 3a47052bbdc876f8b9fb4c42d68f9a50",
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  axios
    .request(config)
    .then((response) => {
      console.log("test", response.data);
    })
    .catch((error: any) => {
      console.log("test", error);
    });
};
export const getNotificationCount = async () => {
  try {
    const token = await getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const data = await restClient.get("/notification-count", config);

    return data.data.data.count;
  } catch (error) {
    console.log("error", error);
  }
};

export const postSeenNotification = async (notificationId: string) => {
  try {
    const token = await getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const data:any = {
      notificationId: notificationId,
    };
    const response = await restClient.post(`/notification-seen`, data, config);

    return response.data.data.count;
  } catch (error) {
    console.log("error", error);
  }
};
export const postSeenNotificationAll = async () => {
  try {
    const token = await getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await restClient.post(`/notification-seen-all`,{data:{test:"adsasd"}}, config);

    return response.data.data.count;
  } catch (error) {
    console.log("error", error);
  }
};

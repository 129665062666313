/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef, useMemo } from "react";
import Layout from "../../components/high-level/layout/layout";
import { User } from "../../hooks/user.hook";
import {
  Button,
  Flex,
  createStyles,
  Text,
  Textarea,
  Image,
  Avatar,
  Loader,
  Progress,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import RecordPage from "../RecordPage/RecordPage";
import WaveAudioRecord from "../../components/low-level/waveAudio/WaveAudioRecord";
import WaveSurfer from "wavesurfer.js";
import { IoArrowBackSharp, IoClose } from "react-icons/io5";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getToken } from "../../utils/auth/auth";
import restClient from "../../api/restClient";

declare global {
  interface Window {
    webkitSpeechRecognition: any;
  }
}
const CreatePostPage = ({ translation }: any) => {
  const navigate = useNavigate();
  const hiddenFileInput: any = useRef(null);
  const inputRef: any = useRef(null);
  const { user } = User();

  const { classes } = useStyles();
  const ws: any = useRef();
  const wavDiv: any = useRef();
  const [audioFile, setAudioFile] = useState<any>(null);

  const [peaks, setPeaks] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [recording, setRecording] = useState<boolean>(false);
  const [duration, setDuration] = useState<any>(0);
  const [price, setPrice] = useState<any>(1);
  const [percentage, setPercentage] = useState<any>(0);
  const [error, setError] = useState<string>("");
  const [voiceAbout, setVoiceAbout] = useState<string>("");
  const [audioFileType, setAudioFileType] = useState<string>("");
  const [audioFileUrl, setAudioFileUrl] = useState<string | undefined>();
  const queryClient = useQueryClient();

  const {
    isLoading,
    isIdle,
    isSuccess,
    mutate: createPost,
  } = useMutation(({ audio, newData }: any) => createPostFn(audio, newData), {
    onMutate: async (newTodo) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: ["creatorPosts-", user?.attributes?.creatorProfile?.username],
      });

      // Snapshot the previous value
      const previousTodo = queryClient.getQueryData([
        "creatorPosts-",
        user?.attributes?.creatorProfile?.username,
      ]);

      // Optimistically update to the new value
      queryClient.setQueryData(
        ["creatorPosts-", user?.attributes?.creatorProfile?.username],
        newTodo
      );

      // Return a context with the previous and new todo
      return { previousTodo, newTodo };
    },
    onError: async (err) => {
      setError(translation.new_post_page.api_error)
      setPercentage(0)
    },
    onSettled: async (newData: any) => {
      queryClient.invalidateQueries({
        queryKey: ["creatorPosts-", user?.attributes?.creatorProfile?.username],
      });
      queryClient.invalidateQueries({
        queryKey: [
          "creatorProfile-",
          user?.attributes?.creatorProfile?.username,
        ],
      });
      // Optimistic update yapılabilir
    },
  });

  const createPostFn = async (audio: any, newData: any) => {
    const data: any = {};

    const formData = new FormData();

    data.text = newData.text;
    data.creator_profile = newData.creator_profile;
    data.isPublic = true;
    data.mediaType = newData.mediaType;
    data.price = newData.price;
    data.assetData = newData.assetData;

    formData.append("data", JSON.stringify(data));
    formData.append("files.asset", audio);

    const token = await getToken();
    const bearer = `Bearer ${token}`;
    const response = await restClient.post("/posts", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: bearer,
      },
      onUploadProgress: (progressEvent: any) => {
        const _percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setPercentage(_percentage);
      },
    });

    return response.data;
  };
  useEffect(() => {
    if (!audioFile) {
      setPeaks([]);
    }
  }, [audioFile]);

  useEffect(() => {}, [inputRef]);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);
  const prices = [
    {
      value: 0,
      label: "Free",
    },
    {
      value: 1,
      label: 1,
    },
    {
      value: 10,
      label: 10,
    },
    {
      value: 15,
      label: 15,
    },
    {
      value: 20,
      label: 20,
    },
  ];

  useEffect(() => {
    if (isSuccess && !isLoading) {
      navigate("/my/profile");
    }
  }, [isSuccess, isLoading, isIdle]);

  const postCreateCreator = async () => {
    if (voiceAbout.length < 2) {
      setError(translation.new_post_page.enter_desc);
    }
    if (audioFile == null) {
      setError(translation.new_post_page.upload_audio);
    }
    if (voiceAbout.length >= 2 && audioFile != null) {
      setError("");
      const createPostData: any = {
        assetData: { peaks, duration },
        username: user?.attributes?.creatorProfile?.username,
        creator_profile: user?.attributes?.creatorProfile?.id,
        text: voiceAbout,
        isPublic: true,
        mediaType: "audio",
        price: price,
      };
      createPost({ audio: audioFile, newData: createPostData });
    }
  };

  const onChange = (e: any) => {
    setVoiceAbout(e.target.value);
  };
  useEffect(() => {
    ws.current = WaveSurfer.create({ container: wavDiv.current });

    ws.current.on("ready", function () {
      console.log("hehehe", ws.current.exportPeaks());
      // console.log('hehehe2', ws.current.getDecodedData())
      try {
        const peaks = ws.current.exportPeaks();
        const duration = ws.current.getDuration();
        setDuration(duration);
        setPeaks(peaks);
      } catch (error) {
        console.log("error", error);
      }
    });
  }, [ws]);

  const handleChange = useMemo(
    () => async (file: any, mediaBlobUrl?: any) => {
      var blobURL = window.URL.createObjectURL(file);

      setAudioFileUrl(blobURL);
      if (file === audioFile) {
        return; // Skip updating if the file is the same
      }
      if (file) {
        setAudioFile(file);
      }
      setAudioFileType("");
      try {
        if (file) {
          const newArrBuff = await file?.arrayBuffer();
          let blob = new Blob([new Uint8Array(newArrBuff)], {
            type: file?.type,
          });
          ws.current.loadBlob(blob);
        }
      } catch (e) {
        console.log(e);
      }
    },
    [audioFile]
  );

  const audioUpload = () => {
    hiddenFileInput.current.click();
    setDuration(0);
  };

  return (
    <Layout
      headerFixed={false}
      mobileHeaderFixed={false}
      mobileHeader={false}
      hiddenFooter
      header={false}
      headerTitle={translation.new_post_page.header_title}
    >
      <Flex className={classes.main} direction="column" gap="sm">
        {audioFileType !== "record" && (
          <div className={classes.mobile_header}>
            <div style={{ width: "82px" }}>
              <Button style={{ paddingLeft: 0 }} onClick={() => navigate(-1)}>
                <IoArrowBackSharp size={25} />
              </Button>
            </div>
            <div>
              <Text color="white" fw={600}>
                {translation.new_post_page.create_audio}
              </Text>
            </div>
            <div style={{ width: "82px" }}></div>
          </div>
        )}
        {audioFileType !== "record" && (
          <div className={classes.container_box}>
            <div
              className={classes.container}
              style={{
                border:
                  error.length > 1 || (audioFile && voiceAbout.length < 2)
                    ? "1px solid red"
                    : "1px solid transparent",
                justifyContent: !audioFile ? "space-between" : "flex-start",
              }}
            >
              {/* <audio controls style={{width:"100%",height:200,backgroundColor:"red",zIndex:999}} src={audioFileUrl}/> */}

              <Textarea
                style={{
                  display: audioFileType === "record" ? "none" : "block",
                }}
                minRows={6}
                readOnly={isLoading}
                classNames={{
                  input: classes.input,
                }}
                onChange={onChange}
                ref={inputRef}
                value={voiceAbout}
                onClick={(e) => e.preventDefault()}
                placeholder={translation.new_post_page.audio_about}
              />
              {audioFile && audioFileType !== "record" && (
                <div className={classes.voice_area_container}>
                  <div className={classes.voice_area}>
                    <div className={classes.voice_area_user_image}>
                      <Avatar
                        mr={20}
                        radius={24}
                        size={127}
                        classNames={{ root: classes.voice_avatar }}
                        src={
                          user?.attributes?.creatorProfile?.avatarThumbs?.thumbs
                            ?.c144
                        }
                      />
                    </div>

                    <WaveAudioRecord
                      url={audioFileUrl}
                      duration={duration}
                      peaks={peaks}
                      setDuration={setDuration}
                    />
                    <Button
                      onClick={() =>
                        isLoading ? undefined : setAudioFile(null)
                      }
                      className={classes.close_button}
                    >
                      <IoClose size={25} color="white" />
                    </Button>
                  </div>
                </div>
              )}

              {audioFileType !== "record" && !audioFile && (
                <Flex justify="right" className={classes.audio_upload_buttons}>
                  <button
                    onClick={audioUpload}
                    className={classes.post_assets_button}
                  >
                    <Image
                      fit="contain"
                      height={"30px"}
                      src={require("../../assets/new-post/file.png")}
                    />
                  </button>
                  <input
                    type="file"
                    accept="audio/mp3,audio/m4a,audio/wav,audio/*;capture=microphone"
                    ref={hiddenFileInput}
                    onChange={(e: any) => handleChange(e.target.files[0])}
                    style={{ display: "none" }}
                  />

                  <button
                    onClick={() => {
                      setAudioFileType("record");
                      setPeaks([]);
                      setDuration(0);
                    }}
                    className={classes.post_assets_button}
                  >
                    <Image
                      fit="contain"
                      height={"30px"}
                      src={require("../../assets/new-post/wave.png")}
                    />
                  </button>
                </Flex>
              )}
            </div>

            {(error?.length > 1 || (audioFile && voiceAbout.length < 2)) && (
              <Text align="left" w="90%" fw={500} fz={16} mt={5} color="red">
                {error.length > 1
                  ? error
                  : translation.new_post_page.enter_explanation}
              </Text>
            )}
          </div>
        )}

        {audioFileType === "record" && (
          <>
            <RecordPage
              setAudioFileType={setAudioFileType}
              user={user}
              translation={translation}
              handleChange={handleChange}
              setRecording={setRecording}
            />
          </>
        )}

        {audioFile && (
          <>
            <Flex
              justify="space-between"
              align="center"
              w="100%"
              mt={16}
              className={classes.price_container}
            >
              <Flex
                w="100%"
                className={classes.price_main_area}
                direction="column"
              >
                <Text
                  color="white"
                  className={classes.price_main_area_tite}
                  fz={16}
                  fw={600}
                >
                  {translation.price}
                </Text>
                <Flex className={classes.price_buttons_area} direction="row">
                  {prices.map((x: any, i: number) => (
                    <Button
                      onClick={() => setPrice(x.value)}
                      className={classes.select_price_button}
                      disabled={isLoading}
                      style={{
                        backgroundColor:
                          x.value === price
                            ? "#D2E951"
                            : "rgba(210, 233, 81, 0.2)",
                        color: x.value === price ? "black" : "white",
                      }}
                      key={i}
                    >
                      <Image mr={5} src={require("../../assets/amount.png")} />
                      {x.label}
                    </Button>
                  ))}
                </Flex>
              </Flex>
            </Flex>

            <Flex
              className={classes.create_button_area}
              justify="space-between"
              align="center"
            >
              <Progress
                color="#D2E951"
                className={classes.progress_bar}
                styles={{
                  root: { backgroundColor: "rgba(217, 217, 217, 0.4)" },
                }}
                radius="50px"
                size="lg"
                w="85%"
                value={percentage}
              />
              <Button
                style={{
                  display: audioFileType === "record" ? "none" : "block",
                }}
                onClick={
                  isLoading || voiceAbout.length < 2
                    ? undefined
                    : postCreateCreator
                }
                className={classes.create_button}
                disabled={
                  isLoading || voiceAbout.length < 2 || peaks.length === 0
                }
              >
                {isLoading ? (
                  <Loader color="black" />
                ) : (
                  <Image
                    height={50}
                    fit="contain"
                    src={require("../../assets/new-post/create.png")}
                  />
                )}
              </Button>
            </Flex>
          </>
        )}
        <div style={{ width: "0px", height: "0px" }} ref={wavDiv}></div>
      </Flex>
    </Layout>
  );
};
const useStyles = createStyles((theme) => ({
  input: {
    border: "0px",
    cursor: "text",
    backgroundColor: "transparent",
    color: "white",
    [`@media (max-width: 500px)`]: {
      height: 150,
    },
  },
  price_buttons_area: {
    [`@media (max-width: 500px)`]: {
      justifyContent: "space-between",
      width: "100%",
    },
  },
  select_price_button: {
    width: 83,
    borderRadius: 60,
    height: 43,
    marginLeft: ".5vw",
    marginRight: ".5vw",
    ":first-of-type": {
      marginRight: ".5vw",
      marginLeft: 0,
    },
    [`@media (max-width: 500px)`]: {
      marginRight: "0vw",
      marginLeft: "0vw",
      width: 83,
      height: 33,
    },
  },
  price_main_area: {
    margin: "0px 20px",
  },
  price_container: {
    marginBottom: 24,
    [`@media (max-width: 500px)`]: {
      flexDirection: "column",
      width: "100%",
      padding: "0px 20px",
      marginBottom: 0,
    },
  },
  create_button_area: {
    [`@media (max-width: 500px)`]: {
      width: "100%",
      marginTop: 10,
    },
  },
  price_main_area_tite: {
    marginBottom: 13,
    [`@media (max-width: 500px)`]: {
      marginBottom: 3,
    },
  },
  container_box: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  container: {
    backgroundColor: " rgba(181, 181, 181, 0.2)",
    width: "100%",
    // height: 367,
    borderRadius: 40,
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    [`@media (max-width: 768px)`]: {
      backgroundColor: "transparent",
    },
    [`@media (max-width: 500px)`]: {
      width: "100%",
      height: "auto",
      padding: "0px 20px 20px 20px",
    },
  },
  voice_area_container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  close_button: {
    position: "absolute",
    right: 0,
    top: 5,
  },
  create_button: {
    width: 60,
    height: 60,
    borderRadius: "50%",
    backgroundColor: theme.colors.green,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  voice_avatar: {
    width: 127,
    height: 127,
    [`@media (max-width: 500px)`]: {
      width: "100%",
      height: "100%",
      minWidth: "100%",
    },
  },
  voice_area_user_image: {
    width: 179,
    marginRight: 20,
    [`@media (max-width: 500px)`]: {
      width: 70,
      height: 70,
      marginRight: 0,
    },
  },
  progress_bar: {
    marginBottom: 30,
    margin: "0px 20px",
  },
  image_and_background: {
    position: "relative",
    width: "16vw",
    height: "30vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [`@media (max-width: 1920px)`]: {
      width: "25vw",
    },
    [`@media (max-width: 1440px)`]: {
      width: "31vw",
    },
    [`@media (max-width: 1280px)`]: {
      width: "40vw",
    },
    [`@media (max-width: 1000px)`]: {
      width: "50vw",
    },
    [`@media (max-width: 768px)`]: {
      width: "60vw",
    },
    [`@media (max-width: 650px)`]: {
      width: "100vw",
    },
  },
  wave_audio_animation_background_1: {
    position: "absolute",
    zIndex: 0,
    top: 30,
    left: 0,
    [`@media (max-width: 768px)`]: {
      top: -40,
    },
  },
  wave_audio_animation_background_1_animation: {
    animation: "audioAnimation 1.5s ease-in-out infinite",
    "@keyframes audioAnimation": {
      "0%": {
        transform: "scale(1)",
      },
      "50%": {
        transform: "scale(1.1)",
      },
      "100%": {
        transform: "scale(1)",
      },
    },
  },
  wave_audio_animation_background_2: {
    position: "absolute",
    bottom: 30,
    zIndex: 0,
    right: 0,
    [`@media (max-width: 768px)`]: {
      bottom: -40,
    },
  },
  wave_audio_animation_background_2_animation: {
    animation: "audioAnimation2 1.7s ease-in-out infinite",
    "@keyframes audioAnimation2": {
      "0%": {
        transform: "scale(1)",
      },
      "50%": {
        transform: "scale(1.2)",
      },
      "100%": {
        transform: "scale(1)",
      },
    },
  },
  voice_area: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    padding: 15,
    width: "85%",
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    position: "relative",
    [`@media (max-width: 500px)`]: {
      flexDirection: "column",
      width: "95%",
      padding: 5,
    },
  },
  cancel_button: {
    height: "35px",
    zIndex: 9,
    width: "35px",
    backgroundColor: "transparent",
    cursor: "pointer",
    border: "0px",
    [`@media (max-width: 500px)`]: {
      display: "none",
    },
  },
  post_assets_button: {
    height: "50px",
    width: "50px",
    borderRadius: "50%",
    backgroundColor: theme.colors.blue,
    marginRight: 14,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    border: "0px",
    "&:hover": {
      backgroundColor: theme.colors.blue[1],
    },
  },
  price_input: {
    backgroundColor: "transparent",
    border: "1px solid rgba(255, 255, 255, 0.4)",
    borderRadius: 30,
    color: "white",
  },
  price_input_label: {
    color: "white",
  },
  price_input_control: {
    color: "white",
    "&:hover": {
      backgroundColor: `${theme.colors.blue} !important`,
    },
  },
  price_input_right_section: {},
  audio_upload_buttons: {
    [`@media (max-width: 768px)`]: {
      position: "absolute",
      bottom: 20,
      right: 0,
    },
  },
  main: {
    [`@media (max-width: 768px)`]: {
      marginTop: "0px !important",
    },
  },
  mobile_header: {
    height: 50,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 20,
    marginTop: 10,
    zIndex: 999,
  },
}));
export default CreatePostPage;

const MicrophoneIcon = ({ active }: any) => {
  // active ? "#D2E951":"#8C8FA5"
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="24.000000pt"
      height="28.000000pt"
      viewBox="0 0 54.000000 81.000000"
    >
      <g
        transform="translate(0.000000,81.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M182 789 c-60 -39 -72 -79 -72 -244 0 -125 3 -151 20 -184 56 -111
   216 -114 277 -5 21 35 23 52 23 187 0 170 -9 202 -69 243 -47 32 -131 33 -179
   3z"
        />
        <path
          d="M2 432 c3 -78 7 -95 33 -138 35 -56 107 -109 171 -125 l44 -11 0 -54
   0 -54 -60 0 c-46 0 -62 -4 -67 -16 -10 -27 18 -34 147 -34 129 0 157 7 147 34
   -5 12 -21 16 -67 16 l-60 0 0 54 c0 53 1 55 33 61 136 30 216 132 217 278 0
   70 -2 77 -20 77 -17 0 -20 -7 -20 -40 0 -117 -45 -201 -131 -244 -70 -34 -128
   -34 -198 0 -86 43 -131 127 -131 244 0 34 -3 40 -21 40 -20 0 -21 -4 -17 -88z"
        />
      </g>
    </svg>
  );
};

export default MicrophoneIcon;

/* eslint-disable react-hooks/exhaustive-deps */
import { Carousel } from "@mantine/carousel";
import { Image, Text, createStyles } from "@mantine/core";
import { useLocalStorage, useViewportSize } from "@mantine/hooks";
import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";

const RecommendCarousel = ({ data, page, setPage, nextPage,loading }: any) => {
  const { width } = useViewportSize();
  const { classes } = useStyles();
  const [carouselCurrentPosition, setCarouselCurrentPosition] = useLocalStorage(
    { key: "carousel-current-position", defaultValue: 0 }
    );

  const flattenedData: any = useMemo(
    () => (data ? data?.pages?.flatMap((item: any) => item.results) : []),
    [data]
  );

  const getFetchNewData = (e: any) => {
    setCarouselCurrentPosition(e);
  };
  useEffect(() => {
    if (carouselCurrentPosition > page) {
      nextPage();
    }
  }, [carouselCurrentPosition]);

  return (
    <div className={classes.show_popular_creator_area}>
      <Carousel
        height={115}
        withControls={width > 768}
        draggable={width < 768}
        styles={{
          control: {
            "&[data-inactive]": {
              opacity: 0,
              cursor: "default",
            },
          },
        }}
        onSlideChange={getFetchNewData}
        initialSlide={carouselCurrentPosition}
        slideSize="110px"
        align="start"
        slidesToScroll={3}
      >
        {flattenedData?.map((x: any, i: number) => (
          <Carousel.Slide key={i}>
            <Link
              to={`/${x?.attributes?.username}`}
              key={x.id}
              className={classes.show_popular_creator_info_area}
            >
              <Image
                radius={20}
                width={80}
                height={90}
                src={x?.attributes?.avatarThumbs?.thumbs?.c144}
              />
              <Text fz={14} fw={500} mt={5} color="white">
                {x?.attributes?.username?.substring(0, 15) +
                  (x.attributes?.username?.length > 15 ? "..." : "")}
              </Text>
            </Link>
          </Carousel.Slide>
        ))}
      </Carousel>
    </div>
  );
};
const useStyles = createStyles((theme) => ({
  show_popular_creator_area: {
    width: "100%",
  
  },
  show_popular_creator_info_area: {
    padding: "0px 10px",
    display: "flex",
    textDecoration: "none",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },

  skeleten: {
    background: "linear-gradient(-45deg, #b0b1b1, #8f9191, #6a6b6b, #555555)",
    backgroundSize: "400% 400%",
    borderRadius: 20,
    paddingBottom: 20,
    animation: "gradient 2s ease infinite",
    "@keyframes gradient": {
      "0%": {
        backgroundPosition: "0% 50%",
      },
      "50%": {
        backgroundPosition: "100% 50%",
      },
      "100%": {
        backgroundPosition: "0% 50%",
      },
    },
  },
}));
export default RecommendCarousel;

import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  MantineProvider,
  ColorScheme,
  ColorSchemeProvider,
} from "@mantine/core";
// import { HMSRoomProvider } from "@100mslive/react-sdk";

import { Provider } from "react-redux";
import store from "./store/store";
import "./i18n";
import { Notifications } from "@mantine/notifications";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

Sentry.init({
  dsn: "https://1534d1926859cac1bb75d57269ee324a@o4505654615539712.ingest.sentry.io/4505782261448704",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost",
        "https://mysterious-beach-67818.herokuapp.com/api",
      ],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const colorScheme: any = "light" || localStorage.getItem("themeMode");
const toggleColorScheme = (value?: ColorScheme) =>
  colorScheme === (value || (colorScheme === "dark" ? "light" : "dark"));

localStorage.setItem("initial-slide-page", "0");
const queryClient = new QueryClient();

root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      <ColorSchemeProvider
        colorScheme={colorScheme}
        toggleColorScheme={toggleColorScheme}
      >
        <MantineProvider
          withNormalizeCSS
          withGlobalStyles
          theme={{
            colorScheme,
            colors: {
              green: ["#D2E951"],
              blue: ["#4D74FE"],
              background: ["#1E1E1E"],
              grey: ["#A1A2A9"],
            },
            fontFamily: "Poppins, sans-serif",
          }}
        >
          <Notifications />
          {/* <HMSRoomProvider> */}
            {" "}
            <App />
          {/* </HMSRoomProvider> */}
        </MantineProvider>
      </ColorSchemeProvider>
    </QueryClientProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useState } from "react";
import Layout from "../../components/high-level/layout/layout";
import {
  ActionIcon,
  Button,
  Checkbox,
  Modal,
  Text,
  TextInput,
  createStyles,
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { IoIosCloseCircle } from "react-icons/io";
import { useGetUser } from "../../api/user/service";
import { useCreateEvent } from "../../api/event/service";
import { useDisclosure } from "@mantine/hooks";
import { IoClose } from "react-icons/io5";
import "./styles.css";

const CreateEventPage = ({ translation }: any) => {
  const { classes } = useStyles();
  const [startDate, setStartDate] = useState<any>(
    new Date(new Date().getTime() + 30 * 60000)
  );
  const [datePicker, setDatePicker] = useState(false);

  const [eventName, setEventName] = useState<string>("");
  const { data: user } = useGetUser();
  const { createEvent } = useCreateEvent();
  const [
    createEventModal,
    { open: createEventModalOpen, close: createEventModalClose },
  ] = useDisclosure(false);

  const createEventFn = async () => {
    const currentTime = new Date();

    const data = {
      owner: [user?.data?.attributes?.creatorProfile?.id],
      user_profiles: [user?.data?.attributes?.userProfile?.id],
      ...(datePicker && { startTime: startDate <= currentTime ? null : new Date(startDate).toISOString() }),
      title: eventName,
    };
    await createEvent(data);
    // if (!user?.data?.attributes?.creatorProfile?.isCreateEvent) {
    //   const data = {
    //     owner: [user?.data?.attributes?.creatorProfile?.id],
    //     user_profiles: [user?.data?.attributes?.userProfile?.id],
    //     startTime: startDate,
    //     title: eventName,
    //   };
    //   await createEvent(data);
    // } else {
    //   createEventModalOpen();
    // }
  };
  const icon = (
    <ActionIcon
      style={{ height: 60 }}
      className={classes.close_date_input}
      onClick={() => setStartDate(null)}
    >
      <IoIosCloseCircle color="lightred" size={60} />
    </ActionIcon>
  );

  return (
    <Layout headerTitle={translation.event_page.creat_event_page}>
      <Modal
        classNames={{
          body: classes.modal_body,
          inner: classes.modal_inner,
        }}
        opened={createEventModal}
        centered
        withCloseButton={false}
        onClose={createEventModalClose}
      >
        <button onClick={createEventModalClose} className={classes.close}>
          <IoClose color="white" size={25} />
        </button>
        <Text fw={800} fz={18} align="center" color="white">
          {translation?.you_have_event}
        </Text>
      </Modal>
      <div className={`${classes.container} `}>
        <TextInput
          mt={10}
          placeholder={`${translation.event_page.event_name}`}
          label={`${translation.event_page.event_name}`}
          value={eventName}
          onChange={(e: any) => setEventName(e.target.value)}
          classNames={{
            label: classes.input_label,
            input: classes.input,
          }}
        />
        <Checkbox
          mt={20}
          styles={{
            label: { color: "white", fontWeight: 800 },
            body: { alignItems: "center" },
            input: {
              "&:checked": {
                background: "#4D74FE",
                border: 0,
              },
            },
          }}
          checked={datePicker}
          size={20}
          label={`${translation.event_page.date_checkbox}`}
          onChange={(event) => setDatePicker(event.currentTarget.checked)}
        />
        {datePicker && (
          <DateTimePicker
            valueFormat="DD/MM/YYYY HH:mm"
            minDate={new Date()}
            mt={10}
            value={startDate}
            classNames={{
              label: classes.input_label,
              input: classes.input,
              day: "input_day",
            }}
            onChange={setStartDate}
            label={`${translation.date}`}
            placeholder="Date"
            rightSection={startDate && icon}
          />
        )}
        <Button
          onClick={createEventFn}
          fullWidth
          mt={20}
          className={classes.create_group_button}
        >
          {translation.create_event}
        </Button>
      </div>
    </Layout>
  );
};
const useStyles = createStyles((theme) => ({
  create_group_button: {
    backgroundColor: theme.colors.blue[0],
    border: 0,
    height: 50,
    color: "white",
    padding: "9px 20px",
    borderRadius: 10,
    marginRight: 10,
    cursor: "pointer",
  },
  container: {
    padding: "4vw 0vw 0vw 0vw",
    marginTop: 2,
    [`@media (max-width: 950px)`]: {
      padding: "0px 0px 60px 0px ",
      marginTop: 40,
    },
    [`@media (max-width: 500px)`]: {
      padding: "10px 0px",
      marginTop: 60,
    },
  },
  modal_body: {
    backgroundColor: theme.colors.blue[0],
    padding: "45px",
    [`@media (max-width: 768px)`]: {
      borderRadius: "33px",
    },
    [`@media (max-width: 500px)`]: {
      borderRadius: "33px 33px 0px 0px",
    },
  },
  modal_inner: {
    [`@media (max-width: 500px)`]: {
      padding: 0,
      alignItems: "flex-end",
    },
  },
  close: {
    position: "absolute",
    zIndex: 999,
    right: 20,
    top: 20,
    backgroundColor: "transparent",
    border: "0px",
    padding: 0,
    cursor: "pointer",
  },
  input_label: {
    color: "white",
    marginBottom: 7,
    fontSize: 16,
  },
  input: {
    backgroundColor: "transparent",
    borderRadius: 30,
    border: "1px solid rgba(255, 255, 255, 0.4)",
    height: 60,
    color: "white",
    fontSize: 18,
    padding: "0px 29px",
  },
  close_date_input: {
    ":hover": {
      backgroundColor: "transparent",
    },
  },
}));
export default CreateEventPage;

import { Flex, createStyles, Text, Notification } from "@mantine/core";
import { useWindowScroll, useElementSize } from "@mantine/hooks";
import LeftMenu from "../letfMenu/leftMenu";
import RightMenu from "../rightMenu/rightMenu";
import Header from "../header/header";
import FooterMobile from "../footer/FooterMobile";
import { useNavigate } from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { useGetTranslations } from "../../../api/translations/service";
import { useEffect, useState } from "react";
import { useGetUser } from "../../../api/user/service";

const Layout = ({
  children,
  header = true,
  padding = true,
  right = true,
  headerFixed = true,
  mobileHeaderFixed = true,
  headerLinear = false,
  mobileHeader = true,
  headerTitle = "PeepVoice",
  littleLeftMenu=false,
  headerRightIcon,
  backButton=false,
  mobileHeaderBack,
  hiddenFooter,
  showNotification = false,
  successNotification,
  errorNotification,
}: any) => {
  const [scroll] = useWindowScroll();
  const { ref, width } = useElementSize();
  const navigate = useNavigate();
  const{data:user}=useGetUser()
  const { classes } = useStyles();
  const [show, setShow] = useState(false);
  const { data } = useGetTranslations();

  const translation = data?.data;
  const rightMenu = () => {
    if (right) {
      return <RightMenu translation={translation} />;
      //   if (user?.isCreator) {
      // return <RightMenu />;
      //   } else {
      //     return <RightMenuAudio />;
      //   }
    }
  };
  useEffect(() => {
    if (showNotification) {
      setShow(true);
      setTimeout(() => {
        setShow(false);
      }, 2000);
    }
  }, [showNotification]);

  return (
    <div className={classes.layout}>
      {/* <div className={classes.background_light_blue}></div> */}
      {/* <div className={classes.background_light_green}></div> */}
      <LeftMenu translation={translation}  littleLeftMenu={littleLeftMenu}/>
      {show && (
        <div className={classes.toast_area}>
          <Notification
            withCloseButton={false}
            styles={{
              root: {
                backgroundColor: "rgb(37, 38, 43)",
                border: "0px",
                zIndex: 999,
              },
            }}
            icon={
              (successNotification?.length > 3 && (
                <AiOutlineCheck size="1.1rem" />
              )) ||
              (errorNotification?.length > 3 && (
                <AiOutlineClose size="1.1rem" />
              ))
            }
            color={errorNotification?.length ? "red" : "teal"}
          >
            <Text color="white">
              {errorNotification?.length > 3
                ? errorNotification
                : successNotification}
            </Text>
          </Notification>
        </div>
      )}
      {mobileHeader && (
        <div className={classes.mobile}>
          <Header
            translation={translation}
            mobileHeaderFixed={mobileHeaderFixed}
            headerTitle={headerTitle}
            backButton={backButton}
            mobileHeaderBack={mobileHeaderBack}
          />
        </div>
      )}
      <div
        ref={ref}
        className={`${classes.main} ${!right  && classes.full_main} ${littleLeftMenu  && classes.full_main_little_left } ${
          header && classes.header_null
        } ${!padding && classes.padding_zero}`}
      >
        {header && (
          <div
            style={{
              position: headerFixed ? "fixed" : "relative",
              width: headerTitle === translation.chats ? "100%" : width,
              background:
                scroll.y < 280 && headerLinear
                  ? "linear-gradient(180deg, rgba(4, 4, 4, 100) 0%, rgba(0,0,0,0) 100%)"
                  : "transparent",
              backgroundColor:
                scroll.y > 280 ? "rgba(53,56,62,.5)" : "transparent",
            }}
            className={classes.header}
          >
            <Flex
              align="center"
              w="100%"
              justify="space-between"
              style={{
                margin: padding ? "0px 0px 0px 0px" : "0px 0px 0px 20px",
              }}
            >
              <Flex align="center">
                {backButton && headerTitle !== translation.home && (
                  <button
                    style={{
                      height: 50,
                      width: 50,
                      cursor: "pointer",
                      backgroundColor: "transparent",
                      textDecoration: "none",
                      border: 0,
                      position: "relative",
                    }}
                    onClick={() => navigate(-1)}
                  >
                    <IoIosArrowRoundBack color="white" size="100%" />
                  </button>
                )}
                <Text fz={18} fw={700} color="white">
                  {headerTitle}
                </Text>
              </Flex>
              {headerRightIcon}
            </Flex>
          </div>
        )}

        {children}
      </div>
      {rightMenu()}

      {!hiddenFooter && user?.data.isCreator && (
        <div className={classes.mobile}>
          <FooterMobile />
        </div>
      )}
    </div>
  );
};
const useStyles = createStyles((theme) => ({
  layout: {
    display: "flex",
    backgroundColor: "#1E1E1E",
    width: "100vw",
    [`@media (max-width: 950px)`]: {
      flexDirection: "row",
    },
    [`@media (max-width: 500px)`]: {
      flexDirection: "column",
    },
  },
  toast_area: {
    position: "absolute",
    zIndex: 999,
    top: 20,
    left: 20,
    [theme.fn.smallerThan("sm")]: {
      right: 20,
      left: "auto",
    },
  },
  header: {
    height: 50,
    display: "flex",
    alignItems: "center",
    zIndex: 999,
    top: 0,
    width: "100%",
    justifyContent: "space-between",
    borderBottom: "1px solid rgba(98, 98, 98, 0.44)",
    [`@media (max-width: 500px)`]: {
      display: "none",
    },
  },
  mobile: {
    display: "none",
    [`@media (max-width: 500px)`]: {
      display: "flex",
    },
  },
  main: {
    minHeight: "100vh",
    zIndex: 99,
    padding: "10px 15px 10px 15px",
    width: "40vw",
    overflowX: "hidden",
    [`@media (max-width: 1265px)`]: {
      width: "60vw",
    },
    [`@media (max-width: 950px)`]: {
      width: "90vw ",
    },
    [`@media (max-width: 500px)`]: {
      width: "100vw !important",
      padding: "0px 0px 90px 0px !important ",
    },
  },
  full_main: {
    width: "70vw",
    padding: "0px 0px !important",
    overflowX: "hidden",
    [`@media (max-width: 1265px)`]: {
      width: "90vw !important",
    },
    [`@media (max-width: 500px)`]: {
      width: "100vw !important",
      padding: "0px 0px 90px 0px !important ",
    },
  },
  header_null: {
    padding: "0px 20px",
    marginTop: 0,
    [`@media (max-width: 1480px)`]: {
      padding: "0px 27px",
    },
    [`@media (max-width: 500px)`]: {
      padding: "0px 0px",
    },
  },
  padding_zero: {
    [`@media (max-width: 500px)`]: {
      padding: "0px 0px 0px 0px !important ",
    },
  },
  background_light_green: {
    backgroundColor: theme.colors.green[0],
    width: 486,
    height: 486,
    position: "fixed",
    left: "50%",
    marginLeft: "-102px",
    bottom: 0,
    opacity: 0.6,
    filter: "blur(225px)",
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      width: 204,
      height: 204,
      filter: "blur(100px)",
      opacity: 0.9,
    },
  },
  background_light_blue: {
    backgroundColor: theme.colors.blue[0],
    width: 728,
    height: 728,
    position: "fixed",
    left: "10%",
    top: 0,
    opacity: 0.7,
    filter: "blur(225px)",
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      width: 328,
      height: 328,
      right: -20,
      bottom: "23%",
      filter: "blur(100px)",
      background: "rgba(77, 116, 254, 0.9)",
    },
  },
  full_main_little_left:{
    width:"90vw !important",
    [`@media (max-width: 500px)`]: {
      width: "100vw !important",
      padding: "0px 0px 90px 0px !important ",
    },
  }
}));
export default Layout;

import {
  useInfiniteQuery,
} from "@tanstack/react-query";
import { getAudiosFn, getFeedDataFn, getRecommendCreatorsFn, getRecommendPublicCreatorsFn } from "../api";

export const useFeedPosts = () => {
  const { data, isLoading, isError, error, isFetchingNextPage, fetchNextPage } =
    useInfiniteQuery({
      queryKey: ["feedData"],
      queryFn: ({ pageParam }) => getFeedDataFn(pageParam),
      cacheTime: 5000,
      getNextPageParam: (lastPage: any, allPages) => {
        return lastPage.results?.length === 10 ? allPages.length + 1 : undefined;
      },
      staleTime: 3600000,

    });
  return { data, isLoading, isFetchingNextPage, isError, error, fetchNextPage };
};

export const useGetRecommendCreators = () => {
  const {
    data,
    isLoading,
    status,
    hasPreviousPage,
    isError,
    error,
    isFetching,
    isFetched,
    isSuccess,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: ["recommendCreators"],
    queryFn: ({ pageParam }) => getRecommendCreatorsFn(pageParam),
    getNextPageParam: (lastPage: any, allPages) => {
      return lastPage.results?.length === 6 ? allPages.length + 1 : undefined;
    },
    staleTime: 3600000,
    // staleTime: 3600000,
    // retry:false,
    // refetchOnMount:false,
    // refetchOnWindowFocus:false,
    // select: (res) => res,
  });

  return { data,isFetched,isFetching, status,isLoading,hasPreviousPage, isError, error, isFetchingNextPage, fetchNextPage,isSuccess };
};
export const useGetPublicRecommendCreators = () => {
  const {
    data,
    isLoading,
    status,
    hasPreviousPage,
    isError,
    error,
    isFetching,
    isFetched,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: ["recommendPublicCreators"],
    queryFn: ({ pageParam }) => getRecommendPublicCreatorsFn(pageParam),
    getNextPageParam: (lastPage: any, allPages) => {
      return lastPage.results?.length === 6 ? allPages.length + 1 : undefined;
    },
    staleTime: 3600000,
    // staleTime: 3600000,
    // retry:false,
    // refetchOnMount:false,
    // refetchOnWindowFocus:false,
    // select: (res) => res,
  });

  return { data,isFetched,isFetching, status,isLoading,hasPreviousPage, isError, error, isFetchingNextPage, fetchNextPage };
};

export const useGetAudios = () => {
  const {
    data,
    isLoading,
    isError,
    refetch,
    error,
    isFetchingNextPage,
    fetchNextPage,
    isSuccess,
  } = useInfiniteQuery({
    queryKey: ["audios"],
    queryFn: ({ pageParam }) => getAudiosFn(pageParam),
    getNextPageParam: (lastPage: any, allPages) => {
      return lastPage.results?.length === 6 ? allPages.length + 1 : undefined;
    },
    // staleTime: 3600000,

  });
  return {
    data,
    isLoading,
    isSuccess,
    isFetchingNextPage,
    isError,
    error,
    fetchNextPage,
    refetch,
  };
};
import React, { useEffect } from "react";
import { Button, Center, Flex, Image, Modal, Text, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

const AgeControl = ({translation}: any) => {
  const [ageModal, { open, close }] = useDisclosure(false);

  const ageRight = () => {
    localStorage.setItem("ageControl", JSON.stringify(true));
    close();
  };

  useEffect(() => {
    const ageControl = localStorage.getItem("ageControl");
    if (ageControl == null || ageControl === "false") {
      localStorage.setItem("ageControl", JSON.stringify(false));
      open();
    }
  }, [open]);

  return (
    <Modal
      opened={ageModal}
      centered
      closeOnClickOutside={false}
      withCloseButton={false}
      styles={{
        body: { borderRadius: "10px" },
        inner: { backgroundColor: "rgba(0,0,0,.75)",zIndex:99999,width:"100vw",height:"100vh" },
        content: { backgroundColor: "#1E1E1E", padding: 30 },
      }}
      onClose={close}
    >
        <Flex direction="column">
          <Center>
            <Image width={100} src={require("../../../assets/logo.png")} />
          </Center>
          <Center mt={30}>
            <Title color="white">{translation?.age_control_modal.title}</Title>
          </Center>
          <Text mt={10} align="center" color="#838383">
          {translation?.age_control_modal.text}
          </Text>
          <Button
            onClick={ageRight}
            mt={30}
            style={{ backgroundColor: "#D2E951" }}
            w="100%"
            h={50}
          >
            <Text align="center" color="black">
          {translation?.age_control_modal.button_text}
            </Text>
          </Button>
        </Flex>
    </Modal>
  );
};

export default AgeControl;

import {
  Image,
  Button,
} from "@mantine/core";
import { createStyles, getStylesRef } from "@mantine/core";
import { Link } from "react-router-dom";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AiFillAudio, AiOutlineUser } from "react-icons/ai";

const PublicLeftMenu = ({ translation,registerModalOpen }: any) => {
  const { classes } = useStyles();

  // const internalMenuList = useMemo(
  //   () => [
  //     {
  //       title: translation.home,
  //       to: "/my/feed",
  //       permission: true,
  //       icon: <HomeIcon active={location.pathname === "/my/feed"} />,
  //     },
  //   ],
  //   [location.pathname, translation.home]
  // );
        
  // {internalMenuList.map(
  //   (x: any, index: number) =>
  //     x.permission && (
  //       <Link className={classes.link} to={x.to} key={index}>
  //         <Flex align="center" className={classes.link_hover}>
  //           {x?.icon}
  //           <Text
  //             className={`${classes.link_text} ${
  //               (location.pathname === x.to ||
  //                 (x.to !== "/" && location.pathname.includes(x.to))) &&
  //               classes.active_link
  //             } `}
  //           >
  //             {/* {translation?.attributes?.fields?.home} */}
  //             {x.title}
  //           </Text>
  //         </Flex>
  //       </Link>
  //     )
  // )}

  // useEffect(() => {
  //   if (!user) {
  //     setMenuList(externalMenuList);
  //   } else {
  //     setMenuList(internalMenuList);
  //   }
  // }, [user, setMenuList, externalMenuList, internalMenuList]);

  return (
    <div
      className={classes.left_box}
      style={{ height: "100vh", position: "relative" }}
    >
      <div className={classes.left_box_container}>
        <div className={classes.group}>
          <Link to="/">
            <Image
              mt={30}
              mb={30}
              ml={10}
              classNames={{ root: classes.logo }}
              src={require("../../../assets/logo.png")}
            />
          </Link>
  
          <Button
            className={classes.new_post_and_become_creator_button}
            onClick={registerModalOpen}
          >
            <span className={classes.link_text} style={{ marginLeft: 0 }}>
              {translation.register}
              {/* {!user?.isCreator
                ? translation.left_menu.createCreator
                : } */}
            </span>
            <span className={classes.link_icon}>
              <AiOutlineUser size={20} />
              {/* {!user?.isCreator ? (
                <FaUserPlus size={20} />
              ) : (
              )} */}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};
const useStyles = createStyles((theme) => ({
  left_box: {
    width: "30vw",
    zIndex: 99,
    [`@media (max-width: 1265px)`]: {
      width: "10vw",
    },
    [`@media (max-width: 500px)`]: {
      width: "0vw",
      display: "none",
    },
  },
  logout_modal: {
    [`@media (max-width: 1265px)`]: {
      width: "0vw",
      display: "none",
    },
  },
  modal_body: {
    backgroundColor: "#171717",
    padding: "45px",
    [`@media (max-width: 768px)`]: {
      borderRadius: "33px",
    },
    [`@media (max-width: 500px)`]: {
      borderRadius: "33px 33px 0px 0px",
    },
  },
  modal_inner: {
    [`@media (max-width: 500px)`]: {
      padding: 0,
      alignItems: "flex-end",
    },
  },
  left_box_container: {
    width: "30vw",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    height: "100vh",
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    background: "rgba(53, 56, 62, 0.4)",
    [`@media (max-width: 1265px)`]: {
      width: "10vw",
      justifyContent: "center",
      alignItems: "center",
    },
    [`@media (max-width: 500px)`]: {
      width: "0vw",
      display: "none",
    },
  },
  group: {
    width: "275px",
    height: "100%",
    display: "flex",
    paddingTop: "10px ",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "flex-start",
    [`@media (max-width: 1265px)`]: {
      alignItems: "center",
      padding: "10px 0px",
    },
  },
  creator_profile: {
    height: "25%",
    width: "275px",
    padding: "10px 20px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "start",
    [`@media (max-width: 1265px)`]: {
      padding: "10px 0px",
    },
  },
  creator_profile_container: {
    width: "100%",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
    [`@media (max-width: 1265px)`]: {
      justifyContent: "center",
    },
  },
  creator_profile_info: {
    [`@media (max-width: 1265px)`]: {
      display: "none",
    },
  },
  amount_area: {
    padding: "0px 20px",
    height: 41,
    borderRadius: 21,
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 14,
    fontWeight: 900,
    marginBottom: 30,
    marginTop: 30,
    [`@media (max-width: 1265px)`]: {
      width: 50,
      padding: "0px 0px",
      height: 50,
      borderRadius: "50%",
      position: "relative",
    },
  },
  amount_image: {
    [`@media (max-width: 1265px)`]: {
      position: "absolute",
      right: -5,
      bottom: -5,
    },
  },
  logo: {
    width: "5vw !important",
    transition: "all 0.5s ease",
    "&:hover": {
      transform: "scale(1.1)",
    },
    [`@media (max-width: 1265px)`]: {},
  },
  link: {
    color: "#8C8FA5",
    textDecoration: "none",
    fontSize: 20,
    width: "100%",
    fontWeight: 400,
    [`@media (max-width: 1265px)`]: {
      margin: "5px 0px",
      width: "fit-content",
    },
    [`&:hover .${getStylesRef("link_hover")}`]: {
      backgroundColor: "rgba(231, 233, 234, .1)",
      borderRadius: 60,
      transition: "all .2s",
    },
  },
  link_hover: {
    padding: 12,
    transition: "all .2s",
    width: "fit-content",
    ref: getStylesRef("link_hover"),
  },
  link_text: {
    marginLeft: 10,
    [`@media (max-width: 1265px)`]: {
      display: "none",
    },
  },
  active_link: {
    color: theme.colors.green[0],
    fontWeight: 600,
  },
  icon: {
    width: 30,
  },
  link_icon: {
    display: "none",
    [`@media (max-width: 1265px)`]: {
      display: "flex",
    },
  },
  new_post_and_become_creator_button: {
    width: "80%",
    marginTop: 20,
    backgroundColor: theme.colors.green[0],
    color: "#111111",
    fontSize: 18,
    fontWeight: "bold",
    borderRadius: 58,
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [`@media (max-width: 1265px)`]: {
      borderRadius: "50%",
      padding: "0px",
      width: 54,
      height: 54,
    },
    [`@media (max-width: 950px)`]: {
      borderRadius: "50%",
      padding: "0px",
      width: 40,
      height: 40,
    },
  },
}));
export default PublicLeftMenu;

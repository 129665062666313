/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  PasswordInput,
  createStyles,
  NavLink,
  Text,
  Button,
  Modal,
  Flex,
  Title,
  TextInput,
  Image,
  Avatar,
  Loader,
  Divider,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { User } from "../../../hooks/user.hook";
import { useDisclosure } from "@mantine/hooks";
import { BsPerson, BsPersonX, BsShieldCheck } from "react-icons/bs";
import { FaChevronRight } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { MdAlternateEmail } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { Auth } from "../../../hooks/auth.hook";
import { useUpdateProfile } from "../../../api/creator/service";
import { useGetUser } from "../../../api/user/service";

const Account = ({ translation, setActiveRight, activeRight }: any) => {
  const { logoutUser, loading } = Auth();
  const { mutateUpdateProfile, updateLoading } = useUpdateProfile();

  const { deleteCreator, deleteUser, changePassword } = User();
  const { data: user } = useGetUser();
  const { classes } = useStyles();
  const [openDeleteAccountModal, { open, close }] = useDisclosure(false);
  const { right_menu } = useParams();
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [currentPasswordError, setCurrentPasswordError] = useState(false);
  const form: any = useForm({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      passwordConfirmation: "",
    },
  });

  const accountInfoData = [
    {
      value: "username",
      label: translation.username,
      permission: user?.data?.isCreator,
      description: "@" + user?.data?.attributes?.creatorProfile?.username,
    },
    {
      value: "email",
      label: translation.email,
      permission: true,
      description: user?.data?.attributes?.user?.email,
    },
  ];

  const securityData = [{ value: "password", label: "Password" }];
  const accountManagementData = [
    {
      value: "delete",
      label: translation.delete_account,
      description: user?.data?.attributes?.creatorProfile?.username
        ? "@" + user?.data?.attributes?.creatorProfile?.username
        : "",
    },
  ];

  const accountData = [
    {
      icon: <BsPerson size={22} />,
      value: "info",
      label: translation.settings_page.account_info,
      description: translation.settings_page.account_info_desc,
    },
    {
      icon: <BsShieldCheck size={22} />,
      value: "security",
      label: translation.settings_page.security,
      description: translation.settings_page.security_desc,
    },
    {
      icon: <BsPersonX size={22} />,
      value: "management",
      label: translation.settings_page.management,
      description: translation.settings_page.management_desc,
    },
    {
      icon: (
        <Image
          fit="contain"
          src={require("../../../assets/logout.png")}
          height={22}
          width={22}
        />
      ),
      value: "logout",
      label: translation.logout,
      description: "",
    },
    {
      icon: (
        <Image
          fit="contain"
          src={require("../../../assets/amount.png")}
          height={22}
        />
      ),
      value: "balance",
      label: translation.balance + ": " + user?.data?.attributes.wallet.balance,
      description: "",
    },
  ];

  useEffect(() => {
    if (user?.data?.attributes.creatorProfile != null) {
      setUsername(user?.data?.attributes.creatorProfile.username);
    }
  }, [user]);
  useEffect(() => {
    if (!right_menu) {
      setActiveRight("");
    }
    if (right_menu === "info") {
      setActiveRight(translation.settings_page.account_info);
    }
    if (right_menu === "security") {
      setActiveRight(translation.settings_page.security);
    }
    if (right_menu === "management") {
      setActiveRight(translation.settings_page.management);
    }
    if (right_menu === "delete") {
      setActiveRight(translation.delete_account);
    }
  }, [right_menu]);

  const deleteAccount = () => {
    if (user?.data?.isCreator) {
      deleteCreator({
        userId: user?.data?.attributes.user?.id,
        creatorId: user?.data?.attributes.creatorProfile.id,
      });
    } else {
      deleteUser(user?.data?.attributes.user?.id);
    }
  };
  const changePass = async () => {
    if (form.values.currentPassword.length < 6) {
      setCurrentPasswordError(true);
    } else {
      await changePassword(form.values);
    }
  };

  const updateUsername = async () => {
    if (username?.length > 2) {
      const data = {
        username: username,
        id: user?.data?.attributes.creatorProfile.id,
        userId: user?.data?.attributes.userProfile.id,
      };
      await mutateUpdateProfile(data);
    }
  };

  const activeSection = () => {
    if (right_menu === "info") {
      return (
        <>
          {accountInfoData.map(
            (x: any, index: number) =>
              x.permission && (
                <NavLink
                  key={index}
                  label={x.label}
                  description={x.description}
                  classNames={{ root: classes.navlink_root }}
                  className={classes.title}
                  rightSection={<FaChevronRight />}
                  onClick={() => {
                    setActiveRight(x.label);
                    navigate(`/my/settings/account/${x.value}`);
                  }}
                />
              )
          )}
        </>
      );
    }
    if (right_menu === "username") {
      return (
        <>
          <TextInput
            mt={10}
            label={translation.username}
            placeholder={translation.username}
            styles={{
              icon: { marginLeft: 8 },
              input: {
                border: username?.includes(" ")
                  ? "1px solid red !important"
                  : undefined,
              },
            }}
            classNames={{
              label: classes.input_label,
              input: classes.input,
            }}
            icon={<MdAlternateEmail color={"white"} />}
            onChange={(e: any) =>
              setUsername(
                e.target.value.toLowerCase().replace(/[^a-zA-Z0-9_-]/g, "")
              )
            }
            value={username}
          />
          {username?.includes(" ") && (
            <Text style={{ color: "red" }}>
              {" "}
              {translation.settings_page.username_not_spaces}
            </Text>
          )}
          <Flex mt={30} className={classes.save_button_area}>
            <Button
              disabled={
                username?.length < 3 ||
                username === user?.data?.attributes?.creatorProfile?.username ||
                username?.includes(" ")
              }
              className={classes.save_button}
              onClick={updateUsername}
            >
              Save
            </Button>
          </Flex>
        </>
      );
    }
    if (right_menu === "email") {
      return (
        <>
          <TextInput
            mt={10}
            label={translation.email}
            placeholder={translation.email}
            classNames={{
              label: classes.input_label,
              input: classes.input,
            }}
            disabled
            defaultValue={user?.data?.attributes?.user?.email}
          />
          <Flex mt={30} className={classes.save_button_area}>
            {/* <Button className={classes.save_button} onClick={updateCreator}>
              Save
            </Button> */}
          </Flex>
        </>
      );
    }
    if (right_menu === "security") {
      return (
        <>
          {securityData.map((x: any, i: number) => (
            <NavLink
              label={x.label}
              key={i}
              description={"*******"}
              classNames={{ root: classes.navlink_root }}
              className={classes.title}
              rightSection={<FaChevronRight />}
              onClick={() => {
                setActiveRight(x.label);
                navigate(`/my/settings/account/${x.value}`);
              }}
            />
          ))}
        </>
      );
    }
    if (right_menu === "password") {
      return (
        <>
          <PasswordInput
            label={translation.settings_page.current_password}
            placeholder={translation.settings_page.current_password}
            mt={5}
            classNames={{
              label: classes.input_label,
              input: classes.input,
              innerInput: classes.inner_input,
              rightSection: classes.input_right_icon,
            }}
            error={currentPasswordError && "error"}
            {...form.getInputProps("currentPassword")}
          />
          <PasswordInput
            label={translation.settings_page.new_password}
            placeholder={translation.settings_page.new_password}
            mt={23}
            classNames={{
              label: classes.input_label,
              input: classes.input,
              innerInput: classes.inner_input,
              rightSection: classes.input_right_icon,
            }}
            {...form.getInputProps("newPassword")}
          />
          <PasswordInput
            label={translation.settings_page.new_password_confirmation}
            placeholder={translation.settings_page.new_password_confirmation}
            mt={23}
            classNames={{
              label: classes.input_label,
              input: classes.input,
              innerInput: classes.inner_input,
              rightSection: classes.input_right_icon,
            }}
            {...form.getInputProps("passwordConfirmation")}
          />
          <Flex mt={30} className={classes.save_button_area}>
            <Button className={classes.save_button} onClick={changePass}>
              {translation.settings_page.update_password}
            </Button>
          </Flex>
        </>
      );
    }
    if (right_menu === "management") {
      return (
        <>
          {accountManagementData.map((x: any, i: number) => (
            <NavLink
              label={x.label}
              key={i}
              description={x.description}
              classNames={{ root: classes.navlink_root }}
              className={classes.title}
              rightSection={<FaChevronRight />}
              onClick={() => {
                setActiveRight(x.label);
                navigate(`/my/settings/account/${x.value}`);
              }}
            />
          ))}
        </>
      );
    }
    if (right_menu === "delete") {
      return (
        <>
          <Modal
            opened={openDeleteAccountModal}
            classNames={{
              body: classes.modal_body,
              inner: classes.modal_inner,
            }}
            onClose={close}
            withCloseButton={false}
            centered
          >
            <button onClick={close} className={classes.close}>
              <IoClose color="white" size={25} />
            </button>
            <Flex align="center" justify="center">
              <div className={classes.delete_image}>
                <Image
                  height="100%"
                  fit="contain"
                  src={require("../../../assets/settings/delete_account_icon.png")}
                />
              </div>
            </Flex>
            <Flex direction="column" align="center">
              <Text className={classes.delete_modal_text} color="white">
                {translation.settings_page.delete_account_question}
              </Text>
              <Button
                className={classes.yes_delete_button}
                onClick={deleteAccount}
              >
                {translation.yes}
              </Button>
            </Flex>
          </Modal>
          {user?.data?.isCreator && (
            <Flex
              style={{ position: "relative" }}
              align="center"
              className={classes.user_info_area}
            >
              <div className={classes.divider}></div>
              <Avatar
                size={70}
                radius="xl"
                src={
                  user?.data?.attributes.creatorProfile?.avatarThumbs?.thumbs
                    ?.c144
                }
              />
              <Flex direction="column" ml={20}>
                <Title fw={600} fz={18} color="white">
                  {user?.data?.attributes?.creatorProfile?.name}
                </Title>
                <Text fw={400} fz={16} color="rgba(150, 155, 158, 1)">
                  {"@" + user?.data?.attributes?.creatorProfile?.username}
                </Text>
              </Flex>
            </Flex>
          )}
          <Title fz={18} mt={32} mb={10} color="white">
            {translation.settings_page.deactive_account_title}
          </Title>
          <Text color="#B9B9B9" fz={15} mb={10}>
            {user?.data?.isCreator
              ? translation.settings_page.deactive_account_desc_creator
              : translation.settings_page.deactive_account_desc}
          </Text>
          <Flex className={classes.save_button_area}>
            <Button
              radius={50}
              className={classes.open_modal_delete_button}
              onClick={open}
            >
              {translation.delete_account}
            </Button>
          </Flex>
        </>
      );
    }
  };

  if (updateLoading) {
    return (
      <Flex
        align="center"
        justify="center"
        style={{ width: "100%", height: "100vh" }}
      >
        <Loader variant="bars" color="#D2E951" />
      </Flex>
    );
  }
  if (loading)
    return (
      <div className={classes.logo}>
        <Image
          src={require("../../../assets/logo.png")}
          fit="contain"
          height="20vh"
          width="20vw"
        />
      </div>
    );
  return (
    <div>
      {right_menu === undefined ? (
        <>
          {accountData.map((x: any, i: number) => (
            <NavLink
              icon={x.icon}
              key={i}
              classNames={{ root: classes.navlink_root }}
              label={x.label}
              className={classes.title}
              description={x.description}
              rightSection={<FaChevronRight />}
              onClick={() => {
                if (x.value === "logout") {
                  logoutUser();
                }
                if (x.value === "balance") {
                  navigate(`/my/payment`);
                } else {
                  setActiveRight(x.label);
                  navigate(`/my/settings/account/${x.value}`);
                }
              }}
            />
          ))}

          <Divider mt={30} mb={30} />
          <Flex
            align="center"
            justify="center"
            style={{ height: "100%", width: "100%" }}
          >
            <Text
              fz={14}
              color="white"
              className={classes.info_text}
              align="center"
            >
              {
                "Für Fragen rund um unseren Zahlungsanieter Segpay, wenden Sie sich bitte an: "
              }
            </Text>
          </Flex>

          <Flex
            mt={20}
            align="center"
            justify="center"
            style={{ height: "100%", width: "100%" }}
          >
            <Text
              fz={14}
              color="white"
              className={classes.info_text}
              align="center"
            >
              Phone
            </Text>
          </Flex>
          <Flex
            align="center"
            justify="center"
            style={{ height: "100%", width: "100%" }}
          >
            <Text
              fz={14}
              color="white"
              className={classes.info_text}
              align="center"
            >
              866.450.4000 (US)
            </Text>
          </Flex>
          <Flex
            align="center"
            justify="center"
            style={{ height: "100%", width: "100%" }}
          >
            <Text
              fz={14}
              color="white"
              className={classes.info_text}
              align="center"
            >
              +1 954.414.1610 (Int’l)
            </Text>
          </Flex>
          <Flex
            mt={20}
            align="center"
            justify="center"
            style={{ height: "100%", width: "100%" }}
          >
            <Text
              fz={14}
              color="white"
              className={classes.info_text}
              align="center"
            >
              Email
            </Text>
          </Flex>
          <Flex
            align="center"
            justify="center"
            style={{ height: "100%", width: "100%" }}
          >
            <Text
              fz={14}
              color="white"
              className={classes.info_text}
              align="center"
            >
              help@segpay.com
            </Text>
          </Flex>
          <Flex
            mt={20}
            align="center"
            justify="center"
            style={{ height: "100%", width: "100%" }}
          >
            <Text
              fz={14}
              color="white"
              className={classes.info_text}
              align="center"
            >
              Self-Service
            </Text>
          </Flex>
          <Flex
            align="center"
            justify="center"
            style={{ height: "100%", width: "100%" }}
          >
            <Text
              fz={14}
              color="white"
              className={classes.info_text}
              align="center"
            >
              cs.segpay.com
            </Text>
          </Flex>
          <Divider mt={30} mb={30} />
        </>
      ) : (
        <>
          <div style={{ padding: "0px 10px" }}>{activeSection()}</div>
        </>
      )}
    </div>
  );
};
const useStyles = createStyles((theme) => ({
  title: {
    padding: "8px 0px",
    color: "white",
  },
  links: {
    borderBottom: "1px solid rgba(138,150,163,.25)",
  },
  navlink_root: {
    "&:hover": {
      backgroundColor: "transparent",
      transform: "scale(1.05)",
      transition: "all .2s",
    },
  },
  logo: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    animation: "lightBlueAnimationLogin 1s ease-in-out infinite",
    "@keyframes lightBlueAnimationLogin": {
      "0%": {
        transform: "scale(1)",
      },
      "50%": {
        transform: "scale(1.2)",
      },
      "100%": {
        transform: "scale(1)",
      },
    },
  },
  info_text: {
    width: "45%",
    [`@media (max-width: 1280px)`]: {
      width: "65% !important",
    },
  },
  input_label: {
    color: "white",
    marginBottom: 7,
    fontSize: 16,
  },
  input: {
    backgroundColor: "transparent",
    borderRadius: 30,
    border: "1px solid rgba(255, 255, 255, 0.4)",
    color: "white",
    height: 50,
    padding: "0px 29px",
    fontSize: 18,
  },
  inner_input: {
    height: 50,
    color: "white",
    padding: "0px 29px",
  },
  input_right_icon: {
    marginRight: 10,
  },
  save_button: {
    backgroundColor: theme.colors.green,
    color: "black",
    width: 220,
    height: 50,
    borderRadius: "50px",
    transition: "all .2s",
    "&:hover": {
      backgroundColor: theme.colors.blue,
      color: "white",
      transition: "all .5s",
      transform: "scale(1.1)",
    },
    [`@media (max-width: 500px)`]: {
      position: "absolute",
      bottom: "20vh",
      width: "90%",
      height: 60,
    },
  },
  yes_delete_button: {
    backgroundColor: theme.colors.green,
    color: "black",
    width: 220,
    height: 50,
    borderRadius: "50px",
    transition: "all .2s",
    "&:hover": {
      transition: "all .5s",
      transform: "scale(1.1)",
    },
    [`@media (max-width: 500px)`]: {
      width: "100%",
    },
  },
  delete_image: {
    height: 210,
    width: 140,
    [`@media (max-width: 500px)`]: {
      width: 88,
      height: 130,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  delete_modal_text: {
    marginBottom: 30,
    marginTop: 30,
    [`@media (max-width: 500px)`]: {
      marginBottom: 10,
      marginTop: 10,
    },
  },
  modal_body: {
    backgroundColor: theme.colors.blue[0],
    padding: "45px",
    [`@media (max-width: 768px)`]: {
      borderRadius: "33px",
    },
    [`@media (max-width: 500px)`]: {
      borderRadius: "33px 33px 0px 0px",
    },
  },
  modal_inner: {
    [`@media (max-width: 500px)`]: {
      padding: 0,
      alignItems: "flex-end",
    },
  },
  close: {
    position: "absolute",
    zIndex: 999,
    right: 30,
    top: 30,
    backgroundColor: "transparent",
    border: "0px",
    padding: 0,
    cursor: "pointer",
  },
  open_modal_delete_button: {
    backgroundColor: "#F86137",
    height: 49,
    width: 234,
    transition: "all .2s",
    "&:hover": {
      transform: "scale(1.05)",
      transition: "all .2s",
    },
    [`@media (max-width: 500px)`]: {
      position: "absolute",
      bottom: "20vh",
      width: "90%",
      height: 60,
    },
  },
  divider: {
    position: "absolute",
    bottom: 0,
    left: -100,
    right: 0,
    paddingLeft: 0,
    width: "200vw",
    backgroundColor: "#525252",
    height: 1,
  },
  user_info_area: {
    padding: "18px 0px 28px 0px",
  },
  save_button_area: {
    justifyContent: "right",
    [`@media (max-width: 500px)`]: {
      justifyContent: "center",
    },
  },
}));
export default Account;

import { Flex, createStyles } from "@mantine/core";

const Steps = ({ steps, step }: any) => {
  const { classes } = useStyles();

  const render = () => {
    const elements = [];

    for (let index = 0; index < steps; index++) {
      elements.push(
        <div
          style={{
            width: `calc(100% / ${steps})`,
            margin:
              index === 0
                ? "0px 5px 0px 0px"
                : index === steps - 1
                ? "0px 0px 0px 5px"
                : "0px 5px",
            backgroundColor:
              step - 1 >= index ? "#D2E951" : "rgba(181, 181, 181, 0.2)",
          }}
          className={classes.steps_bar}
        ></div>
      );
    }
    return elements;
  };
  return (
    <Flex style={{ height: "10vh" ,paddingTop:"5vh" }}>
      {render()}
    </Flex>
  );
};
const useStyles = createStyles((theme) => ({
  steps_bar: {
    backgroundColor: "rgba(181, 181, 181, 0.2)",
    height: 8,
    borderRadius: 8,
  },
}));

export default Steps;

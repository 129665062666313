import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LandingLayout from "../../components/high-level/layout/landingLayout";
import {
  Button,
  Flex,
  Image,
  List,
  Text,
  ThemeIcon,
  Title,
  createStyles,
} from "@mantine/core";
import { AiFillCheckCircle } from "react-icons/ai";

const CreatorLandingPage = ({translation}: any) => {
  const navigate = useNavigate();
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const { classes } = useStyles({ position });

  const handleMouseMove = (event: any) => {
    const { clientX, clientY } = event;
    setPosition({ x: clientX, y: clientY });
  };

  return (
    <LandingLayout translation={translation}>
      <Flex mt={150} w="100%" align="center" justify="center" direction="column">
        <Flex w="80%" align="center" direction="column">
          <Title color="white" align="center" className={classes.title}>
            {translation.landing_creator_page.main_title}
          </Title>
          <Text
            mt={20}
            className={classes.text}
            style={{ letterSpacing: "2px" }}
            
            color="white"
            align="center"
          >
            {translation.landing_creator_page.main_text}
          </Text>
          <Button
            onClick={() => navigate("/register")}
            w={"288"}
            h={60}
            bg="rgba(210, 233, 81, 1)"
            c="black"
            radius={60}
            fz={18}
            mt={37}
            fw={400}
            style={{ zIndex: 9 }}
          >
            <Text className={classes.text} color="white" align="center" fw={600}>
            
            {translation.landing_creator_page.register_button}
            </Text>
          </Button>
          <div className={classes.main_image} onMouseMove={handleMouseMove}>
            <div className={classes.yellow_bg}></div>
            <div className={classes.blue_bg}></div>

            <div
              className={classes.image_area}
              style={{
                willChange: "transform",
                transform: `translate(${position.y / 20}px, ${
                  position.x / 20
                }px)`,
              }}
            >
              <Image src={require("../../assets/landing/landing1.png")} />
            </div>

            <div className={classes.image_left}>
              <Image
                classNames={{ image: classes.image_left_image }}
                src={require("../../assets/landing/landing2.png")}
                style={{
                  transform: `translate(-${position.x / 50}px, -${
                    position.y / 5
                  }px)`,
                }}
              />
            </div>
            <div className={classes.image_right}>
              <Image
                classNames={{ image: classes.image_right_image }}
                src={require("../../assets/landing/landing3.png")}
                style={{
                  transform: `translate(${position.x / 50}px, ${
                    position.y / 5
                  }px)`,
                }}
              />
            </div>
            <div
              className={classes.image_woman}
              style={{
                willChange: "transform",
                transform: `translate(${position.x / 20}px, ${
                  position.y / 20
                }px)`,
              }}
            >
              <Image
                classNames={{ image: classes.image_woman_image }}
                fit="contain"
                src={require("../../assets/creator_image.png")}
              />
            </div>
            <div
              className={classes.image_woman_bg}
              style={{
                willChange: "transform",
                transform: `translate(${position.y / 5}px, ${
                  position.x / 5
                }px) `,
              }}
            >
              <Image
                classNames={{ image: classes.image_woman_bg_image }}
                fit="contain"
                src={require("../../assets/landing/woman_bg.png")}
              />
            </div>
            <div
              className={classes.image_backgorund_1}
              style={{
                willChange: "transform",
                transform: `translate(${position.y / 25}px, ${
                  position.x / 25
                }px)`,
              }}
            >
              <Image src={require("../../assets/landing/landing1_bg.png")} />
            </div>
            <div
              className={classes.image_backgorund_2}
              style={{
                willChange: "transform",
                transform: `translate(${position.y / 30}px, ${
                  position.x / 30
                }px)`,
              }}
            >
              <Image src={require("../../assets/landing/landing1_bg.png")} />
            </div>
          </div>
          <Title mt={120} className={classes.title} fw={700} color="white" align="center">
          {translation.landing_creator_page.sub_title}
          </Title>
          <Text  className={classes.text} mt={20}  fw={400} color="white" align="center">
          {translation.landing_creator_page.sub_text_1}
            
          </Text>
          <Text   mt={0}  className={classes.text} fw={400} color="white" align="center">
          {translation.landing_creator_page.sub_text_2}
            
          </Text>
          <Text mt={20}  className={classes.text} fw={400} color="white" align="center">
          {translation.landing_creator_page.sub_text_3}

          </Text>
          <Text mt={20}  className={classes.text} fw={400} color="white" align="center">
          {translation.landing_creator_page.sub_text_4}

          </Text>
          <Text
            mt={80}
            mb={40}
            style={{ letterSpacing: "2px" }}
            fz={32}
            color="white"
            align="center"
          >
          {translation.landing_creator_page.list_title}
          </Text>
          <List
            mt={20}
            spacing="lg"
            size="lg"
            center
            withPadding
            icon={
              <ThemeIcon color="teal" size={24} radius="xl">
                <AiFillCheckCircle size="1rem" />
              </ThemeIcon>
            }
          >
            <List.Item>
              {" "}
              <Text  className={classes.text} fw={400} color="white" align="center">
          {translation.landing_creator_page.list_text_1}
                
              </Text>
            </List.Item>
            <List.Item>
              {" "}
              <Text  className={classes.text} fw={400} color="white" align="center">
          {translation.landing_creator_page.list_text_2}
              
              </Text>
            </List.Item>
            <List.Item>
              {" "}
              <Text  className={classes.text} fw={400} color="white" align="center">
          {translation.landing_creator_page.list_text_3}
                
              </Text>
            </List.Item>
            <List.Item>
              {" "}
              <Text  className={classes.text} fw={400} color="white" align="center">
          {translation.landing_creator_page.list_text_4}
  
              </Text>
            </List.Item>
            <List.Item>
              {" "}
              <Text  className={classes.text} fw={400} color="white" align="center">
          {translation.landing_creator_page.list_text_5}
                
              </Text>
            </List.Item>
          </List>
        </Flex>
        <Button
            onClick={() => navigate("/register")}
            w={"288"}
            h={60}
            bg="rgba(210, 233, 81, 1)"
            c="black"
            radius={60}
            fz={18}
            mt={120}
            fw={400}
            style={{ zIndex: 9 }}
          >
            <Text className={classes.text} color="white" align="center" fw={600}>
          {translation.landing_creator_page.creator_register_button}
             
            </Text>
          </Button>
        <Flex w="100%" direction="row" justify="space-between">
          <Image
            className={classes.footer_image}
            classNames={{ image: classes.footer_images }}
            mt={139}
            width="auto"
            fit="contain"
            src={require("../../assets/landing/landingfooter1.png")}
          />
          <Image
            mt={232}
            className={classes.footer_image2}
            classNames={{ image: classes.footer_images }}
            width="auto"
            fit="contain"
            src={require("../../assets/landing/landingfooter2.png")}
          />
          <Image
            mt={136}
            classNames={{ image: classes.footer_images }}
            className={classes.footer_image3}
            width="auto"
            fit="contain"
            src={require("../../assets/landing/landingfooter3.png")}
          />
          <Image
            mt={191}
            classNames={{ image: classes.footer_images }}
            className={classes.footer_image4}
            width="auto"
            fit="contain"
            src={require("../../assets/landing/landingfooter4.png")}
          />
          <Image
            mt={40}
            classNames={{ image: classes.footer_images }}
            className={classes.footer_image5}
            width="auto"
            fit="contain"
            src={require("../../assets/landing/landingfooter5.png")}
          />
        </Flex>
      </Flex>
    </LandingLayout>
  );
};
const useStyles = createStyles((theme, { position }: any) => ({
  image_area: {
    background:
      "linear-gradient(rgba(30, 30, 30, 1), rgba(30, 30, 30, 1)) padding-box, linear-gradient(0deg, rgba(77, 116, 254, 1), rgba(195, 233, 81, 1)) border-box",
    border: "1.5px solid transparent",
    borderRadius: 20,
    zIndex: 0,
  },
  title:{
    fontSize:45,
    [`@media (max-width: 768px)`]: {
      fontSize:30
    },
  },
  text:{
    fontSize:22,
    [`@media (max-width: 768px)`]: {
      fontSize:16
    },
  },
  main_image: {
    width: "100%",
    position: "relative",
    marginTop: 200,
    height: "100%",
    display: "flex",
    justifyContent: "center",
  },
  image_woman: {
    position: "absolute",
    zIndex: 1,
    bottom: 1,
  },
  image_woman_image: {
    height: "1200px !important",
    [`@media (max-width: 980px)`]: {
      height: "90vw !important",
    },
  },
  image_left: {
    position: "absolute",
    zIndex: 1,
    bottom: "50%",
    left: 0,
  },
  image_left_image: {
    width: "336px !important",
    [`@media (max-width: 1280px)`]: {
      width: "200px !important",
    },
  },
  image_right: {
    position: "absolute",
    zIndex: 9,
    bottom: "50%",
    right: -10,
  },
  image_right_image: {
    width: "336px !important",
    [`@media (max-width: 1280px)`]: {
      width: "200px !important",
    },
  },
  blue_bg: {
    backgroundColor: "rgba(77, 116, 254, 1)",
    width: 225,
    height: 225,
    position: "absolute",
    top: 100,
    left: "20%",
    zIndex: 1,
    opacity: 0.6,
    filter: "blur(100px)",
  },
  yellow_bg: {
    backgroundColor: "rgba(210, 233, 81, 1)",
    width: 225,
    height: 225,
    position: "absolute",
    bottom: 100,
    zIndex: 1,
    right: "20%",
    opacity: 0.6,
    filter: "blur(100px)",
  },
  image_woman_bg: {
    position: "absolute",
    zIndex: 0,
    top: -300,
    animation: "lightGreenAnimationLogin 2s ease-in-out infinite",
    "@keyframes lightGreenAnimationLogin": {
      "0%": {
        transform: `scale(1) translate(${position.x / 15}px, ${
          position.y / 15
        }px) `,
      },
      "25%": {
        transform: `scale(1.15) translate(${position.x / 15}px, ${
          position.y / 15
        }px) `,
      },
      "50%": {
        transform: `scale(1.1) translate(${position.x / 15}px, ${
          position.y / 15
        }px) `,
      },
      "75%": {
        transform: `scale(1.15) translate(${position.x / 15}px, ${
          position.y / 15
        }px) `,
      },
      "100%": {
        transform: `scale(1) translate(${position.x / 15}px, ${
          position.y / 15
        }px) `,
      },
    },
    [`@media (max-width: 1440px)`]: {
      top: "-200px !important",
    },
    [`@media (max-width: 768px)`]: {
      top: "-150px !important",
    },
    [`@media (max-width: 500px)`]: {
      top: "-200px !important",
    },
  },
  image_woman_bg_image: {
    height: "40vw !important",

    [`@media (max-width: 768px)`]: {
      height: "50vw !important",
    },
    [`@media (max-width: 500px)`]: {
      height: "60vw !important",
    },
  },
  image_backgorund_1: {
    position: "absolute",
    width: "calc(100% - 72px)",
    bottom: -27,
    zIndex: -1,
    [`@media (max-width: 768px)`]: {
      width: "calc(100% - 50px)",
      bottom: -19,
    },
  },
  image_backgorund_2: {
    position: "absolute",
    width: "calc(100% - 174px)",
    bottom: -43,
    zIndex: -2,

    [`@media (max-width: 768px)`]: {
      width: "calc(100% - 103px)",
      bottom: -30,
    },
  },
  footer_images: {
    height: "100vh !important",
    [`@media (max-width: 1280px)`]: {
      height: "60vh !important",
    },
    [`@media (max-width: 768px)`]: {
      height: "40vh !important",
    },
    [`@media (max-width: 500px)`]: {
      height: "30vh !important",
    },
  },
  footer_image: {
    transition: "all .5s",
    animation: "footerImageAnimation 2s ease-in-out infinite",
    "@keyframes footerImageAnimation": {
      "0%": {
        transform: `scale(1) translate(0, 0px) `,
      },
      "50%": {
        transform: `scale(1) translate(0, 15px) `,
      },
      "100%": {
        transform: `scale(1) translate(0, 0px) `,
      },
    },
  },
  footer_image2: {
    animation: "footerImageAnimation2 2.5s ease-in-out infinite",
    "@keyframes footerImageAnimation2": {
      "0%": {
        transform: `scale(1) translate(0, 0px) `,
      },
      "50%": {
        transform: `scale(1) translate(0, -15px) `,
      },
      "100%": {
        transform: `scale(1) translate(0, 0px) `,
      },
    },
  },
  footer_image3: {
    animation: "footerImageAnimation3 2s ease-in-out infinite",
    "@keyframes footerImageAnimation3": {
      "0%": {
        transform: `scale(1) translate(0, 0px) `,
      },
      "50%": {
        transform: `scale(1) translate(0, -20px) `,
      },
      "100%": {
        transform: `scale(1) translate(0, 0px) `,
      },
    },
  },
  footer_image4: {
    animation: "footerImageAnimation3 2s ease-in-out infinite",
    "@keyframes footerImageAnimation3": {
      "0%": {
        transform: `scale(1) translate(0, 0px) `,
      },
      "50%": {
        transform: `scale(1) translate(0, 20px) `,
      },
      "100%": {
        transform: `scale(1) translate(0, 0px) `,
      },
    },
  },
  footer_image5: {
    animation: "footerImageAnimation3 2.5s ease-in-out infinite",
    "@keyframes footerImageAnimation3": {
      "0%": {
        transform: `scale(1) translate(0, 0px) `,
      },
      "50%": {
        transform: `scale(1) translate(0, 15px) `,
      },
      "100%": {
        transform: `scale(1) translate(0, 0px) `,
      },
    },
  },
}));
export default CreatorLandingPage;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import Layout from "../../components/high-level/layout/layout";
import {
  Text,
  createStyles,
  Flex,
  Avatar,
  Title,
  Image,
  Loader,
  Button,
} from "@mantine/core";
import CreatorCard from "../../components/low-level/CreatorProfilePostsCard/CreatorProfilePostsCard";
import { Link, useNavigate } from "react-router-dom";
import { BsPencil } from "react-icons/bs";
import { AiFillAudio } from "react-icons/ai";
import { useElementSize, useScrollIntoView } from "@mantine/hooks";
import {
  useGetCreatorDetail,
  useGetCreatorPosts,
} from "../../api/creator/service";
import { useGetUser } from "../../api/user/service";
import CreatorEvents from "../../components/low-level/Creator/CreatorEvents";

const ProfilePage = ({ translation }: any) => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { data: user } = useGetUser();
  const [activeTab, setActiveTab] = useState("audio");
  const { targetRef } = useScrollIntoView<HTMLDivElement>({
    offset: 60,
  });
  // const [newData, setNewData] = useState([]);
  const {
    data: myProfilePosts,
    fetchNextPage,
    isFetchingNextPage,
    isLoading:postLoading
  }: any = useGetCreatorPosts(user?.data?.attributes?.creatorProfile.username);
  const { data: profileData, isLoading }: any = useGetCreatorDetail(
    user?.data?.attributes?.creatorProfile.username
  );

  const flattenedData: any = useMemo(
    () =>
      myProfilePosts
        ? myProfilePosts?.pages?.flatMap((item: any) => item.data.creatorPosts)
        : [],
    [myProfilePosts]
  );

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        fetchNextPage();
      }
    });

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [targetRef,postLoading]);

  // useEffect(() => {
  //   if (!myProfilePostsLoading && flattenedData.length > 0) {
  //     const _newData = flattenedData.map((x: any) => ({
  //       ...x,
  //       playSound: false,
  //       isFirstPlay: false,
  //     }));
  //     setNewData(_newData);
  //   }
  //     return () => {
  //       setNewData([]);
  //   };
  // }, [myProfilePostsLoading, myProfilePosts]);

  if (isLoading || profileData === undefined) {
    return (
      <Layout>
        <Flex
          align="center"
          justify="center"
          style={{ width: "100%", height: "100vh" }}
        >
          <Loader variant="bars" color="#D2E951" />
        </Flex>
      </Layout>
    );
  }

  return (
    <Layout headerTitle={translation.profile} padding={false}>
      <div className={classes.creator_info}>
        <div
          className={classes.creator_image}
          style={{
            backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%), url(${user?.data?.attributes.creatorProfile.avatarThumbs?.sizes?.original})`,
          }}
        ></div>
        <Flex
          className={classes.name_area}
          direction="row"
          justify="space-between"
          align="flex-end"
        >
          <Flex>
            <Avatar
              size={92}
              radius={20}
              classNames={{
                root: classes.avatar,
                image: classes.creator_avatar,
              }}
              src={
                profileData.data?.creatorInfo?.attributes?.avatarThumbs?.sizes
                  ?.asr24
              }
            />
            <Flex
              className={classes.name_area_content}
              direction="column"
              justify="center"
            >
              <Title className={classes.name} fz={20} color="white">
                {profileData.data?.creatorInfo?.attributes?.name}
              </Title>
              <Text color="#A5A5A5">
                {"@" + profileData.data?.creatorInfo?.attributes?.username}
              </Text>
              <div className={classes.mobile_sounds}>
                <Text fz={18} fw={400} color="white" align="center">
                  {profileData.data?.creatorInfo?.attributes?.postsCount}
                </Text>
                <Text fz={18} fw={400} color="#939393" align="center">
                  {translation.sounds}
                </Text>
              </div>
            </Flex>
          </Flex>
          <div className={classes.edit_and_coast_area}>
            <button className={classes.play_button}>
              {user?.data?.attributes?.wallet?.balance
                ? user?.data?.attributes?.wallet?.balance
                : 0}
              <Image
                width={30}
                height={30}
                className={classes.star_image}
                src={require("../../assets/profile_start.png")}
              />
            </button>
            <Link className={classes.edit_profile} to="/my/profile/edit">
              <BsPencil color="white" size={15} style={{ marginRight: 5 }} />{" "}
              <Text fz={15}>{translation.profile_page.edit_profile}</Text>
            </Link>
          </div>
        </Flex>
      </div>
      <div className={classes.tabs}>
        <Button
          onClick={() => setActiveTab("audio")}
          className={`${classes.tab_button} ${
            activeTab === "audio" && classes.active_tab
          }`}
        >
          {translation.audio}
        </Button>
        <Button
          onClick={() => setActiveTab("event")}
          className={`${classes.tab_button} ${
            activeTab === "event" && classes.active_tab
          }`}
        >
          {translation.events}
        </Button>
      </div>
      {activeTab === "audio" && (
        <>
          <Flex align="center" justify="center">
            <div
              onClick={() => navigate("/post/create")}
              className={classes.new_post_area}
            >
              <Text color="#FFF8F8" opacity={0.8} fz={16} ml={27}>
                {translation.profile_page.save_sound}
              </Text>
              <div className={classes.button}>
                <AiFillAudio size={27} />
              </div>
            </div>
          </Flex>

          <Text
            className={classes.web_sounds}
            fz={18}
            fw={400}
            color="white"
            mb={10}
            align="right"
          >
            {profileData.data?.creatorInfo?.attributes?.postsCount +
              " " +
              translation.sounds}
          </Text>
          <div  className={classes.voice_area}>
            {flattenedData !== undefined &&
              flattenedData?.map((x: any, i: number) => (
                <Flex key={i} direction="column" mt={10}>
                  <CreatorCard data={x} profile={true} />
                </Flex>
              ))}
          </div>
          <div ref={targetRef} />
          <div className={classes.inifinty_loading_area}>
            {isFetchingNextPage && <Loader variant="bars" color="#D2E951" />}
          </div>
        </>
      )}
      {activeTab === "event" &&
      <>
       {user?.data?.isCreator && (
            <Link
              to="/my/create-event"
              className={classes.create_group_button}
            >
              <Image
                mr={10}
                width={18}
                height={18}
                src={require("../../assets/plus.png")}
              />
              <Text style={{textDecoration:'none'}} lh={0} fz={14} fw={600}>
                {translation.create_event}
              </Text>
            </Link>
          )}
      <CreatorEvents translation={translation} user={user} creatorId={profileData.data.creatorInfo.id} />
      </>
      }

    </Layout>
  );
};
const useStyles = createStyles((theme) => ({
  creator_info: {
    alignItems: "flex-end",
    display: "flex",
    position: "relative",
    height: 280,
    width: "100%",
    overflow: "hidden",
    borderEndEndRadius: 20,
    borderEndStartRadius: 20,
    [`@media (max-width: 500px)`]: {
      borderEndEndRadius: 50,
      borderEndStartRadius: 50,
      marginTop: 60,
    },
  },
  voice_area: {
    [`@media (max-width: 500px)`]: {
      margin: "0px 10px",
    },
  },
  creator_image: {
    filter: "blur(1.2px)",
    backgroundSize: "cover",
    objectPosition: "50% 0%",
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "25%",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: -1,
    [`@media (max-width: 500px)`]: {
      marginTop: 60,
    },
  },
  creator_avatar: {
    objectPosition: "50% 0%",
  },
  avatar: {
    border: `1px solid ${theme.colors.green[0]}`,
    [`@media (max-width: 500px)`]: {
      display: "none",
    },
  },
  name_area_content: {
    marginLeft: 25,
    [`@media (max-width: 500px)`]: {
      marginLeft: 20,
    },
  },
  name_area: {
    width: "100%",
    margin: "20px 10px",
  },
  about: {
    [`@media (max-width: 500px)`]: {
      margin: "0px 20px",
    },
  },
  about_more_button: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginRight: 12,
    cursor: "pointer",
    [`@media (max-width: 500px)`]: {
      margin: "10px 0px",
      paddingRight: 20,
    },
  },
  edit_and_coast_area: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  play_button: {
    width: 63,
    height: 63,
    marginBottom: 15,
    borderRadius: "50%",
    border: 0,
    display: "flex",
    position: "relative",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    color: "black",
    fontWeight: 900,
    fontSize: 18,
    background: theme.colors.green[0],
    [`@media (max-width: 950px)`]: {
      width: 64,
      height: 63,
    },
    [`@media (max-width: 500px)`]: {
      width: 53,
      height: 53,
    },
  },
  edit_profile: {
    color: "white",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    fontWeight: 700,
    fontSize: 18,
    marginRight: 10,
    [`@media (max-width: 950px)`]: {
      marginTop: 10,
    },
  },
  star_image: {
    position: "absolute",
    right: -5,
    bottom: -5,
  },
  mobile_sounds: {
    display: "none",
    [`@media (max-width: 500px)`]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "fit-content",
      marginTop: 24,
    },
  },
  web_sounds: {
    [`@media (max-width: 500px)`]: {
      display: "none",
    },
  },
  name: {
    marginBottom: 0,
    [`@media (max-width: 500px)`]: {
      marginBottom: 8,
    },
  },
  new_post_area: {
    width: "100%",
    height: 75,
    marginTop: 20,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    marginBottom: 22,
    backgroundColor: "rgba(181, 181, 181, 0.2)",
    borderRadius: 40,
    [`@media (max-width: 500px)`]: {
      width: "90%",
    },
  },
  button: {
    width: 58,
    height: 58,
    borderRadius: "50%",
    backgroundColor: theme.colors.green[0],
    marginRight: 9,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inifinty_loading_area: {
    paddingTop: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tabs: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    display: "flex",
    justifyContent: "space-between",
    borderRadius: 40,
    marginTop: 30,
    marginBottom: 20,
    [`@media (max-width: 950px)`]: {
      marginTop: 20,
    },
    [`@media (max-width: 500px)`]: {
      marginLeft: 20,
      marginRight: 20,
    },
  },
  tab_view: {
    [`@media (max-width: 500px)`]: {
      paddingRight: 20,
      paddingLeft: 20,
    },
  },
  tab_button: {
    borderRadius: 40,
    width: "100%",
    color: "lightgrey",
    transition: "all 0.3s",
    "&:hover": {
      color: theme.colors.green[0],
    },
  },
  active_tab: {
    // backgroundColor:theme.colors.green[0],
    border: `1px solid ${theme.colors.green[0]}`,
    borderRadius: 40,
    color: "white !important",
    transition: "all 0.3s",
  },
  create_group_button: {
    backgroundColor: theme.colors.blue[0],
    border: 0,
    color: "white",
    padding: "9px 20px",
    height: "70px",
    borderRadius: 40,
    textDecoration:"none",
    cursor: "pointer",
    marginBottom:32,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent:"center"
  },
}));
export default ProfilePage;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Creator } from "../../hooks/creator.hook";
import {
  Flex,
  createStyles,
  TextInput,
  Textarea,
  Title,
  Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import CreatorLayout from "../../components/high-level/layout/creatorLayout";
import Steps from "../../components/low-level/steps/Steps";
import StepsButton from "../../components/low-level/steps/StepsButton";
import FileUploadImage from "../../components/low-level/FileUpload/FileUploadImage";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useViewportSize } from "@mantine/hooks";
import { MdAlternateEmail } from "react-icons/md";
import { useGetUser } from "../../api/user/service";
import { useCreateCreator } from "../../api/creator/service";
import latinize from "latinize";

const CreateCreatorPage = ({ translation }: any) => {
  const navigate = useNavigate();
  const { data: user } = useGetUser();
  const { classes } = useStyles();
  const { width } = useViewportSize();

  const {
    getCreatorUsernameControl,
    getCreatorUsernameSuggestControl,
    usernameCheck,
    usernameSuggestCheck,
  } = Creator();
  const { createCreator, isLoading } = useCreateCreator();
  const [avatar, setAvatar] = useState<any>();
  const [error, setError] = useState<boolean>(false);
  const [errorImage, setErrorImage] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [suggestUsername, setSuggestUsername] = useState<string>("");
  const [errorUsername, setErrorUsername] = useState<string>("");
  const [errorName, setErrorName] = useState<string>("");
  const [step, setStep] = useState<any>(1);

  const steps = 3;
  const form: any = useForm({
    initialValues: {
      name: "",
      username: "",
      about: "",
    },
  });

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 32 || e.keyCode === 191) {
      e.preventDefault();
    }
  };
  const suggestNameControl = async (name: string) => {
    await getCreatorUsernameSuggestControl(name);
  };

  useEffect(() => {
    let suggestedUsername: string;
    if (usernameSuggestCheck?.available) {
      suggestedUsername = name.toLowerCase().replace(/\s/g, "");
    } else {
      suggestedUsername =
        name.toLowerCase().replace(/\s/g, "") + Math.floor(Math.random() * 100);
    }
    setSuggestUsername(latinize(suggestedUsername));
  }, [name, usernameSuggestCheck]);

  const handleUsernameClick = () => {
    setUsername(suggestUsername);
    usernameControl(suggestUsername);
  };
  useEffect(() => {
    if (user?.data?.isCreator) {
      navigate("/my/feed");
    }
  }, [user]);
  useEffect(() => {
    if (name.length > 3) {
      setError(false);
      setErrorName("");
    }
  }, [name]);
  const postCreateCreator = async () => {
    const creatorInformation = {
      userId: user?.data?.attributes.userProfile.id,
      name: name,
      username: username,
      about: form.values.about,
    };
    if (!error) {
      await createCreator({ avatar: avatar, user: creatorInformation });
    }
  };

  const usernameControl = async (e: any) => {
    setUsername(e.replace(/\s/g, ""));
    if (e.length > 2) {
      await getCreatorUsernameControl(e);
    }
  };

  const nextStep = () => {
    if (step === 1) {
      if (avatar === undefined) {
        setError(true);
        setErrorImage(translation.create_creator_page.plase_select_picture);
      } else {
        if (avatar.size > 10485760) {
          setError(true);
          setErrorImage(translation.create_creator_page.plase_select_picture);
        } else {
          setErrorImage("");
          setError(false);
          setStep(step + 1);
        }
      }
    }
    if (step === 2) {
      if (name.length <= 3) {
        setError(true);
        setErrorName(translation.create_creator_page.enter_name);
      }
      if (name.length > 3 && usernameCheck?.available) {
        setError(false);
        setErrorName("");
      }

      if (username.length <= 3) {
        setError(true);
        setErrorUsername(translation.create_creator_page.enter_username);
      }
      if (username.length > 3 && usernameCheck?.available) {
        setError(false);
        setErrorUsername(usernameCheck?.message);
      }
      if (name.length > 3 && username.length > 3 && usernameCheck?.available) {
        setStep(step + 1);
      }
    }
    if (step === 3) {
      setError(false);
      postCreateCreator();
    }
  };

  useEffect(() => {
    if (username.length > 2) {
      if (usernameCheck?.available) {
        setError(false);
        setErrorUsername(usernameCheck?.message);
      } else {
        setError(true);
        setErrorUsername(usernameCheck?.message);
      }
    } else {
      setError(true);
      setErrorUsername("");
    }
  }, [usernameCheck, username]);
  const prevStep = () => {
    if (step === 1) {
      navigate(-1);
    } else {
      setStep(step - 1);
      setError(false);
    }
  };

  return (
    <CreatorLayout
      step={step}
      backgroundImage={require("../../assets/creator/background.webp")}
      direction={"row"}
    >
      <div className={classes.background_div}></div>
      <Flex
        align="center"
        justify="space-between"
        style={{ height: "100%", width: "100%" }}
        direction="column"
      >
        <div className={classes.main}>
          <Flex mt={"3vh"} direction="row" align="center">
            <button
              style={{ marginRight: 10, height: 30 }}
              className={classes.back_button}
              onClick={() => prevStep()}
            >
              <IoIosArrowRoundBack color="white" size={30} />
            </button>
            <div style={{ height: 30, display: "flex", alignItems: "center" }}>
              <Title className={classes.title} fw={400} color="white">
                {translation.create_creator_page.become_creator}
              </Title>
            </div>
          </Flex>
          <div className={classes.mobile_area}>
            <Steps steps={steps} step={step} />
            {step === 1 && (
              <div className={classes.step_area}>
                <FileUploadImage
                  error={errorImage}
                  accept="image/*"
                  image={avatar}
                  setFile={setAvatar}
                />
              </div>
            )}
            {step === 2 && (
              <div className={classes.step_area}>
                <Flex mb={40} direction="column">
                  <TextInput
                    styles={{
                      input: { borderColor: errorName ? "red" : "white" },
                    }}
                    classNames={{
                      label: classes.input_label,
                      input: classes.input,
                    }}
                    label={translation.name}
                    placeholder={translation.name}
                    value={name}
                    maxLength={30}
                    onChange={(e: any) => {
                      setName(e.target.value);
                      setTimeout(() => {
                        suggestNameControl(e.target.value);
                      }, 1000);
                    }}
                  />
                  <Text mt={5} style={{ height: 5 }} color="red">
                    {errorName && errorName}
                  </Text>
                </Flex>
                <TextInput
                  styles={{
                    input: {
                      border:
                        username.length > 2 && usernameCheck?.available
                          ? "1px solid green !important"
                          : username.length < 3
                          ? "1px solid rgba(255, 255, 255, 0.4) !important"
                          : "1px solid red !important",
                    },
                    icon: { marginLeft: 8 },
                  }}
                  icon={
                    <MdAlternateEmail
                      color={
                        form.values.username.length > 0
                          ? "white"
                          : "rgba(255, 255, 255, .5)"
                      }
                    />
                  }
                  classNames={{
                    label: classes.input_label,
                    input: classes.input,
                  }}
                  maxLength={16}
                  label={translation.username}
                  placeholder={translation.username}
                  onKeyDown={(e: any) => handleKeyDown(e)}
                  onChange={(e: any) =>
                    usernameControl(latinize(e.target.value.toLowerCase()))
                  }
                  value={username}
                />
                <Text
                  mt={5}
                  style={{
                    height: 5,
                    color: !error && usernameCheck?.available ? "green" : "red",
                  }}
                >
                  {errorUsername && errorUsername}
                </Text>
                {suggestUsername.length > 2 && (
                  <Flex mt={25} direction="column">
                    <Text fw={600} color="white">
                      {translation.layout.suggestions}
                    </Text>
                    <Text
                      fw={500}
                      fz={16}
                      mt={5}
                      style={{
                        cursor: "pointer",
                        color: "rgba(210, 233, 81, 1)",
                      }}
                      onClick={handleUsernameClick}
                      color="white"
                    >
                      {"@" + suggestUsername}
                    </Text>
                  </Flex>
                )}
              </div>
            )}
            {step === 3 && (
              <div className={classes.step_area}>
                <Textarea
                  label="About (optionel)"
                  maxLength={800}
                  classNames={{
                    input: classes.textarea,
                    label: classes.input_label,
                  }}
                  placeholder="about"
                  {...form.getInputProps("about")}
                />
                <Text
                  align="end"
                  mt={5}
                  mr={5}
                  color={form.values.about.length > 780 ? "red" : "#e8e6e6"}
                  fw={500}
                >
                  {form.values.about.length}/800
                </Text>
              </div>
            )}
          </div>
          <Flex
            justify={width > 768 ? "right" : "center"}
            align="center"
            style={{ height: "20vh" }}
          >
            <StepsButton
              translation={translation}
              completeStep={postCreateCreator}
              steps={steps}
              step={step}
              loading={isLoading}
              error={error}
              nextStep={nextStep}
            />
          </Flex>
        </div>
      </Flex>
    </CreatorLayout>
  );
};
const useStyles = createStyles((theme) => ({
  main: {
    height: "100%",
    zIndex: 9999,
    display: "flex",
    flexDirection: "column",
    width: "25vw",
    [`@media (max-width: 1280px)`]: {
      width: "40vw",
    },
    [`@media (max-width: 768px)`]: {
      width: "90vw",
    },
  },
  logo: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    animation: "lightBlueAnimationLogin 1s ease-in-out infinite",
    "@keyframes lightBlueAnimationLogin": {
      "0%": {
        transform: "scale(1)",
      },
      "50%": {
        transform: "scale(1.2)",
      },
      "100%": {
        transform: "scale(1)",
      },
    },
  },
  background_div: {
    width: "60vw",
    position: "relative",
    [`@media (max-width: 1200px)`]: {
      width: "60vw",
    },
    [`@media (max-width: 768px)`]: {
      width: "0vw",
    },
  },
  background: {
    position: "absolute",
    top: 0,
    left: "15vw",
    height: "100%",
  },
  back_button: {
    backgroundColor: "transparent",
    border: 0,
    padding: "0px 0px 0px 0px ",
    cursor: "pointer",
  },
  title: {
    fontSize: 30,
    marginBlockStart: " 0em",
    marginBlockEnd: "0em",
    [`@media (max-width: 768px)`]: {
      fontSize: 20,
    },
  },
  step_area: {
    height: "80%",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    flexDirection: "column",
    [`@media (max-width: 768px)`]: {
      height: "45vh",
    },
  },
  input_label: {
    color: "white",
    marginBottom: 7,
    fontSize: 16,
  },
  input: {
    backgroundColor: "transparent",
    borderRadius: 30,
    border: "1px solid rgba(255, 255, 255, 0.4)",
    height: 60,
    color: "white",
    fontSize: 18,
    padding: "0px 29px",
  },
  textarea: {
    padding: "15px 29px",
    fontSize: 18,
    backgroundColor: "transparent",
    borderRadius: 30,
    border: "1px solid rgba(255, 255, 255, 0.4)",
    color: "white",
    minHeight: "40vh",
  },
  mobile_area: {
    height: "100%",
    [`@media (max-width: 768px)`]: {
      background: "rgba(0, 0, 0, 0.5)",
      padding: 20,
      borderRadius: 33,
      marginTop: 20,
      height: "65%",
    },
  },
}));
export default CreateCreatorPage;

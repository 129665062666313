import Layout from "../../high-level/layout/layout";
import { Flex, Loader } from "@mantine/core";

const Loading = ({
  right = true,
  headerFixed = true,
  headerTitle,
  padding,
}: any) => {
  return (
    <Layout
      right={right}
      headerFixed={headerFixed}
      headerTitle={headerTitle}
      padding={padding}
    >
      <Flex
        align="center"
        justify="center"
        style={{ width: "100%", height: "100vh" }}
      >
        <Loader variant="bars" color="#D2E951" />
      </Flex>
    </Layout>
  );
};

export default Loading;

import { Navigate, Outlet } from "react-router-dom";

export const UnprotectedLayout = (props: any) => {
  const { user } = props;
  if(user) {
    return <Navigate to="/my/feed" />
  }
  return (
    <>
      <Outlet />
    </>
  );
};

import {
  Avatar,
  Box,
  Button,
  Flex,
  Text,
  Title,
  createStyles,
} from "@mantine/core";
import { useUpdateEvent } from "../../../api/event/service";
import { eventEnum } from "../../../utils/enums/eventEnum";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { formatProfileDate } from "../../../utils/formatProfileDate";
import { eventDateFormat } from "../../../utils/eventDateFormat";
import moment from "moment-timezone";

const EventCard = ({ event, user, translation }: any) => {
  const { classes } = useStyles();
  const { updateEvent } = useUpdateEvent();
  const [startEvent, setStartEvent] = useState(false);
  useEffect(() => {
    if (event) {
      const startTime: any = new Date(event.startTime);
      const now: any = new Date();
      const _remainingTime = startTime - now;
      if (_remainingTime <= 0) {
        setStartEvent(true);
      } else {
        setStartEvent(false);
      }
    }
  }, [event]);
  const join = async () => {
    const data = {
      id: event.id,
      registered_users: [user?.data?.attributes?.userProfile?.id],
      type: eventEnum.JOIN_USER,
    };
    await updateEvent({ newData: data });
  };

  return (
    <Link
      to={event.isOwner || event.joined ? `/my/events/${event.id}` : ""}
      style={{
        textDecoration: "none",
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box
        style={{
          borderRadius: 20,
          border: event.isOnline && "1px solid rgba(233, 127, 81, 1)",
        }}
        mr={4}
        w="100%"
        c="white"
        className={`${classes.box} ${
          (event.joined || event.isOwner) && classes.box_joined
        }`}
      >
        <Flex p="8px" w="100%" align="flex-start" h={"91px"}>
          <Flex direction="column" mr={4}>
            <Avatar
              variant="outline"
              size={40}
              className={`${!!event?.avatar && classes.avatar_animation}`}
              src={event?.avatar?.thumbs?.c144}
              radius={13}
              alt="Random image"
            />
            {event.isOnline && (
              <div className={classes.live_box}>
                Live<div className={classes.live_dot}></div>
              </div>
            )}
          </Flex>
          <div
            style={{
              display: "flex",
              marginLeft: 4,
              flexDirection: "column",
              height: "100%",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div className={classes.creator_info_area}>
              <Title size={14}>
                {event?.title.length > 15
                  ? event?.title.slice(0, 15) +
                    "..." +
                    "'s " +
                    translation.event.toLowerCase()
                  : event?.title + "'s " + translation.event.toLowerCase()}
              </Title>
              <Text className={classes.username} size={12} ta="left">
                {"@" + event?.owner_username}
              </Text>
              <div className={classes.connect_area}>
                <div
                  className={classes.connect_images_area}
                  style={{ width: event?.otherCreator.length * 22 }}
                >
                  {event?.otherCreator.length > 0 &&
                    event?.otherCreator?.map((x: any, index: number) => (
                      <div
                        className={classes.connect_img_area}
                        style={{
                          position: index !== 0 ? "absolute" : "relative",
                          left: index * 15,
                          zIndex: event?.otherCreator.length - index,
                        }}
                      >
                        <Avatar radius="100%" size="100%" src={x.thumbs?.c50} />
                      </div>
                    ))}
                </div>

                {event?.number_of_user !== 0 && (
                  <Text className={classes.connect_friends} color="#ABABB8">
                    {event?.number_of_user}{" "}
                    {translation.event_page.friends_connect}
                  </Text>
                )}
              </div>
              {!startEvent && (
                <div className={classes.date}>
                  <Text color="#e5e5ee" fz={12}>
                    {moment
                      .utc(event.startTime)
                      .tz(moment.tz.guess())
                      .format("DD-MM-YYYY HH:mm")}
                  </Text>
                </div>
              )}
            </div>
          </div>
          {!event.joined && !event.isOwner && (
            <div
              style={{
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Button
                styles={{ root: { paddingLeft: 0, paddingRight: 0 } }}
                className={classes.join_button}
                onClick={join}
              >
                <Flex
                  direction="row"
                  align="center"
                  justify="center"
                  gap={5}
                  pl={5}
                  pr={5}
                >
                  <Text color="black" fz={12}>
                    {startEvent ? "Join" : "Register"}
                  </Text>

                  {/* <Image
                    width={19}
                    height={19}
                    src={require("../../../assets/amount.png")}
                  />
                  <Text color="black" fz={12}>
                    10
                  </Text>*/}
                </Flex>
              </Button>
            </div>
          )}
        </Flex>
      </Box>
    </Link>
  );
};
const useStyles = createStyles((theme) => ({
  creator_info_area: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  date: {},
  username: {
    color: "#C9C9C9",
  },
  join_button: {
    fontSize: 12,
    height: "50%",
    padding: 4,
    borderRadius: 20,
    backgroundColor: theme.colors.green[0],
  },
  connect_images_area: {
    position: "relative",
    display: "flex",
    width: 60,
  },
  connect_img_area: {
    width: 22,
    height: 22,
    border: "1px solid #5E6DA1",
    borderRadius: "50%",
    backgroundColor: "white",
  },
  connect_area: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  connect_friends: {
    fontSize: 11,
    marginLeft: 5,
    [`@media (max-width: 1280px)`]: {
      fontSize: 13,
    },
    [`@media (max-width: 1100px)`]: {
      fontSize: 11,
    },
  },
  box: {
    background: "rgba(255,255,255,.2)",
    minHeight: 91,
  },
  box_joined: {
    background:
      "linear-gradient(280deg, rgba(121,159,21,.3) 0%, rgba(210,233,81,.3) 100%)",
  },
  avatar_animation: {
    opacity: 0,
    animationName: "avatarAnimation",
    animationDuration: ".3s",
    animationTimingFunction: "linear",
    animationFillMode: "both",
    "@keyframes avatarAnimation": {
      "0%": {
        opacity: 0,
      },

      "100%": {
        opacity: 1,
      },
    },
  },
  live_box: {
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    borderRadius: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 4,
    padding: 4,
    fontSize: 14,
  },
  live_dot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginLeft: 4,
    background: "linear-gradient(132.14deg, #E95151 9.16%, #F86137 92.2%)",
  },
}));
export default EventCard;

const VoiceIcon = ({ active, white }: any) => {
  return (
    <svg
      width={active ? 13 : 5}
      height={active ? 18 : 5}
      viewBox="0 0 13 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 2.66667C3 1.19391 4.34315 0 6 0C7.65685 0 9 1.19391 9 2.66667V8C9 9.47276 7.65685 10.6667 6 10.6667C4.34315 10.6667 3 9.47276 3 8V2.66667Z"
        fill="#FFFFFF"
      />
      <path
        d="M1.5 7.68251C1.5 7.31432 1.16421 7.01584 0.75 7.01584C0.335786 7.01584 0 7.31432 0 7.68251V7.99997C0 10.7197 2.29027 12.964 5.25 13.292V14.6667H3.75C3.33579 14.6667 3 14.9651 3 15.3333C3 15.7015 3.33579 16 3.75 16H8.25C8.66421 16 9 15.7015 9 15.3333C9 14.9651 8.66421 14.6667 8.25 14.6667H6.75V13.292C9.70973 12.964 12 10.7197 12 7.99997V7.68251C12 7.31432 11.6642 7.01584 11.25 7.01584C10.8358 7.01584 10.5 7.31432 10.5 7.68251V7.99997C10.5 10.2091 8.48528 12 6 12C3.51472 12 1.5 10.2091 1.5 7.99997V7.68251Z"
        fill="#FFFFFF"
      />
    </svg>
  );
};

export default VoiceIcon;

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getAppSettings, getNotificationEmail, updateNotificationSettings } from "../api";

export const useGetAppSettings = () => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["app-settings"],
    queryFn: () => getAppSettings(),
    staleTime: 3600000,
    // retry:false,
    // refetchOnMount:false,
    // refetchOnWindowFocus:false,
    // select: (res) => res,
  });

  return { data, isLoading, isError, error };
};

export const useGetNotificationEmailSetting = () => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["notification-email"],
    queryFn: () => getNotificationEmail(),
    staleTime: 3600000,
    // retry:false,
    // refetchOnMount:false,
    // refetchOnWindowFocus:false,
    // select: (res) => res,
  });

  return { data, isLoading, isError, error };
};

export const useUpdateNotificationSettings = () => {
  const queryClient = useQueryClient();

  const {
    isLoading: updateLoading,
    error: errorUpdateing,
    mutate: updateNotifctnSettings,
  } = useMutation(( data : any) => updateNotificationSettings(data), {
    onSuccess: async (data: any) => {
      queryClient.invalidateQueries({ queryKey: ["notification-email"] });
    }
  });

  return { updateLoading, errorUpdateing, updateNotifctnSettings };
};
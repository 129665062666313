import {  Navigate, useOutlet } from "react-router-dom";
import {  NovuProvider } from "@novu/notification-center";
import NotificationComp from "../../low-level/Notification/Notification";

export const ProtectedLayout = ({user,translation}: any) => {

  const outlet = useOutlet();
  if (!user) {
    return <Navigate to="/" />;
  }


  
  const novuIdentifier = process.env.REACT_APP_NOVU_IDENTIFIER || "ZTrgWomSqZAY"

  return (
    <>
      <NovuProvider
        subscriberId={user.id.toString()}
        applicationIdentifier={novuIdentifier} // applicationIdentifier
        initialFetchingStrategy={{
          fetchNotifications: true,
        }}
      >
          <NotificationComp  translation={translation}/>
          {outlet}
      </NovuProvider>
    </>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from "react";
import {
  Text,
  Title,
  createStyles,
  Avatar,
  Flex,
  Transition,
} from "@mantine/core";
import { NavLink } from "react-router-dom";
import { ICreatorProfile } from "../../../interfaces/creator-profile.interface";
import { useViewportSize } from "@mantine/hooks";
import { getBase64FromUrl } from "../../../utils/getBase64FromUrl";
import { RootState } from "../../../store/store";
import { useAppSelector } from "../../../store/hooks";

const CreatorCard = ({ creator }: { creator: ICreatorProfile }) => {
  const { width } = useViewportSize();
  const user: any = useAppSelector((state: RootState) => state.user.user);
  const { classes } = useStyles();
  const [img, setImg] = useState<null | string>(null);

  const handleImageLoad = useCallback(async (url: string) => {
    if (!url) return;

    const img = await getBase64FromUrl(url);
    if (img && typeof img === "string") {
      setImg(img);
    }
  }, []);

  const creatorCardAndUserIsSame =
    user.attributes?.creatorProfile?.id === creator?.id;

  useEffect(() => {
    if (!creator.attributes?.avatarThumbs?.thumbs?.c144) return;
    handleImageLoad(creator.attributes?.avatarThumbs?.thumbs?.c144);
  }, [creator]);

  return (
    <>
      {/* <Transition
        mounted={img !== null}
        transition="fade"
        duration={1000}
        timingFunction="ease"
      > */}
      {/* {(styles) => ( */}
      <Flex
        direction="column"
        // style={styles}
      >
        <div
          className={classes.card_box}
          style={{
            paddingBottom: creator.attributes?.about?.length > 0 ? 30 : 4,
          }}
        >
          <Flex direction="row" justify="space-between" mb={10}>
            <div
              className={classes.creator_profile}
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                border: 0,
                flexDirection: "column",
                textDecoration: "none",
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
            >
              <Flex direction="row" justify="space-between" w="100%">
                <NavLink
                  style={{
                    display: "flex",
                    textDecoration: "none",
                    color: "white",
                  }}
                  to={
                    creatorCardAndUserIsSame
                      ? `/my/profile`
                      : `/${creator.attributes?.username}`
                  }
                >
                  <Transition
                    mounted={img !== null}
                    transition="fade"
                    duration={2000}
                    timingFunction="ease"
                  >
                    {(styles) => (
                      <Avatar
                        variant="outline"
                        style={styles}
                        classNames={{
                          root: classes.avatar,
                          image: classes.creator_avatar,
                        }}
                        radius={14}
                        src={width < 768 ? img : img}
                        alt="Random image"
                      />
                    )}
                  </Transition>
                  <Flex
                    direction="column"
                    className={classes.creator_name_area}
                  >
                    <div className={classes.creator_info_area}>
                      <Title size={14}>{creator?.attributes?.name}</Title>
                      <Text className={classes.username} size={12} ta="left">
                        {"@" + creator.attributes?.username}
                      </Text>
                    </div>
                  </Flex>
                </NavLink>
                {/* <ActionIcon onClick={goChat} size={50} variant="transparent">
                      <Image src={require("../../../assets/chat_icon.png")} />
                    </ActionIcon> */}
              </Flex>
              {creator.attributes?.about?.length > 0 && (
                <Flex
                  className={classes.creator_content_area}
                  direction="column"
                  w={"100%"}
                  style={{ textAlign: "left" }}
                  mt={13}
                >
                  <Text lineClamp={4} size={16} w="90%">
                    {creator.attributes?.about}
                  </Text>
                </Flex>
              )}
            </div>
          </Flex>
          <Flex
            className={classes.creator_content_area_mobile}
            direction="column"
            justify="space-between"
          >
            <div className={classes.navLink}>
              {creator.attributes?.about?.length > 0 && (
                <Text mb={10} lineClamp={3} lh={1.2}>
                  {creator.attributes?.about}
                  {/* {isOpenReadMore
                          ? creator.attributes?.about
                          : creator.attributes?.about?.substring(0, 170) +
                            (creator.attributes?.about?.length > 170
                              ? "..."
                              : "")} */}
                </Text>
              )}
            </div>
            {/* <Flex justify="flex-end">
                  {creator.attributes?.about?.length > 20 && (
                    <button
                      style={{
                        margin: "7px",
                        backgroundColor: "transparent",
                        color: "#D2E951",
                        border: 0,
                        fontSize: 15,
                      }}
                      onClick={showReadMore}
                    >
                      {isOpenReadMore ? "Collapse info" : "Read More"}
                    </button>
                  )}
                </Flex> */}
          </Flex>
        </div>
      </Flex>
      {/* )} */}
      {/* </Transition> */}
    </>
  );
};
const useStyles = createStyles((theme) => ({
  card_box: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    textDecoration: "none",
    paddingBottom: 30,
    color: "white",
    backdropFilter: "blur(20px)",
    zIndex: 9,
    borderRadius: 20,
  },
  avatar: {
    height: 87,
    width: 87,
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      height: 62,
      width: 62,
      minWidth: 62,
      borderRadius: "50%",
    },
  },
  creator_avatar: {
    objectPosition: "50% 0%",
  },
  creator_profile: {
    margin: "14px 10px 0px 14px",
    color: "white",
  },
  creator_name_area: {
    marginLeft: 12,
    height: "100%",
    justifyContent: "space-arround",
    display: "flex",
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  username: {
    color: "#C9C9C9",
  },
  creator_content_area: {
    height: "75%",
    display: "flex",
    justifyContent: "center",
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      display: "none",
    },
  },
  navLink: {
    color: "white",
    textDecoration: "none",
  },
  creator_info_area: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
  },
  creator_content_area_mobile: {
    display: "none !important",
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      display: "flex !important",
      margin: "7px 22px",
    },
  },
  chat_button_area: {
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    background: "linear-gradient(to right, red, purple)",
  },
  chat_button: {
    backgroundColor: "white",
    width: 40,
    height: 40,
    borderRadius: "50%",
  },
}));
export default CreatorCard;

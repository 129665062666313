import React from "react";
import { createStyles, Text, Button, Image } from "@mantine/core";
import {  useNavigate } from "react-router-dom";
import AuthLayout from "../../high-level/layout/authLayout";
const LoginMobileFrontPage = (props: any) => {
  const navigate = useNavigate();
  const { classes } = useStyles();

  const showLogin = () => {
    props.setGoLoginAnimation(true);
    setTimeout(() => props.setShowLogin(true), 500);
  };

  const goRegister = () => {
    props.setGoLoginAnimation(true);

    setTimeout(() => {
      navigate("/register");
      props.setShowLogin(true);
    }, 500);
  };

  return (
    <AuthLayout pageName="loginMobile">
      <div style={{ display: "block" }}>
        <div className={classes.main_container}>
          <div className={classes.background_light_blue}></div>
          <div className={classes.background_light_green}></div>
          <div
            className={`${classes.image_area} ${
              props.goLoginAnimation && classes.login_area_animation
            }`}
          >
            <Image src={require("../../../assets/login_image_mobile.png")} />
          </div>
          <div
            className={`${classes.login_area_container} ${
              props.goLoginAnimation && classes.login_area_animation
            }`}
          >
            <div className={classes.login_area}>
              <div className={classes.login_area_bottom}>
                <Text fz="42px" fw={500} ta="center" mb={50}>
                  Verführerisch. Sinnlich. Willkommen!
                </Text>
           
                <Text mb={50} ta="center" fw={400} >
                Erlebe die sinnliche Welt der Erotik und lass dich verführen.
                </Text>
                <Button
                  onClick={showLogin}
                  mb={20}
                  classNames={{ root: classes.login_button }}
                >
                 Schon einen Account? Log dich ein!
                </Button>
                <Button
                  mb={43}
                  classNames={{ root: classes.sign_up_button }}
                  onClick={goRegister}
                >
                  Keinen Account? Registriere dich!
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};
const useStyles = createStyles((theme) => ({
  main_container: {
    width: "100vw",
    height: "100%",
    display: "flex",
    zIndex: 99,
    flexDirection: "column",
    overflow: "hidden",
    overflowX: "hidden",
    position: "relative",
    backgroundColor: "#1E1E1E",
  },
  login_area_animation: {
    animationName: "slideOutUp",
    animationDuration: "0.5s",
    animationFillMode: "both",
    "@keyframes slideOutUp": {
      "0%": {
        transform: "translateY(0)",
      },
      "100%": {
        transform: "translateY(-100%)",
      },
    },
  },
  image_area: {
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 0,
  },
  login_area_container: {
    height: "100%",
    color: "white",
    width: "100%",
    zIndex: 9,
  },
  login_area: {
    width: "85%",
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    height: "100%",
    justifyContent: "center",
    [theme.fn.smallerThan("sm")]: {
      width: "100%",
      alignItems: "center",
    },
  },

  login_button: {
    width: "100%",
    backgroundColor: theme.colors.green[0],
    color: "black",
    borderRadius: 75,
    height: 59,
    zIndex: 20,
    transition: "all .5s",
    "&:hover": {
      transform: "scale(1.02)",
      transition: "all .5s",
    },
  },
  sign_up_button: {
    backgroundColor: "transparent",
    height: 59,
    width: "100%",
    borderRadius: 75,
    zIndex: 20,
    color: theme.colors.green[0],
    border: `1px solid ${theme.colors.green[0]}`,
  },
  login_area_top: {
    height: "20%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.fn.smallerThan("sm")]: {
      a: {
        display: "none",
        height: "10vh",
      },
      height: "20%",
      width: "90%",
      alignItems: "flex-start",
      justifyContent: "left",
      backgroundColor: "transparent",
    },
  },
  login_area_bottom: {
    height: "100%",
    width: "90%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },

  background_light_green: {
    backgroundColor: theme.colors.green[0],
    width: 576,
    height: 576,
    position: "absolute",
    right: "40%",
    bottom: 0,
    opacity: 0.6,
    filter: "blur(225px)",
    transform: "rotate(-15deg)",
    [theme.fn.smallerThan("sm")]: {
      width: 204,
      height: 204,
      filter: "blur(100px)",
      right: -30,
      top: "35%",
      opacity: 1,
    },
  },
  background_light_blue: {
    backgroundColor: theme.colors.blue[0],
    width: 576,
    height: 576,
    position: "absolute",
    left: 0,
    top: 0,
    opacity: 0.6,
    filter: "blur(225px)",
    transform: "rotate(-15deg)",
    [theme.fn.smallerThan("sm")]: {
      width: 204,
      height: 204,
      filter: "blur(100px)",
      left: 10,
      top: 10,
      opacity: 1,
    },
  },
}));
export default LoginMobileFrontPage;

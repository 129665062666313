const MessagesIcon = ({ active, color="white" }: any) => {
  return (
    <svg
    width={active ? 25 : 5}
    height={active ? 23 : 5}
      viewBox="0 0 18 16"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.80047 0.334958C4.50273 0.113953 6.23833 0 8.00023 0C9.76197 0 11.4974 0.11393 13.1995 0.334892C14.7767 0.539645 15.9002 1.86204 15.9937 3.39277C15.7263 3.3035 15.443 3.24427 15.1467 3.21968C14.0074 3.12511 12.8552 3.07692 11.6923 3.07692C10.5293 3.07692 9.37725 3.12511 8.23793 3.21967C6.30294 3.38027 4.92308 5.01716 4.92308 6.85801V10.3743C4.92308 11.7615 5.7064 13.0319 6.91922 13.6434L4.74284 15.8197C4.56684 15.9957 4.30215 16.0484 4.0722 15.9531C3.84224 15.8579 3.69231 15.6335 3.69231 15.3846V12.0784C3.39404 12.0464 3.09676 12.0112 2.80048 11.9727C1.15254 11.7588 0 10.3248 0 8.7078V3.5999C0 1.98295 1.15254 0.54891 2.80047 0.334958Z"
        fill={color}
      />
      <path
        d="M11.6923 4.30769C10.5634 4.30769 9.44523 4.35447 8.33974 4.44622C7.07673 4.55105 6.15385 5.62282 6.15385 6.85801V10.3743C6.15385 11.6114 7.07936 12.6837 8.34397 12.787C9.36456 12.8703 10.3959 12.9158 11.4366 12.9223L13.7187 15.2044C13.8947 15.3804 14.1594 15.433 14.3893 15.3378C14.6193 15.2425 14.7692 15.0181 14.7692 14.7692V12.8083C14.8598 12.8015 14.9502 12.7944 15.0406 12.787C16.3052 12.6838 17.2308 11.6115 17.2308 10.3744V6.85802C17.2308 5.62283 16.3079 4.55106 15.0449 4.44623C13.9394 4.35447 12.8212 4.30769 11.6923 4.30769Z"
        fill={color}
      />
    </svg>
  );
};

export default MessagesIcon;

import { useEffect, useState } from "react";
import { useSocket, SocketContextType } from "../../../contexts/socketContext";
import { useNavigate } from "react-router-dom";
import { useGetUser } from "../../user/service";
import { useGetEvents } from "../../event/service";
import { useQueryClient } from "@tanstack/react-query";

// Info: Falls mal alle chats in die man gehört getrackt werden, sollte
// man dieses in ein kontext objekt umwandeln und auf dem server sollte man
// alle chat in die man gehört durchgehen und in einen socket channel
// einbinden bei jedem socket connect

interface OnlineChatMember {
  userId: string;
  profileId: number;
  socketId: string;
  joinedAt: string;
  isOnline: boolean;
}

interface OnlineChatMembers {
  [chatId: number]: {
    chatId: number;
    members: OnlineChatMember[];
  };
}

interface UserChatResponse {
  chatId: number;
  profileId: number;
  userId: string;
  socketId: string;
  joinedAt: string;
  isOnline: boolean;
}

interface IExcludeOnlineChatMember {
  message: string;
}

export const useOnlineChatMembersService = (): OnlineChatMembers => {
  const { socket } = useSocket() as SocketContextType;
  const [members, setMembers] = useState<OnlineChatMembers>({});
  const navigate = useNavigate();
  const { data: user } = useGetUser();
  const { refetch } = useGetEvents();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!socket) return;

    const handleChatMembers = (response: {
      chatId: number;
      members: OnlineChatMember[];
    }) => {
      setMembers((prevMembers) => ({
        ...prevMembers,
        [response.chatId]: response,
      }));
    };

    const updateOnlineChatMemberData = (response: UserChatResponse) => {
      const { chatId, profileId, userId, socketId, joinedAt, isOnline } =
        response;
      setMembers((prevMembers) => {
        const updatedMembers = { ...prevMembers };
        if (updatedMembers[chatId]) {
          const existingMemberIndex = updatedMembers[chatId].members.findIndex(
            (member) => member.userId === userId
          );
          if (existingMemberIndex !== -1) {
            // Benutzer bereits vorhanden, aktualisiere den Eintrag
            updatedMembers[chatId].members[existingMemberIndex] = {
              userId,
              profileId,
              socketId,
              joinedAt,
              isOnline: isOnline,
            };
          } else {
            // Benutzer nicht vorhanden, füge ihn hinzu
            updatedMembers[chatId].members.push({
              userId,
              profileId,
              socketId,
              joinedAt,
              isOnline: isOnline,
            });
          }
        } else {
          // Chat noch nicht vorhanden, erstelle einen neuen
          updatedMembers[chatId] = {
            chatId: chatId,
            members: [
              {
                userId,
                profileId,
                socketId,
                joinedAt,
                isOnline: isOnline,
              },
            ],
          };
        }
        return updatedMembers;
      });
    };

    const excludeChatMember = (response: {
      chatId: number;
      member: OnlineChatMember & IExcludeOnlineChatMember;
    }) => {
      console.log("User kicked / banned - excluded");
      if (response.member.userId === user?.data?.attributes?.userProfile?.id) {
        navigate("/my/feed");
        refetch();
      }
    };

    const eventFinish = (response: {
      chatId: number;
      member: OnlineChatMember & IExcludeOnlineChatMember;
    }) => {
      navigate("/my/feed");
      refetch();
    };

    const eventLive = (response: any) => {
      queryClient.invalidateQueries({ queryKey: ["event-", response.data.id] });
    };

    socket.on("chatMembers", handleChatMembers);
    socket.on("updateChatMember", updateOnlineChatMemberData);
    socket.on("onEventLive", eventLive);
    socket.on("excludeChatMember", excludeChatMember);
    socket.on("eventFinish", eventFinish);

    return () => {
      socket.off("chatMembers", handleChatMembers);
      socket.off("updateChatMember", updateOnlineChatMemberData);
    };
  }, [socket]);

  return members;
};

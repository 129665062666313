import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  createCreatorFn,
  // createPostFn,
  getCreatorDetailFn,
  getCreatorPostsFn,
  getCreatorPublicPostsFn,
  getPost,
  getPublicCreatorDetailFn,
  upadateProfileFn,
  updatePostFn,
} from "../api";
import { useNavigate } from "react-router-dom";

export const useGetCreatorPosts = (username: string) => {
  const queryClient = useQueryClient();

  const { data, isLoading, isError, error, fetchNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ["creatorPosts-", username],
      queryFn: ({ pageParam }) => getCreatorPostsFn(username, pageParam),
      getNextPageParam: (lastPage: any, allPages) => {
        const nextPage =
          lastPage?.data?.creatorPosts?.length === 6
            ? allPages?.length * 6
            : undefined;
        return nextPage;
      },
      staleTime: 3600000,
      onSuccess: async (newData: any) => {
        queryClient.invalidateQueries({ queryKey: ["chats"] });
      },
    });

  return { data, isLoading, isError, error, fetchNextPage, isFetchingNextPage };
};

export const useCreateCreator = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const {
    data,
    isLoading,
    isError,
    error,
    isSuccess,
    isIdle,
    mutate: createCreator,
  } = useMutation(({ avatar, user }: any) => createCreatorFn(avatar, user), {
    onSuccess: async (newData: any) => {
      navigate("/my/feed");
      queryClient.invalidateQueries({ queryKey: ["user"] });
    },
  });

  return { data, isLoading, isIdle, isSuccess, isError, error, createCreator };
};

// export const useCreatePost = (username: string) => {
//   const queryClient = useQueryClient();

//   // let intervalMs = 1000
//   const {
//     data,
//     isLoading,
//     isError,
//     error,
//     isSuccess,
//     isIdle,
//     mutate: createPost,
//   } = useMutation(({ audio, newData }: any) => createPostFn(audio, newData), {
//     onMutate: async (newTodo) => {
//       // Cancel any outgoing refetches
//       // (so they don't overwrite our optimistic update)
//       await queryClient.cancelQueries({ queryKey: ["creatorPosts-", username] })

//       // Snapshot the previous value
//       const previousTodo = queryClient.getQueryData(["creatorPosts-", username])

//       // Optimistically update to the new value
//       queryClient.setQueryData(["creatorPosts-", username], newTodo)

//       // Return a context with the previous and new todo
//       return { previousTodo, newTodo }
//     },
//     onSettled: async (newData: any) => {
//       queryClient.invalidateQueries({ queryKey: ["creatorPosts-", username] });
//       queryClient.invalidateQueries({ queryKey: ["creatorProfile-", username] });
//       // Optimistic update yapılabilir
//     },
//   });

//   return { data, isLoading, isIdle, isSuccess, isError, error, createPost };
// };

export const useGetCreatorPublicPosts = (username: string) => {
  const { data, isLoading, isError, error, fetchNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ["publicCreatorPosts-", username],
      queryFn: ({ pageParam }) => getCreatorPublicPostsFn(username, pageParam),
      getNextPageParam: (lastPage: any, allPages) => {
        const nextPage =
          lastPage?.data?.creatorPosts?.length === 6
            ? allPages?.length * 6
            : undefined;
        return nextPage;
      },
      staleTime: 3600000,
    });

  return { data, isLoading, isError, error, fetchNextPage, isFetchingNextPage };
};

export const useGetCreatorDetail = (username: string) => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["creatorProfile-", username],
    queryFn: () => getCreatorDetailFn(username),
    staleTime: 3600000,
    onSuccess: async (data: any) => {
      // queryClient.invalidateQueries({ queryKey: ["chats"] });
      // queryClient.refetchQueries({queryKey:['chats']});
    },
    // retry:false,
    // refetchOnMount:false,
    // refetchOnWindowFocus:false,
    // select: (res) => res,
  });

  return { data, isLoading, isError, error };
};

export const useGetPublicCreatorDetail = (username: string) => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["publicCreatorProfile-", username],
    queryFn: () => getPublicCreatorDetailFn(username),
    staleTime: 3600000,
    // retry:false,
    // refetchOnMount:false,
    // refetchOnWindowFocus:false,
    // select: (res) => res,
  });

  return { data, isLoading, isError, error };
};
export const useGetPost = (id: string) => {
  // let intervalMs = 1000
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["post", id],
    queryFn: () => getPost(id),
    // staleTime: 3600000,
    // refetchInterval: intervalMs,
    // retry:false,
    // refetchOnMount:false,
    // refetchOnWindowFocus:false,
    // select: (res) => res,
  });

  return { data, isLoading, isError, error };
};

export const useUpdatePost = (username: string) => {
  const queryClient = useQueryClient();

  const {
    isLoading: updateLoading,
    error: errorUpdateing,
    mutate: mutateUpdatePost,
  } = useMutation(({ id, data }: any) => updatePostFn(id, data), {
    onSuccess: async (data: any) => {
      queryClient.invalidateQueries({ queryKey: ["creatorPosts-", username] });
      window.location.reload();
    },
  });

  return { updateLoading, errorUpdateing, mutateUpdatePost };
};

export const useUpdatePostListen = () => {
  const {
    isLoading: updateLoading,
    error: errorUpdateing,
    mutate: mutateUpdatePost,
  } = useMutation(({ id, data }: any) => updatePostFn(id, data), {});

  return { updateLoading, errorUpdateing, mutateUpdatePost };
};

export const useUpdateProfile = () => {
  const queryClient = useQueryClient();

  const {
    isLoading: updateLoading,
    error: errorUpdateing,
    isError: updateError,
    status: statusUpdate,
    isSuccess: updateSuccess,
    mutate: mutateUpdateProfile,
  } = useMutation((user: any) => upadateProfileFn(user), {
    onSuccess: async (data: any) => {
      queryClient.invalidateQueries({ queryKey: ["user"] });
      queryClient.invalidateQueries({ queryKey: ["feedData"] });
      queryClient.invalidateQueries({
        queryKey: ["creatorProfile-", data.username],
      });
    },
  });

  return {
    updateLoading,
    updateSuccess,
    statusUpdate,
    updateError,
    errorUpdateing,
    mutateUpdateProfile,
  };
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ICreatorsState {
  limit: number;
  start: number;
  creatorPosts:any
}

const initialState: ICreatorsState = {
  limit:5,
  start:0,
  creatorPosts:[]
};

export const slice = createSlice({
  name: "creators",
  initialState: initialState,
  reducers: {
 
    setLoading: (state: any, action: PayloadAction<any>) => {
      state.usernameCheck = action.payload;
    },
    setUsernameCheck: (state: any, action: PayloadAction<any>) => {
      state.usernameCheck = action.payload;
    },
    setUsernameSuggestCheck: (state: any, action: PayloadAction<any>) => {
      state.usernameSuggestCheck = action.payload;
    },
   
  
    setUploadFileProcess: (state: any, action: PayloadAction<number>) => {
      state.uploadFilePercentage = action.payload;
    },
    setCreatorPosts: (state: any, action: PayloadAction<any>) => {
      state.creatorPosts = action.payload;
    },
 
  },
});

export const {
  setUploadFileProcess,
  setCreatorPosts,
  setUsernameCheck,
  setLoading,
  setUsernameSuggestCheck,
} = slice.actions;

export default slice.reducer;

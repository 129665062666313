import { Button, Flex, Image, Select, createStyles } from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";

const LandingHeader = ({
  translation,
  user,
  language = false,
  languageState,
  setLanguageState,
}: any) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.container}>
      <Flex w="90%" justify="space-between" align="center">
        <Link to={"/"}>
          <Image
            width={93}
            height={61}
            src={require("../../../assets/logo.png")}
          />
        </Link>
        <Flex align="center" gap={20}>
          {language && (
            <Select
              value={languageState}
              variant="unstyled"
              styles={{
                input: {
                  backgroundColor: "transparent",
                  color: "white",
                },
                label: {
                  backgroundColor: "transparent",
                  color: "white",
                  ":hover": { backgroundColor: "transparent" },
                },
                dropdown: {
                  backgroundColor: "transparent",
                  color: "black",
                  ":hover": { backgroundColor: "transparent" },
                },
                wrapper: {
                  backgroundColor: "transparent",
                  color: "black",
                  ":hover": { backgroundColor: "transparent" },
                },
                item: {
                  "&[data-selected]": { backgroundColor: "transparent",fontWeight:"bold" },
                  "&[data-hovered]": { backgroundColor: "white" ,color:"black"},
                  ":hover": { backgroundColor: "white",color:"black" },
                  color:"white"
                },
                root: {
                  backgroundColor: "transparent",
                  color: "black",
                  border: "1px solid white",
                  paddingLeft: 5,
                  borderRadius: 5,
                  ":hover": { backgroundColor: "transparent" },
                },
              }}
              style={{}}
              onChange={setLanguageState}
              data={[
                { value: "en", label: "English" },
                { value: "de-DE", label: "German" },
              ]}
            />
          )}
          {user != null ? (
            <Button
              onClick={() => navigate("/")}
              radius={60}
              h="60px"
              w={190}
              fz={18}
              fw={400}
              p={10}
              bg="rgba(77, 116, 254, 1)"
              color="white"
            >
              {translation.home}
            </Button>
          ) : (
            <Button
              onClick={() => navigate("/login")}
              radius={60}
              h="60px"
              w={190}
              fz={18}
              fw={400}
              p={10}
              bg="rgba(77, 116, 254, 1)"
              color="white"
            >
              {translation?.landing_page.login}
            </Button>
          )}
        </Flex>
      </Flex>
    </div>
  );
};
const useStyles = createStyles((theme) => ({
  container: {
    height: 85,
    position: "absolute",
    top: 0,
    zIndex: 999,
    color: "white",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
export default LandingHeader;

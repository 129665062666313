import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  showNotification:false,
  notificationSuccessText:""
};

export const slice = createSlice({
  name: "notifications",
  initialState: initialState,
  reducers: {
    setShowNotification: (state: any, action: PayloadAction<boolean>) => {
      state.showNotification = action.payload;
    },
    setNotificationSuccessText: (state: any, action: PayloadAction<string>) => {
      state.notificationSuccessText = action.payload;
    },
    setNotificationErrorText: (state: any, action: PayloadAction<string>) => {
      state.notificationErrorText = action.payload;
    },
  },
});

export const {
    setShowNotification,
    setNotificationSuccessText,
    setNotificationErrorText
} = slice.actions;

export default slice.reducer;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import {
  Text,
  Title,
  Avatar,
  createStyles,
  Flex,
  Button,
  Image,
  Loader,
  Modal,
} from "@mantine/core";
import WaveAudio from "../waveAudio/WaveAudio";
import LockedWaveAudio from "../waveAudio/LockedWaveAudio";
import { IoIosLock } from "react-icons/io";
import { getBase64FromUrl } from "../../../utils/getBase64FromUrl";
import { Transition } from "@mantine/core";
import { User } from "../../../hooks/user.hook";
import { useDisclosure } from "@mantine/hooks";
import { IoClose } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { usePurchaseVoice } from "../../../api/pay/service";
import { RxDotsHorizontal } from "react-icons/rx";

const PublicCreatorCard = ({ data, closeInfo = false,creator }: any) => {
  const { classes } = useStyles();
  const [duration, setDuration] = useState(0);
  const [img, setImg] = useState<null | string>(null);
  const [id, setId] = useState<null | number>(null);

  const handleImageLoad = useCallback(async (url: string) => {
    if (!url) return;

    const img = await getBase64FromUrl(url);
    if (img && typeof img === "string") {
      setImg(img);
    }
  }, []);

  useEffect(() => {
    if (!creator.avatarThumbs) return;
    handleImageLoad(creator?.avatarThumbs?.thumbs?.c144);
  }, [creator, handleImageLoad]);

  return (
    <>
      <div className={classes.card} style={{ marginBottom: 20 }}>
        <Flex
          direction="row"
          className={classes.flex}
          align={"start" }
        >
          <div
            className={`${classes.avatar_area}  ${classes.not_paid_avatar_area} `}
          >
            <Transition
              mounted={img !== null}
              transition="fade"
              duration={600}
              timingFunction="ease"
            >
              {(styles) => (
                <Avatar
                  style={styles}
                  classNames={{
                    image: classes.creator_image,
                    root: classes.avatar_not_paid,
                  }}
                  opacity={0.5}
                  variant="outline"
                  radius={20}
                  src={img}
                  alt="avatar image"
                />
              )}
            </Transition>

            <div className={classes.lock_icon}>
              <IoIosLock size={"100%"} color="white" />
            </div>
          </div>

          <Flex
            className={classes.content_area}
            justify={!data.isPaid ? "start" : "space-between"}
            direction="column"
          >
            {!closeInfo && (
              <Flex direction="row" justify="space-between">
                <Flex direction="column" className={classes.creator_info}>
                  <Title color="white" size={17}>
                    {creator?.name}
                  </Title>
                  <Text color="#C6CCD2" size={13} ta="left">
                    {"@" + creator?.username}
                  </Text>
                </Flex>
              </Flex>
            )}
            <Flex
              w="100%"
              align={"center"}
              justify={"space-between"}
              direction="row"
            >
              <Text
                color={!data.isPaid ? "rgba(255, 255, 255, 0.3)" : "white"}
                mt={7}
                ml={10}
                mb={5}
                fz={16}
                className={classes.creator_desc}
              >
                {data?.text}
              </Text>
            </Flex>
            {data?.assetData && (
              <LockedWaveAudio
                peaks={data?.assetData?.peaks}
                duration={data?.assetData?.duration}
                setDuration={setDuration}
              />
            )}
          </Flex>
        </Flex>
      </div>
      {/*<AudioPlayer
        audioUrl={data?.attributes?.assets?.audio?.url}
        peaks={data?.attributes?.assetData?.peaks}
        duration={data?.attributes?.assetData?.duration}
      /> */}
    </>
  );
};
const useStyles = createStyles((theme) => ({
  card: {
    background: "rgba(255, 255, 255, 0.2)",
    padding: "14px 12px",
    borderRadius: 20,
    [`@media (max-width: 500px)`]: {
      position: "relative",
      padding: "4px 10px",
    },
  },
  creator_image: {
    objectFit: "cover",
    objectPosition: "50% 0%",
  },
  avatar: {
    height: "152px",
    width: "152px",
    [`@media (max-width: 500px)`]: {
      width: "72px",
      height: "72px",
    },
  },
  avatar_not_paid: {
    height: "152px",
    width: "152px",
    [`@media (max-width: 500px)`]: {
      width: "72px",
      height: "72px",
    },
  },
  avatar_area: {
    height: "152px",
    position: "relative",
    width: "152px",
    [`@media (max-width: 500px)`]: {
      width: "72px",
      height: "72px",
    },
  },
  not_paid_avatar_area: {
    height: "152px",
    [`@media (max-width: 500px)`]: {
      height: "72px",
    },
  },
  avatar_area_close_info: {
    height: "100%",
    position: "relative",
    width: 103,
    [`@media (max-width: 500px)`]: {
      width: 98,
    },
  },
  lock_icon: {
    position: "absolute",
    right: -20,
    bottom: -7,
    width: 60,
    height: 60,
    [`@media (max-width: 500px)`]: {
      width: 40,
    },
  },
  flex: {
    [`@media (max-width: 500px)`]: {
      alignItems: "center",
    },
  },
  content_area: {
    marginLeft: 18,
    width: "calc(100% - 184px)",
    [`@media (max-width: 1440px)`]: {
      width: "calc(100% - 100px)",
      marginLeft: 9,
    },
    [`@media (max-width: 950px)`]: {
      width: "calc(100% - 62px)",
    },
  },
  creator_info: {
    [`@media (max-width: 500px)`]: {
      display: "none",
    },
  },
  edit_button: {
    [`@media (max-width: 500px)`]: {
      display: "none",
    },
  },
  edit_button_mobile: {
    display: "none",
    [`@media (max-width: 500px)`]: {
      display: "flex",
    },
  },
  creator_desc: {},
  buy_button_area: {
    display: "flex",
    justifyContent: "flex-end",
    [`@media (max-width: 500px)`]: {
      position: "absolute",
      bottom: -15,
      right: 0,
    },
  },
  buy_button: {
    background: "linear-gradient(90deg, #D2E951 0%, #799F0C 100%)",
    border: 0,
    borderRadius: 13,
    width: 156,
    marginTop: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 20,
    height: 37,
    [`@media (max-width: 500px)`]: {
      width: 111,
      height: 35,
      fontSize: 16,
      borderRadius: 8,
    },
  },
  star_image: {
    width: "30px !important",
    height: "30px !important",
    [`@media (max-width: 500px)`]: {
      width: "22px !important",
      height: "22px !important",
    },
  },
  modal_body: {
    backgroundColor: theme.colors.blue[0],
    padding: "45px",
    [`@media (max-width: 768px)`]: {
      borderRadius: "33px",
    },
    [`@media (max-width: 500px)`]: {
      borderRadius: "33px 33px 0px 0px",
    },
  },
  modal_inner: {
    [`@media (max-width: 500px)`]: {
      padding: 0,
      alignItems: "flex-end",
    },
  },
  yes_delete_button: {
    backgroundColor: theme.colors.green,
    color: "black",
    width: 220,
    height: 50,
    borderRadius: "50px",
    transition: "all .2s",
    "&:hover": {
      transition: "all .5s",
      transform: "scale(1.1)",
    },
    [`@media (max-width: 500px)`]: {
      width: "100%",
    },
  },
  delete_image: {
    width: 140,
    [`@media (max-width: 500px)`]: {
      width: 88,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  delete_modal_text: {
    marginBottom: 30,
    marginTop: 30,
    [`@media (max-width: 500px)`]: {
      marginBottom: 10,
      marginTop: 10,
    },
  },
  close: {
    position: "absolute",
    zIndex: 999,
    right: 30,
    top: 30,
    backgroundColor: "transparent",
    border: "0px",
    padding: 0,
    cursor: "pointer",
  },
}));

export default PublicCreatorCard;

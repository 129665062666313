import { Flex } from "@mantine/core";
import WaveAudio from "./WaveAudio";

const LockedWaveAudio = ({peaks,duration,setDuration}: any) => {
  return (
    <Flex
      align="center"
      justify="center"
      style={{ width: "100%" }}
    >
      <WaveAudio
        disabled={true}
        interact={false}
        setDuration={setDuration}
        peaks={peaks}
        duration={duration}
      />
    </Flex>
  );
};

export default LockedWaveAudio;

import { Button, Flex, Image, Text, Title, createStyles } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import StepsButton from "../steps/StepsButton";
import { useViewportSize } from "@mantine/hooks";

const CreateCreatorPreFaceMobile = ({ translation }: any) => {
  const [step, setStep] = useState(1);
  var steps = 3;
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { height } = useViewportSize();

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  const nextStep = () => {
    if (step !== steps) {
      setStep(step + 1);
    } else {
      navigate("/creator/create");
    }
  };
  const content = [
    {
      id: 1,
      title: translation?.create_creator_page.create_creator_pre_title_1,
      text: translation?.create_creator_page.create_creator_pre_text_1,
    },
    {
      id: 2,
      title: translation?.create_creator_page.create_creator_pre_title_2,
      text: translation?.create_creator_page.create_creator_pre_text_2,
    },
    {
      id: 3,
      title: translation?.create_creator_page.create_creator_pre_title_3,
      text: translation?.create_creator_page.create_creator_pre_text_3,
    },
  ];

  return (
    <div className={classes.main} style={{ height: height }}>
      <Image
        width="100vw"
        height="100vh"
        src={require(`../../../assets/creator/create_creator_${step}.webp`)}
      />
      <div className={classes.header}>
        <Flex>
          {content.map((x: any, index: number) => (
            <div
              key={index}
              className={classes.steps}
              style={{
                backgroundColor: "white",
                opacity: x.id === step ? 1 : 0.2,
              }}
            ></div>
          ))}
        </Flex>

        <Button onClick={() => navigate("/creator/create")}>Skip</Button>
      </div>
      <div className={classes.content}>
        <div className={classes.info_box_content}>
          <div className={classes.info_box}>
            {content
              ?.filter((y: any) => y.id === step)
              ?.map((x: any, i: number) => (
                <Flex
                  key={i}
                  direction="column"
                  justify="center"
                  align="center"
                  w="75%"
                  pt={35}
                  pb={step === 3 ? 50 : 120}
                  h="100%"
                >
                  <Title align="left" fw={800} fz={step === 3 ? 35 : 25}>
                    {x.title}
                  </Title>
                  <Text mt={21} color="white">
                    {x.text}
                  </Text>
                </Flex>
              ))}
          </div>
          <div className={classes.step_button}>
            <StepsButton
              translation={translation}
              step={step}
              steps={steps}
              nextStep={nextStep}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
const useStyles = createStyles((theme) => ({
  main: {
    position: "absolute",
    zIndex: 9999,
    backgroundColor: "#1E1E1E",
    width: "100vw",
  },
  steps: {
    width: 6,
    height: 6,
    borderRadius: "50%",
    marginRight: 7.5,
    marginLeft: 7.5,
  },
  header: {
    position: "absolute",
    zIndex: 99999,
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    top: 40,
    padding: "0px 20px",
  },
  content: {
    position: "absolute",
    zIndex: 99999,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    bottom: 100,
  },
  info_box: {
    width: "90%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "white",
    backdropFilter: "blur(5.5px)",
    borderRadius: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  info_box_content: {
    position: "relative",
    width: "100%",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  step_button: {
    position: "absolute",
    bottom: -50,
    left: "50%",
    transform: "translate(-50%, 0%)",
  },
}));
export default CreateCreatorPreFaceMobile;

import {
  Button,
  createStyles,
  Divider,
  Flex,
  Image,
  Modal,
  Text,
} from "@mantine/core";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import { IoClose } from "react-icons/io5";
import HomeIcon from "../../../assets/left-menu/home-icon";
import ChatIcon from "../../../assets/left-menu/chat-icon";
import SettingsIcon from "../../../assets/left-menu/settings-icon";
import { HiOutlineUser } from "react-icons/hi";
import MicrophoneIcon from "../../../assets/common/microphone-icon";

const FooterMobile = (props: any) => {
  const { classes } = useStyles();
  const location = useLocation();
  // const [voiceStart, setVoiceStart] = useState(false);
  const [voiceUploadModal, { open, close }] = useDisclosure(false);

  return (
    <>
      <Modal
        opened={voiceUploadModal}
        onClose={close}
        withCloseButton={false}
        classNames={{
          body: classes.modal_body,
          inner: classes.modal_inner,
        }}
      >
        <button onClick={close} className={classes.close}>
          <IoClose color="white" size={25} />
        </button>
        <Flex align="center" justify="center" direction="column">
          <Button h={50} w="100%">
            <Text fw={500} fz={16} color="white">
              Record Voice
            </Text>
          </Button>
          <Divider color="#85A0FF" w={"100%"} />
          <Button h={60} w="100%">
            <Text fw={500} fz={16} color="white">
              Upload Voice
            </Text>
          </Button>
        </Flex>
      </Modal>
      <div className={classes.navigation_bar}>
        <Image
          width={"102vw"}
          src={require("../../../assets/mobile_bottom_nav_bar.png")}
        />
        <div className={classes.navigation_bar_container}>
          <NavLink
            className={`${classes.link} ${classes.active_link}`}
            to={"/"}
          >
            <HomeIcon active={location.pathname === "/my/feed"} />
            <Text
              className={`${location.pathname === "/" && classes.active_link}`}
              fz={"2.5vw"}
            >
              Home
            </Text>
          </NavLink>
          <Link className={classes.link} to={"/my/chat"}>
            <ChatIcon active={location.pathname === "/my/chat"} />{" "}
            <Text
              className={`${
                location.pathname === "/my/chat" && classes.active_link
              }`}
              fz={"2.5vw"}
            >
              Chat
            </Text>
          </Link>
          <Link
            to={"/post/create"}
            onClick={open}
            className={classes.new_post_button}
          >
            <MicrophoneIcon active={location.pathname === "/post/create"} />
          </Link>
          <Link className={classes.link} to={"/my/settings"}>
            <SettingsIcon active={location.pathname.includes("my/settings")} />

            <Text fz={"2.5vw"}>Einstellungen</Text>
          </Link>
          <Link className={classes.link} to={"/my/profile"}>
            <HiOutlineUser
              color={location.pathname === "/my/profile" ||
              location.pathname === "/my/profile/edit" ?"#D2E951": "#8C8FA5"}
              className={`${
                (location.pathname === "/my/profile" ||
                  location.pathname === "/my/profile/edit") &&
                classes.active_link
              }`}
              size={26}
            />{" "}
            <Text fz={"2.5vw"}>Profile</Text>
          </Link>
        </div>
      </div>
    </>
  );
};
const useStyles = createStyles((theme) => ({
  navigation_bar: {
    position: "fixed",
    width: "100vw",
    bottom: -1,
    right: -1,
    zIndex: 99,
    alignItems: "center",
  },
  active_link: {
    color: theme.colors.green[0],
  },
  div: {
    backgroundColor: "transparent",
  },
  navigation_bar_container: {
    position: "absolute",
    bottom: 0,
    alignItems: "baseline",
    display: "flex",
    justifyContent: "space-between",
    width: "100vw",
  },
  link: {
    width: "20%",
    color: "white",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    [theme.fn.smallerThan("sm")]: {
      height: 70,
    },
    [`@media (max-width: 480px)`]: {
      height: 38,
    },
  },
  new_post_button: {
    backgroundColor: theme.colors.green[0],
    width: "15vw",
    height: "15vw",
    marginBottom: "2.5vw",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    pointer: "auto",
    pointerEvents: "auto",
  },
  modal_body: {
    backgroundColor: theme.colors.blue[0],
    padding: "45px",
    [`@media (max-width: 768px)`]: {
      borderRadius: "33px 33px 0px 0px",
      width: "100%",
    },
  },
  modal_inner: {
    [`@media (max-width: 768px)`]: {
      padding: 0,
      alignItems: "flex-end",
    },
  },
  close: {
    position: "absolute",
    zIndex: 999,
    right: 20,
    top: 20,
    backgroundColor: "transparent",
    border: "0px",
    padding: 0,
    cursor: "pointer",
  },
}));
export default FooterMobile;

import React, { useEffect, useState } from "react";
import {
  createStyles,
  Text,
  TextInput,
  PasswordInput,
  Button,
  Group,
  Notification,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { Auth } from "../../hooks/auth.hook";
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import LoginMobileFrontPage from "../../components/low-level/LoginPage/LoginMobileFrontPage";
import { useViewportSize } from "@mantine/hooks";
import AuthLayout from "../../components/high-level/layout/authLayout";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";

const LoginPage = ({ translation }: any) => {
  const { width } = useViewportSize();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const [showLogin, setShowLogin] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [goLoginAnimation, setGoLoginAnimation] = useState(false);
  const [gofirstAnimation, setFirstAnimation] = useState(false);

  const { login, loading, error, loginSuccess } = Auth();

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  useEffect(() => {
    if (width > 768) {
      setShowLogin(true);
      setFirstAnimation(true);
    }
  }, [width]);

  const goLogin = async () => {
    if (form.values.password.length < 3) {
      setErrorPassword(true);
    } else {
      await login(form.values.email, form.values.password);
    }
  };

  const goRegisterPage = () => {
    setGoLoginAnimation(true);
    setTimeout(() => navigate("/register"), 500);
  };
  const goForgotPassPage = () => {
    setGoLoginAnimation(true);
    setTimeout(() => navigate("/forgot-password"), 500);
  };

  useEffect(() => {
    if (error?.length > 1 || loginSuccess?.length > 1) {
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 4000);
    }
  }, [error, loginSuccess]);

  const handleEnterKeyPress = (event: any) => {
    if (event.key === "Enter") {
      goLogin();
    }
  };

  return (
    <AuthLayout pageName="login">
      {showToast && (
        <div className={classes.toast_area}>
          <Notification
            withCloseButton={false}
            styles={{
              root: { backgroundColor: "rgb(37, 38, 43)", border: "0px" },
            }}
            icon={
              (loginSuccess?.length > 3 && <AiOutlineCheck size="1.1rem" />) ||
              (error?.length > 3 && <AiOutlineClose size="1.1rem" />)
            }
            color={error?.length ? "red" : "teal"}
          >
            <Text color="white">
              {error?.length > 3 ? error : loginSuccess}
            </Text>
          </Notification>
        </div>
      )}
      {!showLogin ? (
        <LoginMobileFrontPage
          setGoLoginAnimation={setGoLoginAnimation}
          goLoginAnimation={goLoginAnimation}
          setShowLogin={setShowLogin}
        />
      ) : (
        <div className={`${classes.login_area_container} `}>
          <div className={classes.login_area}>
            <div className={classes.login_area_top}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "right",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Button
                    style={{ borderBottom: "" }}
                    className={classes.button}
                    disabled={loading}
                  >
                    {translation.login_button}
                  </Button>
                  <div className={classes.dvider}></div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Button
                    color="green"
                    disabled={loading}
                    className={classes.button}
                    onClick={goRegisterPage}
                  >
                    {translation.register_button}
                  </Button>
                  <div
                    className={classes.dvider}
                    style={{ backgroundColor: "transparent" }}
                  ></div>
                </div>
              </div>

              <button
                className={classes.go_back_button}
                style={{ borderBottom: "" }}
                onClick={() => {
                  setShowLogin(false);
                  setGoLoginAnimation(false);
                }}
              >
                <BiArrowBack size={20} color="white" />
              </button>
            </div>
            <div
              className={`${classes.login_area_bottom} ${
                goLoginAnimation &&
                width > 768 &&
                classes.login_area_bottom_animation
              } ${gofirstAnimation && classes.login_area_first_animation}`}
            >
              <Text fz="40px" mb="5vh" fw={400} ta="center">
                {" "}
                {translation.login_page.title}
              </Text>
              <TextInput
                label={translation.email_or_username}
                classNames={{
                  input: classes.input,
                  label: classes.input_label,
                }}
                placeholder={translation.email_placeholder}
                {...form.getInputProps("email")}
              />
              <PasswordInput
                label={translation.password}
                onClick={(e) => e.preventDefault()}
                styles={{
                  input: {
                    borderColor: errorPassword
                      ? "red"
                      : "1px solid rgba(255, 255, 255, 0.4)",
                  },
                }}
                classNames={{
                  input: classes.input,
                  innerInput: classes.innerInput,
                  label: classes.input_label,
                  visibilityToggle: classes.visibilityToggle,
                }}
                onKeyUp={(e: any) => handleEnterKeyPress(e)}
                minLength={5}
                mt={15}
                placeholder={translation.password_placeholder}
                {...form.getInputProps("password")}
              />
              {errorPassword && (
                <Text fz={11} color="red" mt={4}>
                  {translation.password_error_message}
                </Text>
              )}

              <Group position="right" mt="md">
                {/* <Button component="a" href="/terms">
              Terms
            </Button> */}
                <button
                  className={classes.forgot_password_button}
                  onClick={goForgotPassPage}
                >
                  {translation.forgot_password}
                </button>
              </Group>
              <Group position="center">
                <Button
                  classNames={{ root: classes.login_button }}
                  className={classes.submit_button}
                  loading={loading}
                  disabled={loading}
                  onClick={goLogin}
                >
                  {translation.login}
                </Button>
                <button
                  className={classes.forgot_password_button_mobile}
                  disabled={loading}
                  onClick={goForgotPassPage}
                >
                  {translation.forgot_password}
                </button>
              </Group>
              <Group position="center" mt="md">
                {/* <Button
            loading={loading}
            component="a"
            color="green"
            href="http://localhost:1337/api/connect/google"
          >
            Login Google
          </Button> */}
                {/* <Button
            loading={loading}
            component="a"
            href="http://localhost:1337/api/connect/facebook"
          >
            Login Facebook
          </Button>
          <Button
            loading={loading}
            component="a"
            color="pink"
            href="http://localhost:1337/api/connect/instagram"
          >
            Login İnstagram
          </Button> */}
              </Group>
            </div>
          </div>
        </div>
      )}
    </AuthLayout>
  );
};
const useStyles = createStyles((theme) => ({
  button: {
    backgroundColor: "transparent",
    color: "white",
    fontSize: 14,
    fontFamily: "Poppins,sans-serif",
    border: 0,
    ":disabled": {
      backgroundColor: "transparent",
    },
  },
  submit_button: {
    ":before": {
      backgroundColor: "transparent !important",
    },
  },
  toast_area: {
    position: "absolute",
    top: 20,
    zIndex:9999,
    left: 20,
    [theme.fn.smallerThan("sm")]: {
      right: 20,
      left: "auto",
    },
  },
  login_area_container: {
    width: "40%",
    zIndex: 99,
    height: "100%",
    color: "white",
    [theme.fn.smallerThan("sm")]: {
      width: "100%",
    },
  },
  login_area_animation: {
    [theme.fn.smallerThan("sm")]: {
      animationName: "slideInDown",
      animationDuration: "1s",
      animationFillMode: "both",
      "@keyframes slideInDown": {
        "0%": {
          transform: "translateY(100%)",
        },
        "100%": {
          transform: "translateY(0%)",
        },
      },
    },
  },
  login_area_first_animation: {
    animationName: "slideInDownFirst",
    animationDuration: "1s",
    animationFillMode: "both",
    "@keyframes slideInDownFirst": {
      "0%": {
        transform: "scale(0)",
        opacity: 0,
      },
      "100%": {
        transform: "scale(1)",
        opacity: 1,
      },
    },
  },

  login_area_bottom_animation: {
    animationName: "slideInDownBottom",
    animationDuration: "1s",
    animationFillMode: "both",
    "@keyframes slideInDownBottom": {
      "0%": {
        transform: "scale(1)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(0)",
        opacity: 0,
      },
    },
  },
  login_area: {
    width: "85%",
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    height: "100%",
    justifyContent: "center",
    [theme.fn.smallerThan("sm")]: {
      width: "100%",
      alignItems: "center",
    },
  },
  input: {
    color: "white",
    borderRadius: 38,
    height: "56px",
    paddingLeft: 29,
    backgroundColor: "transparent",
  },
  innerInput: {
    height: 56,
    color: "white",
    paddingLeft: 29,
  },
  input_label: {
    color: "white",
    marginBottom: 7,
  },
  visibilityToggle: {
    color: theme.colors.green[0],
    marginRight: 29,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  forgot_password_button: {
    color: theme.colors.green[0],
    border: "0px solid",
    cursor: "pointer",
    backgroundColor: "transparent",
    margin: "0px 0px 20px 0px",
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
  forgot_password_button_mobile: {
    display: "none",
    [theme.fn.smallerThan("sm")]: {
      display: "block",
      backgroundColor: "transparent",
      color: theme.colors.green[0],
      border: "0px solid",
    },
  },
  login_button: {
    width: "100%",
    backgroundColor: theme.colors.green[0],
    color: "black",
    borderRadius: 75,
    height: 59,
    zIndex: 20,
    transition: "all .5s",
    "&:hover": {
      transform: "scale(1.02)",

      transition: "all .5s",
    },
  },
  login_area_top: {
    height: "20%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.fn.smallerThan("sm")]: {
      a: {
        display: "none",
        height: "10vh",
      },
      height: "20%",
      width: "90%",
      justifyContent: "space-between !important",
      flexDirection: "row-reverse",
    },
  },
  login_area_bottom: {
    height: "80%",
    [theme.fn.smallerThan("sm")]: {
      height: "80vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "90%",
    },
  },
  go_back_button: {
    display: "none",
    [theme.fn.smallerThan("sm")]: {
      display: "block",
      border: "0px",
      marginTop: 30,
      backgroundColor: "transparent",
    },
  },

  login_front_page: {
    display: "none",
    [theme.fn.smallerThan("sm")]: {
      display: "block",
    },
  },
  dvider: {
    backgroundColor: theme.colors.green[0],
    width: "50%",
    height: 3,
  },
}));
export default LoginPage;

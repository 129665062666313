/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Text,
  createStyles,
  TextInput,
  PasswordInput,
  Button,
  Group,
  Notification,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import { Auth } from "../../hooks/auth.hook";
import { BiArrowBack } from "react-icons/bi";
import { useViewportSize } from "@mantine/hooks";
import AuthLayout from "../../components/high-level/layout/authLayout";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";

const RegisterPage = ({ translation }: any) => {
  const { width } = useViewportSize();
  const { register, error, loading, loginSuccess } = Auth();
  const { classes } = useStyles();
  const [showLogin, setShowLogin] = useState(true);
  const [goAnimation, setGoAnimation] = useState(false);
  const [gofirstAnimation, setFirstAnimation] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (width > 768) {
      setFirstAnimation(true);
    }
  }, [width]);
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      username: "",
      email: "",
      password: "",
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  /*
  useEffect(() => {
    if (token !== "" && token?.length > 2) {
      navigate("/feed");
    }
  }, [token]);
  */
  const onRegister = (values: any) => {
    register(values.username, values.email, values.password);
  };

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    }
    if(loginSuccess){
      setErrorMessage("");
    }
  }, [error, loginSuccess]);
  const goLoginpage = () => {
    setGoAnimation(true);
    setTimeout(() => navigate("/login"), 500);
  };
  useEffect(() => {
    if (error.length > 1 || loginSuccess.length > 1) {
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 2000);
    }
  }, [error, loginSuccess]);

  return (
    <AuthLayout pageName="register">
      {showToast && (
        <div className={classes.toast_area}>
          <Notification
            withCloseButton={false}
            styles={{
              root: { backgroundColor: "rgb(37, 38, 43)", border: "0px" },
            }}
            icon={
              (loginSuccess.length > 3 && <AiOutlineCheck size="1.1rem" />) ||
              (error.length > 3 && <AiOutlineClose size="1.1rem" />)
            }
            color={error.length > 3 ? "red" : "teal"}
          >
            <Text color="white">{error.length > 3 ? error : loginSuccess}</Text>
          </Notification>
        </div>
      )}
      {showLogin && (
        <div className={`${classes.login_area_container}`}>
          <div className={classes.login_area}>
            <div className={classes.login_area_top}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "right",
                }}
              >
                <div className={classes.flexBox}>
                  <Button
                    style={{ borderBottom: "" }}
                    disabled={loading}
                    className={classes.button}
                    onClick={goLoginpage}
                  >
                    {translation.login_button}
                  </Button>
                  <div
                    className={classes.dvider}
                    style={{ backgroundColor: "transparent" }}
                  ></div>
                </div>
                <div className={classes.flexBox}>
                  <Button
                    className={classes.button}
                    color="green"
                    disabled={loading}
                  >
                    {translation.register_button}
                  </Button>
                  <div className={classes.dvider}></div>
                </div>
              </div>

              <button
                className={classes.go_back_button}
                style={{ borderBottom: "" }}
                onClick={() => {
                  navigate("/login");
                }}
              >
                <BiArrowBack size={20} color="white" />
              </button>
            </div>
            <div
              className={`${classes.login_area_bottom} ${
                goAnimation && classes.login_area_bottom_animation
              } ${
                gofirstAnimation &&
                width > 768 &&
                classes.login_area_first_animation
              }`}
            >
              <Text fz="30px" mb="1vh" fw={400} ta="center">
                {translation.register_page.title}
              </Text>
              <Text fz="30px" mb="5vh" fw={400} ta="center">
                {translation.register_page.title_2}
              </Text>
              <form onSubmit={form.onSubmit((values) => onRegister(values))}>
                <TextInput
                  label={translation.email}
                  classNames={{
                    input: classes.input,
                    label: classes.input_label,
                  }}
                  placeholder={translation.email_placeholder}
                  {...form.getInputProps("email")}
                />
                <TextInput
                  label={translation.username}
                  mt={15}
                  classNames={{
                    input: classes.input,
                    label: classes.input_label,
                  }}
                  placeholder={translation.username_placeholder}
                  {...form.getInputProps("username")}
                />
                <PasswordInput
                  minLength={5}
                  classNames={{
                    input: classes.input,
                    innerInput: classes.innerInput,
                    label: classes.input_label,
                    visibilityToggle: classes.visibilityToggle,
                  }}
                  mt={15}
                  label={translation.password}
                  placeholder={translation.password_placeholder}
                  {...form.getInputProps("password")}
                />
                <Text c="dimmed" fz="sm" mt={2}>
                  {errorMessage}
                </Text>

                <Group position="center" mt={30}>
                  <Button
                    className={classes.submit_button}
                    classNames={{ root: classes.login_button }}
                    disabled={loading}
                    loading={loading}
                    type="submit"
                  >
                    {translation.register_page.register_button}
                  </Button>
                </Group>
              </form>
            </div>
          </div>
        </div>
      )}
    </AuthLayout>
  );
};
const useStyles = createStyles((theme) => ({
  button: {
    backgroundColor: "transparent",
    color: "white",
    fontSize: 14,
    fontFamily: "Poppins,sans-serif",
    border: 0,
    ":disabled": {
      backgroundColor: "transparent",
    },
  },
  flexBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  submit_button: {
    ":before": {
      backgroundColor: "transparent !important",
    },
  },
  toast_area: {
    position: "absolute",
    top: 20,
    zIndex: 9999,
    left: 20,
  },
  main_container: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    overflow: "hidden",
    overflowX: "hidden",
    position: "relative",
    backgroundColor: "#1E1E1E",
  },
  image_area: {
    width: "60%",
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
  login_area_container: {
    width: "40%",
    zIndex: 99,
    height: "100%",
    color: "white",
    [theme.fn.smallerThan("sm")]: {
      width: "100%",
    },
  },
  login_area_animation: {
    animationName: "slideInDown",
    animationDuration: "1s",
    animationFillMode: "both",
    "@keyframes slideInDown": {
      "0%": {
        transform: "translateY(100%)",
      },
      "100%": {
        transform: "translateY(0%)",
      },
    },
  },
  login_area: {
    width: "85%",
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    height: "100%",
    justifyContent: "center",
    [theme.fn.smallerThan("sm")]: {
      width: "100%",
      alignItems: "center",
    },
  },

  input: {
    border: "1px solid rgba(255, 255, 255, 0.4)",
    color: "white",
    borderRadius: 38,
    height: "56px",
    paddingLeft: 29,
    backgroundColor: "transparent",
  },
  innerInput: {
    height: 56,
    color: "white",
    paddingLeft: 29,
  },
  input_label: {
    color: "white",
    marginBottom: 7,
  },
  visibilityToggle: {
    color: theme.colors.green[0],
    marginRight: 29,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  forgot_password_button: {
    color: theme.colors.green[0],
    border: "0px solid",
    cursor: "pointer",
    backgroundColor: "transparent",
    margin: "10px 0px 39px 0px",
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
    zIndex: 20,
  },
  forgot_password_button_mobile: {
    display: "none",
    zIndex: 20,
    [theme.fn.smallerThan("sm")]: {
      display: "block",
      backgroundColor: "transparent",
      color: theme.colors.green[0],
      border: "0px solid",
    },
  },
  login_button: {
    width: "100%",
    backgroundColor: theme.colors.green[0],
    color: "black",
    borderRadius: 75,
    height: 59,
    zIndex: 20,
    transition: "all .5s",
    "&:hover": {
      transform: "scale(1.02)",
      transition: "all .5s",
    },
  },
  login_area_top: {
    height: "20%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.fn.smallerThan("sm")]: {
      a: {
        display: "none",
        height: "10vh",
      },
      height: "20%",
      width: "90%",
      justifyContent: "space-between !important",
      flexDirection: "row-reverse",
    },
  },
  login_area_bottom: {
    opacity: 0,
    height: "80%",
    [theme.fn.smallerThan("sm")]: {
      opacity: 1,
      zIndex: 9,
      height: "80%",
      width: "90%",
    },
  },
  login_area_first_animation: {
    animationName: "slideInDownFirst",
    animationDuration: "1s",
    animationFillMode: "both",
    "@keyframes slideInDownFirst": {
      "0%": {
        transform: "scale(0)",
        opacity: 0,
      },
      "100%": {
        transform: "scale(1)",
        opacity: 1,
      },
    },
  },
  login_area_bottom_animation: {
    animationName: "slideInDownBottom",
    animationDuration: "1s",
    animationFillMode: "both",
    "@keyframes slideInDownBottom": {
      "0%": {
        transform: "scale(1)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(0)",
        opacity: 0,
      },
    },
  },
  go_back_button: {
    display: "none",
    [theme.fn.smallerThan("sm")]: {
      display: "block",
      zIndex: 9,
      border: "0px",
      marginTop: 30,
      backgroundColor: "transparent",
    },
  },

  login_front_page: {
    display: "none",
    [theme.fn.smallerThan("sm")]: {
      display: "block",
    },
  },
  dvider: {
    backgroundColor: theme.colors.green[0],
    width: "50%",
    height: 3,
  },
}));
export default RegisterPage;

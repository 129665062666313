/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { createStyles } from "@mantine/core";
import WaveSurfer from "wavesurfer.js";
import { BsFillStopFill, BsFillPlayFill } from "react-icons/bs";
import { formatTime } from "../../../utils/formatTime";
import { useUpdatePostListen } from "../../../api/creator/service";
import { useParams } from "react-router-dom";

const peaksData: any = {
  bits: 8,
  length: 436,
  sample_rate: 22050,
  samples_per_pixel: 1102,
  data: [],
};
const getRandomFloat = (min: any, max: any) => {
  return Math.random() * (max - min) + min;
};

for (let i = 0; i < 300; i++) {
  let randomNumber = 0;

  if (i >= 16 && i <= 250) {
    randomNumber = getRandomFloat(-1, 0);
  } else {
    randomNumber = getRandomFloat(0, 1);
  }
  peaksData.data.push(randomNumber);
}

export default function WaveAudio({
  url,
  showAudioTime = true,
  disabled = false,
  interact = true,
  listenCount,
  playSound,
  setDuration,
  peaks,
  id,
  duration,
  isFirstPlay,
}: {
  url?: string;
  showAudioTime?: boolean;
  disabled?: boolean;
  interact?: boolean;
  peaks?: any;
  playSound?: boolean;
  setDuration?: any;
  listenCount?: number;
  duration?: any;
  audioCurrentTime?: number;
  isFirstPlay?: boolean;
  play?: any;
  id?: number | null;
}) {
  const { classes } = useStyles();
  const waveformRef: any = useRef(null);
  const wavesurfer: any = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [timePlay, setTimePlay] = useState(false);
  const [playCountControl, setPlayCountControl] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);
  const [playCount, setPlayCount] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const { mutateUpdatePost } = useUpdatePostListen();
  /*
  useEffect(() => {
    if (peaksData.data.length > 600) {
      peaksData.data.push([]);
    }
  }, [url]);
  */

  useEffect(() => {
    setPlayCount(listenCount ? +listenCount : 0);
  }, [listenCount]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  // create new WaveSurfer instance
  // On component mount and when url changes

  useEffect(() => {
    if (duration === 0) {
      setDuration(0);
    }
  }, [duration]);

  useEffect(() => {
    setPlaying(false);
    setTimePlay(false);
    const formWaveSurferOptions = (ref: any) => ({
      container: ref,
      waveColor: "rgba(255, 255, 255, 0.3)",
      progressColor: "#D2E951",
      cursorColor: "black",
      barWidth: 3,
      barRadius: 4,
      responsive: true,
      hideScrollbar: true,
      height: 38,
      barGap: 5.5,
      interact: interact,
      url: url ? url : "https://download.samplelib.com/mp3/sample-3s.mp3",
      // If true, normalize by the maximum peak instead of 1.0.
      normalize: true,
      mediaType: "audio",
      peaks: peaks,
    });
    const options: any = formWaveSurferOptions(waveformRef.current);

    wavesurfer.current = WaveSurfer.create(options);

    // wavesurfer.current.on("ready", function () {
    //   // setLoading(false);
    //   let _totalTime = wavesurfer.current.getDuration();
    //   setTotalTime(_totalTime);
    //   // https://wavesurfer-js.org/docs/methods.html
    //   // wavesurfer.current.play();

    //   // make sure object stillavailable when file loaded
    // });

    // wavesurfer.current.on('ready', (duration:any) => {
    //   setTotalTime(duration);
    // })
    // wavesurfer.current.on('play', () => setPlaying(true)),

    // wavesurfer.current.on("audioprocess", function () {
    //   let _currentTime = wavesurfer.current.getCurrentTime();
    //   setCurrentTime(_currentTime);
    // });
    wavesurfer.current.on("finish", function () {
      setPlaying(false);
      setTimePlay(false);
      setPlayCountControl(false);
    });

    // Removes events, elements and disconnects Web Audio nodes.
    // when component unmount
    return () => wavesurfer.current.destroy();
  }, [interact, peaks, url]);

  useEffect(() => {
    if (!wavesurfer) return;
    setCurrentTime(0);
    setPlaying(false);
    const subscriptions = [
      wavesurfer.current.on("play", () => {
        setPlaying(true);
      }),
      wavesurfer.current.on("pause", () => {
        setPlaying(false);
      }),
      wavesurfer.current.on("timeupdate", (currentTime: any) => {
        if (currentTime !== 0) {
          setCurrentTime(currentTime);
          setTimePlay(true);
        }
      }),
    ];
    return () => {
      subscriptions.forEach((unsub) => unsub());
    };
  }, [wavesurfer]);

  useEffect(() => {
    if (wavesurfer.current) {
      const _remainingTime = duration - currentTime;
      setRemainingTime(_remainingTime);
    }
  }, [wavesurfer, timePlay, currentTime, duration]);

  const handlePlayPause = useCallback(() => {
    if (wavesurfer.current.isPlaying()) {
      setTimePlay(false);
      wavesurfer.current.pause();
    } else {
      if (!playCountControl) {
        const data = {
          listenCount: playCount + 1,
        };
        mutateUpdatePost({ id, data });
        setPlayCount(playCount + 1);
      }
      setPlayCountControl(true);
      setTimePlay(true);
      wavesurfer.current.play();
    }
  }, [wavesurfer, playCountControl]);

  useEffect(() => {
    if (!playSound) {
      wavesurfer.current.pause();
    } else {
      wavesurfer.current.play();
    }
  }, [playSound]);

  const getLeftPadding = () => {
    if (windowSize.innerWidth < 600) {
      return "0px";
    }
    if (showAudioTime) {
      return "10px";
    } else {
      return "0px";
    }
  };

  const getRightPadding = () => {
    if (windowSize.innerWidth < 600) {
      return "2px";
    } else {
      return "0px";
    }
  };

  return (
    <div style={{ width: "100%", position: "relative" }}>
      {/* {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <Skeleton height="100%" />
        </div>
      )} */}
      <div
        className={classes.main}
        style={{
          display: "flex",
          alignItems: "center",
          borderRadius: 40,
          padding: !showAudioTime ? "10px 15px" : "10px 0px",
        }}
      >
        <div>
          <button
            className={classes.play_button}
            style={{ border: 0, opacity: disabled ? 0.5 : 1 }}
            onClick={handlePlayPause}
            disabled={disabled}
          >
            {!playing ? (
              <BsFillPlayFill size={"100%"} className={classes.icon} />
            ) : (
              <BsFillStopFill size={"100%"} className={classes.icon} />
            )}
          </button>
        </div>
        <div
          style={{
            width: "calc(90% - 20px)",
            marginLeft: getLeftPadding(),
            marginRight: 10,
          }}
          id="waveform"
          ref={waveformRef}
        />
        {showAudioTime && (
          <span style={{ paddingRight: getRightPadding() }}>
            <div style={{ width: "10%", height: "100%", color: "white" }}>
              {timePlay
                ? formatTime(remainingTime) === "-1:-1"
                  ? "0:00"
                  : formatTime(remainingTime)
                : isFirstPlay
                ? formatTime(remainingTime)
                : formatTime(duration)}
            </div>
          </span>
        )}
      </div>
    </div>
  );
}
const useStyles = createStyles((theme) => ({
  play_button: {
    backgroundColor: "white",
    width: 40,
    height: 40,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [`@media (max-width: 500px)`]: {
      backgroundColor: "transparent",
      width: 60,
      height: 60,
    },
  },
  main: {
    width: "100%",
    [`@media (max-width: 500px)`]: {
      marginRight: 10,
    },
  },
  icon: {
    color: "black",
    [`@media (max-width: 500px)`]: {
      color: "white",
    },
  },
}));

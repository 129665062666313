import { useAppDispatch, useAppSelector } from "../store/hooks";
import { AppDispatch, RootState } from "../store/store";
import {
  setUser,
  setLoading,
  setProfileId,
  setUserLoading,
} from "../store/reducers/user.reducer";
import { getToken, removeToken } from "../utils/auth/auth";
import restClient from "../api/restClient";
import { logEvent, setUserGA } from "../utils/analytics-service";
import { deleteMeEvent } from "../utils/log-events";

export const User = () => {
  const dispatch: AppDispatch = useAppDispatch();

  const loading: any = useAppSelector(
    (state: RootState) => state.authReducer.loading
  );
  const userLoading: any = useAppSelector(
    (state: RootState) => state.authReducer.userLoading
  );
  const user: any = useAppSelector((state: RootState) => state.user.user);

  const getUser = async () => {
    dispatch(setUserLoading(true));

    const token = await getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      await restClient
        .get(`/account`, config)
        .then(function (response) {
          if (response.data.data.attributes?.creatorProfile?.username) {
            const creatorProfileName =
              response.data.data.attributes.creatorProfile.username;
            if (creatorProfileName) {
              getUserPosts(creatorProfileName);
            }
          }
          dispatch(setUser(response.data.data));
          console.log("1", response.data);

          setUserGA(response.data.id);
        })
        .catch(function (error) {
          dispatch(setUserLoading(false));
        });
    } catch (error) {}
  };
  const getUserPosts = async (creatorProfileName: string) => {
    const token = await getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      await restClient
        .get(
          `/posts?pagination[start]=0&pagination[limit]=1&filters[creator_profile][username][$eq]=${creatorProfileName}`,
          config
        )
        .then(function (response) {
          // console.log("zxcresponse", response);
        })
        .catch(function (error) {
          console.log("error", error);
        });
    } catch (error) {}
  };

  const getFindUserId = async (id: number) => {
    dispatch(setProfileId(id));
  };
  const changePassword = async (values: any) => {
    const token = await getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    dispatch(setLoading(true));
    try {
      await restClient
        .post(
          `/auth/change-password`,
          {
            currentPassword: values.currentPassword,
            password: values.newPassword,
            passwordConfirmation: values.passwordConfirmation,
          },
          config
        )
        .then(function (response) {
          dispatch(setLoading(false));
        })
        .catch(function (error) {
          console.log("error", error);
          dispatch(setLoading(false));
        });
    } catch (error) {
      console.log("error", error);
    }
  };
  const deleteCreator = async ({ userId, creatorId }: any) => {
    const token = await getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      await restClient
        .put(
          "/creator-profiles/" + creatorId,
          { data: { isActive: false } },
          config
        )
        .then(function (response) {
          restClient
            .delete("/users/" + userId, config)
            .then(function () {
              removeToken();
              logEvent(deleteMeEvent);
              window.location.reload();
            })
            .catch(function (error) {
              console.log("error", error);
            });
        })
        .catch(function (error) {
          console.log("zxcerror", error);
        });
    } catch (error) {}
    // navigate("/");
    // window.location.reload();
  };

  const deleteUser =  async (id:number) =>{
    const token = await getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      await restClient.delete("/users/" + id, config).then(function () {
        removeToken();
        logEvent(deleteMeEvent);
        window.location.reload();
      });
    } catch (error) {
      console.log("error", error);
    }
  }

  const logoutUser = () => {
    window.location.reload();
    removeToken();
  };
  return {
    getUser,
    getUserPosts,
    deleteCreator,
    deleteUser,
    changePassword,
    getFindUserId,
    logoutUser,
    userLoading,
    user,
    loading,
  };
};

import { getToken } from "../../utils/auth/auth";
import restClient from "../restClient";

export const getFeedDataFn = async (pageParam: number) => {
  try {
    const page = pageParam ? pageParam : 1;
    const offset = (page - 1) * 10;
    const token = await getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const data = await restClient.get(
      `/creator-profiles?populate=*&sort=createdAt:DESC&pagination[start]=${offset}&pagination[limit]=10`,
      config
    );

    return {
      results: data.data.data,
      offset: offset + 10,
      pagination: data.data.meta.pagination,
    };
  } catch (error) {
    console.error("post api error:", error);
    throw error;
  }
};

export const getRecommendCreatorsFn = async (pageParam: number) => {
  try {
    const page = pageParam ? pageParam : 1;
    const offset = (page - 1) * 6;
    const token = await getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const data = await restClient.get(
      `/creator-profiles?filters[recommend][$eq]=true&pagination[start]=${offset}&pagination[limit]=6`,
      config
    );
    return {
      results: data.data.data,
      page: page,
      offset: offset + 6,
      pagination: data.data.meta.pagination,
    };
  } catch (error) {
    console.error("post recommend api error:", error);
    throw error;
  }
};

export const getRecommendPublicCreatorsFn = async (pageParam: number) => {
  try {
    const page = pageParam ? pageParam : 1;
    const offset = (page - 1) * 6;

    const data = await restClient.get(
      `/public-creator-recommended?pagination[start]=${offset}&pagination[limit]=6`
    );
    return {
      results: data.data,
      page: page,
      offset: offset + 6,
      pagination: data?.data?.meta?.pagination,
    };
  } catch (error) {
    console.error("post recommend api error:", error);
    throw error;
  }
};

export const getAudiosFn = async (pageParam: number) => {
  try {
    const page = pageParam ? pageParam : 1;
    const offset = (page - 1) * 6;
    const token = await getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const data = await restClient.get(
      `/feed-audios?pagination[start]=${offset}&pagination[limit]=6`,
      config
    );

    return {
      results: data.data.data,
      offset: offset + 6,
      pagination: data.data.meta.pagination,
    };
  } catch (error) {
    console.error("post api error:", error);
    throw error;
  }
};

import {
  Flex,
  createStyles,
  Text,
  Image,
  Button,
} from "@mantine/core";
interface IPropsState {
  headerTitle?: string;
  translation:any;
  mobileHeaderFixed?: boolean;
  registerModalOpen:any;
  loginModalOpen:any;
}

const PublicHeader = ({
  headerTitle,
  translation,
  mobileHeaderFixed,
  registerModalOpen,
  loginModalOpen,
}: IPropsState) => {
  const { classes } = useStyles();
  return (
    <>
      <div
        className={classes.main}
        style={{
          position: mobileHeaderFixed ? "fixed" : "relative",
          width: "100%",
          padding: "0px 10px",
        }}
      >
        <Flex
          style={{ height: 60 }}
          gap="md"
          justify="space-between"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <div className={classes.title}>
            {" "}
            <Text truncate>{headerTitle}</Text>
          </div>
          <Image height={40} width={60} src={require("../../../assets/logo.png")}/>
        </Flex>
        <Flex mb={10} gap={10} align="center" justify="center">
          <Button w="48%" onClick={loginModalOpen} className={classes.login_button}>{translation.login}</Button>
          <Button w="48%" onClick={registerModalOpen} className={classes.register_button}>{translation.register}</Button>
        </Flex>
      </div>
    </>
  );
};
const useStyles = createStyles((theme) => ({
  main: {
    zIndex: 999,
    backgroundColor: "rgba(0, 0, 0, 0.65)",
    backdropFilter:"blur(12px)"
  },
  title: {
    fontSize: 20,
    width: "50%",
    fontWeight: 600,
    color: "white",
  },
  drawer_menu: {
    backgroundColor: "gba(35, 35, 35, 0.5)",
    backdropFilter: "blur(4.5px)",
    width: "100vw",
    position: "fixed",
    overflowY: "hidden",
    height: "100vh",
    zIndex: 9999,
  },
  drawer_menu_container: {
    left: 0,
    backgroundColor: "#35383E",
    zIndex: 99999,
    width: "80%",
  },
  active_link_button: {
    backgroundColor: "rgba(77, 116, 254, .4)",
    color: "white",
    fontSize: 18,
    fontWeight: 600,
  },
  active_link: {
    color: "white",
    fontSize: 18,
    fontWeight: 600,
  },
  user_info: {
    padding: "15px 15px",
    borderBottom: "1px solid #525252",
  },
  link_text: {
    color: "white",
  },
  new_post_and_become_creator_button: {
    backgroundColor: theme.colors.green[0],
    color: "#111111",
    fontSize: 18,
    fontWeight: "bold",
    width: "calc(100% - 70px)",
    borderRadius: 58,
    padding: "10px",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  link: {
    color: "#8C8FA5",
    fontSize: 18,
    width: "100%",
    padding: "15px 35px",
    textDecoration: "none",
  },

  amount_area: {
    padding: "0px 10px",
    height: 36,
    borderRadius: 21,
    backgroundColor: " rgba(255, 255, 255, 0.2)",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 14,
    fontWeight: 900,
  },
  terms_link:{
    color: "#8C8FA5",
    fontSize: 14,
    textDecoration: "none",
  },
  login_button: {
    backgroundColor: "transparent",
    border: ".5px solid white",
    borderRadius: 20,
    transition: "all .5s",
    ":hover": {
      backgroundColor: "rgba(255,255,255,.1)",
      transform: "scale(1.05)",
      transition: "all .5s",
    },
  },
  register_button: {
    backgroundColor: "white",
    borderRadius: 20,
    color: "black",
    transition: "all .5s",
    ":hover": {
      backgroundColor: "rgba(255,255,255,.8)",
      transform: "scale(1.05)",
      transition: "all .5s",
    },
  },
}));
export default PublicHeader;

import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import { useNavigate } from "react-router-dom";
import {
  createEventFn,
  getCreatorEventsFn,
  getEvent,
  getEventsFn,
  updateEventFn,
} from "../api";
import { useGetUser } from "../../user/service";

export const useCreateEvent = () => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const {
    data,
    isLoading,
    isError,
    error,
    isSuccess,
    isIdle,
    mutate: createEvent,
  } = useMutation((createData: any) => createEventFn(createData), {
    onSuccess: async (newData: any) => {
      queryClient.invalidateQueries({ queryKey: ["events"] });
      queryClient.refetchQueries({ queryKey: ["events"] });
      queryClient.setQueryData(["events"], (oldData: any) => {
        if (oldData) {
          let newEvent = newData.data.res.data;
          newEvent = {
            ...newEvent,
            isOwner: true,
            joined: true,
          };
          const updatedData = {
            ...oldData,
            pages: [
              ...oldData.pages,
              {
                results: [
                  ...oldData.pages[oldData.pages.length - 1].results,
                  newEvent,
                ],
              },
            ],
          };
          return updatedData;
        }
        return oldData;
      });
      queryClient.invalidateQueries({ queryKey: ["user"] });
      navigate(`/my/events/${newData.data.res.data.id}`);
    },
  });

  return { data, isLoading, isIdle, isSuccess, isError, error, createEvent };
};
export const useUpdateEvent = () => {
  const { data: user } = useGetUser();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const {
    data,
    isLoading,
    isError,
    error,
    isSuccess,
    isIdle,
    mutate: updateEvent,
  } = useMutation(({ newData }: any) => updateEventFn(newData), {
    onSuccess: async (newData: any) => {
      if (
        newData.userId === user?.data?.attributes?.userProfile?.id &&
        newData.type !== "joinEventUser"
      ) {
        navigate("/my/feed");
      }

      queryClient.invalidateQueries({
        queryKey: ["event-", parseInt(newData.id)],
      });
      queryClient.invalidateQueries({
        queryKey: ["creator-events", newData.creator_id],
      });
      queryClient.invalidateQueries({ queryKey: ["events"] });
      queryClient.invalidateQueries({ queryKey: ["user"] });
      queryClient.invalidateQueries({ queryKey: ["chats"] });
      queryClient.invalidateQueries({
        queryKey: ["chat-creator-detail-", parseInt(newData.id)],
      });
    },
  });

  return { data, isLoading, isIdle, isSuccess, isError, error, updateEvent };
};

export const useGetEvents = () => {
  const {
    data,
    isLoading,
    isError,
    refetch,
    error,
    isFetchingNextPage,
    fetchNextPage,
    isSuccess,
  } = useInfiniteQuery({
    queryKey: ["events"],
    queryFn: ({ pageParam }) => getEventsFn(pageParam),
    getNextPageParam: (lastPage: any, allPages) => {
      return lastPage.results?.length === 10 ? allPages.length + 1 : undefined;
    },
  });
  return {
    data,
    isLoading,
    isSuccess,
    isFetchingNextPage,
    isError,
    error,
    fetchNextPage,
    refetch,
  };
};

export const useGetCreatorEvents = (id: number) => {
  const { data, isLoading, isError, error, isFetchingNextPage, fetchNextPage } =
    useInfiniteQuery({
      queryKey: ["creator-events", id],
      queryFn: ({ pageParam }) => getCreatorEventsFn(pageParam, id),
      getNextPageParam: (lastPage: any, allPages) => {
        return lastPage.results?.length === 10
          ? allPages.length + 1
          : undefined;
      },
    });
  return { data, isLoading, isFetchingNextPage, isError, error, fetchNextPage };
};
export const useGetEvent = (id: number) => {
  const queryClient = useQueryClient();

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["event-", id],
    queryFn: () => getEvent(id),
    // refetchInterval: 3000,
    // staleTime: 3600000,
    onSuccess: async (data: any) => {
      queryClient.invalidateQueries({ queryKey: ["events"] });
    },
  });

  return { data, isLoading, isError, error };
};

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getAnalyticsFn, getStatisticsEventFn, getStatisticsFn, postPayout } from "../api";

export const useGetStatistics = (id: number) => {
  const { data, isLoading, isError, isSuccess, error } = useQuery({
    queryKey: ["statistics"],
    queryFn: () => getStatisticsFn(id),
    staleTime: 3600000,
    // retry:false,
    // refetchOnMount:false,
    // refetchOnWindowFocus:false,
    // select: (res) => res,
  });

  return { data, isLoading, isError, isSuccess, error };
};

export const useGetStatisticsEvents = (id: number) => {
  const { data, isLoading, isError, isSuccess, error } = useQuery({
    queryKey: ["statistics-events"],
    queryFn: () => getStatisticsEventFn(id),
    staleTime: 3600000,
    // retry:false,
    // refetchOnMount:false,
    // refetchOnWindowFocus:false,
    // select: (res) => res,
  });

  return { data, isLoading, isError, isSuccess, error };
};

export const useGetAnalytics = (id: number) => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["analyics"],
    queryFn: () => getAnalyticsFn(id),
    staleTime: 3600000,
    // retry:false,
    // refetchOnMount:false,
    // refetchOnWindowFocus:false,
    // select: (res) => res,
  });

  return { data, isLoading, isError, error };
};

export const usePayout = () => {
  const {
    mutate: payout,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useMutation(
    ( id : any) => postPayout(id)
    // retry:false,
    // refetchOnMount:false,
    // refetchOnWindowFocus:false,
    // select: (res) => res,
  );

  return { payout, isLoading,isSuccess, isError, error };
};

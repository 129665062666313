/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from "react";
import {
  ActionIcon,
  Avatar,
  Center,
  createStyles,
  Flex,
  Image,
  Input,
  Loader,
  Text,
  Title,
} from "@mantine/core";
import Layout from "../../components/high-level/layout/layout";
import { BiSearch } from "react-icons/bi";
import { useViewportSize } from "@mantine/hooks";
import { IoIosArrowRoundBack } from "react-icons/io";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { useGetChats } from "../../api/chat/service";
import ChatRightArea from "../../components/low-level/Chats/ChatRightArea";
import { useScrollRestoration } from "use-scroll-restoration";
import "./styles.css";
import {
  useGetNotificationCount,
  useNotificationSeenAll,
} from "../../api/notifications/service";
import { useGetUser } from "../../api/user/service";
import MessagesIcon from "../../assets/event/messages";

const ChatPage = ({ translation }: any) => {
  let { id }: any = useParams();
  const location = useLocation();
  const [openChat, setOpenChat] = useState(false);
  const { data, isLoading, isFetchingNextPage, fetchNextPage } = useGetChats();
  const [activeTab, setActiveTab] = useState("chat");
  const { width, height } = useViewportSize();
  const { classes } = useStyles({ height });
  const divRef: any = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const [search, setSearch]: any = useState("");
  const [setSelectGroup]: any = useState({});
  const [openVoice, setOpenVoice] = useState(false);
  const [groupActive, setGroupActive]: any = useState(false);
  const [isCreator, setIsCreator]: any = useState(false);
  const { data: user } = useGetUser();
  const [mobileMenuBigger, setMobileFooterBigger] = useState(false);

  const [newData, setNewData]: any = useState([]);
  const { seenAllNotificationFn } = useNotificationSeenAll();
  const { data: notificationCount } = useGetNotificationCount();

  const { ref, setScroll } = useScrollRestoration("chat", {
    debounceTime: 0,
    persist: "localStorage",
  });

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  // PROBILEM  
  // useEffect(() => {
  //   console.log("zxcnewData1",newData,isLoading)
  //   if (newData.length > 0 && id && !isLoading) {
  //     const exists = newData.some((item:any) => item.id.toString() === id);
  //   console.log("zxcnewData2",exists)
  //     if (!exists) {
  //       navigate('/my/feed'); 
  //     }
  //   }
  // }, [newData, id,isLoading]);

  useEffect(() => {
    if (id && notificationCount > 0) {
      seenAllNotificationFn();
    }
  }, [id, location.pathname, notificationCount]);

  const flattenedData: any = useMemo(
    () => (data ? data?.pages?.flatMap((item: any) => item.results) : []),
    [data]
  );

  useEffect(() => {
    const handleScroll = () => {
      const currentDivRef = divRef.current;
      if (
        !isLoading &&
        !isFetchingNextPage &&
        currentDivRef &&
        currentDivRef?.scrollTop + currentDivRef?.clientHeight >=
          currentDivRef.scrollHeight
      ) {
        setScroll({ y: divRef?.scrollTop });
        fetchNextPage();
      }
    };
    const currentDivRef = divRef.current;

    if (currentDivRef) {
      currentDivRef.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (currentDivRef) {
        currentDivRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isFetchingNextPage, divRef.current, isLoading, flattenedData]);

  //   const elRef = useCallback((node:any) => {
  //     if (node !== null &&  !isLoading &&
  //       !isFetchingNextPage &&
  //       node?.scrollTop + node?.clientHeight >=
  //       node.scrollHeight) {
  //         setScroll({ y: divRef?.scrollTop });
  //         fetchNextPage();
  //         console.log("renodef", node); // node = elRef.current
  //     }
  // }, [isLoading,isFetchingNextPage]);

  const passingTime = (createAt: any) => {
    var nowDate: any = new Date();
    var time = nowDate - createAt;
    var millisecondsInSecond: number = 1000;
    var millisecondsInMinute: number = millisecondsInSecond * 60;
    var millisecondsInHour: number = millisecondsInMinute * 60;
    var millisecondsInDay: number = millisecondsInHour * 24;

    var seconds: number = Math.floor(
      (time % millisecondsInMinute) / millisecondsInSecond
    );
    var minutes: number = Math.floor(
      (time % millisecondsInHour) / millisecondsInMinute
    );
    var hours: number = Math.floor(
      (time % millisecondsInDay) / millisecondsInHour
    );
    var days: number = Math.floor(time / millisecondsInDay);
    var week: number = Math.floor(days / 7);

    if (seconds < 60 && minutes === 0 && hours === 0 && days === 0) {
      return seconds === 0
        ? "Now"
        : seconds + " " + translation.chat_page.times.second;
    }
    if (minutes !== 0 && hours === 0) {
      return minutes + " " + translation.chat_page.times.minute;
    }
    if (hours !== 0 && days === 0) {
      return hours + " " + translation.chat_page.times.hour;
    }
    if (days !== 0 && week === 0) {
      return days + " " + translation.chat_page.times.day;
    } else {
      return week + " " + translation.chat_page.times.week;
    }
  };

  useEffect(() => {
    if (search.length > 0) {
      const data = flattenedData.filter((item: any) =>
        item?.attributes?.creator_profiles?.data?.username
          .toLowerCase()
          .includes(search)
      );
      setNewData(data);
    } else {
      setNewData(flattenedData);
    }
  }, [search, flattenedData]);

  useEffect(() => {
    if (location.pathname.split("/").includes("group")) {
      setGroupActive(true);
      const findActiveGroupDetail = newData.find(
        (item: any) => item.id === parseInt(id)
      );

      setSelectGroup(findActiveGroupDetail);
    }
  }, [id, location.pathname]);

  return (
    <Layout
      padding={false}
      headerTitle={translation.chats}
      hiddenFooter={true}
      right={false}
      headerFixed={false}
      mobileHeaderFixed={false}
      mobileHeader={false}
      header={width > 768}
    >
      {id === undefined && (
        <div className={classes.header_mobile}>
          <button
            style={{
              height: 50,
              width: 50,
              cursor: "pointer",
              backgroundColor: "transparent",
              textDecoration: "none",
              border: 0,
              position: "relative",
            }}
            className={classes.back_button}
            onClick={() => navigate(`/my/feed`)}
          >
            <IoIosArrowRoundBack color="white" size="100%" />
          </button>
          <Text
            className={classes.back_button_text}
            fz={18}
            fw={700}
            color="white"
          >
            Creators Chat List
          </Text>
        </div>
      )}
      <Flex
        direction="row"
        className={classes.left_menu_area}
        style={{ overflowY: "hidden", overflowX: "hidden" }}
      >
        {(groupActive
          ? user?.data?.isCreator != null
            ? true
            : false
          : true) &&
          !openVoice && (
            <div
              className={`${classes.left_menu} 
          ${id !== undefined && classes.left_menu_select_creator}`}
            >
                <div className={classes.search_area}>
                  <Input
                    icon={<BiSearch />}
                    value={search}
                    onChange={(e: any) => setSearch(e.target.value)}
                    classNames={{ input: classes.search }}
                    placeholder="Search"
                  />
                </div>
              <div className={classes.left_menu_scroll}>
                {isLoading ? (
                  <Center mt={10}>
                    <Loader variant="bars" color="#D2E951" />
                  </Center>
                ) : newData?.length > 0 ? (
                  <>
                    <div
                      ref={(el) => {
                        divRef.current = el;
                        ref(el);
                      }}
                      className="scroll_area"
                      style={{ height: "100%" }}
                    >
                      {newData
                        ?.sort((a: any, b: any) => {
                          if (
                            !a?.attributes?.lastMessage &&
                            !b?.attributes?.lastMessage
                          ) {
                            return 0; // both a and b don't have lastMessage, they are equal
                          } else if (!a?.attributes?.lastMessage) {
                            return 1; // a doesn't have lastMessage, it should come after
                          } else if (!b?.attributes?.lastMessage) {
                            return -1; // b doesn't have lastMessage, it should come after
                          } else {
                            const dateA: any = new Date(
                              a?.attributes?.lastMessage?.messageSentTime
                            );
                            const dateB: any = new Date(
                              b?.attributes?.lastMessage?.messageSentTime
                            );
                            return dateB - dateA; // both have lastMessage, compare them
                          }
                        })
                        .map((x: any, i: number) => (
                          <Link
                            to={`/my/chat/${x.id}`}
                            key={i}
                            style={{
                              backgroundColor: x.attributes.newMessage
                                ? "rgba(77, 116, 254, 0.4)"
                                : "transparent",
                            }}
                            className={classes.creator_area}
                          >
                            <Avatar
                              size={63}
                              src={
                                x?.attributes?.isCreator
                                  ? x?.attributes?.creator_profiles?.data
                                      ?.avatar?.images?.sizes?.original
                                  : ""
                              }
                              radius={14}
                            />
                            <div className={classes.creator_info}>
                              <Title lineClamp={1} color="white" fz={16}>
                                {x?.attributes?.isUser
                                  ? x?.attributes?.creator_profiles?.data
                                      ?.username
                                  : translation.chat_page.not_found_user}
                              </Title>
                              <Text
                                w={"85%"}
                                lineClamp={1}
                                color="#A1A2A9"
                                fz={12}
                              >
                                {x?.attributes?.lastMessage?.contentType ===
                                "audio"
                                  ? "audio"
                                  : x?.attributes?.lastMessage
                                  ? x?.attributes?.lastMessage?.text
                                  : translation.chat_page
                                      .not_have_message_send_new_message}
                              </Text>
                            </div>
                            <div className={classes.message_info_area}>
                              {x?.attributes?.lastMessage && (
                                <Text className={classes.timer} fz={12}>
                                  {passingTime(
                                    new Date(
                                      x?.attributes?.lastMessage?.messageSentTime
                                    )
                                  )}
                                </Text>
                              )}
                              <div style={{ display: "flex" }}>
                                {x.attributes.newAudio &&
                                x.attributes.newAudio !== 0 ? (
                                  <div className={classes.notification}>
                                    <Image
                                      width={12}
                                      src={require("../../assets/chat/microphone.png")}
                                    />
                                    <div
                                      className={classes.notification_length}
                                    >
                                      <Text fw={600} fz={14}>
                                        {x.attributes.newAudio}
                                      </Text>
                                    </div>
                                  </div>
                                ) : (
                                  <div style={{ height: 24 }}></div>
                                )}
                                {x.attributes.newMessage &&
                                x.attributes.newMessage !== 0 ? (
                                  <div
                                    className={`${classes.notification} ${classes.notification_media}`}
                                  >
                                    <Image
                                      width={12}
                                      src={require("../../assets/chat/chat_message.png")}
                                    />
                                    <div
                                      className={`${classes.notification_length} ${classes.notification_length_media}`}
                                    >
                                      <Text fw={600} fz={14}>
                                        {x.attributes.newMessage}
                                      </Text>
                                    </div>
                                  </div>
                                ) : (
                                  <div style={{ height: 24 }}></div>
                                )}
                              </div>
                            </div>
                          </Link>
                        ))}
                    </div>
                  </>
                ) : (
                  <Center mt={10}>
                    <Text fw={600} color="white">
                      Nothing found
                    </Text>
                  </Center>
                )}
              </div>
            </div>
          )}
        <div
          className={`${classes.right_menu} 
          
          ${groupActive && classes.group_area}
        ${id !== undefined && classes.right_menu_select_creator}`}
          onClick={() =>
            mobileMenuBigger && width < 768 && setMobileFooterBigger(false)
          }
        >
          {id === undefined ? (
            <Center mt={50}>
              <Text w="50%" fz={24} align="center" fw={600} color="white">
                {translation.chat_page.please_select_chat}
              </Text>
            </Center>
          ) : (
            <ChatRightArea
              setOpenVoice={setOpenVoice}
              openVoice={openVoice}
              setIsCreator={setIsCreator}
              setOpenChat={setOpenChat}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              openChat={openChat}
              user={user}
              translation={translation}
            />
          )}
        </div>
        {width < 768 && isCreator && (
          <div
            onClick={() => setMobileFooterBigger(!mobileMenuBigger)}
            style={{ cursor: "pointer" }}
            className={`${classes.mobile_footer_area} ${
              !mobileMenuBigger && classes.mobile_footer_chat_area
            }`}
          >
            <div
              className={`${classes.mobile_footer}  ${
                !mobileMenuBigger && classes.mobile_footer_chat
              }`}
            >
              <ActionIcon
                style={{
                  backgroundColor:
                    activeTab === "media"
                      ? "rgba(77, 116, 254, 1)"
                      : "rgba(255, 255, 255, 0.3)",
                }}
                onClick={() => {
                  setActiveTab("media");
                  setMobileFooterBigger(false);
                }}
                radius={50}
                size={!mobileMenuBigger ? 10 : 49}
              >
                <Image
                  w={"80% !important"}
                  src={require("../../assets/media.png")}
                />
              </ActionIcon>
              <ActionIcon
                style={{
                  backgroundColor:
                    activeTab === "chat"
                      ? "rgba(77, 116, 254, 1)"
                      : "rgba(255, 255, 255, 0.3)",
                }}
                onClick={(e: any) => {
                  setActiveTab("chat");
                  setMobileFooterBigger(false);
                  e.stopPropagination();
                }}
                radius={50}
                size={!mobileMenuBigger ? 10 : 49}
              >
                <MessagesIcon active={mobileMenuBigger} />
              </ActionIcon>
            </div>
          </div>
        )}
      </Flex>{" "}
    </Layout>
  );
};
const useStyles = createStyles((theme, { height }: any) => ({
  left_menu: {
    height: "calc(100vh - 60px)",
    width: "45%",
    borderRight: "1px solid rgba(98, 98, 98, 0.44)",
    [`@media (max-width: 768px)`]: {
      width: "100%",
      marginTop: 0,
    },
  },
  search: {
    backgroundColor: "rgba(181, 181, 181, 0.2);",
    borderRadius: 40,
    border: 0,
    color: "white",
  },
  search_area: {
    margin: "30px 20px",
    [`@media (max-width: 768px)`]: {
      margin: " 20px 25px 20px 25px",
    },
  },
  timer: {
    width: "100%",
    textAlign: "center",
    color: theme.colors.grey[0],
  },
  notification: {
    backgroundColor: theme.colors.blue[0],
    color: "white",
    width: 27,
    height: 27,
    display: "flex",
    borderRadius: 6,
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    marginRight: 10,
  },
  notification_media: {
    backgroundColor: `#B65AFF !important `,
  },
  notification_length_media: {
    backgroundColor: `#B65AFF !important `,
  },
  notification_length: {
    position: "absolute",
    right: -7,
    top: -9,
    width: 18,
    height: 18,
    backgroundColor: theme.colors.blue[0],
    border: "1px solid #3C55AE",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  creator_area: {
    padding: "20px 0px 20px 20px",
    width: "100%",
    display: "flex",
    textDecoration: "none",
    cursor: "pointer",
    [`@media (max-width: 768px)`]: {
      padding: "20px 25px 20px 25px",
    },
  },
  back_button: {
    display: "none",
    [`@media (max-width: 500px)`]: {
      display: "block",
    },
  },
  back_button_text: {
    marginLeft: 0,
    [`@media (max-width: 500px)`]: {
      marginLeft: 44,
    },
  },
  creator_info: {
    marginLeft: 12,
    width: "60%",
  },
  avatar: {
    width: 60,
    height: 60,
    backgroundColor: theme.colors.grey,
  },
  message_info_area: {
    width: "30%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  left_menu_scroll: {
    height: "calc(100vh - 149px)",
    overflow: "hidden",
    borderRight: "1px solid rgba(98, 98, 98, 0.44)",
    [`@media (max-width: 768px)`]: {
      paddingBottom: "56px",
      height: "calc(100vh - 76px)",
    },
  },
  right_menu: {
    width: "60%",
    height: "100dvh",
    [`@media (max-width: 768px)`]: {
      width: "0%",
      display: "none",
    },
  },
  right_menu_select_creator: {
    [`@media (max-width: 768px)`]: {
      width: "100vw !important",
      display: "flex !important",
      flexDirection: "column",
      marginTop: 0,
    },
  },
  left_menu_select_creator: {
    [`@media (max-width: 768px)`]: {
      width: "0vw",
      display: "none",
    },
  },
  careator_info_area: {
    width: "100%",
    padding: "25px 30px",
    borderBottom: "1px solid rgba(98, 98, 98, 0.44)",
    [`@media (max-width: 768px)`]: {
      padding: "25px 30px 15px 30px",
    },
  },
  sounds_area: {
    overflowY: "scroll",
    marginTop: 20,
    padding: "0px 30px",
    height: "calc(100% - 162px)",
    [`@media (max-width: 768px)`]: {
      margin: "0px 0px",
      marginTop: 0,
      paddingTop: 20,
    },
  },
  avatar_area: {
    width: 65,
    height: 65,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "linear-gradient(132.14deg, #D2E951 9.16%, #4D74FE 92.2%)",
    borderRadius: 14,
  },
  header_mobile: {
    marginLeft: 20,
    display: "none",
    [`@media (max-width: 768px)`]: {
      display: "flex",
      alignItems: "center",
    },
  },
  left_menu_area: {
    height: "calc(100vh - 50px)",
    [`@media (max-width: 768px)`]: {
      height: height,
      overflowX: "hidden",
    },
  },
  group_area: {
    width: "100%",
  },
  mobile_footer_area: {
    position: "absolute",
    bottom: 12,
    width: "100%",
    height: 62,
    zIndex: 99999,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all .5s",
  },
  mobile_footer: {
    width: "90%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    borderRadius: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 13,
    paddingRight: 13,
    transition: "all .5s",
  },
  mobile_footer_chat_area: {
    transition: "all .5s",
    height: 20,
    bottom: 4,
  },
  mobile_footer_chat: {
    width: "40% !important",
    bottom: "4px !important",
    transition: "all .5s",
  },
}));

export default ChatPage;

import React, {  useState } from "react";
import {
  Text,
  createStyles,
  PasswordInput,
  Button,
  Group,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import { Auth } from "../../hooks/auth.hook";
import { useLocation } from "react-router-dom";
import AuthLayout from "../../components/high-level/layout/authLayout";
import { BiArrowBack } from "react-icons/bi";

const ResetPasswordPage = ({translation}:any) => {
  const location = useLocation();
  const { resetPassword, loading } = Auth();
  const { classes } = useStyles();
  const [passwordConfirmationError, setPasswordConfirmationError] =
    useState(false);

  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      password: "",
      passwordConfirmation: "",
    },
  });

  const passwordValidControl = () => {
    if (form.values.password !== form.values.passwordConfirmation) {
      setPasswordConfirmationError(true);
    } else {
      return true;
    }
  };
  const onResetPassword = (values: any) => {
    if (passwordValidControl()) {
      resetPassword(
        location.search.replace("?code=", ""),
        values.password,
        values.passwordConfirmation
      );
    }
  };


  return (
    <AuthLayout>
      <div className={`${classes.login_area_container} `}>
        <div className={classes.login_area}>
          <div className={classes.login_area_top}>
            <button
              className={classes.go_back_button}
              onClick={() => navigate("/login")}
            >
              <BiArrowBack size={20} color="white" />
            </button>
          </div>
          <div className={classes.login_area_bottom}>
            <Text fz="40px" fw={300}>
              {translation.reset_password_page.reset}
            </Text>
            <Text fz="40px" mb="5vh" fw={400}>
            {translation.reset_password_page.password}
            </Text>

            <form onSubmit={form.onSubmit((values) => onResetPassword(values))}>
              <PasswordInput
                label={translation.reset_password_page.password}
                classNames={{
                  input: classes.input,
                  innerInput: classes.innerInput,
                  label: classes.input_label,
                  visibilityToggle: classes.visibilityToggle,
                }}
                placeholder={translation.reset_password_page.password}
                {...form.getInputProps("password")}
              />
            
              <PasswordInput
                mt={15}
                classNames={{
                  input: classes.input,
                  innerInput: classes.innerInput,
                  label: classes.input_label,
                  visibilityToggle: classes.visibilityToggle,
                }}
                label={translation.settings_page.new_password_confirmation}
                placeholder={translation.settings_page.new_password_confirmation}
                {...form.getInputProps("passwordConfirmation")}
              />
              <Text c="red" fz="sm" mt={2}>
                {passwordConfirmationError && "passwords are not the same"}
              </Text>
              <Group position="center" mt={30}>
                <Button
                  classNames={{ root: classes.login_button }}
                  loading={loading}
                  type="submit"
                  disabled={
                    form.values.password.length < 6 ||
                    form.values.passwordConfirmation.length < 6
                  }
                >
                  {translation.reset_password_page.reset_password}
                </Button>
              </Group>
            </form>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};
const useStyles = createStyles((theme) => ({
  login_area_container: {
    width: "40%",
    height: "100%",
    color: "white",
    zIndex: 9,
    [theme.fn.smallerThan("sm")]: {
      width: "100%",
    },
  },
  input: {
    border: "1px solid rgba(255, 255, 255, 0.4)",
    color: "white",
    borderRadius: 38,
    height: "56px",
    paddingLeft: 29,
    backgroundColor: "transparent",
  },
  innerInput: {
    height: 56,
    color: "white",
    paddingLeft: 29,
  },
  input_label: {
    color: "white",
    marginBottom: 10,
  },
  visibilityToggle: {
    color: theme.colors.green[0],
    marginRight: 29,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  login_area: {
    width: "85%",
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    height: "100%",
    justifyContent: "center",
    [theme.fn.smallerThan("sm")]: {
      width: "100%",
      alignItems: "center",
    },
  },
  login_area_top: {
    height: "20%",
    width: "90%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    backgroundColor: "transparent",
  },
  login_area_bottom: {
    height: "80%",
    [theme.fn.smallerThan("sm")]: {
      height: "80%",
      width: "90%",
    },
  },
  go_back_button: {
    border: "0px",
    cursor: "pointer",
    zIndex: 20,
    backgroundColor: "transparent",
  },
  login_button: {
    width: "100%",
    backgroundColor: theme.colors.green[0],
    color: "black",
    borderRadius: 75,
    height: 59,
    zIndex: 20,
    transition: "all .5s",
    "&:hover": {
      transform: "scale(1.02)",
      transition: "all .5s",
    },
  },
}));
export default ResetPasswordPage;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useReactMediaRecorder } from "react-media-recorder-2";
import {
  Flex,
  createStyles,
  Text,
  Image,
  Title,
  Button,
  Center,
  Avatar,
  Modal,
} from "@mantine/core";
import { IoArrowBackSharp, IoClose } from "react-icons/io5";
import { BsFillPauseFill, BsFillPlayFill } from "react-icons/bs";

import WaveAudio from "../../components/low-level/waveAudio/WaveAudio";
import { useDisclosure, useViewportSize } from "@mantine/hooks";
import { RiDeleteBinLine } from "react-icons/ri";
import MicrophoneIcon from "../../assets/common/microphone-icon";

const RecordPage = ({
  translation,
  setAudioFileType,
  handleChange,
  setRecording,
  user,
}: any) => {
  const { classes } = useStyles();
  const {
    status,
    startRecording,
    pauseRecording,
    resumeRecording,
    stopRecording,
    clearBlobUrl,
    mediaBlobUrl,
  }: // previewAudioStream,
  any = useReactMediaRecorder({
    video: false,
    blobPropertyBag: {
      // type: isSafari ? "video/mp4;codecs=avc1" : "video/webm;codec=h246"
    },
  });

  const { width } = useViewportSize();
  const [againButtonModal, { open, close }] = useDisclosure(false);

  const handleConvert = async (mediaBlobUrl: string) => {
    let blob = await fetch(mediaBlobUrl).then((r) => r.blob());
    const randomName = Math.random().toString(36).substring(7);
    const file = new File([blob], `${randomName}.mp3`, {});
    return file;
  };

  const handleRecordChange = async (mediaBlobUrl: any) => {
    const file = await handleConvert(mediaBlobUrl);
    handleChange(file, mediaBlobUrl);
  };

  useEffect(() => {
    if (mediaBlobUrl) {
      handleRecordChange(mediaBlobUrl);
    }
  }, [mediaBlobUrl]);

  useEffect(() => {
    if (status === "recording") {
      setRecording(true);
    } else {
      setRecording(false);
    }
  }, [setRecording, status]);

  const statusView = () => {
    if (status === "idle") {
      return (
        <Flex direction="column" align="center" justify="center">
          <Title mt={70} color="white">
            {translation.new_post_page.whats_happen}
          </Title>
          <Text mb={70} fz={20} color="rgba(173, 172, 172, 1)">
            {translation.new_post_page.hit_record}
          </Text>
        </Flex>
      );
    }
    if (status === "stopped") {
      return "";
    }
    if (status === "recording") {
      return (
        <Flex direction="row" align="center" justify="center">
          <Text size="xl" color="white">
            {translation.new_post_page.recording}
          </Text>
        </Flex>
      );
    }
    if (status === "paused") {
      return (
        <Flex direction="row" align="center" justify="center">
          <Text size="xl" color="white">
            {translation.new_post_page.paused}
          </Text>
        </Flex>
      );
    } else {
      return status;
    }
  };

  const clearRecording = () => {
    clearBlobUrl();
    handleChange();
    close();
  };
  return (
    <>
      <Modal
        opened={againButtonModal}
        classNames={{
          body: classes.modal_body,
          inner: classes.modal_inner,
        }}
        onClose={close}
        withCloseButton={false}
        centered
      >
        <button onClick={close} className={classes.close}>
          <IoClose color="white" size={25} />
        </button>
        <Flex align="center" justify="center">
          <div className={classes.delete_image}>
            <RiDeleteBinLine color="white" size={"100%"} />
          </div>
        </Flex>
        <Flex direction="column" align="center">
          <Text
            fz={20}
            className={classes.delete_modal_text}
            align="center"
            color="white"
          >
            {translation.new_post_page.delete_text}
          </Text>
          <Button
            className={classes.yes_delete_button}
            onClick={clearRecording}
          >
            {translation.yes}
          </Button>
        </Flex>
      </Modal>
      <div className={classes.mobile_header}>
        <div style={{ width: "82px" }}>
          <Button
            style={{ paddingLeft: 0 }}
            onClick={() => {
              setAudioFileType("");
              clearRecording();
            }}
          >
            <IoArrowBackSharp size={25} />
          </Button>
        </div>
        <div>
          <Text color="white" size="xl" fw={600}>
            {width > 500
              ? translation.new_post_page.record
              : status === "idle"
              ? translation.new_post_page.record
              : statusView()}
          </Text>
        </div>
        <div style={{ width: "82px" }}>
          {status === "paused" && (
            <Flex direction="column" align="center" style={{ width: "100%" }}>
              <button
                onClick={() => stopRecording()}
                className={`${classes.finish_button} ${classes.finish_button_header}`}
              >
                <Flex align="center" justify="center">
                  <Text color="black" weight="bold">
                    {translation.done}
                  </Text>
                </Flex>
              </button>
            </Flex>
          )}
        </div>
      </div>
      <Flex
        className={classes.main_box}
        direction="column"
        justify="center"
        align="center"
      >
        <Center>
          <div className={classes.image_and_background}>
            <Image
              width={344}
              height={287}
              fit="contain"
              className={`${classes.wave_audio_animation_background_1} ${
                status === "recording" &&
                classes.wave_audio_animation_background_1_animation
              }`}
              src={require("../../assets/new-post/background_1.webp")}
            />
            <Image
              width={344}
              height={287}
              fit="contain"
              className={`${classes.wave_audio_animation_background_2} ${
                status === "recording" &&
                classes.wave_audio_animation_background_2_animation
              }`}
              src={require("../../assets/new-post/background_2.webp")}
            />
            <Avatar
              size={179}
              radius="50%"
              style={{ border: "1px solid #D2E951", zIndex: 9 }}
              src={user?.attributes?.creatorProfile?.avatarThumbs?.thumbs?.c250}
            />
          </div>
        </Center>
        {width > 500 && <p style={{ zIndex: 9 }}>{statusView()}</p>}
        {width < 500 && status === "idle" && (
          <p style={{ zIndex: 9 }}>{statusView()}</p>
        )}

        {status === "idle" && (
          <button
            style={{ backgroundColor: "#D2E951" }}
            className={`${classes.button} `}
            onClick={startRecording}
          >
            <MicrophoneIcon />
          </button>
        )}
        {/* <video ref={videoRef} width={500} height={500} controls />; */}
        {status === "stopped" && (
          <WaveAudio url={mediaBlobUrl} showAudioTime={false} />
        )}

        <Flex direction="row" justify="center">
          {status === "recording" && (
            <div style={{ marginTop: 65, marginBottom: 70, display: "flex" }}>
              <button
                style={{ backgroundColor: "#D2E951" }}
                className={classes.button}
                onClick={pauseRecording}
              >
                <BsFillPauseFill color="black" size={40} />
              </button>
            </div>
          )}

          {status === "paused" && (
            <div style={{ marginTop: 65, marginBottom: 70, display: "flex" }}>
              <button
                style={{ backgroundColor: "#D2E951", marginRight: 41 }}
                className={`${classes.button} `}
                onClick={resumeRecording}
              >
                <BsFillPlayFill color="black" size={40} />
              </button>
              <button
                style={{ backgroundColor: "#4D74FE" }}
                className={classes.button}
                onClick={() => {
                  open();
                }}
              >
                <RiDeleteBinLine color="white" size={32} />
              </button>
            </div>
          )}
        </Flex>

        {status === "paused" && (
          <Flex
            className={classes.done}
            direction="column"
            align="center"
            style={{ width: "100%" }}
          >
            <button onClick={stopRecording} className={classes.finish_button}>
              <Flex align="center" justify="center">
                <Text ml="10px" color="black" weight="bold">
                  {translation.done}
                </Text>
              </Flex>
            </button>
          </Flex>
        )}
      </Flex>
    </>
  );
};
const useStyles = createStyles((theme) => ({
  button: {
    width: 75,
    height: 75,
    borderRadius: "50%",
    border: 0,
    zIndex: 9,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "blue",
    },
  },
  finish_button: {
    width: 234,
    backgroundColor: theme.colors.green,
    height: 50,
    borderRadius: 50,
    zIndex: 999,
    border: 0,
    cursor: "pointer",
    [`@media (max-width: 500px)`]: {
      width: 82,
      height: 37,
    },
  },
  mobile_header: {
    height: 50,
    zIndex: 999,
    padding: 20,
    marginTop: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  image_and_background: {
    position: "relative",
    width: "16vw",
    height: "30vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [`@media (max-width: 1920px)`]: {
      width: "25vw",
    },
    [`@media (max-width: 1440px)`]: {
      width: "31vw",
    },
    [`@media (max-width: 1280px)`]: {
      width: "40vw",
    },
    [`@media (max-width: 1000px)`]: {
      width: "50vw",
    },
    [`@media (max-width: 768px)`]: {
      width: "60vw",
    },
    [`@media (max-width: 650px)`]: {
      width: "100vw",
    },
  },
  wave_audio_animation_background_1: {
    position: "absolute",
    zIndex: 0,
    top: 30,
    left: 0,
    [`@media (max-width: 768px)`]: {
      top: -40,
    },
  },
  wave_audio_animation_background_1_animation: {
    animation: "audioAnimation 1.5s ease-in-out infinite",
    "@keyframes audioAnimation": {
      "0%": {
        transform: "scale(1)",
      },
      "50%": {
        transform: "scale(1.1)",
      },
      "100%": {
        transform: "scale(1)",
      },
    },
  },
  wave_audio_animation_background_2: {
    position: "absolute",
    bottom: 30,
    zIndex: 0,
    right: 0,
    [`@media (max-width: 768px)`]: {
      bottom: -40,
    },
  },
  wave_audio_animation_background_2_animation: {
    animation: "audioAnimation2 1.7s ease-in-out infinite",
    "@keyframes audioAnimation2": {
      "0%": {
        transform: "scale(1)",
      },
      "50%": {
        transform: "scale(1.2)",
      },
      "100%": {
        transform: "scale(1)",
      },
    },
  },
  finish_button_header: {
    display: "none",
    [`@media (max-width: 500px)`]: {
      display: "block",
    },
  },

  done: {
    [`@media (max-width: 500px)`]: {
      display: "none",
    },
  },
  main_box: {
    [`@media (max-width: 500px)`]: {
      height: "80vh",
      justifyContent: "space-between",
    },
  },
  delete_image: {
    height: 210,
    width: 140,
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    [`@media (max-width: 500px)`]: {
      width: 88,
      height: 130,
    },
  },
  delete_modal_text: {
    marginBottom: 30,
    marginTop: 30,
    [`@media (max-width: 500px)`]: {
      marginBottom: 10,
      marginTop: 10,
    },
  },
  modal_body: {
    backgroundColor: theme.colors.blue[0],
    padding: "45px",
    [`@media (max-width: 768px)`]: {
      borderRadius: "33px",
    },
    [`@media (max-width: 500px)`]: {
      borderRadius: "33px 33px 0px 0px",
    },
  },
  modal_inner: {
    [`@media (max-width: 500px)`]: {
      padding: 0,
      alignItems: "flex-end",
    },
  },
  close: {
    position: "absolute",
    zIndex: 999,
    right: 30,
    top: 30,
    backgroundColor: "transparent",
    border: "0px",
    padding: 0,
    cursor: "pointer",
  },
  open_modal_delete_button: {
    backgroundColor: "#F86137",
    height: 49,
    width: 234,
    transition: "all .2s",
    "&:hover": {
      transform: "scale(1.05)",
      transition: "all .2s",
    },
    [`@media (max-width: 500px)`]: {
      position: "absolute",
      bottom: "20vh",
      width: "90%",
      height: 60,
    },
  },
  yes_delete_button: {
    backgroundColor: theme.colors.green,
    color: "black",
    width: 220,
    height: 50,
    borderRadius: "50px",
    transition: "all .2s",
    "&:hover": {
      transition: "all .5s",
      transform: "scale(1.1)",
    },
    [`@media (max-width: 500px)`]: {
      width: "100%",
    },
  },
}));
export default RecordPage;

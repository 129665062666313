import {
  Flex,
  createStyles,
  Text,
  Title,
  Button,
} from "@mantine/core";
import { useWindowScroll, useElementSize } from "@mantine/hooks";
import { useGetTranslations } from "../../../api/translations/service";
import PublicLeftMenu from "../letfMenu/publicLeftMenu";
import PublicHeader from "../header/publicHeader";
import PublicRightMenu from "../rightMenu/publicRightMenu";

const PublicLayout = ({
  children,
  header = true,
  padding = true,
  right = true,
  headerFixed = true,
  mobileHeaderFixed = true,
  headerLinear = false,
  mobileHeader = true,
  headerTitle = "PeepVoice",
  headerRightIcon,
  registerModalOpen,
  loginModalOpen,
}: any) => {
  const [scroll] = useWindowScroll();
  const { ref, width } = useElementSize();
  const { classes } = useStyles();
  const { data } = useGetTranslations();

  const translation = data?.data;

  const rightMenu = () => {
    if (right) {
      return <PublicRightMenu translation={translation} />;
      //   if (user?.isCreator) {
      // return <RightMenu />;
      //   } else {
      //     return <RightMenuAudio />;
      //   }
    }
  };

  // useEffect(() => {
  //   document.body.style.overflow = "visible";
  //   return () => {
  //     document.body.style.overflow = "hidden";
  //   };
  // }, []);

  return (
    <div className={classes.layout}>
      <div className={classes.background_light_blue}></div>
      <div className={classes.background_light_green}></div>
      <PublicLeftMenu
        registerModalOpen={registerModalOpen}
        translation={translation}
      />
      {mobileHeader && (
        <div className={classes.mobile}>
          <PublicHeader
            translation={translation}
            registerModalOpen={registerModalOpen}
            loginModalOpen={loginModalOpen}
            mobileHeaderFixed={mobileHeaderFixed}
            headerTitle={headerTitle}
          />
        </div>
      )}
      <div
        ref={ref}
        className={`${classes.main} ${!right && classes.full_main} ${
          header && classes.header_null
        } ${!padding && classes.padding_zero}`}
      >
        {header && (
          <div
            style={{
              position: headerFixed ? "fixed" : "relative",
              width: width,
              background:
                scroll.y < 280 && headerLinear
                  ? "linear-gradient(180deg, rgba(4, 4, 4, 100) 0%, rgba(0,0,0,0) 100%)"
                  : "transparent",
              backgroundColor:
                scroll.y > 280 ? "rgba(53,56,62,.5)" : "transparent",
            }}
            className={classes.header}
          >
            <Flex
              align="center"
              w="100%"
              justify="flex-start"
              style={{
                margin: padding ? "0px 0px 0px 0px" : "0px 0px 0px 20px",
              }}
            >
              <Text ml={20} fz={18} fw={700} color="white">
                {headerTitle}
              </Text>
              {headerRightIcon}
            </Flex>
          </div>
        )}

        {children}
      </div>
      <div className={classes.footer}>
        <div className={classes.footer_left_div}></div>
        <div className={classes.footer_main_container}>
          <Flex className={classes.footer_text_area} direction="column">
            <Title className={classes.footer_title}  color="white">
              Don’t miss what’s happening
            </Title>
            <Text className={classes.footer_text} fz={15} fw={400} color="white">
              People on PeepVoice are the first to know.
            </Text>
          </Flex>
          <Flex className={classes.button_area} direction="row" gap={15}>
            <Button onClick={loginModalOpen} className={classes.login_button}>
              {translation.login}
            </Button>
            <Button
              onClick={registerModalOpen}
              className={classes.register_button}
            >
              {translation.register}
            </Button>
          </Flex>
        </div>
      </div>
      {rightMenu()}
    </div>
  );
};

const useStyles = createStyles((theme) => ({
  layout: {
    display: "flex",
    backgroundColor: "#1E1E1E",
    width: "100vw",
    [`@media (max-width: 950px)`]: {
      flexDirection: "row",
    },
    [`@media (max-width: 500px)`]: {
      flexDirection: "column",
    },
  },
  toast_area: {
    position: "absolute",
    zIndex: 999,
    top: 20,
    left: 20,
    [theme.fn.smallerThan("sm")]: {
      right: 20,
      left: "auto",
    },
  },
  header: {
    height: 50,
    display: "flex",
    alignItems: "center",
    zIndex: 999,
    top: 0,
    width: "100%",
    justifyContent: "space-between",
    borderBottom: "1px solid rgba(98, 98, 98, 0.44)",
    [`@media (max-width: 500px)`]: {
      display: "none",
    },
  },
  mobile: {
    display: "none",
    [`@media (max-width: 500px)`]: {
      display: "flex",
    },
  },
  main: {
    minHeight: "100vh",
    zIndex: 99,
    padding: "10px 15px 10px 15px",
    width: "40vw",
    overflowX: "hidden",
    [`@media (max-width: 1265px)`]: {
      width: "60vw",
    },
    [`@media (max-width: 950px)`]: {
      width: "90vw ",
    },
    [`@media (max-width: 500px)`]: {
      width: "100vw !important",
      padding: "0px 0px 90px 0px !important ",
    },
  },
  full_main: {
    width: "70vw",
    padding: "0px 0px !important",
    [`@media (max-width: 1265px)`]: {
      width: "90vw !important",
    },
    [`@media (max-width: 500px)`]: {
      width: "100vw !important",
      padding: "0px 0px 90px 0px !important ",
    },
  },
  header_null: {
    padding: "0px 20px",
    marginTop: 0,
    [`@media (max-width: 1480px)`]: {
      padding: "0px 27px",
    },
    [`@media (max-width: 500px)`]: {
      padding: "0px 0px",
    },
  },
  padding_zero: {
    [`@media (max-width: 500px)`]: {
      padding: "0px 0px 0px 0px !important ",
    },
  },
  background_light_green: {
    backgroundColor: theme.colors.green[0],
    width: 486,
    height: 486,
    position: "fixed",
    left: "50%",
    marginLeft: "-102px",
    bottom: 0,
    opacity: 0.6,
    filter: "blur(225px)",
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      width: 204,
      height: 204,
      filter: "blur(100px)",
      opacity: 0.9,
    },
  },
  background_light_blue: {
    backgroundColor: theme.colors.blue[0],
    width: 728,
    height: 728,
    position: "fixed",
    left: "10%",
    top: 0,
    background: "rgba(77, 116, 254, 0.9)",
    opacity: 0.7,
    filter: "blur(225px)",
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      width: 328,
      height: 328,
      right: -20,
      bottom: "23%",
      filter: "blur(100px)",
      background: "rgba(77, 116, 254, 0.9)",
    },
  },
  footer: {
    backgroundColor: theme.colors.blue[0],
    height: "9.7vh",
    width: "100vw",
    position: "fixed",
    zIndex: 9999,
    bottom: 0,
    display: "flex",
    [`@media (max-width: 500px)`]: {
      display: "none",
    },
  },
  footer_left_div: {
    width: "30vw",
    [`@media (max-width: 1265px)`]: {
      width: "10vw",
    },
  },
  footer_main_container: {
    width: "70vw",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [`@media (max-width: 1265px)`]: {
      width: "90vw",
    },
  },
  login_button: {
    backgroundColor: "transparent",
    border: ".5px solid white",
    borderRadius: 20,
    transition: "all .5s",
    ":hover": {
      backgroundColor: "rgba(255,255,255,.1)",
      transform: "scale(1.05)",
      transition: "all .5s",
    },
  },
  register_button: {
    backgroundColor: "white",
    borderRadius: 20,
    color: "black",
    transition: "all .5s",
    ":hover": {
      backgroundColor: "rgba(255,255,255,.8)",
      transform: "scale(1.05)",
      transition: "all .5s",
    },
  },
  footer_text_area: {
    width: "75%",
    [`@media (max-width: 950px)`]: {
      width: "66%",
    },
    [`@media (max-width: 768px)`]: {
      width: "55%",
    },
  },
  button_area:{
    width: "25%",
    [`@media (max-width: 950px)`]: {
      width: "33%",
    },
    [`@media (max-width: 768px)`]: {
      width: "45%",
    },
  },
  footer_title:{
    fontSize:23,
    [`@media (max-width: 768px)`]: {
      fontSize:17,
    },
  },
  footer_text:{
    fontSize:15,
    [`@media (max-width: 768px)`]: {
      fontSize:12,
    },
  },
}));
export default PublicLayout;

import { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Text,
  Title,
  createStyles,
} from "@mantine/core";
import { useUpdateEvent } from "../../../api/event/service";
import { eventEnum } from "../../../utils/enums/eventEnum";
import { Link } from "react-router-dom";
const CreatorEventCard = ({ event, user, translation, creatorId }: any) => {
  const { classes } = useStyles();
  const { updateEvent } = useUpdateEvent();
  const [startEvent, setStartEvent] = useState(false);
  // useEffect(() => {
  //   if (event) {
  //     const startTime: any = new Date(event.startTime);
  //     const now: any = new Date();
  //     const _remainingTime = startTime - now;
  //     if (_remainingTime <= 0) {
  //       setStartEvent(true);
  //       setRemainingTime(`Event is started`);
  //     } else {
  //       setStartEvent(false);
  //       const days = Math.floor(_remainingTime / (1000 * 60 * 60 * 24));
  //       const hours = Math.floor(
  //         (_remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  //       );
  //       if (days > 0) {
  //         setRemainingTime(
  //           `${translation.event_page.remaining_1} ${days} ${translation.event_page.remaining_day}  ${hours} ${translation.event_page.remaining_hours} `
  //         );
  //       } else {
  //         setRemainingTime(
  //           `${translation.event_page.remaining_1} ${hours} ${translation.event_page.remaining_hours}`
  //         );
  //       }
  //     }
  //   }
  // }, [event]);
  const join = async () => {
    const data = {
      id: event.id,
      creator_id: creatorId,
      registered_users: [user?.data?.attributes?.userProfile?.id],
      type: eventEnum.JOIN_USER,
    };
    await updateEvent({ newData: data });
  };

  return (
    <Link
      to={event.isOwner || event.joined ? `/my/events/${event.id}` : ""}
      style={{
        textDecoration: "none",
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box
        style={{ borderRadius: 20 }}
        mr={4}
        w="100%"
        c="white"
        className={`${classes.box} ${
          (event.joined || event.isOwner) && classes.box_joined
        }`}
      >
        <Flex p="8px" w="100%" align="flex-start" h="100%">
          <Avatar
            variant="outline"
            size={40}
            className={`${!!event?.avatar && classes.avatar_animation}`}
            src={event?.avatar?.thumbs?.c144}
            radius={13}
            alt="Random image"
          />
          <div
            style={{
              display: "flex",
              marginLeft: 4,
              flexDirection: "column",
              height: "100%",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div className={classes.creator_info_area}>
              <Title size={14}>
                {event?.title + "'s " + translation.event.toLowerCase()}
              </Title>
              <Text className={classes.username} size={12} ta="left">
                {"@" + event?.owner_username}
              </Text>
              <div className={classes.connect_area}>
                <div
                  className={classes.connect_images_area}
                  style={{ width: event?.otherCreator.length * 22 }}
                >
                  {event?.otherCreator.length > 0 &&
                    event?.otherCreator?.map((x: any, index: number) => (
                      <div
                        className={classes.connect_img_area}
                        style={{
                          position: index !== 0 ? "absolute" : "relative",
                          left: index * 15,
                          zIndex: event?.otherCreator.length - index,
                        }}
                      >
                        <Avatar radius="100%" size="100%" src={x.thumbs?.c50} />
                      </div>
                    ))}
                </div>
                {event?.number_of_user !== 0 && (
                  <Text className={classes.connect_friends} color="#ABABB8">
                    {event?.number_of_user}{" "}
                    {translation.event_page.friends_connect}
                  </Text>
                )}
              </div>
            </div>
          </div>
          {!event.joined && !event.isOwner && (
            <div
              style={{
                minHeight: 58,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Button
                styles={{ root: { paddingLeft: 0, paddingRight: 0 } }}
                className={classes.join_button}
                onClick={join}
              >
                <Flex
                  direction="row"
                  align="center"
                  justify="center"
                  gap={5}
                  pl={5}
                  pr={5}
                >
                  <Text color="black" fz={12}>
                    {startEvent ? "Join" : "Register"}
                  </Text>
                  {/*
                  <Image
                    width={19}
                    height={19}
                    src={require("../../../assets/amount.png")}
                  />
                 <Text color="black" fz={12}>
                    10
                  </Text> */}
                </Flex>
              </Button>
            </div>
          )}
        </Flex>
      </Box>
    </Link>
  );
};
const useStyles = createStyles((theme) => ({
  creator_info_area: {
    display: "flex",
    height: "100%",
    marginLeft: 16,
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  username: {
    color: "#C9C9C9",
  },
  join_button: {
    fontSize: 12,
    height: "50%",
    padding: 4,
    borderRadius: 20,
    backgroundColor: theme.colors.green[0],
  },
  connect_images_area: {
    position: "relative",
    display: "flex",
    width: 60,
  },
  connect_img_area: {
    width: 22,
    height: 22,
    border: "1px solid #5E6DA1",
    borderRadius: "50%",
    backgroundColor: "white",
  },
  connect_area: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  connect_friends: {
    fontSize: 11,
    marginLeft: 5,
    [`@media (max-width: 1280px)`]: {
      fontSize: 13,
    },
    [`@media (max-width: 1100px)`]: {
      fontSize: 11,
    },
  },
  box: {
    background: "rgba(255,255,255,.2)",
    paddingBottom: 10,
  },
  box_joined: {
    background:
      "linear-gradient(280deg, rgba(121,159,21,.3) 0%, rgba(210,233,81,.3) 100%)",
  },
  avatar_animation: {
    opacity: 0,
    animationName: "avatarAnimation",
    animationDuration: ".3s",
    animationTimingFunction: "linear",
    animationFillMode: "both",
    "@keyframes avatarAnimation": {
      "0%": {
        opacity: 0,
      },

      "100%": {
        opacity: 1,
      },
    },
  },
}));
export default CreatorEventCard;


const SettingsIcon = ({ active }: any) => {
  // active ? "#D2E951":"#8C8FA5"
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0714 22.1429C17.6338 22.1429 22.1429 17.6338 22.1429 12.0714C22.1429 6.50914 17.6338 2 12.0714 2C6.50914 2 2 6.50914 2 12.0714C2 17.6338 6.50914 22.1429 12.0714 22.1429Z"
        stroke={active ? "#D2E951":"#8C8FA5"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0718 12.8463C12.4997 12.8463 12.8466 12.4995 12.8466 12.0716C12.8466 11.6437 12.4997 11.2969 12.0718 11.2969C11.644 11.2969 11.2971 11.6437 11.2971 12.0716C11.2971 12.4995 11.644 12.8463 12.0718 12.8463Z"
        fill={active ? "#D2E951":"#8C8FA5"}
        stroke={active ? "#D2E951":"#8C8FA5"}   
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.4228 12.8463C7.85067 12.8463 8.19752 12.4995 8.19752 12.0716C8.19752 11.6437 7.85067 11.2969 7.4228 11.2969C6.99493 11.2969 6.64807 11.6437 6.64807 12.0716C6.64807 12.4995 6.99493 12.8463 7.4228 12.8463Z"
        fill={active ? "#D2E951":"#8C8FA5"}
        stroke={active ? "#D2E951":"#8C8FA5"}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7199 12.8463C17.1478 12.8463 17.4946 12.4995 17.4946 12.0716C17.4946 11.6437 17.1478 11.2969 16.7199 11.2969C16.292 11.2969 15.9452 11.6437 15.9452 12.0716C15.9452 12.4995 16.292 12.8463 16.7199 12.8463Z"
        fill={active ? "#D2E951":"#8C8FA5"}
        stroke={active ? "#D2E951":"#8C8FA5"}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SettingsIcon;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { AppDispatch, RootState } from "../store/store";
import {
  setUser,
  setUserToken,
} from "../store/reducers/user.reducer";
import { useLocalStorage } from "@mantine/hooks";
import { getToken } from "../utils/auth/auth";
import restClient from "../api/restClient";
import { useState } from "react";
import { logEvent, setUserGA } from "../utils/analytics-service";
import { reAuthEvent } from "../utils/log-events";
export const ReAuth = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const [token, setToken] = useLocalStorage({
    key: "token",
    defaultValue: null,
  });

  const error: any = useAppSelector(
    (state: RootState) => state.authReducer.error
  );

  const [loading, setLoading] = useState(true);

  const reAuth = async (url?: string) => {
    setLoading(true);
    try {
      
      const token = await getToken();

      if (!token) {
        deleteUserInfo();
        setLoading(false);
      }
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        await restClient
          .get(`/account`, config)
          .then(function (response) {
            handleUserInfo(response.data.data);
            logEvent(reAuthEvent);
            setUserGA(response.data.data.id)
            setTimeout(()=>{
              setLoading(false);
            },2000)
          })
          .catch(function (error) {
            deleteUserInfo();
            setLoading(false);
          });
      } catch (error) {
        deleteUserInfo();
        setLoading(false);
      }
    } catch (error) {
      deleteUserInfo();
      setLoading(false);
    }
  };

  const handleUserInfo = async (user: any) => {
    dispatch(setUser(user));
  };

  const deleteUserInfo = async () => {
    dispatch(setUserToken(null));
    setToken(null);
    dispatch(setUser(null));
  };

  return {
    loading,
    error,
    reAuth
  };
};

import {  Loader, createStyles } from "@mantine/core";
import {
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { HiOutlineChevronRight } from "react-icons/hi";

const StepsButton = ({
  translation,
  nextStep,
  step,
  steps,
  error,
  loading,
}: any) => {
  const { classes } = useStyles();

  return (
    <div
      onClick={() => (loading ? null : nextStep())}
      className={classes.button_area}
    >
      {loading ? (
        <div className={classes.start_button_area}>
          <div
            className={classes.start_button}
            style={{
              backgroundColor: "rgba(77, 116, 254, 0.9)",
            }}
          >
            <Loader color="white" />
          </div>
          <div
            className={classes.start_button_background_div_1}
            style={{
              filter: "drop-shadow(0px 0px 24px rgba(77, 116, 254, 0.5)",
              background:
                "linear-gradient(180deg, rgba(77, 116, 254, 0.9) 0%, rgba(121, 159, 12, 0) 100%)",
            }}
          ></div>
          <div
            className={classes.start_button_background_div_2}
            style={{
              filter: "drop-shadow(0px 0px 24px rgba(77, 116, 254, 0.5)",
              background:
                "linear-gradient(180deg, rgba(77, 116, 254, 0.9) 0%, rgba(121, 159, 12, 0) 100%)",
            }}
          ></div>
        </div>
      ) : steps !== step ? (
        <CircularProgressbarWithChildren
          value={step}
          maxValue={steps}
          styles={{
            trail: {
              stroke: `rgba(181, 181, 181, 0.2)`,
            },
            path: {
              stroke: error ? "rgba(77, 116, 254, 0.9)" : `#D2E951`,
            },
          }}
        >
          <div
            className={classes.children}
            style={{
              backgroundColor: error ? "rgba(77, 116, 254, 0.9)" : "#D2E951",
            }}
          >
            {steps === step ? (
              translation.create_creator_page.start
            ) : (
              <HiOutlineChevronRight
                color={error ? "white" : "black"}
                size={25}
              />
            )}
          </div>
        </CircularProgressbarWithChildren>
      ) : (
        <div className={classes.start_button_area}>
          <div
            className={classes.start_button}
            style={{
              color: error ? "white" : "black",
              backgroundColor: error ? "rgba(77, 116, 254, 0.9)" : "#D2E951",
            }}
          >
            {translation.create_creator_page.start}
          </div>
          <div
            className={classes.start_button_background_div_1}
            style={{
              filter: error
                ? "drop-shadow(0px 0px 24px rgba(77, 116, 254, 0.5)"
                : "drop-shadow(0px 0px 24px rgba(210, 233, 81, 0.5))",
              background: error
                ? "linear-gradient(180deg, rgba(77, 116, 254, 0.9) 0%, rgba(121, 159, 12, 0) 100%)"
                : "linear-gradient(180deg, rgba(210, 233, 81, 0.9) 0%, rgba(121, 159, 12, 0) 100%)",
            }}
          ></div>
          <div
            className={classes.start_button_background_div_2}
            style={{
              filter: error
                ? "drop-shadow(0px 0px 24px rgba(77, 116, 254, 0.5)"
                : "drop-shadow(0px 0px 24px rgba(210, 233, 81, 0.5))",
              background: error
                ? "linear-gradient(180deg, rgba(77, 116, 254, 0.9) 0%, rgba(121, 159, 12, 0) 100%)"
                : "linear-gradient(180deg, rgba(210, 233, 81, 0.9) 0%, rgba(121, 159, 12, 0) 100%)",
            }}
          ></div>
        </div>
      )}
    </div>
  );
};
const useStyles = createStyles((theme) => ({
  button_area: {
    width: "100px",
    height: "100px",
    cursor: "pointer",
  },
  children: {
    width: "64px",
    height: "64px",
    borderRadius: "50%",
    backgroundColor: theme.colors.green[0],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  start_button_area: {
    width: 100,
    height: 100,
    cursor: "pointer",
    displa: "flex",
    position: "relative",
  },
  start_button_background_div_1: {
    position: "absolute",
    transform: "rotate(16.42deg)",
    borderRadius: "50%",
    top: -5,
    left: -8,
    zIndex: -1,
    width: 100,
    height: 100,
  },
  start_button_background_div_2: {
    position: "absolute",
    borderRadius: "50%",
    transform: "rotate(165deg)",
    bottom: -5,
    right: -8,
    zIndex: -1,
    width: 100,
    height: 100,
  },
  start_button: {
    width: 100,
    height: 100,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default StepsButton;

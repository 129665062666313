import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  getChatsFn,
  getCreatorDetailFn,
  getLastMessage,
  getMessages,
  postMessageAudioFn,
  postMessageFn,
} from "../api";
import { logEvent } from "../../../utils/analytics-service";
import { sendAudioMessage, sendTextMessage } from "../../../utils/log-events";

export const useGetChats = () => {
  const { data, isLoading, isError, error, isFetchingNextPage, fetchNextPage } =
    useInfiniteQuery({
      queryKey: ["chats"],
      queryFn: ({ pageParam }) => getChatsFn(pageParam),
      getNextPageParam: (lastPage: any, allPages) => {
        return lastPage.results?.length === 15
          ? allPages.length + 1
          : undefined;
      },
    });

  return { data, isLoading, isFetchingNextPage, fetchNextPage, isError, error };
};

export const useGetChatCreatorDetail = (id: number) => {
  const queryClient = useQueryClient();

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["chat-creator-detail-", id],
    queryFn: () => getCreatorDetailFn(id),
    refetchInterval: 3000,
    // staleTime: 3600000,
    onSuccess: async (data: any) => {
      queryClient.invalidateQueries({ queryKey: ["events"] });
    },
  });

  return { data, isLoading, isError, error };
};

// Hook, um Nachrichten zu bekommen und die letzte Nachricht alle 3 Sekunden zu aktualisieren

export const useGetMessages = (id: number) => {
  const queryClient = useQueryClient();
  const {
    data,
    isLoading,
    isError,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<any>(
    ["messages-", id],
    ({ pageParam = 0 }) => getMessages(pageParam, id),
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.results?.length === 30
          ? allPages.length + 1
          : undefined;
      },
      onSuccess: async (newData: any) => {
        queryClient.invalidateQueries({ queryKey: ["chats"] });
      },
    }
  );

  useQuery(["last-message", id], () => getLastMessage(id), {
    refetchInterval: 3000,
    onSuccess: (newMessage) => {
      queryClient.setQueryData<any>(["messages-", id], (old: any) => {
        if (old && old.pages.length > 0) {
          // Überprüfen, ob die neue Nachricht bereits vorhanden ist
          const alreadyExists = old.pages[0].results.some(
            (message: any) => message.id === newMessage.id
          );

          if (!alreadyExists) {
            return {
              ...old,
              pages: [
                {
                  results: [newMessage, ...old.pages[0].results],
                  pagination: old.pages[0].pagination,
                },
                ...old.pages.slice(1),
              ],
            };
          }
        }

        return old;
      });
    },
  });

  return {
    data,
    isLoading,
    isError,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  };
};

export const usePostMessage = (senderId: number, id: number) => {
  const queryClient = useQueryClient();

  // let intervalMs = 1000
  const {
    data,
    isLoading,
    isError,
    error,
    isSuccess,
    isIdle,
    mutate: sendMessage,
  } = useMutation((data: any) => postMessageFn(data), {
    onMutate: async (data: any) => {
      await queryClient.cancelQueries({ queryKey: ["messages-", id] });
      const previousData: any = queryClient.getQueryData(["messages-", id]);

      queryClient.setQueryData(["messages-", id], (oldQuerydata: any) => {
        return {
          ...oldQuerydata,
          pages: [
            {
              ...oldQuerydata.pages[0],
              results: [
                {
                  messageSenderInfo: { id: senderId },
                  isCreator: data?.creator_profile !== null ? true : false,
                  isLoading: true,
                  contentType: "text",
                  attributes: data,
                },
                ...oldQuerydata.pages[0].results,
              ],
            },
          ],
        };
      });
      return { previousData };
    },
    onSuccess: async (data: any) => {
      logEvent(sendTextMessage);
      queryClient.invalidateQueries({ queryKey: ["messages-", id] });
      queryClient.invalidateQueries({ queryKey: ["chats"] });
    },
    onError: (error, variables, context) => {
      if (context?.previousData) {
        queryClient.setQueryData(["messages-", id], context.previousData);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["messages-", id] });
    },
  });

  return { data, isLoading, isIdle, isSuccess, isError, error, sendMessage };
};
export const usePostMessageAudio = (
  senderId: number,
  id: number,
  blob: any
) => {
  const queryClient = useQueryClient();
  // let intervalMs = 1000
  const {
    data,
    isLoading,
    isError,
    error,
    isSuccess,
    isIdle,
    mutate: sendMessageAudioChat,
  } = useMutation(({ data, audio }: any) => postMessageAudioFn(data, audio), {
    onMutate: async (data: any) => {
      await queryClient.cancelQueries({ queryKey: ["messages-", id] });
      const previousData: any = queryClient.getQueryData(["messages-", id]);

      queryClient.setQueryData(["messages-", id], (oldQuerydata: any) => {
        return {
          ...oldQuerydata,
          pages: [
            {
              ...oldQuerydata.pages[0],
              results: [
                {
                  messageSenderInfo: { id: senderId },
                  isCreator: data?.creator_profile !== null ? true : false,
                  isLoading: true,
                  audio: { audio: { url: blob } },
                  id:
                    oldQuerydata.pages[0].results.length === 0
                      ? 0
                      : oldQuerydata.pages[0].results[0].id + 1,
                  attributes: { ...data.data },
                },
                ...oldQuerydata.pages[0].results,
              ],
            },
          ],
        };
      });
      return { previousData };
    },
    onSettled: async (data: any) => {
      queryClient.invalidateQueries({ queryKey: ["messages-", id] });
    },
    onSuccess: async (data: any) => {
      logEvent(sendAudioMessage);
      queryClient.invalidateQueries({ queryKey: ["chats"] });
    },
    // onError: (err, newTodo, context:any) => {
    //   queryClient.setQueryData(["messages-", id], context.previousTodos)
    // },
  });

  return {
    data,
    isLoading,
    isIdle,
    isSuccess,
    isError,
    error,
    sendMessageAudioChat,
  };
};

import { useEffect, useState } from "react";
import {
  Flex,
  createStyles,
  Button,
  Image,
  Text,
  Title,
  Avatar,
} from "@mantine/core";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TbMenu } from "react-icons/tb";
import { Auth } from "../../../hooks/auth.hook";
import { IoArrowBackSharp, IoStatsChartOutline } from "react-icons/io5";
import { HiOutlineUser } from "react-icons/hi";
import { SlLogout } from "react-icons/sl";
import CreateCreatorPreFaceMobile from "../../low-level/CreateCreator/CreateCreatorPreFaceMobile";
import HomeIcon from "../../../assets/left-menu/home-icon";
import ChatIcon from "../../../assets/left-menu/chat-icon";
import SettingsIcon from "../../../assets/left-menu/settings-icon";
import { useViewportSize } from "@mantine/hooks";
import { useGetUser } from "../../../api/user/service";
interface IPropsState {
  header?: boolean;
  headerTitle?: string;
  mobileHeaderFixed?: boolean;
  translation: any;
  backButton?: any;
  mobileHeaderBack?: boolean;
}
const Header = ({
  header,
  headerTitle,
  translation,
  mobileHeaderFixed,
  backButton,
  mobileHeaderBack,
}: IPropsState) => {
  const { logoutUser } = Auth();
  const navigate = useNavigate();
  const location = useLocation();

  const [opened, setOpened] = useState(false);
  const [openCreateCrator, setOpenCreateCreator] = useState(false);
  const { classes } = useStyles();
  const{data:user}= useGetUser()
  const { height } = useViewportSize();

  const data = [
    {
      title: translation.home,
      to: "/my/feed",
      permission: true,
      icon: <HomeIcon active={location.pathname === "/my/feed"} />,
    },
    /*{
      title: "Notification",
      to: "#",
      permission: true,
      icon: <BsBell size={25} />,
    },*/
    {
      title: translation.profile,
      to: "/my/profile",
      permission: user?.data?.isCreator,
      icon: (
        <HiOutlineUser
          className={`${
            (location.pathname === "/my/profile" ||
              location.pathname === "/my/profile/edit") &&
            classes.active_link
          }`}
          size={26}
        />
      ),
    },

    {
      title: translation.chat,
      to: "/my/chat",
      permission: true,
      icon: <ChatIcon active={location.pathname === "/my/chat"} />,
    },
    {
      title: translation.statistics,
      to: "/my/statistics",
      permission: user?.data?.isCreator,
      icon: (
        <IoStatsChartOutline
          color={
            location.pathname.includes("my/statistics")
              ? "#D2E951"
              : "#8C8FA5"
          }
        />
      ),
    },
    {
      title: translation.settings,
      to: "/my/settings",
      permission: true,
      icon: <SettingsIcon active={location.pathname.includes("my/settings")} />,
    },
    // {
    //   title: "Deine Lips",
    //   to: "/my/payment",
    //   permission: true,
    //   icon: (
    //     <RiMoneyDollarCircleLine
    //       className={`${
    //         location.pathname === "/payment" && classes.active_link
    //       }`}
    //       size={25}
    //     />
    //   ),
    // },
  ];

  useEffect(() => {
    if (opened) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "visible";
    };
  }, [opened]);

  const drawer = () => {
    if (opened) {
      return (
        <div
          onClick={() => setOpened(false)}
          className={`${classes.drawer_menu} `}
        >
          <div
            onClick={(e: any) => {
              e.stopPropagation();
            }}
            style={{height:height,}}
            className={classes.drawer_menu_container}
          >
            <Flex
              h={height}
              justify="space-between"
              direction="column"
              bg="blue"
            >
              <Flex direction="column" justify="space-between" mt={8}>
                <Flex
                  direction="row"
                  justify="space-between"
                  align="center"
                  className={classes.user_info}
                >
                  {user?.data.isCreator && (
                    <>
                      <Avatar
                        size={55}
                        radius={"50%"}
                        src={
                          user?.data?.attributes?.creatorProfile?.avatarThumbs?.thumbs
                            .c250
                        }
                      />
                      <Flex direction="column">
                        <Title fz={18} color="white">
                          {user?.data.attributes?.creatorProfile?.name}
                        </Title>
                        <Text color="#969B9E">
                          {"@" + user?.data?.attributes?.creatorProfile?.username}
                        </Text>
                      </Flex>
                    </>
                  )}
                  <Link
                    to="/my/payment"
                    style={{ textDecoration: "none" }}
                    className={classes.amount_area}
                  >
                    <Image
                      width={20}
                      height={20}
                      mr={7}
                      src={require("../../../assets/amount.png")}
                    />
                    {user?.data.attributes?.wallet?.balance
                      ? user?.data?.attributes?.wallet?.balance
                      : 0}
                  </Link>
                </Flex>
                <Flex
                  gap="md"
                  justify="space-between"
                  align="center"
                  direction="column"
                  wrap="wrap"
                  style={{
                    borderBottom: "1px solid #525252",
                    marginBottom: 20,
                  }}
                >
                  {data.map(
                    (x: any,i:number) =>
                      x.permission && (
                        <Link
                        key={i}
                          to={x.to}
                          className={`${classes.link} ${
                            location.pathname === x.to &&
                            classes.active_link_button
                          }`}
                        >
                          {" "}
                          <Flex align="center">
                            {x?.icon}
                            <Text
                              className={`${
                                location.pathname === x.to && classes.link_text
                              }`}
                              ml={13}
                            >
                              {x.title}
                            </Text>
                          </Flex>
                        </Link>
                      )
                  )}
                </Flex>
                <Flex w="100%" justify="center">
                  <Link
                    className={classes.new_post_and_become_creator_button}
                    to={!user?.data.isCreator ? "" : "/post/create"}
                    onClick={() =>
                      !user?.data.isCreator
                        ? (setOpened(false), setOpenCreateCreator(true))
                        : undefined
                    }
                  >
                    <span style={{ color: "black" }}>
                      {!user?.data.isCreator
                        ? translation.left_menu.createCreator
                        : translation.left_menu.createPost}
                    </span>
                  </Link>
                </Flex>
              </Flex>
              <Flex
                direction="column"
                justify="space-between"
                align="left"
                mb={10}
              >
                <Flex mb={20} direction="row" justify="space-around">
                  <Link className={classes.terms_link} to="/privacy">{translation.datenschutz}</Link>
                  <Link className={classes.terms_link} to="/terms">{translation.agb}</Link>
                  <Link className={classes.terms_link} to="/legalnotice">{translation.legal_notice}</Link>
                </Flex>
                <Button onClick={logoutUser}>
                  <SlLogout size={20} color="whtie" />
                  <Text fz={18} ml={20}>
                    {translation.logout}
                  </Text>
                </Button>
              </Flex>
            </Flex>
          </div>
        </div>
      );
    }
  };
  return (
    <>
      {drawer()}
      {openCreateCrator && (
        <CreateCreatorPreFaceMobile translation={translation} />
      )}
      <div
        className={classes.main}
        style={{
          position: mobileHeaderFixed ? "fixed" : "relative",
          width: "100%",
          height: 60,
          padding: "0px 10px",
        }}
      >
        <Flex
          style={{ height: 60 }}
          gap="md"
          justify="space-between"
          align="center"
          direction="row"
          wrap="wrap"
        >
          {headerTitle !== translation.home ? (
            <Button
              style={{ height: 50 }}
              onClick={() => (mobileHeaderBack ? backButton() : navigate(-1))}
            >
              <IoArrowBackSharp size={25} />
            </Button>
          ) : (
            <Button style={{ height: 50 }} onClick={() => setOpened(true)}>
              <TbMenu size={25} />
            </Button>
          )}
          <div className={classes.title}>
            {" "}
            <Text truncate>{headerTitle}</Text>
          </div>
          <div
            className={classes.amount_area}
            onClick={() => navigate("/my/payment")}
          >
            <Image
              width={20}
              height={20}
              mr={4}
              src={require("../../../assets/amount.png")}
            />
            {user?.data.attributes?.wallet?.balance}
          </div>
        </Flex>
      </div>
    </>
  );
};
const useStyles = createStyles((theme) => ({
  main: {
    zIndex: 999,
    backgroundColor: theme.colors.background[0],
  },
  title: {
    fontSize: 20,
    width: "50%",
    fontWeight: 600,
    color: "white",
  },
  drawer_menu: {
    backgroundColor: "gba(35, 35, 35, 0.5)",
    backdropFilter: "blur(4.5px)",
    width: "100vw",
    position: "fixed",
    overflowY: "hidden",
    height: "100vh",
    zIndex: 9999,
  },
  drawer_menu_container: {
    left: 0,
    backgroundColor: "#35383E",
    zIndex: 99999,
    width: "80%",
  },
  active_link_button: {
    backgroundColor: "rgba(77, 116, 254, .4)",
    color: "white",
    fontSize: 18,
    fontWeight: 600,
  },
  active_link: {
    color: "white",
    fontSize: 18,
    fontWeight: 600,
  },
  user_info: {
    padding: "15px 15px",
    borderBottom: "1px solid #525252",
  },
  link_text: {
    color: "white",
  },
  new_post_and_become_creator_button: {
    backgroundColor: theme.colors.green[0],
    color: "#111111",
    fontSize: 18,
    fontWeight: "bold",
    width: "calc(100% - 70px)",
    borderRadius: 58,
    padding: "10px",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  link: {
    color: "#8C8FA5",
    fontSize: 18,
    width: "100%",
    padding: "15px 35px",
    textDecoration: "none",
  },

  amount_area: {
    padding: "0px 10px",
    height: 36,
    borderRadius: 21,
    backgroundColor: " rgba(255, 255, 255, 0.2)",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 14,
    fontWeight: 900,
  },
  terms_link:{
    color: "#8C8FA5",
    fontSize: 14,
    textDecoration: "none",
  }
}));
export default Header;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from "react";
import {
  ActionIcon,
  Avatar,
  Button,
  Center,
  createStyles,
  Divider,
  Flex,
  Image,
  Input,
  Loader,
  Menu,
  Modal,
  Text,
  Title,
} from "@mantine/core";
import Layout from "../../components/high-level/layout/layout";
import { BiSearch } from "react-icons/bi";
import { useDisclosure, useViewportSize } from "@mantine/hooks";
import { IoIosArrowRoundBack } from "react-icons/io";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { useScrollRestoration } from "use-scroll-restoration";
import { TbDots } from "react-icons/tb";
import "./styles.css";
import {
  useGetNotificationCount,
  useNotificationSeenAll,
} from "../../api/notifications/service";
import { useGetUser } from "../../api/user/service";
import {
  useGetEvent,
  useGetEvents,
  useUpdateEvent,
} from "../../api/event/service";
import EventRightArea from "../../components/low-level/Events/EventRightArea";
import { eventEnum } from "../../utils/enums/eventEnum";
import MessagesIcon from "../../assets/event/messages";
import MembersIcon from "../../assets/event/members";
import VoiceIcon from "../../assets/event/voice";
import { useOnlineChatMembersService } from "../../api/chat/service/online-chat-members-service";
import EventCreatorDetailArea from "../../components/low-level/Events/EventCreatorDetailArea";
import { AiOutlineClose } from "react-icons/ai";
import useAgora from "../../api/event/service/agora-service";
import AgoraPlayer from "../../components/low-level/AgoraPlayer/AgoraPlayer";
import { useSocket } from "../../contexts/socketContext";
import { passingTime } from "../../utils/passing-time";

const EventsPage = ({ translation }: any) => {
  let { id }: any = useParams();
  const location = useLocation();
  const { data: eventData } = useGetEvent(parseInt(id));

  const { width, height } = useViewportSize();
  const { classes } = useStyles({ height });
  const divRef: any = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const [search, setSearch]: any = useState("");
  const [openVoice, setOpenVoice] = useState(false);
  const [openMemberArea, setOpenMemberArea] = useState(false);
  const [mobileMenuBigger, setMobileFooterBigger] = useState(false);
  const [groupActive, setGroupActive]: any = useState(false);
  const [footerControl, setFooterControl]: any = useState(false);
  const { data: user } = useGetUser();
  const { data, isLoading, isFetchingNextPage, fetchNextPage } = useGetEvents();
  const [newData, setNewData]: any = useState([]);
  const [newMembersData, setNewMembersData]: any = useState([]);
  const [creatorHeight, setCreatorHeight] = useState(0);
  const [opened, { open, close }] = useDisclosure(false);
  const [
    memberControl,
    { open: openMemberControlModal, close: closeMemberControlModal },
  ] = useDisclosure(false);

  const { seenAllNotificationFn } = useNotificationSeenAll();
  const { data: notificationCount } = useGetNotificationCount();
  const { updateEvent, isLoading: loadingUpdate, isSuccess } = useUpdateEvent();
  const [openChat, setOpenChat] = useState(false);
  const [eventControlLoading, setEventControlLoading] = useState(true);
  const members = useOnlineChatMembersService();
  const { excludeMemberFromChat }: any = useSocket();

  const {
    // localVideoTrack,
    leave,
    join,
    init,
    joinState,
    remoteUsers,
    connecting,
    clientInitialized,
    mute,
    unmute,
    isMuted,
  }: any = useAgora(user?.data?.attributes?.user);

  useEffect(() => {
    if (newData.length > 0 && id) {
      const exists = newData.find((item: any) => item.id.toString() === id);
      if (exists && !exists?.joined && !exists?.isOwner) {
        setEventControlLoading(false);
        // openMemberControlModal();
        navigate("/my/feed");
      } else {
        setEventControlLoading(false);
      }
      if (!exists) {
        setEventControlLoading(false);
        navigate("/my/feed");
      }
    }
  }, [newData, id, members]);

  useEffect(() => {
    init();
  }, [user]);

  useEffect(() => {
    if (remoteUsers.length > 0) {
      setOpenVoice(true);
    }
  }, [remoteUsers, joinState]);

  useEffect(() => {
    if (eventData?.results?.isOwner && !footerControl) {
      setOpenVoice(true);
      setFooterControl(true);
    }
    if (clientInitialized && !eventData?.results?.isOwner) {
      join(chatId, "audience");
    }
  }, [eventData, user, clientInitialized, footerControl]);

  useEffect(() => {
    if (
      remoteUsers.length === 0 &&
      eventData &&
      !eventData?.results?.isOwner &&
      !footerControl
    ) {
      setOpenVoice(false);
      setFooterControl(true);
    }
  }, [remoteUsers, eventData, footerControl]);

  // const userId = user?.data?.attributes?.user?.id;
  // const profileId = user?.data?.attributes?.userProfile?.id;
  const chatId = eventData?.results?.attributes?.chat?.data?.id;

  const { ref, setScroll } = useScrollRestoration("chat", {
    debounceTime: 0,
    persist: "localStorage",
  });

  useEffect(() => {
    if (
      !openVoice &&
      !openMemberArea &&
      eventData &&
      !eventData?.results?.isOwner
    ) {
      setOpenChat(true);
    }
  }, [openVoice, openMemberArea, eventData]);

  function mergeAndMapData(chatData: any, eventData: any) {
    const mergedData = eventData?.results?.users?.registered_users?.map(
      (member: any) => {
        const matchedUser = chatData?.members.find(
          (user: any) => user.profileId === member.id
        );
        if (matchedUser) {
          member.isOnline = matchedUser.isOnline;
        }
        return member;
      }
    );
    return mergedData;
  }

  // Log the updated data
  useEffect(() => {
    if (eventData) {
      let membersData = members[eventData?.results?.attributes?.chat?.data?.id];
      const updatedMembersData = mergeAndMapData(membersData, eventData);
      setNewMembersData(updatedMembersData);
    }
  }, [members, eventData, id]);

  useEffect(() => {
    return () => {
      leave(); 
    };
  }, [navigate, location]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);
  useEffect(() => {
    if (id && notificationCount > 0) {
      seenAllNotificationFn();
    }
  }, [id, location.pathname, notificationCount]);

  const flattenedData: any = useMemo(
    () => (data ? data?.pages?.flatMap((item: any) => item?.results) : []),
    [data]
  );

  useEffect(() => {
    const handleScroll = () => {
      const currentDivRef = divRef.current;
      if (
        !isLoading &&
        !isFetchingNextPage &&
        currentDivRef &&
        currentDivRef?.scrollTop + currentDivRef?.clientHeight >=
          currentDivRef.scrollHeight
      ) {
        setScroll({ y: divRef?.scrollTop });
        fetchNextPage();
      }
    };
    const currentDivRef = divRef.current;

    if (currentDivRef) {
      currentDivRef.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (currentDivRef) {
        currentDivRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isFetchingNextPage, divRef.current, isLoading, flattenedData]);

  useEffect(() => {
    if (search.length > 0) {
      const data = flattenedData.filter((item: any) =>
        item?.attributes?.creator_profiles?.data?.username
          .toLowerCase()
          .includes(search)
      );
      setNewData(data);
    } else {
      setNewData(flattenedData);
    }
  }, [search, flattenedData]);

  useEffect(() => {
    if (id !== undefined) {
      setGroupActive(true);
    }
  }, [id, location.pathname]);

  const removeFromEvent = (userId: number) => {
    const data = {
      id: id,
      registered_users: [userId],
      type: eventEnum.REMOVE_USER,
    };
    updateEvent({ newData: data });
    excludeMemberFromChat(chatId, userId);
  };
  const banFromEvent = (userId: number) => {
    const data = {
      id: id,
      registered_users: [userId],
      type: eventEnum.BAN_USER,
    };
    updateEvent({ newData: data });
    excludeMemberFromChat(chatId, userId);
  };

  const joinEvent = async () => {
    const data = {
      id: id,
      registered_users: [user?.data?.attributes?.userProfile?.id],
      type: eventEnum.JOIN_USER,
    };
    await updateEvent({ newData: data });
  };
  const CreatorModal = () => {
    return (
      <Modal
        classNames={{ body: classes.modal_body }}
        withCloseButton={false}
        overlayProps={{
          opacity: 0.75,
          blur: 5,
        }}
        centered
        size={600}
        zIndex={999}
        opened={opened}
        onClose={close}
      >
        <Flex
          direction="row"
          justify="space-between"
          align="center"
          mb={20}
          className={classes.modal_title}
        >
          <Flex direction="column" justify="center">
            <Title className={classes.name} fz={20} fw="bold" color="white">
              {eventData?.results?.owner?.name}
            </Title>
            <Text color="#A5A5A5" fz={16}>
              {" "}
              {"@" + eventData?.results?.owner?.username}
            </Text>
          </Flex>
          <ActionIcon variant="transparent" mr={5} onClick={close}>
            <AiOutlineClose color="white" size={20} />
          </ActionIcon>
        </Flex>

        <Image
          width="100%"
          height="45vh"
          fit="cover"
          styles={{ image: { border: "2px solid black" } }}
          radius={20}
          src={eventData?.results?.owner?.avatar?.sizes?.original}
        />

        <Title fz={20} mt={20} mb={7} color="white">
          {translation.profile_edit_page.about_me}
        </Title>
        <Text fz={15} color="white">
          {eventData?.results?.owner?.about}
        </Text>
      </Modal>
    );
  };
  const MemberControlModal = () => {
    return (
      <Modal
        classNames={{ body: classes.modal_body }}
        withCloseButton={false}
        closeOnClickOutside={false}
        overlayProps={{
          opacity: 0.75,
          blur: 5,
        }}
        centered
        size={600}
        zIndex={999}
        opened={memberControl}
        onClose={closeMemberControlModal}
      >
        <Text align="center" fz={20} mt={20} mb={7} color="white">
          {translation.event_page.not_register_event}
        </Text>
        <Flex gap={10} w="100%" justify="center" align="center">
          <Button
            onClick={joinEvent}
            style={{ backgroundColor: "green" }}
            w="48%"
            variant="filled"
          >
            {translation.event_page.register}
          </Button>
          <Button onClick={() => navigate("/my/feed")} color="red" w="48%">
            {translation.event_page.return_go_home}
          </Button>
        </Flex>
      </Modal>
    );
  };
  const handleJoinAsHost = () => {
    console.log("AGORA0");
    join(chatId, "host");
  };

  // const handleJoin = () => {
  //   console.log("AGORA0");
  //   join(chatId, "audience");
  // };
  if (eventControlLoading) {
    return (
      <Layout
        padding={false}
        littleLeftMenu={true}
        headerTitle={translation.events}
        hiddenFooter={true}
        right={false}
        headerFixed={false}
        mobileHeaderFixed={false}
        mobileHeader={false}
        header={!id && width > 768}
      >
        <div className={classes.logo}>
          <Image
            src={require("../../assets/logo_loading.png")}
            fit="contain"
            height="20vh"
            width="20vw"
          />
        </div>
      </Layout>
    );
  }

  return (
    <Layout
      padding={false}
      littleLeftMenu={true}
      headerTitle={translation.events}
      hiddenFooter={true}
      right={false}
      headerFixed={false}
      mobileHeaderFixed={false}
      mobileHeader={false}
      header={!id && width > 768}
    >
      {remoteUsers?.map((user: any, index: number) => (
        <AgoraPlayer key={index} audioTrack={user.audioTrack}></AgoraPlayer>
      ))}
      {id === undefined && (
        <div className={classes.header_mobile}>
          <button
            style={{
              height: 50,
              width: 50,
              cursor: "pointer",
              backgroundColor: "transparent",
              textDecoration: "none",
              border: 0,
              position: "relative",
            }}
            className={classes.back_button}
            onClick={() => navigate(`/my/feed`)}
          >
            <IoIosArrowRoundBack color="white" size="100%" />
          </button>
          <Text
            className={classes.back_button_text}
            fz={18}
            fw={700}
            color="white"
          >
            Creators Chat List
          </Text>
        </div>
      )}
      <Flex
        onClick={() =>
          mobileMenuBigger && width < 768 && setMobileFooterBigger(false)
        }
        direction="column"
      >
        <CreatorModal />
        <MemberControlModal />
        {id && user && (
          <EventCreatorDetailArea
            user={user}
            open={open}
            translation={translation}
            openVoice={openVoice}
            setOpenChat={setOpenChat}
            handleJoinAsHost={handleJoinAsHost}
            openChat={openChat}
            setOpenVoice={setOpenVoice}
            setCreatorHeight={setCreatorHeight}
            creatorData={eventData}
            isMuted={isMuted}
            mute={mute}
            unmute={unmute}
          />
        )}
        <Flex
          direction="row"
          className={classes.left_menu_area}
          style={{
            overflowY: "hidden",
            overflowX: "hidden",
            height: `calc(100dvh - ${creatorHeight}px`,
          }}
        >
          {(groupActive
            ? eventData?.results?.isOwner
              ? true
              : false
            : true) &&
            ((!openChat && !openVoice) || width > 768) && (
              <div
                className={`${classes.left_menu} 
          ${
            id !== undefined ||
            (openMemberArea && classes.left_menu_select_creator)
          }`}
              >
                {!groupActive && (
                  <div className={classes.search_area}>
                    <Input
                      icon={<BiSearch />}
                      value={search}
                      onChange={(e: any) => setSearch(e.target.value)}
                      classNames={{ input: classes.search }}
                      placeholder="Search"
                    />
                  </div>
                )}
                <div className={classes.left_menu_scroll}>
                  {isLoading ? (
                    <Center mt={10}>
                      <Loader variant="bars" color="#D2E951" />
                    </Center>
                  ) : newData?.length > 0 ? (
                    <>
                      <div
                        ref={(el) => {
                          divRef.current = el;
                          ref(el);
                        }}
                        className="scroll_area"
                        style={{ height: "100%" }}
                      >
                        {groupActive ? (
                          <Flex p={20} direction="column">
                            <Title fz={20} color="white">
                              {translation.event_page.event_members}
                            </Title>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {newMembersData
                                ?.sort(
                                  (a: any, b: any) => b.isOnline - a.isOnline
                                )
                                ?.map((x: any, i: number) => (
                                  <Flex
                                    key={i}
                                    direction="row"
                                    align="center"
                                    justify="space-between"
                                    mt={26}
                                  >
                                    <Flex align="center">
                                      <Avatar
                                        w={60}
                                        opacity={x.isOnline ? 1 : 0.5}
                                        h={60}
                                        src={x?.avatar?.thumbs?.c144}
                                      />
                                      <Flex
                                        opacity={x.isOnline ? 1 : 0.5}
                                        direction="column"
                                      >
                                        <Text
                                          fz={15}
                                          c="white"
                                          fw="700"
                                          ml={10}
                                        >
                                          {x?.username}
                                        </Text>
                                        {x.isCreator && (
                                          <Text
                                            fz={10}
                                            c="white"
                                            fw="200"
                                            ml={10}
                                          >
                                            isCreator
                                          </Text>
                                        )}
                                      </Flex>
                                    </Flex>
                                    <Menu
                                      styles={{
                                        dropdown: {
                                          border: "0px",
                                          backgroundColor:
                                            "rgba(77, 116, 254, 1)",
                                          borderRadius: 18,
                                        },
                                        arrow: {
                                          backgroundColor:
                                            "rgba(77, 116, 254, 1)",
                                          border: 0,
                                          left:
                                            width > 768
                                              ? "-4px !important"
                                              : "auto",
                                          right:
                                            width <= 768
                                              ? "-4px !important"
                                              : undefined,
                                        },
                                      }}
                                      position="right"
                                      withArrow
                                      offset={5}
                                      shadow="md"
                                    >
                                      <Menu.Target>
                                        <ActionIcon
                                          disabled={loadingUpdate}
                                          variant="transparent"
                                        >
                                          <TbDots color="white" />
                                        </ActionIcon>
                                      </Menu.Target>
                                      <Menu.Dropdown>
                                        <Menu.Item
                                          style={{ padding: "5px 10px" }}
                                          onClick={() => removeFromEvent(x.id)}
                                          color="white"
                                        >
                                          <Text color="white" fz={14} fw={700}>
                                            {translation.event_page.remove_user}
                                          </Text>
                                        </Menu.Item>
                                        <Divider opacity={0.5} />
                                        <Menu.Item
                                          style={{ padding: "5px 10px" }}
                                          onClick={() => banFromEvent(x.id)}
                                          color="white"
                                        >
                                          <Text color="white" fz={14} fw={700}>
                                            {translation.event_page.ban_user}
                                          </Text>
                                        </Menu.Item>
                                      </Menu.Dropdown>
                                    </Menu>
                                  </Flex>
                                ))}
                            </div>
                          </Flex>
                        ) : (
                          newData
                            ?.sort((a: any, b: any) => {
                              if (
                                !a?.attributes?.lastMessage &&
                                !b?.attributes?.lastMessage
                              ) {
                                return 0; // both a and b don't have lastMessage, they are equal
                              } else if (!a?.attributes?.lastMessage) {
                                return 1; // a doesn't have lastMessage, it should come after
                              } else if (!b?.attributes?.lastMessage) {
                                return -1; // b doesn't have lastMessage, it should come after
                              } else {
                                const dateA: any = new Date(
                                  a?.attributes?.lastMessage?.messageSentTime
                                );
                                const dateB: any = new Date(
                                  b?.attributes?.lastMessage?.messageSentTime
                                );
                                return dateB - dateA; // both have lastMessage, compare them
                              }
                            })
                            .map((x: any, i: number) => (
                              <Link
                                to={`/my/events/${x.id}`}
                                key={i}
                                style={{
                                  backgroundColor: "transparent",
                                }}
                                className={classes.creator_area}
                              >
                                <Avatar
                                  size={63}
                                  src={x?.avatar?.sizes?.original}
                                  radius={14}
                                />
                                <div className={classes.creator_info}>
                                  <Title color="white" fz={16}>
                                    {x?.custom_title
                                      ? x?.title
                                      : x?.title +
                                        translation.chat_page.chat_event}
                                  </Title>
                                  <Text
                                    w={"85%"}
                                    lineClamp={1}
                                    color="#A1A2A9"
                                    fz={12}
                                  >
                                    {x?.attributes?.lastMessage?.contentType ===
                                    "audio"
                                      ? "audio"
                                      : x?.attributes?.lastMessage
                                      ? x?.attributes?.lastMessage?.text
                                      : translation.chat_page
                                          .not_have_message_send_new_message}
                                  </Text>
                                </div>
                                <div className={classes.message_info_area}>
                                  {x?.attributes?.lastMessage && (
                                    <Text className={classes.timer} fz={12}>
                                      {passingTime(
                                        new Date(
                                          x?.attributes?.lastMessage?.createdAt
                                        ),
                                        translation
                                      )}
                                    </Text>
                                  )}
                                </div>
                              </Link>
                            ))
                        )}
                      </div>
                    </>
                  ) : (
                    <Center mt={10}>
                      <Text fw={600} color="white">
                        Nothing found
                      </Text>
                    </Center>
                  )}
                </div>
              </div>
            )}
          {(!openMemberArea || width > 768) && (
            <div
              className={`${classes.right_menu} 
        ${classes.group_area}
        ${id !== undefined && classes.right_menu_select_creator}`}
            >
              {id === undefined ? (
                <Center mt={50}>
                  <Text w="50%" fz={24} align="center" fw={600} color="white">
                    {translation.chat_page.please_select_chat}
                  </Text>
                </Center>
              ) : (
                <EventRightArea
                  user={user}
                  openChat={openChat}
                  translation={translation}
                  creatorData={data}
                  joinState={joinState}
                  data={eventData}
                  openVoice={openVoice}
                  handleJoinAsHost={handleJoinAsHost}
                  setOpenVoice={setOpenVoice}
                  openMemberArea={openMemberArea}
                  isMuted={isMuted}
                  mute={mute}
                  unmute={unmute}
                  connecting={connecting}
                />
              )}
            </div>
          )}
          {width < 768 && (
            <div
              onClick={() => setMobileFooterBigger(!mobileMenuBigger)}
              style={{ cursor: "pointer" }}
              className={`${classes.mobile_footer_area} ${
                !mobileMenuBigger && classes.mobile_footer_chat_area
              }`}
            >
              <div
                className={`${classes.mobile_footer}  ${
                  !mobileMenuBigger && classes.mobile_footer_chat
                }`}
              >
                <ActionIcon
                  style={{
                    backgroundColor: openVoice
                      ? "rgba(77, 116, 254, 1)"
                      : "rgba(255, 255, 255, 0.3)",
                  }}
                  onClick={() => {
                    setOpenVoice(true);
                    setOpenMemberArea(false);
                    setOpenChat(false);
                    setMobileFooterBigger(false);
                  }}
                  radius={50}
                  size={!mobileMenuBigger ? 10 : 49}
                >
                  <VoiceIcon active={mobileMenuBigger} />
                </ActionIcon>
                <ActionIcon
                  style={{
                    backgroundColor: openChat
                      ? "rgba(77, 116, 254, 1)"
                      : "rgba(255, 255, 255, 0.3)",
                  }}
                  onClick={(e: any) => {
                    setOpenVoice(false);
                    setOpenMemberArea(false);
                    setOpenChat(true);
                    setMobileFooterBigger(false);
                    e.stopPropagination();
                  }}
                  radius={50}
                  size={!mobileMenuBigger ? 10 : 49}
                >
                  <MessagesIcon active={mobileMenuBigger} />
                </ActionIcon>
                {eventData?.results?.isOwner && (
                  <ActionIcon
                    onClick={(e: any) => {
                      setOpenMemberArea(true);
                      setOpenChat(false);
                      setOpenVoice(false);
                      setMobileFooterBigger(false);
                    }}
                    style={{
                      backgroundColor: openMemberArea
                        ? "rgba(77, 116, 254, 1)"
                        : "rgba(255, 255, 255, 0.3)",
                    }}
                    radius={50}
                    size={!mobileMenuBigger ? 10 : 49}
                  >
                    <MembersIcon active={mobileMenuBigger} />
                  </ActionIcon>
                )}
              </div>
            </div>
          )}
        </Flex>{" "}
      </Flex>
    </Layout>
  );
};
const useStyles = createStyles((theme, { height }: any) => ({
  left_menu: {
    height: "100%",
    width: "45%",
    backgroundColor: "rgba(0,0,0,.05)",
    [`@media (max-width: 768px)`]: {
      width: "100%",
      marginTop: 0,
    },
  },
  search: {
    backgroundColor: "rgba(181, 181, 181, 0.2);",
    borderRadius: 40,
    border: 0,
    color: "white",
  },
  search_area: {
    margin: "30px 20px",
    [`@media (max-width: 768px)`]: {
      margin: " 20px 25px 20px 25px",
    },
  },
  timer: {
    width: "100%",
    textAlign: "center",
    color: theme.colors.grey[0],
  },
  notification: {
    backgroundColor: theme.colors.blue[0],
    color: "white",
    width: 27,
    height: 27,
    display: "flex",
    borderRadius: 6,
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    marginRight: 10,
  },
  notification_media: {
    backgroundColor: `#B65AFF !important `,
  },
  notification_length_media: {
    backgroundColor: `#B65AFF !important `,
  },
  notification_length: {
    position: "absolute",
    right: -7,
    top: -9,
    width: 18,
    height: 18,
    backgroundColor: theme.colors.blue[0],
    border: "1px solid #3C55AE",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  creator_area: {
    padding: "20px 0px 20px 20px",
    width: "100%",
    display: "flex",
    textDecoration: "none",
    cursor: "pointer",
    [`@media (max-width: 768px)`]: {
      padding: "20px 25px 20px 25px",
    },
  },
  back_button: {
    display: "none",
    [`@media (max-width: 500px)`]: {
      display: "block",
    },
  },
  back_button_text: {
    marginLeft: 0,
    [`@media (max-width: 500px)`]: {
      marginLeft: 44,
    },
  },
  creator_info: {
    marginLeft: 12,
    width: "60%",
  },
  avatar: {
    width: 60,
    height: 60,
    backgroundColor: theme.colors.grey,
  },
  message_info_area: {
    width: "30%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  left_menu_scroll: {
    height: "calc(100vh - 149px)",
    overflow: "hidden",
    [`@media (max-width: 768px)`]: {
      paddingBottom: "56px",
      height: "calc(100vh - 76px)",
    },
  },
  right_menu: {
    width: "60%",
    height: "100%",
    [`@media (max-width: 768px)`]: {
      width: "0%",
      display: "none",
    },
  },
  right_menu_select_creator: {
    width: "100%",
    [`@media (max-width: 768px)`]: {
      width: "100vw !important",
      display: "flex !important",
      flexDirection: "column",
      marginTop: 0,
    },
  },
  left_menu_select_creator: {
    [`@media (max-width: 768px)`]: {
      width: "0vw",
      display: "none",
    },
  },
  careator_info_area: {
    width: "100%",
    padding: "25px 30px",
    borderBottom: "1px solid rgba(98, 98, 98, 0.44)",
    [`@media (max-width: 768px)`]: {
      padding: "25px 30px 15px 30px",
    },
  },
  sounds_area: {
    overflowY: "scroll",
    marginTop: 20,
    padding: "0px 30px",
    height: "calc(100% - 162px)",
    [`@media (max-width: 768px)`]: {
      margin: "0px 0px",
      marginTop: 0,
      paddingTop: 20,
    },
  },
  avatar_area: {
    width: 65,
    height: 65,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "linear-gradient(132.14deg, #D2E951 9.16%, #4D74FE 92.2%)",
    borderRadius: 14,
  },
  header_mobile: {
    marginLeft: 20,
    display: "none",
    [`@media (max-width: 768px)`]: {
      display: "flex",
      alignItems: "center",
    },
  },
  left_menu_area: {
    position: "relative",
    [`@media (max-width: 768px)`]: {
      height: height,
      overflowX: "hidden",
    },
  },
  group_area: {
    width: "100%",
  },
  mobile_footer_area: {
    position: "absolute",
    bottom: 12,
    width: "100%",
    height: 62,
    zIndex: 99999,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all .5s",
  },
  mobile_footer: {
    width: "90%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    borderRadius: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 13,
    paddingRight: 13,
    transition: "all .5s",
  },
  mobile_footer_chat_area: {
    transition: "all .5s",
    height: 20,
    bottom: 4,
  },
  mobile_footer_chat: {
    width: "40% !important",
    bottom: "4px !important",
    transition: "all .5s",
  },
  modal_body: {
    backgroundColor: theme.colors.blue[0],
    padding: "45px",
    [`@media (max-width: 500px)`]: {
      backgroundColor: "transparent",
      padding: "0px 0px",
    },
  },
  modal_title: {
    position: "sticky",
    top: 0,
    zIndex: 99,
    backgroundColor: theme.colors.blue[0],
    [`@media (max-width: 500px)`]: {
      position: "relative",
      backgroundColor: "transparent",
    },
  },
  name: {
    marginBottom: 0,
    [`@media (max-width: 500px)`]: {
      marginBottom: 8,
    },
  },
  logo: {
    height: "100dvh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default EventsPage;

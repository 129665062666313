export const passingTime = (createAt: any, translation: any) => {
  var nowDate: any = new Date();
  var time = nowDate - createAt;
  var millisecondsInSecond = 1000;
  var millisecondsInMinute = millisecondsInSecond * 60;
  var millisecondsInHour = millisecondsInMinute * 60;

  var seconds = Math.floor(
    (time % millisecondsInMinute) / millisecondsInSecond
  );
  var minutes = Math.floor((time % millisecondsInHour) / millisecondsInMinute);
  var hours = Math.floor(time / millisecondsInHour);
  var days = Math.floor(time / (1000 * 60 * 60 * 24));
  var week = Math.floor(days / 7);

  if (minutes < 1) {
    return seconds === 0
      ? "Now"
      : seconds + " " + translation.chat_page.times.second;
  }
  if (minutes >= 1 && hours < 1 && minutes < 60) {
    return minutes + " " + translation.chat_page.times.minute;
  }
  if (hours < 24 && hours > 0) {
    return hours + " " + translation.chat_page.times.hour;
  }
  if (hours >= 24 && days <= 7) {
    return days + " " + translation.chat_page.times.day;
  } else {
    return week + " " + translation.chat_page.times.week;
  }
};

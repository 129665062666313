import { useQuery } from "@tanstack/react-query";
import { getUserFn } from "../api";

export const useGetUser = () => {
  const { data, isLoading, isSuccess,isError, error } = useQuery({
    queryKey: ["user"],
    queryFn: () => getUserFn(),
    staleTime: 3600000,
  });

  return { data, isLoading,isSuccess, isError, error };
};

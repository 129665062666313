import { useAppDispatch, useAppSelector } from "../store/hooks";
import { AppDispatch, RootState } from "../store/store";
import {
  setUsernameCheck,
  setUsernameSuggestCheck,
} from "../store/reducers/creators.reducer";
import restClient from "../api/restClient";
import { getToken } from "../utils/auth/auth";
export const Creator = () => {
  const dispatch: AppDispatch = useAppDispatch();

  const usernameCheck: any = useAppSelector(
    (state: RootState) => state.creators.usernameCheck
  );
  const usernameSuggestCheck: any = useAppSelector(
    (state: RootState) => state.creators.usernameSuggestCheck
  );


  const uploadFilePercentage: any = useAppSelector(
    (state: RootState) => state.creators.uploadFilePercentage
  );
  const loading: any = useAppSelector(
    (state: RootState) => state.creators.loading
  );

 


  const getCreatorUsernameControl = async (username: string) => {
    const token = await getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      await restClient
        .get(`/user-check/` + username, config)
        .then(function (response) {
          dispatch(setUsernameCheck(response.data));
        })
        .catch(function (error) {
          console.log("error", error);
        });
    } catch (error) {
      console.log("error", error);
    }
  };
  const getCreatorUsernameSuggestControl = async (username: string) => {
    const token = await getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      await restClient
        .get(`/user-check/` + username, config)
        .then(function (response) {
          dispatch(setUsernameSuggestCheck(response.data));
        })
        .catch(function (error) {
          console.log("error", error);
        });
    } catch (error) {
      console.log("error", error);
    }
  };



  return {
    getCreatorUsernameControl,
    getCreatorUsernameSuggestControl,
    uploadFilePercentage,
    usernameCheck,
    usernameSuggestCheck,
    loading,
 
  };
};

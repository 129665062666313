import {
  Text,
  Title,
  createStyles,
  Avatar,
  Flex,
} from "@mantine/core";
import { NavLink } from "react-router-dom";
import WaveAudioFeed from "../waveAudio/WaveAudio";
import { ICreatorProfile } from "../../../interfaces/creator-profile.interface";
import { useViewportSize } from '@mantine/hooks';
const CreatorAudioCard = ({ creator }: { creator: ICreatorProfile }) => {
  const { classes } = useStyles();
  const {  width } = useViewportSize();

return (
    <>
      <Flex direction="column" >
        <div className={classes.card_box}>
          <Flex direction="row" justify="space-between" mb={10}>
            <div
              className={classes.creator_profile}
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                border: 0,
                textDecoration: "none",
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
            >
              <NavLink to={`/${creator.attributes?.username}`}>
                <Avatar
                  size={184}
                  variant="outline"
                  radius={14}
                  classNames={{ root: classes.avatar }}
                  src={width < 768 ? creator.attributes?.avatarThumbs?.thumbs?.c50 : creator.attributes?.avatarThumbs?.thumbs?.c250}
                  alt={creator.attributes?.username}
                />
              </NavLink>
              <Flex direction="column" className={classes.creator_name_area}>
                <NavLink
                  className={classes.navLink}
                  to={`/${creator.attributes?.username}`}
                >
                  <Title size={17}>{creator?.attributes?.name}</Title>
                  <Text className={classes.username} size={13} ta="left">
                    {"@" + creator.attributes?.username}
                  </Text>
                </NavLink>
                <Flex
                  className={classes.creator_content_area}
                  direction="column"
                >
                  <WaveAudioFeed
                    id={creator.id}
                    url={require("../../../assets/test.mp3")}
                  />
                </Flex>
              </Flex>
            </div>
          </Flex>
          <Flex
            className={classes.creator_content_area_mobile}
            direction="column"
            justify="space-between"
          >
            <WaveAudioFeed
              id={creator.id}
              url={require("../../../assets/test.mp3")}
            />
          </Flex>
        </div>
      </Flex>
    </>
  );
};
const useStyles = createStyles((theme) => ({
  card_box: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    margin: "5px 0px",
    color: "white",
    backdropFilter: "blur(20px)",
    zIndex: 9,
    borderRadius: 20,
  },
  creator_profile: {
    margin: "13px 18px 0px 18px",
    color: "white",
  },
  creator_name_area: {
    marginLeft: 20,
    width: "100%",
    height: "100%",
    justifyContent: "space-arround",
    display: "flex",
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      display: "flex",
      justifyContent:"center"
    },
  },
  username: {
    color: "#C9C9C9",
  },
  creator_content_area: {
    height: "75%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      display: "none",
    },
  },
  avatar: {
    height: 184,
    width:184,
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      height: 62,
      width:62,
      minWidth:62,
      borderRadius:"50%"
    },
  },
  navLink: {
    color: "white",
    textDecoration: "none",
  },
  creator_content_area_mobile: {
    display: "none !important",
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      display: "flex !important",
      margin: "7px 22px",
    },
  },
}));
export default CreatorAudioCard;

import { Button, Center, Image, Text, Title, createStyles } from "@mantine/core";
import {  useLocalStorage } from "@mantine/hooks";
import React, { useEffect, useState } from "react";

const CookiePermission = ({ translation }: any) => {
  const { classes } = useStyles();
  const [cookie, setCookie] = useLocalStorage({ key: 'acceptCookie', defaultValue: false });
  const [show, setShow] = useState(false);

  const acceptCookie = () => {
    setCookie(true)
  }


  useEffect(() => {
    if (cookie === false) {
      setShow(true)
    }
  }, [cookie])
  if (cookie) {
    return null
  }

  return (
    <>
      {show &&
        <div className={classes.main_area}>
          <Center>
            <Image width={90} src={require("../../../assets/logo.png")} />
          </Center>
          <Title align="center" className={classes.title} color="white">{translation.cookie_modal.title}</Title>
          <Center>
            <Text align="center" className={classes.text} color="#838383">
              {translation.cookie_modal.text}
            </Text>

          </Center>
          <Center>
            <Text align="center" className={classes.text} color="#838383">
              <a href="/privacy" style={{ color: "white" }}> Datenschutzseite </a>
            </Text>
          </Center>
          <Center>
            <Button onClick={acceptCookie} variant="outline" color="lime"> {translation.cookie_modal.button_text}</Button>
          </Center>
        </div>
      }
    </>
  );
};
const useStyles = createStyles((theme) => ({
  main_area: {
    width: "100vw",
    padding: "20px 0px",
    position: "fixed",
    zIndex: 99999,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,.8)",
  },
  title: {
    [`@media (max-width: 768px)`]: {
      fontSize: 17
    },
  },
  text: {
    width: "50%",
    [`@media (max-width: 768px)`]: {
      width: "80%",
      fontSize: 13
    },
  }
}));
export default CookiePermission;

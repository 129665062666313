import React, { useEffect, useRef, useState } from "react";
import Layout from "../../components/high-level/layout/layout";
import { useGetPost, useUpdatePost } from "../../api/creator/service";
import { useNavigate, useParams } from "react-router-dom";
import {
  ActionIcon,
  Avatar,
  Button,
  Flex,
  Image,
  Loader,
  Modal,
  Text,
  Textarea,
  createStyles,
} from "@mantine/core";
import {
  IoArrowBackSharp,
  IoClose,
  IoEyeOffOutline,
  IoEyeOutline,
} from "react-icons/io5";
import { User } from "../../hooks/user.hook";
import WaveAudio from "../../components/low-level/waveAudio/WaveAudio";
import { TbTrash } from "react-icons/tb";
import { useDisclosure } from "@mantine/hooks";

const PostEditPage = ({ translation }: any) => {
  const navigate = useNavigate();
  let { id }: any = useParams();
  const [duration, setDuration] = useState(0);
  const { classes } = useStyles();
  const [voiceAbout, setVoiceAbout] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const inputRef: any = useRef(null);
  const { user } = User();
  const { data, isLoading }: any = useGetPost(id);
  const { mutateUpdatePost, updateLoading }: any = useUpdatePost(
    user?.attributes?.creatorProfile?.username
  );
  const [price, setPrice] = useState<any>(1);
  const [postPublicModal, { open, close }] = useDisclosure(false);

  const prices = [
    {
      value: 1,
    },
    {
      value: 10,
    },
    {
      value: 15,
    },
    {
      value: 20,
    },
  ];

  useEffect(() => {
    if (data !== undefined) {
      setVoiceAbout(data.attributes.text);
      setPrice(data.attributes.price);
      setDuration(data.attributes.assetData.duration);
    }
  }, [data]);
  useEffect(() => {
    if (
      data?.attributes.text === voiceAbout &&
      data?.attributes.price === price
    ) {
      setIsButtonDisabled(true); // Veri istenen durumdaysa butonu aktif yap
    } else {
      setIsButtonDisabled(false); // Değilse butonu devre dışı bırak
    }
    if (voiceAbout.length < 2) {
      setError(translation.new_post_page.enter_desc);
    } else {
      setError("");
    }
  }, [voiceAbout, price, data, translation]);

  if (isLoading || data === undefined) {
    return (
      <Layout>
        <Flex
          align="center"
          justify="center"
          style={{ width: "100%", height: "100vh" }}
        >
          <Loader variant="bars" color="#D2E951" />
        </Flex>
      </Layout>
    );
  }
  const onChangeText = (e: any) => {
    setVoiceAbout(e.target.value);
  };
  const postCreateCreator = async () => {
    const newData = {
      text: voiceAbout,
      price: price,
    };
    await mutateUpdatePost({ id, data: newData });
  };

  const postPublicFn = async () => {
    const newData = {
      isPublic: !data?.attributes?.isPublic,
    };
    await mutateUpdatePost({ id, data: newData });
  };

  const PostPublicModal = () => {
    return (
      <Modal
        classNames={{
          body: classes.modal_body,
          inner: classes.modal_inner,
        }}
        overlayProps={{
          opacity: 0.75,
          blur: 5,
        }}
        centered
        size={600}
        zIndex={999}
        withCloseButton={false}
        opened={postPublicModal}
        onClose={close}
      >
        <button onClick={close} className={classes.close}>
          <IoClose color="white" size={25} />
        </button>
        <Text align="center" fz={20} mt={20} mb={7} color="white">
          {data?.attributes?.isPublic
            ? translation.edit_post_page.edit_hidden_post
            : translation.edit_post_page.edit_show_post}
        </Text>
        <Flex mt={20} gap={10} w="100%" justify="center" align="center">
          <Button
            onClick={close}
            style={{ backgroundColor: "green" }}
            w="48%"
            disabled={updateLoading}
            variant="filled"
          >
            {translation.no}
          </Button>
          <Button
            disabled={updateLoading}
            onClick={postPublicFn}
            color="red"
            w="48%"
          >
            {translation.yes}
          </Button>
        </Flex>
      </Modal>
    );
  };
  return (
    <Layout
      headerFixed={false}
      mobileHeaderFixed={false}
      mobileHeader={false}
      header={false}
      hiddenFooter
      headerRightIcon={<Text>selam</Text>}
    >
      <PostPublicModal />
      <Flex className={classes.main} direction="column" gap="sm">
        <div className={classes.mobile_header}>
          <div style={{ width: "82px" }}>
            <Button style={{ paddingLeft: 0 }} onClick={() => navigate(-1)}>
              <IoArrowBackSharp size={25} />
            </Button>
          </div>
          <div>
            <Text color="white" fw={600}>
              {translation.edit_post_page.edit_post}
            </Text>
          </div>
          <div
            style={{
              width: "82px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ActionIcon
              variant="transparent"
              onClick={open}
              size={30}
              color="white"
            >
              {data?.attributes?.isPublic ? (
                <IoEyeOutline color="white" size={30} />
              ) : (
                <IoEyeOffOutline color="white" size={30} />
              )}
            </ActionIcon>
          </div>
        </div>
        <div className={classes.container_box}>
          {(isLoading || updateLoading) && (
            <div style={{ position: "absolute", top: "50%", zIndex: 9999 }}>
              <Loader size={50} variant="bars" color="rgb(200,232,64)" />
            </div>
          )}
          <div
            className={classes.container}
            style={{
              border:
                error.length > 1 || voiceAbout.length < 2
                  ? "1px solid red"
                  : "1px solid transparent",
              justifyContent: "flex-start",
              opacity: (isLoading || updateLoading) && 0.5,
            }}
          >
            <Textarea
              style={{
                display: "block",
              }}
              minRows={6}
              readOnly={isLoading || updateLoading}
              classNames={{
                input: classes.input,
              }}
              onChange={onChangeText}
              ref={inputRef}
              value={voiceAbout}
              onClick={(e) => e.preventDefault()}
              placeholder={translation.new_post_page.audio_about}
            />
            <div className={classes.voice_area_container}>
              <div className={classes.voice_area}>
                <div className={classes.voice_area_user_image}>
                  <Avatar
                    mr={20}
                    radius={24}
                    size={127}
                    classNames={{ root: classes.voice_avatar }}
                    src={
                      user?.attributes?.creatorProfile?.avatarThumbs?.thumbs
                        ?.c144
                    }
                  />
                </div>

                <WaveAudio
                  url={data?.attributes?.assets?.audio?.url}
                  duration={duration}
                  peaks={data?.attributes?.assetData?.peaks}
                  setDuration={setDuration}
                />
              </div>
            </div>
          </div>
          {(error?.length > 1 || voiceAbout.length < 2) && (
            <Text align="left" w="90%" fw={500} fz={16} mt={5} color="red">
              {error.length > 1
                ? error
                : translation.new_post_page.enter_explanation}
            </Text>
          )}
        </div>
        <Flex
          justify="space-between"
          align="center"
          w="100%"
          mt={16}
          className={classes.price_container}
        >
          <Flex w="100%" className={classes.price_main_area} direction="column">
            <Text
              color="white"
              className={classes.price_main_area_tite}
              fz={16}
              fw={600}
            >
              {translation.price}
            </Text>
            <Flex className={classes.price_buttons_area} direction="row">
              {prices.map((x: any, i: number) => (
                <Button
                  onClick={() => setPrice(x.value)}
                  className={classes.select_price_button}
                  disabled={isLoading || updateLoading}
                  style={{
                    opacity: (isLoading || updateLoading) && 0.5,
                    backgroundColor:
                      x.value === price ? "#D2E951" : "rgba(210, 233, 81, 0.2)",
                    color: x.value === price ? "black" : "white",
                  }}
                  key={i}
                >
                  <Image mr={5} src={require("../../assets/amount.png")} />
                  {"$" + x.value}
                </Button>
              ))}
            </Flex>
          </Flex>
          <Flex
            className={classes.create_button_area}
            justify="right"
            align="center"
          >
            <Button
              style={{
                display: "block",
                opacity: (isLoading || updateLoading) && 0.5,
              }}
              onClick={
                isLoading || voiceAbout.length < 2
                  ? undefined
                  : postCreateCreator
              }
              className={classes.create_button}
              disabled={
                isLoading ||
                updateLoading ||
                voiceAbout.length < 2 ||
                isButtonDisabled
              }
            >
              {isLoading ? (
                <Loader color="black" />
              ) : (
                <Image
                  height={50}
                  fit="contain"
                  src={require("../../assets/new-post/create.png")}
                />
              )}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Layout>
  );
};
const useStyles = createStyles((theme) => ({
  input: {
    border: "0px",
    cursor: "text",
    backgroundColor: "transparent",
    color: "white",
    [`@media (max-width: 500px)`]: {
      height: 150,
    },
  },
  main: {
    [`@media (max-width: 768px)`]: {
      marginTop: "0px !important",
    },
  },
  container_box: {
    width: "100%",
    display: "flex",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  container: {
    backgroundColor: " rgba(181, 181, 181, 0.2)",
    width: "100%",
    position: "relative",
    // height: 367,
    borderRadius: 40,
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    [`@media (max-width: 768px)`]: {
      backgroundColor: "transparent",
    },
    [`@media (max-width: 500px)`]: {
      width: "100%",
      height: "auto",
      padding: "0px 20px 20px 20px",
    },
  },
  mobile_header: {
    height: 50,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 20,
    marginTop: 10,
    zIndex: 999,
  },
  voice_area_container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  voice_avatar: {
    width: 127,
    height: 127,
    [`@media (max-width: 500px)`]: {
      width: "100%",
      height: "100%",
      minWidth: "100%",
    },
  },
  voice_area_user_image: {
    width: 179,
    marginRight: 20,
    [`@media (max-width: 500px)`]: {
      width: 70,
      height: 70,
      marginRight: 0,
    },
  },
  voice_area: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    padding: 15,
    width: "85%",
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    position: "relative",
    [`@media (max-width: 500px)`]: {
      flexDirection: "column",
      width: "95%",
      padding: 5,
    },
  },
  price_container: {
    marginBottom: 24,
    [`@media (max-width: 500px)`]: {
      flexDirection: "column",
      width: "100%",
      padding: "0px 20px",
      marginBottom: 0,
    },
  },
  select_price_button: {
    width: 83,
    borderRadius: 60,
    height: 43,
    marginLeft: ".5vw",
    marginRight: ".5vw",
    ":first-of-type": {
      marginRight: ".5vw",
      marginLeft: 0,
    },
    [`@media (max-width: 500px)`]: {
      marginRight: "0vw",
      marginLeft: "0vw",
      width: 83,
      height: 33,
    },
  },
  price_main_area: {
    margin: "0px 20px",
  },
  price_main_area_tite: {
    marginBottom: 13,
    [`@media (max-width: 500px)`]: {
      marginBottom: 3,
    },
  },
  price_buttons_area: {
    [`@media (max-width: 500px)`]: {
      justifyContent: "space-between",
      width: "100%",
    },
  },
  create_button_area: {
    [`@media (max-width: 500px)`]: {
      width: "100%",
      marginTop: 10,
    },
  },
  create_button: {
    width: 60,
    height: 60,
    borderRadius: "50%",
    backgroundColor: theme.colors.green,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  progress_bar: {
    marginBottom: 30,
    margin: "0px 20px",
  },
  modal_body: {
    backgroundColor: theme.colors.blue[0],
    padding: "45px",
    [`@media (max-width: 500px)`]: {
      backgroundColor: "transparent",
      padding: "0px 0px",
    },
  },
  modal_inner: {
    [`@media (max-width: 500px)`]: {
      padding: 0,
      alignItems: "flex-end",
    },
  },
  close: {
    position: "absolute",
    zIndex: 999,
    right: 30,
    top: 30,
    backgroundColor: "transparent",
    border: "0px",
    padding: 0,
    cursor: "pointer",
  },
}));
export default PostEditPage;
